import React, { useEffect, useState } from 'react';
import SubmissionFlowHeader from '../../components/SubmissionFlowHeader/SubmissionFlowHeader';
import { HeaderBreadcrumb, Link } from '@components/SubmissionFlowHeader/styles';
import * as Styled from './styles';
import SubmissionFlowFooter from '@components/SubmissionFlowFooter/SubmissionFlowFooter';
import { useParams } from 'react-router';
import { StyledSVG } from '@common/styles';
import { useFetchHtml } from '@common/hooks/useFetchHtml';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { StyledLink } from '@common/components/Link/styles';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import { Html } from './Html';
import { useFetchTasks } from '@common/hooks/useFetchTasks';
import { SubmissionTaskType } from '@common/types';

const staleTime = 10 * 1000 * 60;

const { ePILNew, ePILUpdate } = SubmissionTaskType;

interface Props {
  onContinue: () => void;
}

export const ViewHtml = ({ onContinue }: Props) => {
  const [html, setHtml] = useState('');

  const { taskId = '', submissionId = '' } = useParams();
  const { submission } = useFetchSubmission(submissionId, staleTime);
  const { tasks } = useFetchTasks({ submissionId, staleTime, skipCompany: true });
  const submissionTask = tasks.find((t) => t.submissionTaskId.toString() === taskId);
  const originalTaskId = tasks.find(
    (t) => t.documentId === submissionTask?.documentId && ![ePILNew, ePILUpdate].includes(t.type),
  )?.submissionTaskId;

  const document = submission?.documents.find((d) => d.documentId === submissionTask?.documentId);

  const { htmlData } = useFetchHtml();
  const { downloadAndOpen } = useDownloadAndOpenFile();

  useEffect(() => {
    setHtml(htmlData);
  }, [htmlData]);

  const submissionTaskName = `${submissionId}/${taskId}_${submission?.productGroup?.productGroupName?.replaceAll(' ', '_')}`;

  const handleFileClick = () => {
    downloadAndOpen({ submissionId, taskId: originalTaskId });
  };

  return (
    <Styled.Wrapper>
      <SubmissionFlowHeader title="View html" subTitle={document?.documentTitle}>
        <HeaderBreadcrumb>
          <Link> Submissions</Link> &gt;
          <Link> Submission details</Link> &gt;
          <Link> {submissionTaskName}</Link> &gt;
          <Link> View html</Link>
        </HeaderBreadcrumb>
      </SubmissionFlowHeader>
      <Styled.HorizontalDivider />
      <Styled.FullPagePopupBackground>
        <Styled.FullPageTabs>
          <Styled.SectionHeader>
            <StyledSVG src="/icons/html.svg" />
            <StyledLink onClick={handleFileClick} to="#">
              {document?.originalFileName}
            </StyledLink>
          </Styled.SectionHeader>
          <Styled.SectionBody>
            <Html html={html} docType={submissionTask?.type} />
          </Styled.SectionBody>
        </Styled.FullPageTabs>
      </Styled.FullPagePopupBackground>
      <SubmissionFlowFooter onContinue={onContinue} continueText="Continue" />
    </Styled.Wrapper>
  );
};
