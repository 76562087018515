import React from 'react';
import * as Styled from './styles';
import { StyledSVG } from '@common/styles';

export interface ISelectedActiveIngredient {
  index: number;
  name: string;
  isListed?: boolean;
  onDeleteClick: (index: number) => void;
}

export const SelectedActiveIngredient = ({ index, name, isListed, onDeleteClick }: ISelectedActiveIngredient) => {
  const handleRemoveClick = () => {
    onDeleteClick(index);
  };

  return (
    <Styled.SelectedActiveIngredientWrap>
      <Styled.SelectedActiveIngredientName isListed={isListed} data-testid={`${index}-active-ingredient`}>
        {name}
      </Styled.SelectedActiveIngredientName>
      <Styled.SelectedActiveIngredientRemoveButton data-testid="remove-active-ingredient" onClick={handleRemoveClick}>
        <StyledSVG src={`/icons/trash_bin.svg`} />
      </Styled.SelectedActiveIngredientRemoveButton>
    </Styled.SelectedActiveIngredientWrap>
  );
};
