import { Textarea } from '@common/components/Textarea/Textarea';
import { ISubmissionReasonDto } from '@common/features/submission/types';

interface IReadOnlySubmissionReason {
  reasonsForSubmission: ISubmissionReasonDto[];
}

export const ReadOnlySubmissionReason = ({ reasonsForSubmission }: IReadOnlySubmissionReason) => {
  const text = reasonsForSubmission && reasonsForSubmission[0] && (reasonsForSubmission[0].text as string);

  return (
    <>
      <Textarea value={text} disabled={true} onChange={(e) => {}} />
    </>
  );
};
