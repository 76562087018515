import { createSelector } from '@reduxjs/toolkit';
import { IProductGroupState } from './types';
import { RootState } from '@common/store';

const selectProductGroupState = (state: RootState) => state.productGroup;

export const selectProductGroup = createSelector(
  selectProductGroupState,
  (state: IProductGroupState) =>
    state.productGroupData ?? {
      id: null,
      productType: '',
      name: '',
    },
);
