import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { fetchCompanyProducts } from '@common/services/productService';
import { IPublishedProductDto, IPublishedProductsDto } from '@common/features/product/types';
import { useEffect, useState } from 'react';

export const queryKey = 'fetchCompanyProducts';

const validateElements = (destination: IPublishedProductDto[], newItems: IPublishedProductDto[]): boolean => {
  return destination.every((x) => !newItems.some((y) => y.id === x.id));
};

export const useFetchCompanyProducts = (
  companyId: number,
  filtersQueryString: string,
  searchString?: string,
  orderBy?: string,
  limit?: number,
  offset?: number,
  resetRecords?: boolean,
) => {
  const token = useAppSelector(selectToken);
  const [records, setRecords] = useState<IPublishedProductDto[]>([]);

  const queryString = () => {
    var queryParts = [];

    if (searchString) {
      queryParts.push(`searchTerm=${searchString}`);
    }

    if (filtersQueryString) {
      queryParts.push(filtersQueryString);
    }

    if (orderBy) {
      queryParts.push(`orderBy=${orderBy}`);
    }

    if (limit) {
      queryParts.push(`limit=${limit}`);
    }

    if (offset) {
      queryParts.push(`offset=${offset}`);
    }

    return queryParts.join('&');
  };

  const {
    data = null,
    isFetching: isFetchingCompanyProducts,
    isLoading: isLoadingCompanyProducts,
  } = useQuery<IPublishedProductsDto>(
    [queryKey, companyId, filtersQueryString, searchString, orderBy, limit, offset],
    () => fetchCompanyProducts(token, companyId, queryString()),
    {
      enabled: !!token && !!companyId,
    },
  );

  useEffect(() => {
    const products = data?.items;
    if (products != null) {
      if (resetRecords) {
        setRecords([...products]);
      }
      setRecords((prevData) => {
        if (validateElements(prevData, products)) return [...prevData, ...products];
        else return prevData;
      });
    }
  }, [data?.items, resetRecords]);

  return {
    companyProducts: records,
    isFetchingCompanyProducts,
    isLoadingCompanyProducts,
    totalCount: data?.totalCount ?? 0,
  };
};
