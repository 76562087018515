import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none;
  height: 40px;
  margin: 4px 0;
`;

const selectedStyle = css`
  background-color: ${({ theme }) => theme.elements.primaryLight};
  color: ${({ theme }) => theme.elements.primary};
`;

const unselectedStyle = css`
  background-color: white;
  color: ${({ theme }) => theme.elements.unselectedSidebarEntry};
  :hover {
    background-color: ${({ theme }) => theme.elements.primaryLight};
  }
`;

interface IEntryWrapperProps {
  selected: boolean;
}

export const SidebarEntryWrapper = styled.div<IEntryWrapperProps>`
  display: flex;
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  align-items: center;
  ${({ selected }) => (selected ? selectedStyle : unselectedStyle)};
`;

export const IconWrapper = styled.div<IEntryWrapperProps>`
  margin: 0 5px;
  display: flex;
  svg {
    path {
      fill: ${({ selected }) => (selected ? ({ theme }) => theme.elements.primary : ({ theme }) => theme.elements.mediumGrey)};
    }
  }
`;
