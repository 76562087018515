import { UseQueryResult } from '@tanstack/react-query';
import { ISubmissionTaskTableRow } from './submissionsTableSetup';
import { ITasksDto } from '../../features/submission/types';
import { SubmissionTaskType } from '@common/types';
import { TaskTypeToStringMap } from '@common/helpers';

interface IFetchedTasks {
  [k: number]: ISubmissionTaskTableRow[];
}

export const mapSubmissionsTasks = (tasksQueries: UseQueryResult[], submissionIdsToFetchTasks: number[]) => {
  let mappedTasks: IFetchedTasks = {};

  tasksQueries.forEach((q: UseQueryResult, i) => {
    const taskDto = q.data as ITasksDto;
    const tasks = taskDto?.items;

    mappedTasks[submissionIdsToFetchTasks[i]] = tasks?.map((t) => {
      const taskType = t?.type as SubmissionTaskType;
      return {
        ...t,
        documentType: taskType === SubmissionTaskType.DocumentRetire ? t.documentType : TaskTypeToStringMap[taskType],
        submissionId: submissionIdsToFetchTasks[i],
      } as ISubmissionTaskTableRow;
    });
  });

  return mappedTasks;
};
