import { ISelectOption } from '@common/types';
// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const checkIfItemIsEmpty = (item: unknown) => {
  if (!item) {
    return true;
  }

  if (Array.isArray(item) && !item.length) {
    return true;
  }

  if ((item as ISelectOption) && (item as ISelectOption).value?.length === 0) {
    return true;
  }

  return false;
};
