import React from 'react';
import { StyledSVG } from '@common/styles';
import { ProductStatus as ProductStatusType } from '@common/features/submission/types';

export interface IProductStatus {
  value: ProductStatusType;
}

const ProductStatus = ({ value }: IProductStatus) => {
  const renderStatus = () => {
    switch (value) {
      case ProductStatusType.Published:
        return <StyledSVG height={25} src="/icons/published_tag.svg" />;
      case ProductStatusType.Submitted:
        return <StyledSVG height={25} src="/icons/submitted_tag.svg" />;
      case ProductStatusType.Discontinued:
        return <StyledSVG height={25} src="/icons/discontinued_tag.svg" />;
      case ProductStatusType.Retired:
        return <StyledSVG height={25} src="/icons/retired_tag.svg" />;
      default:
        return <StyledSVG height={25} src="/icons/draft_tag.svg" />;
    }
  };

  return renderStatus();
};

export default ProductStatus;
