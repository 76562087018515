import { useState } from 'react';
import Divider from '../Divider/Divider';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper, Select } from '@common/components';
import { ISelectOption } from '@common/types';
import { reasonsForDiscontinueOptions } from '@common/features/reasons/discontinuationReasons';

interface IProps {
  onCancel: () => void;
  onConfirm: (reason: ISelectOption) => void;
  isLoading?: boolean;
}

export const DiscontinueProductModal = ({ onCancel, onConfirm, isLoading }: IProps) => {
  const [reason, setReason] = useState<ISelectOption>(reasonsForDiscontinueOptions[0]);

  const handleReasonChange = (value: ISelectOption) => {
    setReason(value);
  };

  const handleConfirm = () => {
    onConfirm(reason);
  };

  return (
    <ModalWrapper isOpen={true} contentStyles={{ width: '35rem' }}>
      <Styled.Title>Discontinue product</Styled.Title>
      <Styled.Title>Reason for Discontinue</Styled.Title>
      <Select name="reasonForDiscontinue" value={reason} onChange={handleReasonChange} options={reasonsForDiscontinueOptions} />
      <Styled.Footer>
        <Button onClick={onCancel} text="Cancel" type={ButtonTypes.TEXT} />
        <Divider type="vertical" size="30px" />
        <Button onClick={handleConfirm} text="Confirm" type={ButtonTypes.PRIMARY} isLoading={isLoading} />
      </Styled.Footer>
    </ModalWrapper>
  );
};
