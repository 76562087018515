export enum FileRoles {
  Original = 'original',
  Xml = 'xml',
  Html = 'html',
  ImageConversion = 'image-conversion',
  ImageUser = 'image-user',
  ErrorsXml = 'errors-xml',
  MigrationUnknown = 'migration-unknown',
  AlternativeText = 'alternative-text',
}
