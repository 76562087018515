import { useForm } from 'react-hook-form';
import { ISelectOption } from '@common/types';

interface IRetireContentDetailsForm {
  reason: ISelectOption | null;
}

export enum RetireContentDetailsFormField {
  reason = 'reason',
}

export const usePrepareRetireContentDetailsForm = () => {
  const methods = useForm<IRetireContentDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      [RetireContentDetailsFormField.reason]: null,
    },
  });

  return { methods, ...methods };
};
