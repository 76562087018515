import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { fetchDocumentVersionHistory } from '@common/services/documentService';
import { IDocumentHistoryVersion } from '../model/IDocumentHistoryVersion';

export const fetchVersionHistory = 'fetchDocumentVersionHistory';

interface IDocumentVersionHistoryResult {
  items: IDocumentHistoryVersion[];
  isLoading: boolean;
}

export const useFetchDocumentVersionHistory = (documentId: number): IDocumentVersionHistoryResult => {
  const token = useAppSelector(selectToken);

  const { data, isFetching } = useQuery<IDocumentHistoryVersion[]>([fetchVersionHistory], () =>
    fetchDocumentVersionHistory(token, documentId),
  );

  return {
    items: data ?? [],
    isLoading: isFetching,
  };
};
