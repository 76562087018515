import styled from 'styled-components';

export const Heading = styled.h2`
  font-size: ${({ theme }) => theme.elementFontSizes.subSectionTitle};
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Subtext = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
`;
