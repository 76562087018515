import styled from 'styled-components';

export const CreateSubmissionContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const CreateSubmissionWrap = styled.div`
  width: 90%;
`;

export const CreateSubmissionCompactWrap = styled.div`
  width: 60%;
  @media (min-width: 1920px) {
    width: 50%;
  }
`;

export const CreateSubmissionHeadingWrap = styled(CreateSubmissionCompactWrap)`
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
`;

export const CreateSubmissionTextHeadingText = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.pageTitle};
  color: ${({ theme }) => theme.elements.submissionHeading};
`;

export const CreateSubmissionTextHeadingSubtext = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
`;
