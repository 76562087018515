import styled from 'styled-components';
import { ProductSectionInner } from '../ProductSection/styles';

export const ProductDetailsWrap = styled.div`
  width: 100%;
  height: 100%;
`;

export const ProductDetailsInner = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  height: 100%;
  padding-top: 40px;
  justify-content: space-between;

  ${ProductSectionInner} {
    width: 55%;
    max-width: 750px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
  width: 60%;
  padding: 0 60px;
`;

export const HeadingText = styled.h1`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionTitle};
  margin-bottom: 5px;
`;

export const HeadingSubtext = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.textLightGrey};
`;

export const ProductDetailsContentWrap = styled.div`
  padding: 20px 0 20px 20px;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.elements.backgroundWhite};
  max-height: calc(100vh - 400px);
  padding: 0 60px;
  overflow: auto;

  @media (min-width: 1400px) {
    max-height: calc(100vh - 370px);
  }
`;

export const Spacer = styled.div`
  height: 200px;
  content: '';
`;
