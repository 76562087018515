import { SubmissionStatus } from '@common/services/types';

export type SubmissionStatusToDisplayName = {
  [key in SubmissionStatus]?: string;
};

export const SubmissionStatusToDisplayNameMap: SubmissionStatusToDisplayName = {
  [SubmissionStatus.InProgress]: 'In Progress',
  [SubmissionStatus.Approved]: 'Published',
  [SubmissionStatus.Published]: 'Published',
  [SubmissionStatus.Cancelled]: 'Published',
  [SubmissionStatus.Draft]: 'Draft',
};
