import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormSelectedItems } from '@common/hooks/useFormSelectedItems';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';

export const useLegalCategoriesSelection = () => {
  const {
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<IProductForm>();

  const { toggleItem, isItemSelected } = useFormSelectedItems(ProductFormPaths.legalCategories);

  const legalCategories = watch(ProductFormPaths.legalCategories);

  useEffect(() => {
    trigger(ProductFormPaths.legalCategories);
  }, [trigger, legalCategories]);

  const withLegalCategoriesError = !!errors.productDetails?.legalCategories;

  return {
    errors,
    withLegalCategoriesError,
    toggleItem,
    isItemSelected,
  };
};
