import styled from 'styled-components';

export const BadgeWrapper = styled.span`
  color: ${({ theme }) => theme.elements.mediumGrey};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small};
  background-color: ${({ theme }) => theme.elements.badgeGrey};
  border-radius: 8px;
  height: 23px;
  width: fit-content;
  padding: 2px 8px;
`;
