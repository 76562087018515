import {
  legalCategoriesCheckboxes,
  productStatusCheckboxes,
  productTypeCheckboxes,
} from '@common/components/LegalCategories/constants';
import { ICheckboxEntry } from '@common/types';
import { ITypeaheadQuery, emptyTypeaheadQuery, useFormTypeahead } from '@common/hooks/useFormTypeahead';
import { useFilters } from '@common/components/FiltersDropdown/useFilters';

export enum ProductsListFiltersFormFields {
  hasBlackTriangle = 'hasBlackTriangle',
  legalCategories = 'legalCategories',
  types = 'types',
  statuses = 'statuses',
  company = 'company',
}

export const useProductsListFilters = (companiesQuery?: ITypeaheadQuery) => {
  const initialValues = {
    [ProductsListFiltersFormFields.hasBlackTriangle]: null,
    [ProductsListFiltersFormFields.legalCategories]: [],
    [ProductsListFiltersFormFields.types]: [],
    [ProductsListFiltersFormFields.statuses]: [],
    [ProductsListFiltersFormFields.company]: null,
  };

  const {
    filtersQueryString,
    dirtyValues,
    isFiltersOpen,
    handleClear,
    setNonCheckboxValue,
    setCheckboxValue,
    handleCancelFilters,
    handleToggleFilters,
    handleClearAllFilters,
    handleConfirmFilters,
  } = useFilters(initialValues, initialValues);

  const appliedFiltersCount = [
    dirtyValues[ProductsListFiltersFormFields.statuses].length > 0,
    dirtyValues[ProductsListFiltersFormFields.types].length > 0,
    dirtyValues[ProductsListFiltersFormFields.legalCategories].length > 0,
    !!dirtyValues[ProductsListFiltersFormFields.company],
    dirtyValues[ProductsListFiltersFormFields.hasBlackTriangle] != null,
  ].filter((x) => x === true).length;

  const {
    isLoading: isLoadingCompanies,
    items: companies,
    onInputChange: onCompanyInputChange,
  } = useFormTypeahead(companiesQuery ?? emptyTypeaheadQuery);

  const mappedLegalCategories = legalCategoriesCheckboxes.map((lc) => ({ ...lc, value: lc.searchValue }));

  return {
    filtersQueryString,
    filtersDropdownProps: {
      isOpen: isFiltersOpen,
      appliedFiltersCount,
      onConfirm: handleConfirmFilters,
      onCancel: handleCancelFilters,
      onClearAll: handleClearAllFilters,
      onToggle: handleToggleFilters,
    },
    blackTriangleFilterProps: {
      value: dirtyValues[ProductsListFiltersFormFields.hasBlackTriangle],
      label: 'Black triangle',
      onChange: (...event: any[]) => setNonCheckboxValue(ProductsListFiltersFormFields.hasBlackTriangle, event[0]),
      onClear: () => handleClear([ProductsListFiltersFormFields.hasBlackTriangle], null),
    },
    companyFilterProps: {
      value: dirtyValues[ProductsListFiltersFormFields.company],
      isLoading: isLoadingCompanies,
      options: companies,
      label: 'Company',
      placeholder: 'Enter Company',
      noOptionsMessage: 'Company not found',
      onChange: (...event: any[]) => setNonCheckboxValue(ProductsListFiltersFormFields.company, event[0]),
      onInputChange: onCompanyInputChange,
      onClear: () => handleClear([ProductsListFiltersFormFields.company], null),
    },
    legalCategoryFilterProps: {
      label: 'Legal categories',
      options: mappedLegalCategories,
      values: dirtyValues[ProductsListFiltersFormFields.legalCategories],
      onChange: (item: ICheckboxEntry) => setCheckboxValue(ProductsListFiltersFormFields.legalCategories, item),
      onClear: () => handleClear([ProductsListFiltersFormFields.legalCategories], []),
    },
    productTypeFilterProps: {
      label: 'Product type',
      options: productTypeCheckboxes,
      values: dirtyValues[ProductsListFiltersFormFields.types],
      onChange: (item: ICheckboxEntry) => setCheckboxValue(ProductsListFiltersFormFields.types, item),
      onClear: () => handleClear([ProductsListFiltersFormFields.types], []),
    },
    productStatusFilterProps: {
      label: 'Status',
      options: productStatusCheckboxes,
      values: dirtyValues[ProductsListFiltersFormFields.statuses],
      onChange: (item: ICheckboxEntry) => setCheckboxValue(ProductsListFiltersFormFields.statuses, item),
      onClear: () => handleClear([ProductsListFiltersFormFields.statuses], []),
    },
  };
};
