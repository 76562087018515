import React from 'react';
import { useParams } from 'react-router';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import ProductGroup from '../../features/productGroup/components/SubmissionEditableProductGroup';
import SubmissionReadonlyProductGroup from '../../features/productGroup/components/SubmissionReadonlyProductGroup';

export const SubmissionProductGroup = () => {
  const { submissionId = '' } = useParams();

  const { submission, refetchFetchSubmission, isFetchingSubmission } = useFetchSubmission(submissionId);

  const shouldBeEditable = submission?.isEditable;

  if (shouldBeEditable) {
    return <ProductGroup submission={submission!} successCallback={refetchFetchSubmission} isLoading={isFetchingSubmission} />;
  }

  return <SubmissionReadonlyProductGroup submission={submission!} />;
};
