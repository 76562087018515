import React, { ReactNode } from 'react';
import UserInfo from './UserInfo';
import { HeaderText, HeaderWrap, Right } from './styles';

interface IHeader {
  children?: ReactNode;
}

const Header = ({ children }: IHeader) => {
  // comparing to length to avoid evaluation at compile time
  var header = (process.env.REACT_APP_PLATFORMNAME ?? "").length !== 0 ? `${process.env.REACT_APP_PLATFORMNAME} PLATFORM` : "";

  return (
    <HeaderWrap>
      <HeaderText>{header}</HeaderText>
      <Right>
        {children}
        <UserInfo />
      </Right>
    </HeaderWrap>
  );
};

export default Header;
