import { putSubmissionTaskStatus } from '@common/services/submissionTaskService';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken, selectUserId } from '@common/features/user/selectors';
import { SubmissionTaskStatuses } from '@common/services/types';
import useWaitingMutation from '@common/hooks/useWaitingMutation';

interface ITaskStatusChange {
  taskId: string;
  status: SubmissionTaskStatuses;
  additionalInformation?: string;
}

export const useTaskStatusChange = () => {
  const token = useAppSelector(selectToken);
  const userId = useAppSelector(selectUserId);

  const { mutate, isLoading } = useWaitingMutation(({ taskId, status, additionalInformation = '' }: ITaskStatusChange) =>
    putSubmissionTaskStatus(token, taskId, { userId, additionalInformation, status }),
  );

  return { changeTaskStatus: mutate, isChangingTaskStatus: isLoading };
};
