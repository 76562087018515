import { IReadonlyContentDetails } from './types';
import { useAppSelector } from '@common/hooks/redux';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { selectProductGroup } from '@common/features/productGroup/slices/productGroup/selectors';
import { DocumentType } from '@common/types';
import * as Styled from './styles';
import { useFetchPublicProductGroupState } from '@common/features/productGroup/hooks/useFetchPublicProductGroupState';
import Tabs from '@common/components/Tabs/Tabs';
import { ReadonlyDocumentContent } from '../../components/DocumentContentDetails/ReadonlyDocumentContent';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import DocumentDetailsSkeletonLoader from './DocumentDetailsSkeletonLoader';
import { HtmlDocument } from '@components/HtmlDocument/HtmlDocument';
import { htmlFileIsAvailable } from '@features/document/helpers';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { useHandleTaskFileByRole } from '@common/hooks/useHandleTaskFileByRole';
import { FileRoles } from '@common/features/document/types';

const PublishedDocumentDetailsContent = ({
  showHistoryFiltering,
  contentType,
  productGroupId,
  documentId,
  onCancel,
  onFileDownload,
}: IReadonlyContentDetails) => {
  const productGroup = useAppSelector(selectProductGroup);
  const { publicProductGroupState, isFetchingPublicProductGroupState, isPublicProductGroupStateFetched } =
    useFetchPublicProductGroupState(productGroupId!);
  const { showVersionHistory } = useFeatureFlags();

  const document = publicProductGroupState?.documents.find((d) => d.documentId === Number.parseInt(documentId!));
  const documentTitle = document?.documentTitle;
  const fileName = document?.originalFileName;
  const version = document?.version ?? 1;
  const approvalDate = document?.regulatorApprovalDate;
  const approved = !!document?.regulatorApprovalDate;
  const firstAuthorisationDate = document?.authorisedDate;
  const regulator = document?.approvedByRegulator;
  const reasonsForSubmission = document?.reasonsForSubmission;
  const documentState = document?.state;
  const hasDigitalVersion = document?.hasDigitalVersion ?? null;
  const url = document?.link ?? null;
  const { contextHandleFile } = useHandleTaskFileByRole('', documentId, FileRoles.AlternativeText);

  const reasonForRetirement =
    document?.reasonForRetirement?.length! > 0
      ? { label: document?.reasonForRetirement as string, value: document?.reasonForRetirement as string }
      : undefined;

  const displayHtmlView = !!document && htmlFileIsAvailable(document, publicProductGroupState!.documents);

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} Details`,
        content: (
          <ReadonlyDocumentContent
            documentTitle={documentTitle ?? ''}
            version={version ?? 1}
            fileName={fileName ?? ''}
            contentType={contentType}
            approvalDate={approvalDate ?? null}
            approved={approved}
            url={url}
            firstAuthorisationDate={firstAuthorisationDate ?? null}
            regulator={regulator ?? ''}
            reasonsForSubmission={reasonsForSubmission ?? []}
            reasonForRetirement={reasonForRetirement}
            documentState={documentState!}
            hasDigitalVersion={hasDigitalVersion}
            onFileClick={onFileDownload}
            contentDescription={document?.contentDescription ?? ''}
            isVisibleToHCP={document?.isVisibleToHCP ?? null}
            htmlContent={document?.htmlContent ?? null}
            onAlternativeTextFileNameClick={contextHandleFile?.openAlternativeTextFile}
            isAdmin={false}
            customerUrl={undefined}
          />
        ),
      },
    ];

    if (showVersionHistory) {
      items.push({
        label: `Version history`,
        content: <DocumentVersionHistory documentId={document?.documentId ?? 0} showHistoryFiltering={showHistoryFiltering} />,
      });
    }

    if (displayHtmlView)
      items.push({
        label: `View html`,
        content: <HtmlDocument documentId={documentId} documentType={contentType} version={version.toString()} />,
      });

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  if (isFetchingPublicProductGroupState || !isPublicProductGroupStateFetched) {
    return <DocumentDetailsSkeletonLoader />;
  }

  return (
    <Styled.ContentDetailsWrap>
      <SubmissionFlowHeader title={productGroup.name} />
      {renderContent()}
      <SubmissionFlowFooter onCancel={onCancel} cancelText="Back" />
    </Styled.ContentDetailsWrap>
  );
};

export default PublishedDocumentDetailsContent;
