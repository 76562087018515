import { useState } from 'react';

interface Props {
  confirmRedirect: () => void;
}

export const useCancelModal = ({ confirmRedirect }: Props) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const openCancelModal = () => setIsCancelModalOpen(true);

  const closeCancelModal = () => setIsCancelModalOpen(false);

  const handleCancelModalConfirm = () => {
    closeCancelModal();
    return confirmRedirect();
  };

  return {
    isCancelModalOpen,
    openCancelModal,
    closeCancelModal,
    handleCancelModalConfirm,
  };
};
