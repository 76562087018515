import { useMutation, MutationFunction, UseMutationOptions, MutationKey } from '@tanstack/react-query';

const useWaitingMutation = <TData = any, TError = unknown, TVariables = any, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey' | 'mutationFn'>,
  mutationKey?: MutationKey,
) => {
  const { mutate, ...rest } = useMutation((mutationKey = []), mutationFn, options);

  // When isLoading is true empty function is returned so that data cannot be submitted twice
  return { mutate: rest.isLoading ? () => {} : mutate, ...rest };
};

export default useWaitingMutation;
