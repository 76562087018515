import styled from 'styled-components';
import { IPleaseWaitLoader } from './PleaseWaitLoader';

export const PleaseWaitLoader = styled.div<IPleaseWaitLoader>`
  display: flex;
  flex-direction: column;
  height: ${({ small }) => (small ? '134px' : '234px')};
  width: ${({ small }) => (small ? '234px' : '446px')};
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: ${({ theme, small }) => (small ? theme.fontSizes.medium : theme.fontSizes.xLarge)};
  font-weight: 600;

  svg {
    height: ${({ small }) => (small ? '35px' : '65px')};
    margin-bottom: 0.5rem;
    animation: rotation 2s infinite ease;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
