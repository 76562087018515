import { useAppSelector } from '@hooks/redux';
import { selectToken } from '@features/user/selectors';
import { useQuery } from '@tanstack/react-query';
import { fetchProductForms } from '../services/productService';
import { ISelectOption } from '@common/types';

export const queryKey = 'fetchProductForms';

export const useFetchProductForms = (search: string | null = null, limit?: number) => {
  const token = useAppSelector(selectToken);

  const { data, isFetching, isFetched } = useQuery([queryKey], () => fetchProductForms(token, search, limit), {
    enabled: !!token,
    select: (data) =>
      data?.map((x: { name: string; snomedCode: string }) => ({
        label: x.name,
        value: x.snomedCode,
      })) as ISelectOption[],
    staleTime: Infinity,
  });

  return {
    productForms: data,
    isFetching,
    isFetched,
  };
};
