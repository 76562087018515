import { createColumnHelper } from '@tanstack/react-table';
import { Controller, useFormContext } from 'react-hook-form';
import { ProductSection } from '../ProductSection/ProductSection';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { Button, ButtonTypes, Input } from '@common/components';
import { ErrorMessage } from '../ErrorMessage';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';
import * as CommonStyles from '../styles';

interface IAmpId {
  elementId: string;
  ampId: string;
  ampIdToAdd: string;
  isNoAmpId: boolean;
  readonly?: boolean;
  onAmpIdAdd: () => void;
  onAmpIdRemove: (index: number) => void;
  onNoAmpIdChange: () => void;
}

export const AmpId = ({ elementId, ampId, isNoAmpId, onAmpIdAdd, onAmpIdRemove, onNoAmpIdChange, readonly }: IAmpId) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const isDataEmpty = !ampId.length && !isNoAmpId;

  const errorMessage = errors?.productDetails?.ampIdForm?.ampIdInput?.message;

  const columnHelper = createColumnHelper<IAmpId>();

  const columns = [
    columnHelper.accessor('ampId', {
      header: 'Code',
    }),
    ...(readonly
      ? []
      : [
          columnHelper.display({
            header: 'Action',
            cell: (item) => {
              return (
                <CommonStyles.RemoveButton onClick={() => onAmpIdRemove(parseInt(item.cell.id))}>
                  <StyledSVG src={`/icons/trash_bin.svg`} />
                </CommonStyles.RemoveButton>
              );
            },
          }),
        ]),
  ];

  return (
    <ProductSection
      elementId={elementId}
      heading="AMP id"
      subtext="The AMP id is the SNOMED code for the Actual Medicinal Product as stated on the NHS's Dictionary of Medicines and devices."
      withError={!readonly && isDataEmpty}
    >
      <Styled.AmpIdWrap>
        <CheckboxOption
          item={{ id: 'noAmpId', text: 'No AMP id is available' }}
          name="noAmpId"
          isChecked={isNoAmpId}
          onChange={onNoAmpIdChange}
          disabled={readonly}
        />
        {!readonly && (
          <>
            <CommonStyles.InputButtonWrapper>
              <Controller
                control={control}
                name={ProductFormPaths.ampIdInput}
                render={({ field: { value = '', onChange } }) => (
                  <Input
                    inputType={'number'}
                    min="0"
                    label="Enter AMP id"
                    name="Amp Id"
                    disabled={isNoAmpId || ampId.length > 0}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Button
                disabled={isNoAmpId || ampId.length > 0}
                text="Add"
                type={ButtonTypes.PRIMARY_BORDER}
                onClick={onAmpIdAdd}
              />
            </CommonStyles.InputButtonWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        {!isNoAmpId && ampId.length > 0 && <DatapharmTable columns={columns} documents={[{ ampId }]} />}
      </Styled.AmpIdWrap>
    </ProductSection>
  );
};
