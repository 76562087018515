import React from 'react';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { mapToProductsData, getProductsTableColumnData } from './helpers';
import { createColumns } from '../../../helpers';
import { IDocumentDto, IProductChangesDto, IProductDto } from '@common/features/submission/types';
import * as Styled from './styles';
import { SubmissionTaskType } from '@common/types';

interface Props {
  isLoading: boolean;
  removeButtonText?: string;
  products: IProductDto[];
  documents: IDocumentDto[];
  productsChanges?: IProductChangesDto[];
  isReadonly?: boolean;
  showDocumentCountChanges: boolean;
  submissionTaskType: SubmissionTaskType;
  onEditXml?: () => void;
  onUpdateClick?: (productId: string) => void;
  onRemoveClick?: (productId: string) => void;
  onDetailsClick?: (productId: string) => void;
}

export const ProductsTab = ({
  isLoading,
  removeButtonText = 'Remove',
  products,
  documents,
  productsChanges,
  isReadonly = false,
  showDocumentCountChanges,
  submissionTaskType,
  onEditXml = () => {},
  onUpdateClick = () => {},
  onRemoveClick = () => {},
  onDetailsClick = () => {},
}: Props) => {
  const columnsData = getProductsTableColumnData(
    isReadonly,
    removeButtonText,
    showDocumentCountChanges,
    onEditXml,
    onUpdateClick,
    onRemoveClick,
    onDetailsClick,
  );
  const columns = createColumns(columnsData);
  const productsTableData = mapToProductsData(products, documents, submissionTaskType, productsChanges);

  return (
    <Styled.DetailsTableWrapper>
      {!isLoading && <DatapharmTable columns={columns} documents={productsTableData!} data-testid="products-table" />}
    </Styled.DetailsTableWrapper>
  );
};
