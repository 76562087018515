export const RemoveModalContent = {
  document: {
    title: 'Are you sure you want to remove your draft?',
    getDescription: (title: string) => `You are about to remove your draft changes for ${title}, do you want to continue?`,
  },
  product: {
    title: 'Are you sure you want to remove your draft?',
    getDescription: (title: string) => `You are about to remove your draft changes for ${title}, do you want to continue?`,
  },
};
