import styled from 'styled-components';
import { CheckboxOptionWrap } from '../CheckboxOption/styles';
import { Table } from '../DatapharmTable/styles';

export const ContractGroupWrap = styled.div`
  ${CheckboxOptionWrap} {
    margin-bottom: 10px;
  }

  ${Table} {
    width: 70%;
  }
`;
