import styled from 'styled-components';

export const SlaTableWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.tiny};

  h3 {
    text-align: center;
  }
`;

export const SlaName = styled.td`
  align-self: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const SlaValue = styled.td<{ center?: boolean }>`
  text-align: ${({ center }) => (center ? 'center' : 'none')};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

export const SlaDisclaimer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  font-weight: 600;
`;
