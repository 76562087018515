import React from 'react';
import { NotifyModal } from '@components/NotifyModal';
import { ModalTypes } from '@components/NotifyModal/NotifyModal';
import { RemoveDiscontinuationBannerContent } from '@components/RetainmentProductModal/style';

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
  productName: string;
}

export const ReinstatementProductModal = ({ onCancel, onConfirm, productName }: IProps) => {
  return (
    <NotifyModal
      title="Confirm removal of discontinue banner"
      width="500px"
      modalType={ModalTypes.error}
      isOpen={true}
      cancelText="Cancel"
      onClose={onCancel}
      onConfirm={onConfirm}
    >
      <RemoveDiscontinuationBannerContent>
        {`You are about to remove the discontinue banner on ${productName}, do you want to continue?`}
      </RemoveDiscontinuationBannerContent>
    </NotifyModal>
  );
};
