import { Select } from '@common/components/Select/styles';
import styled from 'styled-components';

export const RetireModalContent = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
  width: 580px;
  font-weight: 600;

  ${Select} {
    width: 90%;
    font-weight: 400;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalHeading = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const ContentText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ListWrapper = styled.div``;

export const ProductList = styled.ul`
  margin-top: 10px;
`;

export const ListHeading = styled.span`
  color: #918f90;
  font-weight: 400;
`;

export const WarningDisplay = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red};
  padding: 20px 15px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 14px;
  column-gap: 30px;
`;

export const ListItem = styled.li``;
