import { useState } from 'react';
import { IModalState } from '../types';

export const useRetireContentModal = () => {
  const [modalState, setModalState] = useState<IModalState>({ isOpen: false, documentId: null });

  const openModal = () => setModalState({ ...modalState, isOpen: true });

  const closeModal = () => setModalState({ ...modalState, isOpen: false });

  return {
    modalState,
    openModal,
    closeModal,
    setModalState,
  };
};
