import styled from 'styled-components';
export const Watermark = styled.div`
    position: fixed;
    width:calc(100vw);
    left:10px;
    top:calc(100vh * 0.35 );
    height:auto;
    color: rgb(95, 165, 204, 0.4);
    z-index: 100;
    -webkit-transform: rotate(331deg);
    transform: rotate(331deg);
    text-align: center;
    vertical-align: center;
    font-size: ${() => process.env.REACT_APP_WATERMARK_SIZE};
    pointer-events: none;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-size-adjust:none;
    -ms-text-size-adjust:none;
    -moz-text-size-adjust:none;
    text-size-adjust:none;
`;
