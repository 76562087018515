import styled from 'styled-components';
import { Select, StyledLabel } from '../Select/styles';

export const Wrapper = styled.div`
  ${Select} {
    max-width: 270px;
  }

  ${StyledLabel} {
    white-space: pre;
  }
`;
