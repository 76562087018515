import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import theme from '@common/theme';

export const SubmissionFlowFooterWrap = styled.div`
  background-color: ${theme.elements.defaultWhite};
  min-height: 75px;
  border-top: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const SubmissionFlowFooterInner = styled.div`
  display: flex;
  color: black;
  height: 100%;
  align-items: center;
  padding: 0px 30px;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusMessage = styled.span`
  color: ${theme.elements.textGrey};
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  margin-left: 10px;
`;

export const ErrorText = styled.span`
  color: ${theme.colors.red};
  font-size: ${({ theme }) => theme.elementFontSizes.errorMessage};
  margin-right: 10px;
`;

export const StyledSVG = styled(ReactSVG)`
  width: 30px;
  height: 30px;
`;
