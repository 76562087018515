import React from 'react';
import { SectionWrap } from './styles';

interface IDashboardSection {
  title?: string;
  children: any;
}

export const DashboardSection = ({ title, children }: IDashboardSection) => (
  <SectionWrap>
    {title ? <h3>{title}</h3> : null}
    <>{children}</>
  </SectionWrap>
);

export default DashboardSection;
