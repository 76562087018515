import useWaitingMutation from '@hooks/useWaitingMutation';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { fetchProductSubmissionStatuses } from '@common/services/productService';
import { IProductSubmissionStatusDto } from '../types';
import { useCreateDraftFromProductGroup } from '../../CreateSubmission/hooks/useCreateDraftFromProductGroup';
import { SubmissionStatus } from '@common/services/types';
import { SubmissionStatus as SubmissionStatusEnum } from '@common/types';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';

export const queryKey = 'fetchProductSubmissionStatuses';

export const useFetchProductSubmissionStatuses = () => {
  const token = useAppSelector(selectToken);
  const { goToProductGroup } = useAppNavigation();

  const { mutate: draftMutate } = useCreateDraftFromProductGroup((submissionId: number) =>
    goToProductGroup(submissionId.toString()),
  );

  const { mutate: submissionMutate } = useWaitingMutation((productGroupId: number | null) =>
    fetchProductSubmissionStatuses(token, productGroupId!, SubmissionStatusEnum.Active),
  );

  const handleUpdate = (productGroupId: number, newSubmissionData: any) => {
    submissionMutate(productGroupId, {
      onSuccess: (data) => handleSuccess(data, newSubmissionData),
    });
  };

  const handleSuccess = (submissionStatuses: IProductSubmissionStatusDto[], newSubmissionData: any) => {
    const draftSubmission = submissionStatuses.find((item) => item.status === SubmissionStatus.Draft);

    if (draftSubmission) {
      return goToProductGroup(draftSubmission.submissionId.toString());
    }

    return draftMutate({
      ...newSubmissionData,
    });
  };

  return {
    submissionMutate,
    handleUpdate,
  };
};
