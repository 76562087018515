import React from 'react';

import { CheckboxOption } from '@common/components/CheckboxOption/CheckboxOption';
import { Textarea } from '@common/components/Textarea/Textarea';
import { getNewSubmissionReasons, getUpdateSubmissionReasons } from '@common/features/reasons/submissionReasons';
import * as Styled from './styles';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { DocumentType, ICheckboxEntry } from '@common/types';
import { ISubmissionReasonDto } from '@common/features/submission/types';

interface IReadonlySubmissionDetails {
  contentType: DocumentType;
  version: number;
  reasonsForSubmission: ISubmissionReasonDto[];
  details: string;
}

export const ReadonlySubmissionDetails = ({
  contentType,
  version,
  reasonsForSubmission,
  details,
}: IReadonlySubmissionDetails) => {
  const isItemSelected = (item: ICheckboxEntry) => {
    return !!reasonsForSubmission.find((reason) => reason.id === item.id);
  };

  const renderDetails = () => {
    var reasons = version === 1 ? getNewSubmissionReasons(contentType) : getUpdateSubmissionReasons(contentType);
    return reasons.map((item, index) => {
      const isSelected = isItemSelected(item);
      return (
        <CheckboxOption
          data-testid={`reason-checkbox-${index}`}
          key={item.id}
          name={`submission detail ${item.id}`}
          item={item}
          onChange={() => {}}
          isChecked={isSelected}
          disabled
        />
      );
    });
  };

  return (
    <Styled.SubmissionDetailsWrap>
      <Styled.SubmissionReasonsWrap>
        <Styled.SubmissionReasonsLabel>Reasons for submission</Styled.SubmissionReasonsLabel>
        <Styled.SubmissionReasonsContent>{renderDetails()}</Styled.SubmissionReasonsContent>
      </Styled.SubmissionReasonsWrap>
      <Textarea
        placeholder="Notes here (eg. Section 1 update)"
        label="Submission notes"
        value={details}
        name={ContentDetailsFormField.details}
        disabled={true}
        onChange={() => {}}
      />
    </Styled.SubmissionDetailsWrap>
  );
};
