import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchDocumentVersionHistory = async (token: string, documentId: number) => {
  const url = buildUrl(`/document/${documentId}/history`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

export { fetchDocumentVersionHistory };
