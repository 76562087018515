import { IActiveIngredientsStrength } from '@common/features/product/types';
import { ISelectOption } from '@common/types';

export enum AddProductFormFields {
  extraInformation = 'extraInformation',
  productForm = 'productForm',
  activeIngredientsStrength = 'activeIngredientsStrength',
  emcProductName = 'emcProductName',
  medicinalProductName = 'medicinalProductName',
}
export interface IAddProductForm {
  [AddProductFormFields.extraInformation]: string;
  [AddProductFormFields.productForm]: ISelectOption | null;
  [AddProductFormFields.activeIngredientsStrength]: IActiveIngredientsStrength;
  [AddProductFormFields.emcProductName]: string;
  [AddProductFormFields.medicinalProductName]: string;
}

export enum AddProductFormErrors {
  requiredField = 'This field is required',
}
