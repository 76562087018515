import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { Wrap, Top, Left, FileIcon, Info, FileName, FileSize, CancelIcon, Right, Progress } from './styles';
import theme from '@common/theme';

interface IFileItem {
  loadingPresents?: number;
  isLoading?: boolean;
  fileName?: string;
  fileSize?: string;
  onClick: () => void;
}

export const FileItem = ({ loadingPresents = 0, isLoading = true, fileName = '', fileSize = '', onClick }: IFileItem) => {
  return (
    <Wrap>
      <Top>
        <Left>
          <FileIcon src={`/icons/file.svg`} />
          <Info>
            <FileName>{fileName}</FileName>
            <FileSize>{fileSize}</FileSize>
          </Info>
        </Left>
        <Right>
          {onClick && <CancelIcon src={`/icons/cancel.svg`} onClick={onClick} />}
          <Progress>{loadingPresents}% Complete</Progress>
        </Right>
      </Top>
      {isLoading && (
        <ProgressBar
          completed={loadingPresents}
          isLabelVisible={false}
          height="7px"
          borderRadius="10px"
          baseBgColor={theme.colors.defaultWhite}
          bgColor={theme.elements.primaryMedium}
        />
      )}
    </Wrap>
  );
};
