import { Option, OptionLabel, SwitcherWrapper } from './styles';

export interface ISwitcherOption {
  value: any;
  label?: string;
}

interface ISwitcher {
  items: ISwitcherOption[];
  selectedOption: ISwitcherOption;
  onOptionClick: (option: any) => void;
}

const Switcher = ({ items, selectedOption, onOptionClick }: ISwitcher) => {
  const handleOptionClick = (item: ISwitcherOption) => {
    if (item.value !== selectedOption.value) {
      onOptionClick(item.value);
    }
  };

  return (
    <SwitcherWrapper>
      {items.map((item: ISwitcherOption, index) => (
        <Option
          title={item.label}
          selected={item.value === selectedOption.value}
          key={index}
          onClick={() => handleOptionClick(item)}
          data-testid={`${item.value}-tab`}
        >
          <OptionLabel>{item.label}</OptionLabel>
        </Option>
      ))}
    </SwitcherWrapper>
  );
};

export default Switcher;
