import { UseFormReturn } from 'react-hook-form';
import { ProductGroupForm, ProductGroupFormFields } from '@common/features/productGroup/hooks/useProductGroupForm/types';

export const useMaHolder = (methods: UseFormReturn<ProductGroupForm, any, undefined>) => {
  const {
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const isVisible = watch(ProductGroupFormFields.isSameAsSubmitterCompany) === false;
  const isSameAsSubmitterCompany = watch(ProductGroupFormFields.isSameAsSubmitterCompany);
  const companyName = watch(ProductGroupFormFields.companyName);

  const handleSameAsSubmitterCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ProductGroupFormFields.isSameAsSubmitterCompany, Number(e.target?.value) === 1, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(ProductGroupFormFields.companyName, '', { shouldDirty: true });
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ProductGroupFormFields.companyName, e.target.value, { shouldDirty: true });
    trigger(ProductGroupFormFields.isSameAsSubmitterCompany);
  };

  const showMaHolderWarning = !!errors.maHolder?.isSameAsSubmitterCompany;

  return {
    isVisible,
    isSameAsSubmitterCompany,
    companyName,
    showMaHolderWarning,
    handleSameAsSubmitterCompanyChange,
    handleCompanyNameChange,
  };
};
