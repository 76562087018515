import styled from 'styled-components';
import { Input } from '../Input/Input';
import { InputWrap } from '../Input/styles';

export const FormInput = styled(Input)`
  ${InputWrap} {
    padding: 10px 15px;
    height: auto;
    border-radius: 10px;

    input {
      font-size: 16px;
      margin: 2px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
