import * as Styled from './styles';
import { StyledSVG } from '@common/styles';
import { ISidebarSection } from './types';

interface ISidebarSectionProps<T> {
  sections: ISidebarSection<T>[];
  displayedSection: ISidebarSection<T>;
  onSectionClick: (section: ISidebarSection<T>) => void;
}

export const Sidebar = <T,>({ sections, displayedSection, onSectionClick }: ISidebarSectionProps<T>) => (
  <Styled.SidebarWrapper>
    {sections.map((section) => {
      const isSelected = displayedSection.name === section.name;
      const handleClick = () => onSectionClick(section);

      return (
        <Styled.SidebarEntry key={section.name} isSelected={isSelected} onClick={handleClick}>
          {section.name}
          {section?.showWarning && <StyledSVG src="/icons/small_warning_icon.svg" />}
        </Styled.SidebarEntry>
      );
    })}
  </Styled.SidebarWrapper>
);
