import styled from 'styled-components';

export const ProductName = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  padding: 17px 24px;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  min-height: 5rem;

  & + & {
    margin-top: 10px;
  }

  label {
    font-weight: bold;
    display: block;
  }

  span {
    font-size: 14px;
  }
`;

export const NarrowProductName = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  padding: 0.75rem 0.5rem;
  display: block;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const NameWrapper = styled.div`
  width: 49%;
`;
