import React from 'react';
import { NoContentPanelWrapper, NoContentTitle } from './styles';
import { StyledSVG } from '@common/styles';
import { UnderlinedLink } from '../UnderlinedLink/styles';

interface INoContentPanel {
  title: string;
  helpMessage?: string;
  helpLink?: string;
}

const NoContentPanel = ({ title, helpMessage, helpLink }: INoContentPanel) => {
  return (
    <NoContentPanelWrapper>
      <StyledSVG src="/icons/information.svg" />
      <NoContentTitle>{title}</NoContentTitle>
      {helpMessage && helpLink ? (
        <UnderlinedLink to={helpLink} target="_blank">
          {helpMessage}
        </UnderlinedLink>
      ) : null}
    </NoContentPanelWrapper>
  );
};

export default NoContentPanel;
