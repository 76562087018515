import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import { HeaderBreadcrumb } from '@components/SubmissionFlowHeader/styles';
import * as Styled from './tabs/ProductInfo/styles';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import ProductInfoSkeletonLoader from './tabs/ProductInfo/ProductInfoSkeletonLoader';
import { RectangleSkeletonLoader } from '@common/styles';
import TabsSkeletonLoader from '@common/components/Tabs/TabsSkeletonLoader';

const ProductContentSkeletonLoader = () => {
  return (
    <Styled.LayoutWrapper>
      <SubmissionFlowHeader>
        <HeaderBreadcrumb>
          <RectangleSkeletonLoader height="2rem" width="30rem" />
        </HeaderBreadcrumb>
      </SubmissionFlowHeader>
      <Styled.ProductWrap>
        <TabsSkeletonLoader>
          <ProductInfoSkeletonLoader />
        </TabsSkeletonLoader>
      </Styled.ProductWrap>
      <SubmissionFlowFooter />
    </Styled.LayoutWrapper>
  );
};

export default ProductContentSkeletonLoader;
