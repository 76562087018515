import React from 'react';
import * as Styled from '@common/features/productGroup/styles';
import { TabContentWrapper } from '@common/features/productGroup/styles';
import { productTypeSelectOptions } from '@common/constants';
import { ProductGroupFormFields } from '@common/features/productGroup/hooks/useProductGroupForm/types';
import { HookFormSelect } from '@common/components/Select/HookFormSelect';
import { FormInput } from '@common/components/FormInput/styles';

interface IProductTypeTab {
  editable?: boolean;
  value?: string;
}

const ProductTypeTab = ({ editable = true, value = '' }: IProductTypeTab) => {
  return (
    <TabContentWrapper>
      <Styled.BadgeElementWrapper>
        <Styled.Subtitle data-testid="product-type-tab-title">Product Type</Styled.Subtitle>
      </Styled.BadgeElementWrapper>
      <Styled.Content>{editable ? <EditableProductTypeTab /> : <ReadonlyProductTypeTab value={value} />}</Styled.Content>
    </TabContentWrapper>
  );
};

const ReadonlyProductTypeTab = ({ value = '' }) => {
  return (
    <Styled.ProductGroupTabForm>
      <FormInput disabled name={ProductGroupFormFields.productType} value={value} />
    </Styled.ProductGroupTabForm>
  );
};

const EditableProductTypeTab = () => {
  return (
    <>
      <Styled.Description>
        Select the category you think is the most appropriate for your products within this product group
      </Styled.Description>
      <Styled.ProductGroupTabForm>
        <HookFormSelect name={ProductGroupFormFields.productType} options={productTypeSelectOptions} />
      </Styled.ProductGroupTabForm>
    </>
  );
};

export default ProductTypeTab;
