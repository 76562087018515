import theme from '@common/theme';
import styled from 'styled-components';

export const FooterWrap = styled.div`
  display: flex;
  color: white;
  background-color: ${theme.elements.primary};
  height: 60px;
  align-items: center;
  padding: 0px 30px;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
`;

export const FooterLink = styled.a`
  display: flex;
  color: white;
  padding: 5px;
  margin: 5px;
  text-decoration: none;
  align-items: center;
`;

export const FooterImage = styled.img`
  height: 35px;
`;
