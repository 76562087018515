import { useAppSelector } from '@hooks/redux';
import { selectToken } from '@features/user/selectors';
import { useQuery } from '@tanstack/react-query';
import { getPublishedDocument } from '@services/submissionTaskService';
import { DocumentExtensionType, DocumentType } from '@common/types';

export const useFetchPublishedDocument = (
  handleAsFileStream: boolean,
  documentId?: string,
  version?: string,
  documentType?: DocumentType,
  extensionType?: DocumentExtensionType,
) => {
  const token = useAppSelector(selectToken);

  const getQueryString = () => {
    const queryParts = [];

    if (extensionType) {
      queryParts.push(`extensionType=${extensionType}`);
    }

    return queryParts.join('&');
  };

  const queryString = getQueryString();

  const { data, isFetching, refetch } = useQuery(
    ['getPublishedDocument', documentId, version, documentType, queryString],
    () => getPublishedDocument(token, documentId!, version!, documentType!, queryString, handleAsFileStream),
    {
      enabled: !!token && !!documentId && !!version && !!documentType,
    },
  );

  return { data, documentFetching: isFetching, refetch };
};
