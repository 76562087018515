import React from 'react';
import { StyledRadio } from './styles';

export interface IRadio {
  name?: string;
  size?: number;
  id?: string;
  isChecked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  value?: string | number;
  disabled?: boolean;
}

export const Radio = ({ name, size = 14, id, isChecked, onChange = () => {}, onClick, value, disabled }: IRadio) => (
  <StyledRadio
    id={id}
    name={name}
    width={size}
    height={size}
    checked={isChecked}
    onChange={onChange}
    onClick={onClick}
    value={value}
    disabled={disabled}
  />
);
