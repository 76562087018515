import React from 'react';
import Divider from '@common/components/Divider/Divider';
import { Button, ButtonTypes } from '@common/components';
import * as Styled from '../DatapharmTable/styles';
import theme from '@common/theme';
import { IProductActions } from '../DatapharmTable/types';
import { ProductStatus } from '@common/features/submission/types';

const ProductActions = ({ status, callbacks }: IProductActions) => {
  const renderButtons = () => {
    switch (status) {
      case ProductStatus.Discontinued:
        return (
          <>
            <Button
              type={ButtonTypes.TEXT}
              text=""
              icon="/icons/reinstate.svg"
              height={25}
              onClick={callbacks?.onReinstatementClick}
              color={theme.elements.primaryMedium}
            />
          </>
        );
      case ProductStatus.Retired:
        return (
          <>
            <Button
              type={ButtonTypes.TEXT}
              text="Update"
              icon="/icons/update.svg"
              height={25}
              onClick={callbacks?.onUpdateClick}
              color={theme.elements.primaryMedium}
            />
            <Divider type="vertical" size="30px" />
          </>
        );
      case ProductStatus.Published:
        return (
          <>
            <Button
              type={ButtonTypes.TEXT}
              text="Update"
              icon="/icons/update.svg"
              height={25}
              onClick={callbacks?.onUpdateClick}
              color={theme.elements.primaryMedium}
            />
            <Divider type="vertical" size="30px" />
            <Button
              type={ButtonTypes.TEXT}
              text="Discontinue"
              icon="/icons/discontinue.svg"
              height={25}
              onClick={callbacks?.onDiscontinueClick}
              color={theme.elements.retireOrange}
            />
          </>
        );
      default:
        return (
          <>
            <Button
              type={ButtonTypes.TEXT}
              text="Update"
              icon="/icons/update.svg"
              height={25}
              onClick={callbacks?.onUpdateClick}
              color={theme.elements.primaryMedium}
            />
            <Divider type="vertical" size="30px" />
            <Button
              type={ButtonTypes.TEXT}
              text="Remove"
              icon="/icons/trash_bin.svg"
              height={25}
              onClick={callbacks?.onRemoveClick}
              color={theme.elements.removeRed}
            />
          </>
        );
    }
  };

  return <Styled.ActionsWrapper>{renderButtons()}</Styled.ActionsWrapper>;
};

export default ProductActions;
