import styled from 'styled-components';

import { ErrorMessage } from '@common/components/ErrorMessage';
import { StyledSVG } from '@common/styles';

export const NoContentError = styled(ErrorMessage)`
  display: flex;
  font-weight: 600;

  ${StyledSVG} {
    margin-right: 10px;
  }
`;
