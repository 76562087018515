import { createSlice } from '@reduxjs/toolkit';
import { SubmissionState } from './types';
import { productTypeSelectOptions } from '@common/constants';
import { createSubmissionDraft, generateProductGroupPlan } from './thunks';

export const initialState: SubmissionState = {
  isLoading: false,
  error: '',
  isReviewPopupOpen: false,
  isDuplicateWarningPopupOpen: false,
  activeSubmissionType: null,
  draftSubmission: {
    productType: {
      value: productTypeSelectOptions[0].value,
      label: productTypeSelectOptions[0].label,
    },
    genericName: '',
    activeIngredients: [],
    error: '',
  },
  productGroupPlan: null,
  documentTitle: '',
  uploadedFileName: '',
  fileName: '',
  fileSize: null,
  isFileBeingUploaded: false,
  uploadProgress: 0,
};

const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setDocumentTitle(state, action) {
      state.documentTitle = action.payload;
    },
    setUploadedFileName(state, action) {
      state.uploadedFileName = action.payload;
    },
    setActiveSubmissionType(state, action) {
      state.activeSubmissionType = action.payload;
    },
    setDraftSubmissionGenericName(state, action) {
      state.draftSubmission.genericName = action.payload;
    },
    setDraftSubmissionProductType(state, action) {
      state.draftSubmission.productType = action.payload;
    },
    setDraftSubmissionError(state, action) {
      state.draftSubmission.error = action.payload;
    },
    removeActiveIngredient(state, action) {
      state.draftSubmission.activeIngredients = state.draftSubmission.activeIngredients.filter((item) => item !== action.payload);
    },
    addActiveIngredient(state, action) {
      state.draftSubmission.activeIngredients.push(action.payload);
    },
    clearDraftSubmissionState(state) {
      state.draftSubmission.activeIngredients = [];
      state.draftSubmission.genericName = '';
      state.activeSubmissionType = null;
      state.draftSubmission.productType = initialState.draftSubmission.productType;
      state.isReviewPopupOpen = false;
      state.isDuplicateWarningPopupOpen = false;
      state.draftSubmission.error = '';
    },
    closeReviewDialog(state) {
      state.isReviewPopupOpen = false;
    },
    closeDuplicateWarning(state) {
      state.isDuplicateWarningPopupOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateProductGroupPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateProductGroupPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.productGroupPlan = action.payload;
      state.isReviewPopupOpen = !action.payload.doesProductGroupExist;
      state.isDuplicateWarningPopupOpen = action.payload.doesProductGroupExist;
    });
    builder.addCase(generateProductGroupPlan.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(createSubmissionDraft.pending, (state) => {
      state.isLoading = true;
      state.isReviewPopupOpen = false;
    });
    builder.addCase(createSubmissionDraft.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createSubmissionDraft.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.isReviewPopupOpen = true;
    });
  },
});

export const {
  setActiveSubmissionType,
  setDraftSubmissionGenericName,
  setDraftSubmissionProductType,
  setDraftSubmissionError,
  addActiveIngredient,
  removeActiveIngredient,
  clearDraftSubmissionState,
  closeReviewDialog,
  closeDuplicateWarning,
  setDocumentTitle,
  setUploadedFileName,
} = submissionSlice.actions;

export default submissionSlice.reducer;
