import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { fetchHtml } from '@common/services/adminService';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

export const useFetchHtml = (enabled: boolean = true) => {
  const token = useAppSelector(selectToken);
  const { taskId = '' } = useParams();

  const { data, isFetching, isFetched } = useQuery(['fetchHtml', taskId], () => fetchHtml(token, taskId), {
    enabled: !!token && !!taskId && enabled,
  });

  return { htmlData: data, isFetchingHtml: isFetching, isHtmlFetched: isFetched };
};
