import React from 'react';
import { Grid } from './styles';
import { HookFormSelectAll } from '@common/components/Select/HookFormSelectAll';
import {
  AuditsForm,
  IAuditsForm,
  ProductReportColumnLabels,
  ProductReportColumns,
  ProductReportColumnValueToLabelMap,
} from './useAuditsForm';
import { StyledLabel } from '@common/components/Select/styles';
import { ProductStatus } from '@common/features/submission/types';
import { useFormContext } from 'react-hook-form';

const { Published, Discontinued, Retired } = ProductStatus;
const productStatuses = [Published, Discontinued, Retired];

const { ProductGroup, ProductName, ProductId, Status, DiscontinuedDate } = ProductReportColumns;

export const ProductReportFilters = () => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IAuditsForm>();

  const productStatusesOptions = productStatuses.map((t) => ({ label: t, value: t }));

  const isDiscontinuedSelected = watch(AuditsForm.productStatuses).find((s) => s.value === Discontinued);
  const columnOptions = Object.values(ProductReportColumns).map((c) => {
    if (
      (!isDiscontinuedSelected && [DiscontinuedDate].includes(c)) ||
      [ProductGroup, ProductName, ProductId, Status].includes(c)
    ) {
      return { label: ProductReportColumnValueToLabelMap[c] as ProductReportColumnLabels, value: c, disabled: true };
    }

    return { label: ProductReportColumnValueToLabelMap[c] as ProductReportColumnLabels, value: c, disabled: false };
  });

  const handleDiscontinueDeselect = () => {
    const statuses = getValues(AuditsForm.productStatuses);
    if (!statuses.find((o) => o.value === Discontinued)) {
      removeDiscontinedDate();
    }
  };

  const handleAllDeselect = () => {
    removeDiscontinedDate();
  };

  const removeDiscontinedDate = () => {
    const columns = structuredClone(getValues(AuditsForm.productColumns));
    const filteredColumns = columns.filter((c) => c.value !== DiscontinuedDate);

    setValue(AuditsForm.productColumns, filteredColumns);
  };

  return (
    <Grid rowCount={2}>
      <StyledLabel>Select product status</StyledLabel>
      <HookFormSelectAll
        name={AuditsForm.productStatuses}
        options={productStatusesOptions}
        error={errors.productStatuses?.message}
        width="20rem"
        onValueChange={handleDiscontinueDeselect}
        onAllDeselect={handleAllDeselect}
        inlineLabel
      />
      <StyledLabel>Select columns</StyledLabel>
      <HookFormSelectAll name={AuditsForm.productColumns} options={columnOptions} width="20rem" inlineLabel />
    </Grid>
  );
};
