import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { Button, ButtonTypes, ProductGroupNameWithSubstances } from '@common/components';
import ProductStatus from '@common/components/ProductStatus/ProductStatus';

import { ProductStatus as ProductStatusType } from '@common/features/submission/types';
import theme from '@common/theme';

import { IPublishedProductDto } from '@common/features/product/types';
import { BlackTriangle, MultiRowText } from '@common/styles';
import { getProductTypeLabel } from '@common/helpers';
import { useCustomSorting } from '@common/components/DatapharmTable/useCustomSorting';

export const useProductColumnSorting = () => {
  var columnSortValues = {
    displayName: 'DisplayName',
    hasBlackTriangle: 'BlackTriangle',
    legalCategories: 'LegalCategories',
    productGroupName: 'ProductGroup',
    productFamilyName: 'ProductFamilyName',
    type: 'ProductType',
    id: 'ProductId',
    status: 'Status',
  };

  return useCustomSorting<IPublishedProductDto>(columnSortValues);
};

export const prepareProductsColumns = (
  handleUpdate: (productGroupId: number, submissionData?: any) => void,
  handleRedirect: (productGroupId: string, productId: string) => void,
) => {
  const columnHelper = createColumnHelper<IPublishedProductDto>();

  return [
    columnHelper.accessor('displayName', {
      header: 'Product Name',
    }),
    columnHelper.accessor('hasBlackTriangle', {
      header: () => <BlackTriangle>{'\u25BC'}</BlackTriangle>,
      cell: ({ getValue }) => (getValue() === null ? '-' : !!getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor('legalCategories', {
      header: 'Legal categories',
      cell: ({ getValue }) => <MultiRowText>{getValue().sort().join('\n')}</MultiRowText>,
    }),
    columnHelper.accessor('productGroupName', {
      header: 'Product Group',
      cell: (item) => {
        const substances = item.row.original.substances;
        return <ProductGroupNameWithSubstances productGroupName={item.getValue()} substances={substances} />;
      },
    }),
    columnHelper.accessor('productFamilyName', {
      header: 'Product Family',
    }),
    columnHelper.accessor('type', {
      header: 'Product type',
      cell: ({ getValue }) => getProductTypeLabel(getValue()),
    }),
    columnHelper.accessor('id', {
      header: 'Product ID',
    }),
    columnHelper.accessor(() => {}, {
      // accessor required to enable sorting of columns fetched from backend https://github.com/TanStack/table/issues/4136
      id: 'status',
      header: 'Status',
      cell: ({ row }) => {
        const { isRetired, isDiscontinued } = row.original;
        var status = isRetired
          ? ProductStatusType.Retired
          : isDiscontinued
          ? ProductStatusType.Discontinued
          : ProductStatusType.Published;
        return <ProductStatus value={status} />;
      },
    }),
    columnHelper.display({
      header: 'Details',
      cell: ({ row }) => {
        const { productGroupId, id } = row.original;
        const onDetailsClick = () => handleRedirect(productGroupId, id);

        return (
          <Button
            value={productGroupId}
            onClick={onDetailsClick}
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/eye.svg"
            height={25}
          />
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const { productGroupId, productFamilyName, productFamilyId, type, productGroupName } = row.original;

        const submissionData = {
          type,
          familyName: productFamilyName,
          familyId: productFamilyId,
          name: productGroupName,
          id: productGroupId,
        };

        const handleClick = () => {
          handleUpdate(parseInt(productGroupId), submissionData);
        };

        return (
          <Button
            type={ButtonTypes.TEXT}
            text="Update"
            icon="/icons/update.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={handleClick}
          />
        );
      },
    }),
  ];
};
