import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const CtaButtonsWrap = styled.div`
  display: flex;
  margin-top: 10px;
  row-gap: 20px;
  column-gap: 20px;
`;

export const StyledSVG = styled(ReactSVG)`
  div {
    display: flex;
  }
`;

export const StyledXmlSVG = styled(ReactSVG)`
  div {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 14px;
  }
`;

export const SubmissionFlowWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const SubmissionFlowInner = styled.div`
  background-color: ${({ theme }) => theme.elements.backgroundWhite};
  padding: 25px 0 0 25px;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const PageTitle = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.pageTitle};
  color: ${({ theme }) => theme.elements.submissionHeading};
`;

export const PageSubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
  margin-bottom: 2rem;
`;

export const MultiRowText = styled.div`
  white-space: pre-line;
`;

export const BlackTriangle = styled.div`
  font-size: 24px;
`;

interface IRedItalicFont {
  lineThrough?: boolean;
}

export const TextForChanges = styled.p<IRedItalicFont>`
  color: ${({ theme }) => theme.colors.red};
  font-style: italic;
  text-decoration: ${({ lineThrough }) => (lineThrough ? `line-through` : `none`)};
`;

export const Title = styled.div<{ small?: boolean }>`
  font-size: ${({ theme, small }) => {
    return small ? theme.elementFontSizes.subSectionTitle : theme.elementFontSizes.modalTitle;
  }};
  font-weight: 600;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.elements.textGrey};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.elements.textDarkGrey};
`;

export const InputWrap = styled.div`
  margin-top: 22px;
  max-width: 400px;
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.elements.primaryMedium};
  font-weight: 600;
`;

export const GroupedCheckboxSections = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
`;

interface IRectangleSkeletonLoader {
  height?: string;
  width?: string;
}
export const RectangleSkeletonLoader = styled.div<IRectangleSkeletonLoader>`
  width: ${({ width }) => width ?? '40vw'};
  height: ${({ height }) => height ?? '5vh'};
  border-radius: 10px;

  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => theme.elements.lightGrey};
    }
    100% {
      background-color: ${({ theme }) => theme.elements.disabledInputBg};
    }
  }
`;

export const HtmlView = styled.div`
  li {
    margin-left: 40px;
  }
  * {
    margin-left: 5px;
    font-family: 'Montserrat', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  font-size: 12pt;
  overflow: auto;
  min-height: 150px;
  margin: 0px;
  padding: 0px;
  border-radius: 14px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  outline: none;
`;
