import { useTaskStatusChange } from '@common/hooks/useTaskStatusChange';
import { SubmissionTaskStatuses } from '@common/services/types';
import { ITaskIdSubmissionId } from '@common/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const useCancelSubmissionTaskModal = (onCancelSuccess?: (submissionId: number) => void) => {
  const [idForCancelSubmission, setIdForCancelSubmission] = useState<ITaskIdSubmissionId | undefined>(undefined);
  const { changeTaskStatus, isChangingTaskStatus } = useTaskStatusChange();

  const methods = useForm();
  const { handleSubmit: handleSubmitForm, reset } = methods;

  const handleCancelSubmissionTaskSuccess = () => {
    setIdForCancelSubmission(undefined);
    onCancelSuccess && onCancelSuccess(idForCancelSubmission?.submissionId!);
    reset({});
  };

  const handleCancelSubmissionTaskSubmit = () =>
    handleSubmitForm(({ additionalInformation }) => {
      changeTaskStatus(
        {
          additionalInformation,
          taskId: idForCancelSubmission!.taskId.toString(),
          status: SubmissionTaskStatuses.Cancelled,
        },
        { onSuccess: handleCancelSubmissionTaskSuccess },
      );
    })();

  const handleCancelSubmissionTaskCancel = () => {
    setIdForCancelSubmission(undefined);
    reset({});
  };

  const handleCancelSubmissionTask = (taskId: number, submissionId: number, index: number) =>
    setIdForCancelSubmission({ taskId, submissionId });

  return {
    handleCancelSubmissionTaskCancel,
    handleCancelSubmissionTaskSubmit,
    handleCancelSubmissionTask,
    cancelSubmissionTaskForm: methods,
    idForCancelSubmission,
    isCancellingTask: isChangingTaskStatus,
  };
};
