import styled from 'styled-components';
import { ComponentWrap, InputWrap } from '@common/components/Input/styles';
import { Select } from '@common/components/Select/styles';

export const BrandOrGenericNameInputWrapper = styled.div`
  ${Select} {
    flex-basis: 48%;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
`;

export const BrandOrGenericNameInput = styled.div`
  flex-basis: 48%;

  ${ComponentWrap} {
    ${InputWrap} {
      padding: 10px 15px;
      height: auto;
      border-radius: 10px;

      input {
        font-size: ${({ theme }) => theme.elementFontSizes.input};
        margin: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      svg {
        width: 24px;
        height: 24px;
        fill: #b7b5b6;
      }
    }
  }
`;
