import { Select } from '@common/components/Select/styles';
import styled from 'styled-components';

export const AddNoteWrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 700px;
  row-gap: 30px;

  ${Select} {
    width: 350px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: ${({ theme }) => theme.elementFontSizes.modalTitle};
  font-weight: bold;
`;
