import styled from 'styled-components';
import { Select } from '../Select/styles';

export const Wrapper = styled.div`
  ${Select} {
    max-width: 270px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 15px;
`;
