import React from 'react';
import {
  BadgeElementWrapper,
  ContentHeaderSummary,
  ContentHeaderWrapper,
  Description,
  Subtitle,
  TableWrapper,
} from '@common/features/productGroup/styles';
import NoContentPanel from '@components/NoContentPanel/NoContentPanel';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { DocumentType } from '@common/types';
import { IDocumentDto } from '@common/features/submission/types';
import Badge from '@common/components/Badge/Badge';
import { prepareColumns } from './helpers';
import { IDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { getDocumentTypeLabel } from '@common/helpers';
import { getDocumentAttributes } from '@common/features/document/helpers';

interface ISubTabContent {
  contentType: DocumentType;
  documents: IDocumentDto[];
  onContentDetailsClick: (documentId: string) => void;
}

const ReadonlyDocumentsTabContent = ({ contentType, documents, onContentDetailsClick }: ISubTabContent) => {
  const handleDetailsClick = (documentId: string) => {
    onContentDetailsClick(documentId.toString());
  };

  const readonlyDocumentTableCallbacks: IDocumentTableCallbacks = {
    onDetailsClick: handleDetailsClick,
  };

  const documentAttributes = getDocumentAttributes(contentType, false);

  const columns = prepareColumns(false, readonlyDocumentTableCallbacks, documentAttributes);

  const contentTypeLabel = getDocumentTypeLabel(contentType);

  return (
    <>
      <ContentHeaderWrapper>
        <ContentHeaderSummary>
          <BadgeElementWrapper>
            <Subtitle>{contentTypeLabel}(s)</Subtitle>
            <Badge value={documents?.length} data-testid={`${contentType}-counter`} />
          </BadgeElementWrapper>
          <Description>
            These are all the {contentTypeLabel}s that are part of this product group, a {contentTypeLabel} can be associated to
            more than one product/presentation in the product group.
          </Description>
        </ContentHeaderSummary>
      </ContentHeaderWrapper>
      <TableWrapper>
        {!documents || documents.length === 0 ? (
          <NoContentPanel
            title="No content found"
            helpMessage="How do I add content?"
            helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
          />
        ) : (
          <DatapharmTable documents={documents} columns={columns} data-testid="documents-table" />
        )}
      </TableWrapper>
    </>
  );
};

export default ReadonlyDocumentsTabContent;
