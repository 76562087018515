import { Id } from '@common/types';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { reinstateProduct } from '@common/services/submissionService';
import { useParams } from 'react-router';
import { useAppSelector } from './redux';
import { selectToken } from '@common/features/user/selectors';

interface Props {
  productId: Id;
}

export const useReinstateProduct = () => {
  const { submissionId = '' } = useParams();
  const token = useAppSelector(selectToken);

  const { mutate, isLoading } = useWaitingMutation(({ productId }: Props) => reinstateProduct(token, submissionId, productId));

  return { reinstateProduct: mutate, isReinstatingProduct: isLoading };
};
