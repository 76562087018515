import React, { ReactNode } from 'react';
import * as Styled from '@common/features/productGroup/styles';

interface ICard {
  title?: string;
  children: ReactNode;
}

export const Card = ({ title, children }: ICard) => (
  <Styled.Card>
    <Styled.ContentWrapper>
      {title && (
        <Styled.ContentHeaderWrapper>
          <Styled.Subtitle>{title}</Styled.Subtitle>
        </Styled.ContentHeaderWrapper>
      )}
      {children}
    </Styled.ContentWrapper>
  </Styled.Card>
);
