import { IoIosArrowDown } from 'react-icons/io';
import styled from 'styled-components';
import { StyledButton } from '../Button/styles';

export const Table = styled.table`
  flex: 1;
  text-align: left;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.elements.borderGrey};
    text-align: left;
    padding: 0.5rem;
    vertical-align: middle;
  }
`;

export const Header = styled.thead`
  position: sticky;
  top: -22px;
  background-color: ${({ theme }) => theme.elements.tabBackgroundGrey};

  tr {
    outline: 1px solid ${({ theme }) => theme.elements.borderGrey};
  }
`;

export const HeaderEntry = styled.th`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 10px 5px 10px 15px;

  &.sortable {
    cursor: pointer;
  }
`;

export const SortIndicator = styled(IoIosArrowDown)`
  color: ${({ theme }) => theme.elements.textGrey};
  transition: all 0.2s ease-out;
  margin-left: 5px;
  opacity: 0;

  &.desc {
    opacity: 1;
  }

  &.asc {
    opacity: 1;
    transform: rotate(180deg);
  }
`;

export const Body = styled.tbody`
  max-height: 250px;
  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.elements.grey};
  }
`;

export const BodyEntry = styled.td`
  font-size: 14px;
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 18px 5px 0 15px;
  vertical-align: top;
  height: 75px;
`;

export const Details = styled.td`
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 0 !important;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: top;
  white-space: nowrap;

  ${StyledButton} {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

interface IRow {
  isSelected?: boolean;
}

export const Row = styled.tr<IRow>`
  outline: 1px double ${({ theme, isSelected }) => (isSelected ? `${theme.elements.primaryMedium}` : 'none')};
`;

export const ExpandRowIcon = styled.div`
  display: flex;
  align-items: start;
  text-align: center;
  height: 100%;
  font-size: 24px;

  svg {
    cursor: pointer;
  }
`;
