import { Select } from '@common/components/Select/styles';
import styled from 'styled-components';

export const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 40%;
  min-width: 40rem;

  .auditType {
    width: 50%;
  }

  .filterSelect {
    min-width: 70% !important;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  ${Select} {
    width: 400px;
  }
`;

export const CheckboxGrid = styled.div`
  display: grid;

  grid-template-columns: repeat(2, 13rem);
  grid-template-rows: repeat(2, 2rem);

  div {
    display: flex;
    align-items: center;
  }
`;

export const Grid = styled.div<{ rowCount: number }>`
  display: grid;
  align-items: center;
  grid-template-columns: max-content minmax(10rem, 20rem);
  grid-template-rows: repeat(${({ rowCount }) => rowCount}, auto);
  grid-gap: 1rem;
`;
