import React from 'react';
import * as Styled from './HealthCareProfessional/styles';
import { Checkbox } from '@common/components';
import { IConfirmationWrapper } from './HealthCareProfessional/types';

export const ConfirmationWrapper = ({ value, text, disabled, name, onChange }: IConfirmationWrapper) => {
  const changeHandler = () => {
    if (disabled) {
      return;
    }
    onChange(!value);
  };
  return (
    <Styled.ConfirmationWrap isConfirmed={value} onClick={changeHandler} disabled={disabled}>
      <Checkbox disabled={disabled} name={name} id={name} isChecked={value} />
      <Styled.ConfirmationText>
        <label htmlFor={name}>{text}</label>
      </Styled.ConfirmationText>
    </Styled.ConfirmationWrap>
  );
};
