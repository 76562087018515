import React from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';
import Divider from '../Divider/Divider';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
}

export const ConfirmSubmissionCancellation = ({ onConfirm, onCancel, isOpen, isLoading = false }: IProps) => (
  <ModalWrapper isOpen={isOpen}>
    <Styled.TitleIcon src={'/icons/warning-sign.svg'} />
    <Styled.Title>Confirm submission cancellation</Styled.Title>
    <Styled.SubTitle>
      {'Cancelling the submission will also cancel all associated tasks, please\nconfirm if you want to continue.'}
    </Styled.SubTitle>
    <Styled.Footer>
      <Button onClick={onCancel} text="Cancel" type={ButtonTypes.TEXT} data-testid="cancel-button" />
      <Divider type="vertical" size="50px" thickness={1} />
      <Button onClick={onConfirm} text="Confirm" type={ButtonTypes.PRIMARY} isLoading={isLoading} />
    </Styled.Footer>
  </ModalWrapper>
);
