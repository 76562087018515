import React from 'react';
import { NewsTableWrap, NewsItem, NewsDate, NewsDescription, NewsName } from './styles';
import { Link } from '../styles';
import { News } from './types';
import DashboardSection from '../DashboardSection/DashboardSection';

interface INewsSection {
  newsItems: Array<News>;
  title: string;
}

const NewsSection = ({ title, newsItems }: INewsSection) => {
  return (
    <DashboardSection title={title}>
      <NewsTableWrap>
        {newsItems.map((x, i) => {
          var addSeparator = i < newsItems.length - 1;
          return (
            <NewsItem addSeparator={addSeparator} key={'news-' + i}>
              <NewsDate>{x.date}</NewsDate>
              <NewsName>
                <Link target="_blank" href={x.link}>
                  {x.title}
                </Link>
              </NewsName>
              <NewsDescription>{x.decription}</NewsDescription>
            </NewsItem>
          );
        })}
      </NewsTableWrap>
    </DashboardSection>
  );
};

export default NewsSection;
