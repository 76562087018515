import styled, { css } from 'styled-components';
import { StyledLink } from '@common/components/Link/styles';
import { StyledSVG } from '@common/styles';

export const SidebarWrapper = styled.div`
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
  min-width: 200px;

  ${StyledLink} {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 15px 10px;
    border-radius: 4px;
  }
`;

const selectedStyle = css`
  background-color: ${({ theme }) => theme.elements.primaryLight};
  color: ${({ theme }) => theme.elements.primary};
`;

const unselectedStyle = css`
  background-color: white;
  color: ${({ theme }) => theme.elements.unselectedSidebarEntry};
  :hover {
    background-color: ${({ theme }) => theme.elements.primaryLight};
  }
`;

interface ISidebarEntry {
  isSelected: boolean;
}

export const SidebarEntry = styled.div<ISidebarEntry>`
  ${({ isSelected }) => (isSelected ? selectedStyle : unselectedStyle)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.elements.primaryMedium : 'transparent')};
  color: ${({ theme, isSelected }) => (isSelected ? theme.elements.defaultWhite : theme.elements.textLightGrey)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;

  ${StyledSVG} {
    display: flex;
    align-items: center;
  }
`;
