import { ProductStatus } from '@common/features/submission/types';
import { ProductTypeSelectLabels, ProductTypeSelectValues } from '@common/types';

enum legalCategoryTypes {
  Pom,
  P,
  GSL,
  CE_MARK,
}

export interface ILegalCategory {
  id: legalCategoryTypes;
  text: string;
  value: string;
  searchValue: string;
}

export const legalCategories: ILegalCategory[] = [
  {
    id: legalCategoryTypes.Pom,
    text: 'Prescription only medicine',
    value: 'POM',
    searchValue: 'Prescription only medicine',
  },
  {
    id: legalCategoryTypes.P,
    text: 'Pharmacy',
    value: 'P',
    searchValue: 'Pharmacy',
  },
  {
    id: legalCategoryTypes.GSL,
    text: 'General sales list',
    value: 'GSL',
    searchValue: 'General sales list',
  },
  {
    id: legalCategoryTypes.CE_MARK,
    value: 'CE Mark',
    text: 'CE Mark',
    searchValue: 'CE Mark',
  },
];

export const legalCategoriesCheckboxes = [
  {
    ...legalCategories.find((x) => x.id === legalCategoryTypes.Pom)!,
    tooltipText: `Prescription-Only Medicine (POM) - has to be prescribed by a doctor or other authorised health professional and it has to be dispensed from a pharmacy or from another specifically licensed place`,
  },
  {
    ...legalCategories.find((x) => x.id === legalCategoryTypes.P)!,
    tooltipText: `Pharmacy (P) - an intermediate level of control, can be bought only from pharmacies and under a pharmacist's supervision`,
  },
  {
    ...legalCategories.find((x) => x.id === legalCategoryTypes.GSL)!,
    tooltipText: `General Sales List (GSL) - may be bought from retail stores, such as a newsagent, a supermarket or a vending machine in a shop`,
  },
  { ...legalCategories.find((x) => x.id === legalCategoryTypes.CE_MARK)! },
];

export const productTypeCheckboxes = [
  {
    id: 1,
    text: ProductTypeSelectLabels.BRANDED_INNOVATOR,
    value: ProductTypeSelectValues.BRANDED_INNOVATOR,
  },
  {
    id: 2,
    text: ProductTypeSelectLabels.GENERIC,
    value: ProductTypeSelectValues.GENERIC,
  },
  {
    id: 3,
    text: ProductTypeSelectLabels.BRANDED_GENERIC,
    value: ProductTypeSelectValues.BRANDED_GENERIC,
  },
];

export const productStatusCheckboxes = [
  {
    id: 1,
    text: ProductStatus[ProductStatus.Published],
    value: ProductStatus[ProductStatus.Published],
  },
  {
    id: 2,
    text: ProductStatus[ProductStatus.Retired],
    value: ProductStatus[ProductStatus.Retired],
  },
  {
    id: 3,
    text: ProductStatus[ProductStatus.Discontinued],
    value: ProductStatus[ProductStatus.Discontinued],
  },
];

export const hasBlackTriangleOptions = ['true', 'false'];
