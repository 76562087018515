import styled from 'styled-components';

export const FiltersBadgeWrapper = styled.div`
  display: inline-block;

  span {
    color: white;
    background-color: ${({ theme }) => theme.elements.removeRed};
    margin-left: 5px;
    border-radius: 50%;
  }
`;
