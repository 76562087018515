import React, { ReactNode } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginScopes } from './msalConfig';
import { MsalError } from '../components/MsalError/MsalError';

interface IAuth {
  children: ReactNode;
}

export const AuthGuard = ({ children }: IAuth) => {
  const request = {
    scopes: loginScopes,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={request}
      errorComponent={MsalError}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
