import styled from 'styled-components';

import { ReactSVG } from 'react-svg';

interface IStyledSVG {
  isChecked: boolean;
}

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: -24px;
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;

export const StyledSVG = styled(ReactSVG).withConfig({
  shouldForwardProp: (prop) => !['isChecked'].includes(prop),
})<IStyledSVG>`
  border: ${({ isChecked, theme }) => (isChecked ? 'none' : `1px solid ${theme.colors.borderGray}`)};
  border-radius: 3px;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;

  svg {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
`;

export const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
