import styled from 'styled-components';

const spacing = '10px';
const arrowWidth = '19px';
const arrowStart = '-21px';

export const ChangeEntry = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-radius: 10px;
  border: 1px ${({ theme }) => theme.elements.primaryMedium} solid;
  padding: 9px 13px;
  position: relative;
  background-color: ${({ theme }) => theme.elements.infoBackground};

  .entry-title {
    font-weight: bold;
  }

  & + & {
    margin-top: ${spacing};
  }

  ul {
    margin-left: 1em;
    overflow-wrap: break-word;
    width: 85%;
  }

  ul ul {
    font-size: 13px;
    color: ${({ theme }) => theme.elements.textDarkGrey};
    list-style: disc;
  }
`;

export const EntryHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChangeList = styled.div`
  & & {
    padding-top: ${spacing};
    padding-left: ${arrowWidth};
    margin-left: 17px;

    ${ChangeEntry} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 10px;
        width: ${arrowWidth};
        top: calc(50% - 10px);
        left: ${arrowStart};
        border-radius: 0 0 0 10px;
        border-left: 1px ${({ theme }) => theme.elements.primaryMedium} solid;
        border-bottom: 1px ${({ theme }) => theme.elements.primaryMedium} solid;
      }

      &:last-child::after {
        content: '';
        display: block;
        position: absolute;
        width: ${arrowWidth};
        top: -11px;
        bottom: calc(50% + 7px);
        left: ${arrowStart};
        border-left: 1px ${({ theme }) => theme.elements.primaryMedium} solid;
      }
    }
  }
`;
