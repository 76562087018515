import * as Styled from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { ConfirmationWrapper } from '../ConfirmationWrapper';

export const HealthCareProfessional = () => {
  const { control } = useFormContext();
  return (
    <Styled.RegulatorApprovalWrap>
      <Styled.ConfirmationHeading>Who should be able to view this content?</Styled.ConfirmationHeading>
      <Styled.ConfirmationHeading>
        If 'Healthcare Professional' is selected user will have to self certify as an HCP to view
      </Styled.ConfirmationHeading>
      <Controller
        control={control}
        name={ContentDetailsFormField.isVisibleToHCP}
        render={({ field: { value, onChange } }) => (
          <ConfirmationWrapper name="HCP" value={value} onChange={onChange} text={`Healthcare professionals only`} />
        )}
      />
    </Styled.RegulatorApprovalWrap>
  );
};
