import React from 'react';
import { StyledSVG, StyledCheckbox, FlexWrap } from './styles';

export interface ICheckbox {
  name: string;
  id?: string;
  readonly?: boolean;
  isChecked: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ['data-testid']?: string;
}

export const Checkbox = ({ name, id, isChecked, readonly = false, disabled, onChange, ...other }: ICheckbox) => (
  <FlexWrap>
    <StyledCheckbox data-testid={other['data-testid']} defaultChecked={isChecked}  readOnly={readonly} name={name} onChange={onChange} id={id} disabled={disabled} />
    <StyledSVG 
      isChecked={isChecked}
      name={name}
      src={isChecked ? (disabled ? `/icons/square_tick_gray.svg` : `/icons/square_tick.svg`) : ''}
    />
  </FlexWrap>
);
