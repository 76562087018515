import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  html * {
    font-family: 'Open Sans', sans-serif;
  }
  body {
    background-color: ${theme.elements.background};
    min-height: 100%;
  }
  #root {
    display: flex;
    height: 100vh;
  }
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  .disabled-scrollbar {
    overflow: hidden;
  }
`;

export default GlobalStyle;
