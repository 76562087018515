import { ISelectOption } from '@common/types';
import { useState } from 'react';

export interface ITypeaheadResult {
  items?: ISelectOption[];
  isLoading: boolean;
}

export type ITypeaheadQuery = (prefix: string) => ITypeaheadResult;

export const emptyTypeaheadQuery = () => ({ isLoading: false });

export const useFormTypeahead = (fetchData: ITypeaheadQuery) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { items, isLoading } = fetchData(searchTerm);

  const onInputChange = (value: string) => {
    setSearchTerm(value);
  };

  const clear = () => {
    setSearchTerm('');
  };

  return {
    isLoading,
    items,
    clear,
    onInputChange,
  };
};
