import { useState } from 'react';
import { useSoftDeleteSubmission } from '@hooks/useSoftDeleteSubmission';

export const useConfirmSubmissionRemoval = (onCancelSubmissionSuccess?: (submissionId: number) => void) => {
  const [submissionIdForRemoval, setSubmissionIdForCancel] = useState<number | undefined>(undefined);
  const { removeSubmission, isRemovingSubmission } = useSoftDeleteSubmission();

  const handleRemovalSubmissionSuccess = () => {
    setSubmissionIdForCancel(undefined);
    onCancelSubmissionSuccess && onCancelSubmissionSuccess(submissionIdForRemoval!);
  };
  const handleConfirmRemoval = () => removeSubmission(submissionIdForRemoval!, { onSuccess: handleRemovalSubmissionSuccess });
  const handleRemovalCancellationCancel = () => setSubmissionIdForCancel(undefined);
  const handleSubmissionRemoval = (submissionId: number) => setSubmissionIdForCancel(submissionId);

  return {
    submissionIdForRemoval,
    isRemovingSubmission,
    handleConfirmRemoval,
    handleRemovalCancellationCancel,
    handleSubmissionRemoval,
  };
};
