export const routes: Routes = {
  login: '/login',
  logout: '/logout',
  silentRenew: '/silentRenew',
  newToken: '/newtoken',
  forgotPassword: '/forgotpassword',
  callback: '/callback',
  logoutCallback: '/logout/callback',
  submissions: '/submissions',
  drafts: '/drafts',
  products: '/allProducts',
  audits: '/audits',
  productGroups: '/product-groups',
  product: '/product',
  createSubmission: '/createSubmission',
};

interface Routes {
  login: string;
  logout: string;
  silentRenew: string;
  newToken: string;
  forgotPassword: string;
  callback: string;
  logoutCallback: string;
  submissions: string;
  products: string;
  drafts: string;
  audits: string;
  product: string;
  productGroups: string;
  createSubmission: string;
}

export const paths: Paths = {
  submissionSummary: 'submissionSummary',
  submissionTasks: 'submission-tasks',
  html: 'html',
};

interface Paths {
  submissionSummary: string;
  submissionTasks: string;
  html: string;
}
