import { Wrapper } from './styles';
import { ChangeType } from '@common/features/submission/types';
import { CellWithChanges } from '../CellWithChanges/CellWithChanges';

interface IProps {
  isGlutenFree: boolean | null;
  isLactoseFree: boolean | null;
  isSuitableForVegans: boolean | null;
  isPregnancyRisk: boolean | null;
  lactoseFreeChangeType?: ChangeType;
  glutenFreeChangeType?: ChangeType;
  suitableForVegansChangeType?: ChangeType;
  pregnancyRiskChangeType?: ChangeType;
}

const booleanToString = (value: boolean | null) => (value === null ? '-' : value ? 'Yes' : 'No');

export const AllergyAndPregnancyRiskInfo = ({
  isGlutenFree,
  isLactoseFree,
  isSuitableForVegans,
  isPregnancyRisk,
  lactoseFreeChangeType,
  glutenFreeChangeType,
  suitableForVegansChangeType,
  pregnancyRiskChangeType,
}: IProps) => {
  const lactoseFreeValue = `Lactose Free - ${booleanToString(isLactoseFree)}\n`;
  const glutenFreeValue = `Gluten Free - ${booleanToString(isGlutenFree)}\n`;
  const vegetarianValue = `Vegetarian - ${booleanToString(isSuitableForVegans)}\n`;
  const pregnancyRiskValue = `Pregnancy risk information available - ${booleanToString(isPregnancyRisk)}\n`;

  return (
    <Wrapper>
      <CellWithChanges value={lactoseFreeValue} changeType={lactoseFreeChangeType} />
      <CellWithChanges value={glutenFreeValue} changeType={glutenFreeChangeType} />
      <CellWithChanges value={vegetarianValue} changeType={suitableForVegansChangeType} />
      <CellWithChanges value={pregnancyRiskValue} changeType={pregnancyRiskChangeType} />
    </Wrapper>
  );
};
