import React from 'react';
import * as Styled from './styles';
import { FormInput } from '@common/components/FormInput/styles';
import { Select } from '@common/components';
import { createSelectOption } from '@common/components/Select/helpers';
import { IProductInfo } from './types';
import { ActiveIngredientsStrengthDisplay } from '@common/components/ActiveIngredientsStrength/ActiveIngredientsStrengthDisplay';
import Divider from '@common/components/Divider/Divider';
import { reasonsForDiscontinueOptions } from '@common/features/reasons/discontinuationReasons';
import { ProductFormPaths } from '../../hooks/useProductForm';
import { ReadonlyProductNames } from '../../../ProductNames/ReadonlyProductNames';

const ReadOnlyProductInfo = ({ product, productType, activeIngredients, productGroupName }: IProductInfo) => {
  const divider = <Divider type="horizontal" size="100%" margin={30} />;

  const numerators = activeIngredients.map((a) => ({ numeratorUnit: a.numeratorUnit, numeratorValue: a.numeratorValue }));

  return (
    <Styled.TabContentWrap className="product__tabContentWrap">
      <Styled.LeftCard>
        <Styled.ProductInformation>
          <Styled.TabTitle>Product info</Styled.TabTitle>
          <Styled.FormRow>
            <FormInput label="Product type" name="Product type" value={productType} />
            <FormInput label="Brand/generic name" name="Generic name" value={productGroupName} />
          </Styled.FormRow>
          {numerators && numerators.length > 0 ? (
            <>
              {divider}
              <ActiveIngredientsStrengthDisplay activeIngredients={activeIngredients} />
              {divider}
            </>
          ) : (
            divider
          )}
          <Styled.FormRow>
            <Select
              value={createSelectOption(product?.productForm)}
              label="Product form"
              readonly
              name="productForm"
              onChange={() => {}}
              options={[]}
            />
            <FormInput label="Extra information" name="ExtraInformation" value={product?.extraInformation} disabled />
          </Styled.FormRow>
          {product?.discontinuationReason && (
            <>
              {divider}
              <Styled.FormRow>
                <Select
                  label="Reason for Discontinue"
                  name={ProductFormPaths.discontinuationReason}
                  options={reasonsForDiscontinueOptions}
                  disabled={true}
                  value={createSelectOption(product?.discontinuationReason)}
                  onChange={() => {}}
                />
              </Styled.FormRow>
            </>
          )}
        </Styled.ProductInformation>
        <ReadonlyProductNames emcNameValue={product?.name} medicinalNameValue={product?.regulatorApprovedName} />
      </Styled.LeftCard>
    </Styled.TabContentWrap>
  );
};

export default ReadOnlyProductInfo;
