import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';
import Tabs from '@common/components/Tabs/Tabs';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import { ProductHistory } from './tabs/ProductHistory';
import { ProductDetails } from '@components/ProductDetails/ProductDetails';
import { HeaderBreadcrumb, Link } from '@components/SubmissionFlowHeader/styles';
import { AssociatedContent } from './tabs/AssociatedContent';
import { ProductInfo } from './tabs/ProductInfo';
import * as Styled from './tabs/ProductInfo/styles';
import { AssociatedContentReadonlySections, ProductInfoReadonlySections, ProductTabs } from './types';
import {
  getAssociatedProducts,
  getDocumentAfterSelectionChanged,
  getProductType,
  mapErrors,
  prepareUpdateProductDetailsData,
} from './helpers';
import { AlreadyAssociatedProductsModal } from './tabs/AssociatedContent/AlreadyAssociatedProductsModal';
import { useUpdateProductDetails } from './hooks/useUpdateProductDetails';
import { Id } from '@common/types';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { IProductDto, ISubmissionDto } from '@common/features/submission/types';
import { useDiscontinueProduct } from '@common/hooks/useDiscontinueProduct';
import ReadOnlyProductInfo from './tabs/ProductInfo/ReadOnlyProductInfo';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useProductForm, IProductForm, ProductFormPaths } from './hooks/useProductForm';
import { NotifyModal } from '@common/components/NotifyModal';
import { ValidateProductErrors } from '../ValidateProductErrors/ValidateProductErrors';

export const tabsLabelsWrapperStyle: React.CSSProperties = {
  paddingLeft: '40px',
};

const errorIcon = '/icons/small_warning_icon.svg';

interface ISubmissionProductContent {
  product?: IProductDto;
  submission: ISubmissionDto;
  productInfoReadonlySections?: ProductInfoReadonlySections;
  associatedContentReadonlySections?: AssociatedContentReadonlySections;
  isProductDiscontinued?: boolean;
  isProductRetired?: boolean;
  onCancel: () => void;
  onResultSuccess?: (() => void) | null;
  shouldDisplayAmpId: boolean;
  shouldDisplayContactGroup: boolean;
}

const SubmissionProductContent = ({
  product,
  submission,
  productInfoReadonlySections = {},
  associatedContentReadonlySections,
  isProductDiscontinued,
  isProductRetired,
  onCancel,
  onResultSuccess,
  shouldDisplayAmpId,
  shouldDisplayContactGroup,
}: ISubmissionProductContent) => {
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>(state?.tab ?? ProductTabs.ProductInfo);
  const [isValidateOpen, setIsValidateOpen] = useState(false);

  const { showProductHistory } = useFeatureFlags();

  const productId = product?.productId!;
  const submissionId = submission.submissionId;

  const isMissingProductNameDetails = !product?.hasPublishedIngredientStrenghts;

  const activeIngredients = useMemo(() => product?.activeIngredients ?? [], [product?.activeIngredients]);

  const productType = getProductType(submission?.productGroup.productType);

  const productGroupName = submission?.productGroup.productGroupName ?? '';

  const documents = submission?.documents ?? [];

  const {
    getValues,
    productFormMethods,
    handleSubmit: handleSubmitProductForm,
    formState: { dirtyFields, errors },
    isProductDetailsValid,
    isProductInfoValid,
    isAssociatedContentValid,
    documentIdAfterSelectionChanged,
    draftOrPublishedDocuments,
    showAssociatedContentWarning,
    handleFileOpen,
    handleSelect,
    handleConfirm,
  } = useProductForm({ productGroupName, product, documents, activeIngredients });

  const documentAfterSelectionChanged = getDocumentAfterSelectionChanged(submission?.documents, documentIdAfterSelectionChanged);

  const associatedProducts = getAssociatedProducts(
    productId,
    submission?.products!,
    documentAfterSelectionChanged?.linkedProductIds!,
  );

  const { updateProductDetails, isUpdatingProductDetails } = useUpdateProductDetails(submissionId, productId);
  const { discontinueProduct, isDiscontinuingProduct } = useDiscontinueProduct();

  const resultActions = {
    onSuccess: () => {
      toast.success('Draft saved');
      if (onResultSuccess) onResultSuccess();
    },
  };

  const handleUpdateProductDetails = () => {
    if (isProductDiscontinued && !isDiscontinuingProduct) {
      discontinueProduct({ reason: getValues(ProductFormPaths.discontinuationReason)!, productId }, resultActions);
    } else {
      if (!isUpdatingProductDetails) {
        handleSubmitProductForm(
          (data: IProductForm) =>
            updateProductDetails(prepareUpdateProductDetailsData(data, dirtyFields, product!), resultActions),
          () => setIsValidateOpen(true),
        )();
      }
    }
  };

  const handleCancelValidationModal = () => setIsValidateOpen(false);

  const getUploadHandlers = () => (isProductDiscontinued ? undefined : handleUpdateProductDetails);

  const getItems = useCallback(() => {
    const items = [
      {
        label: ProductTabs.ProductInfo,
        content:
          isMissingProductNameDetails || isProductDiscontinued ? (
            <ProductInfo
              product={product!}
              productType={productType}
              activeIngredients={activeIngredients}
              productGroupName={productGroupName}
              showError={!isProductInfoValid && !isProductDiscontinued}
              productInfoReadonlySections={productInfoReadonlySections}
            />
          ) : (
            <ReadOnlyProductInfo
              product={product!}
              productType={productType}
              activeIngredients={activeIngredients}
              productGroupName={productGroupName}
            />
          ),
        icon: !isProductInfoValid && !isProductDiscontinued ? errorIcon : undefined,
      },
      {
        label: ProductTabs.AssociatedContent,
        content: (
          <AssociatedContent
            showMissingAssociationWarning={showAssociatedContentWarning && !isProductDiscontinued}
            showRetiredProductWarning={!!isProductRetired}
            documents={draftOrPublishedDocuments}
            onSelect={handleSelect}
            onFileOpen={(documentId: Id) => handleFileOpen(submissionId, documentId)}
            disabledSections={associatedContentReadonlySections}
          />
        ),
        icon: !isAssociatedContentValid && !isProductDiscontinued ? errorIcon : undefined,
      },
      {
        label: ProductTabs.ProductDetails,
        content: (
          <ProductDetails
            readonly={isProductDiscontinued}
            shouldDisplayContactGroup={shouldDisplayContactGroup}
            shouldDisplayAmpId={shouldDisplayAmpId}
          />
        ),
        icon: !isProductDetailsValid && !isProductDiscontinued ? errorIcon : undefined,
      },
    ];

    if (showProductHistory) items.push({ label: ProductTabs.ProductHistory, content: <ProductHistory />, icon: undefined });

    return items;
  }, [
    activeIngredients,
    associatedContentReadonlySections,
    draftOrPublishedDocuments,
    handleFileOpen,
    handleSelect,
    isAssociatedContentValid,
    isMissingProductNameDetails,
    isProductDetailsValid,
    isProductDiscontinued,
    isProductInfoValid,
    isProductRetired,
    product,
    productGroupName,
    productInfoReadonlySections,
    productType,
    shouldDisplayAmpId,
    shouldDisplayContactGroup,
    showAssociatedContentWarning,
    showProductHistory,
    submissionId,
  ]);

  const mappedErrors = mapErrors(errors);

  return (
    <>
      <Styled.LayoutWrapper>
        <SubmissionFlowHeader title={product?.name}>
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Product
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        <Styled.ProductWrap>
          <FormProvider {...productFormMethods}>
            <Tabs
              initialTab={state?.tab}
              items={getItems()}
              styles={{
                tabsLabelsWrapper: tabsLabelsWrapperStyle,
              }}
              selectedTab={selectedTab}
              onTabSelected={(selectedTab) => setSelectedTab(selectedTab)}
            />
          </FormProvider>
        </Styled.ProductWrap>
        <SubmissionFlowFooter onCancel={onCancel} onContinue={getUploadHandlers()} continueText="Save changes" />
      </Styled.LayoutWrapper>
      <AlreadyAssociatedProductsModal
        isOpen={!!documentIdAfterSelectionChanged && (documentAfterSelectionChanged?.linkedProductIds?.length ?? 0) > 1}
        document={documentAfterSelectionChanged?.documentTitle ?? ''}
        products={associatedProducts}
        onConfirm={handleConfirm}
      />
      <NotifyModal
        isOpen={isValidateOpen}
        title="Please add the following missing information"
        onClose={handleCancelValidationModal}
      >
        <ValidateProductErrors
          productInfoErrors={mappedErrors.productInfoErrors}
          associatedContentErrors={mappedErrors.associatedContentErrors}
          productDetailsErrors={mappedErrors.productDetailsErrors}
        />
      </NotifyModal>
    </>
  );
};

export default SubmissionProductContent;
