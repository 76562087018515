import { useDispatch } from 'react-redux';
import { setThirdPartyLink } from '../globalSlice';

export const useThirdPartyLinkWarning = () => {
  const dispatch = useDispatch();

  const handleUrlClick = (e: any, url: string) => {
    e.preventDefault();
    dispatch(setThirdPartyLink(url));
  };

  return { handleUrlClick };
};
