import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppDispatch, useAppSelector } from '@common/hooks/redux';
import { setProductGroupName } from '../../slices/productGroup/productGroupSlice';
import { fetchPublicProductGroupState } from '../../services/productGroupService';
import { IPublicProductGroupState } from '../../types';
import { usePleaseWaitOverlay } from '@common/components/PleaseWaitOverlay/usePleaseWaitOverlay';
import { mapPublicProductGroupState } from '../../helpers';

const staleTime = 60 * 1000;
export const fetchPublicProductGroupStateQueryKey = 'fetchPublicProductGroupState';

export const useFetchPublicProductGroupState = (id?: string) => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const {
    data,
    isFetching: isFetchingPublicProductGroupState,
    refetch,
    isFetched: isPublicProductGroupStateFetched,
  } = useQuery<IPublicProductGroupState>(
    [fetchPublicProductGroupStateQueryKey, id],
    () => {
      handleShowOverlay();
      return fetchPublicProductGroupState(token, id!);
    },
    {
      enabled: !!token && !!id,
      staleTime: staleTime,
      select: (data) => mapPublicProductGroupState(data),
    },
  );

  useEffect(() => {
    dispatch(setProductGroupName(data?.productGroup.productGroupName));
  }, [data?.productGroup.productGroupName, dispatch]);

  useEffect(() => {
    (!isFetchingPublicProductGroupState || isPublicProductGroupStateFetched) && handleHideOverlay();
  }, [isFetchingPublicProductGroupState, isPublicProductGroupStateFetched, handleHideOverlay]);

  return {
    publicProductGroupState: data,
    isFetchingPublicProductGroupState,
    refetchPublicProductGroupState: refetch,
    isPublicProductGroupStateFetched,
  };
};
