import styled from "styled-components";

export const Tag = styled.span`
  text-transform: uppercase;
  border-radius: 5px;
  padding: 5px 6px;
  font-size: 11px;
  font-weight: bold;
  cursor: default;

  &.success {
    border: 1px solid ${({theme}) => theme.elements.infoNew};
    color: ${({theme}) => theme.elements.infoNew};
  }

  &.normal {
    border: 1px solid ${({theme}) => theme.elements.infoUpdate};
    color: ${({theme}) => theme.elements.infoUpdate};
  }
`;
