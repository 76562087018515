import React from 'react';

import { CheckboxOption } from '@common/components/CheckboxOption/CheckboxOption';
import { Textarea } from '@common/components/Textarea/Textarea';
import { getNewSubmissionReasons, getUpdateSubmissionReasons } from '@common/features/reasons/submissionReasons';
import * as Styled from './styles';
import { ContentDetailsFormField, ContentDetailsFormFieldErrors } from '../../pages/ContentDetails/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useFormSelectedItems } from '@common/hooks/useFormSelectedItems';
import { ErrorMessage } from '@components/ErrorMessage';
import { DocumentType } from '@common/types';
import { StyledLink } from '@common/components/Link/styles';

interface ISubmissionDetails {
  contentType: DocumentType;
  version: number;
}

export const SubmissionDetails = ({ contentType, version }: ISubmissionDetails) => {
  const { toggleItem, isItemSelected } = useFormSelectedItems(ContentDetailsFormField.reasonsForSubmission);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const maxDetailsLengthError = errors[ContentDetailsFormField.details]?.message?.toString();

  const renderDetails = () => {
    var reasons = version === 1 ? getNewSubmissionReasons(contentType) : getUpdateSubmissionReasons(contentType);

    return reasons.map((item, index) => {
      const isSelected = isItemSelected(item);
      return (
        <CheckboxOption
          data-testid={`reason-checkbox-${index}`}
          key={item.id}
          name={`submission detail ${item.id}`}
          item={item}
          onChange={toggleItem}
          isChecked={isSelected}
        />
      );
    });
  };

  var showChangeDetails = version > 1;

  return (
    <Styled.SubmissionDetailsWrap>
      <Styled.SubmissionReasonsWrap>
        <Styled.SubmissionReasonsLabel>Reasons for submission</Styled.SubmissionReasonsLabel>
        <Styled.SubmissionReasonsContent>{renderDetails()}</Styled.SubmissionReasonsContent>
      </Styled.SubmissionReasonsWrap>
      {showChangeDetails && (
        <>
          <Styled.SubmissionChangeDetails>
            <Styled.SubmissionChangeDetailsHeading>{`${contentType} change details`}</Styled.SubmissionChangeDetailsHeading>
            <Styled.SubmissionChangeDetailsText>
              Please add the details of what has been changed below, this information is published on the website exactly as you
              enter it.
            </Styled.SubmissionChangeDetailsText>
            <Styled.SubmissionChangeDetailsText>
              We recommend not adding a contact telephone number or copying the whole {contentType} without explanation. For more
              information click{' '}
              <StyledLink to={process.env.REACT_APP_HELP_URL ?? ''} target="_blank">
                here &gt;
              </StyledLink>
            </Styled.SubmissionChangeDetailsText>
          </Styled.SubmissionChangeDetails>
          <Controller
            control={control}
            name={ContentDetailsFormField.details}
            rules={{ maxLength: { value: 500, message: ContentDetailsFormFieldErrors.Details } }}
            render={({ field: { value, onChange } }) => (
              <Textarea
                placeholder="Enter notes here (eg. Section 1 update)"
                value={value}
                name={`${contentType} change details`}
                onChange={onChange}
              />
            )}
          />
          {maxDetailsLengthError && <ErrorMessage>{maxDetailsLengthError}</ErrorMessage>}
        </>
      )}
    </Styled.SubmissionDetailsWrap>
  );
};
