import React from 'react';

import { NoContentError } from '../NoContentError';

import * as Styled from './styles';

interface IProductSection {
  elementId?: string;
  heading: string;
  subtext?: JSX.Element | string;
  withError?: boolean;
  children: JSX.Element;
}

export const ProductSection = ({ heading, subtext, children, elementId, withError = false }: IProductSection) => {
  return (
    <Styled.ProductSectionWrap id={elementId}>
      <Styled.ProductSectionInner>
        <Styled.HeadingArea>
          <Styled.Heading>{heading}</Styled.Heading>          
          {subtext && 
          (typeof subtext === "string") ? <Styled.Subtext>{subtext}</Styled.Subtext> : <>{subtext} </>
          }
        </Styled.HeadingArea>
        {withError && <NoContentError text="Please complete the following section" />}
        {children}
      </Styled.ProductSectionInner>
    </Styled.ProductSectionWrap>
  );
};
