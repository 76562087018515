import React from 'react';

import * as Styled from './styles';

import 'react-toastify/dist/ReactToastify.css';

export const Toast = () => {
  return (
    <Styled.ToastWrapper
      progressClassName="toastProgress"
      bodyClassName="toastBody"
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnHover
      theme="light"
    />
  );
};
