import styled from 'styled-components';
import { StyledSVG, SubmissionFlowInner } from '@common/styles';
import { Wrapper } from '@common/components/Select/styles';

export const SmpcDetailsWrap = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 35px 35px 35px 50px;

  ${SubmissionFlowInner} {
    width: 60%;
    @media (min-width: 1920px) {
      width: 50%;
    }

    padding: 0;
    background-color: ${({ theme }) => theme.elements.defaultWhite};
  }

  ${Wrapper} {
    width: 350px;
  }
`;

export const SmpcDetailsHeadingWrap = styled.div`
  margin-bottom: 35px;
`;

export const SmpcDetailsHeadingText = styled.h2`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionTitle};
`;

export const SmpcDetailsHeadingSubtext = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
`;

export const SmpcContentDetailsWrap = styled.div``;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.elements.customLayoutGrey};
`;

export const Error = styled(StyledSVG)``;
