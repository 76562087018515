import { ISubmissionReasonDto } from '@common/features/submission/types';
import { DocumentType } from '@common/types';

export interface IReadonlyDocumentContent {
  documentTitle: string;
  fileName: string;
  contentType: DocumentType;
  approvalDate: Date;
  approved: boolean;
  firstAuthorisationDate: Date;
  regulator: string;
  reasonsForSubmission: ISubmissionReasonDto[];
}

export enum DocumentDetailsReadonlySection {
  fileName = 'fileName',
  documentTitle = 'documentTitle',
  approved = 'approved',
  regulator = 'regulator',
  approvalDate = 'approvalDate',
  firstAuthorisationDate = 'firstAuthorisationDate',
  reasonsForSubmission = 'reasonsForSubmission',
  hasDigitalVersion = 'hasDigitalVersion',
  hasAlternativeTextFile = 'hasAlternativeTextFile',
  adminWeblink = 'adminWeblink',
}

export type DocumentDetailsReadonlySections = {
  [key in DocumentDetailsReadonlySection]?: boolean;
};
