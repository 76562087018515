import theme from '@common/theme';
import { ButtonTypes } from '../Button/types';
import Divider from '../Divider/Divider';
import { Button } from '../Button/Button';
import * as Styled from './styles';
import { AllowedSubmissionActions } from '@common/types';
import { Fragment } from 'react';

interface IProps {
  allowedActions: AllowedSubmissionActions[];
  onCancel: () => void;
  onRemove: () => void;
}

export const SubmissionActions = ({ allowedActions, onCancel, onRemove }: IProps) => {
  const itemsCount = allowedActions.length;

  const renderButtons = (action: AllowedSubmissionActions) => {
    switch (action) {
      case AllowedSubmissionActions.Cancel:
        return (
          <Button type={ButtonTypes.TEXT} text="Cancel submission" height={25} color={theme.colors.red} onClick={onCancel} />
        );
      case AllowedSubmissionActions.Remove:
        return (
          <Button type={ButtonTypes.TEXT} text="Remove submission" height={25} color={theme.colors.red} onClick={onRemove} />
        );
      default:
        return <div />;
    }
  };

  return (
    <Styled.ActionsWrapper>
      {allowedActions.map((a, i) => (
        <Fragment key={a}>
          {renderButtons(a)}
          {i !== itemsCount - 1 && <Divider type="vertical" size="30px" margin={10} />}
        </Fragment>
      ))}
    </Styled.ActionsWrapper>
  );
};
