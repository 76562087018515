import styled from 'styled-components';

interface IDividerProps {
  size: string | undefined;
  thickness: number | undefined;
  margin: number | undefined;
}

export const VerticalDivider = styled.div<IDividerProps>`
  height: ${({ size }) => (size ? size : '1em')};
  width: 0;
  margin: ${({ margin }) => (margin ? `0 ${margin}px` : '0 20px')};
  border-right: ${({ thickness }) => (thickness ? thickness : 1)}px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const HorizontalDivider = styled.div<IDividerProps>`
  width: ${({ size }) => (size ? size : '100%')};
  margin: 20px 0;
  margin: ${({ margin }) => (margin ? `${margin}px 0` : '20px 0')};
  border-bottom: ${({ thickness }) => (thickness ? thickness : 1)}px solid ${({ theme }) => theme.elements.borderGrey};
`;
