import { ChangeEvent } from 'react';
import { RadioOption } from '../RadioOption/RadioOption';
import { FiltersItem } from './FiltersItem';

interface IProps {
  value: boolean | null;
  label: string;
  onChange: (value: boolean) => void;
  onClear: () => void;
}

export const YesNoFilter = ({ value, label, onChange, onClear }: IProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value === 'yes');
  };

  return (
    <FiltersItem label={label} onClear={onClear} appliedFiltersCount={value === null ? 0 : 1}>
      <>
        <RadioOption label="Yes" size={22} name={label} value="yes" isChecked={value === true} onChange={handleChange} />
        <RadioOption label="No" size={22} name={label} value="no" isChecked={value === false} onChange={handleChange} />
      </>
    </FiltersItem>
  );
};
