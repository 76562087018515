export const formatActiveIngredientName = (input: string) => {
  let formattedString = input.trim().toLowerCase();

  // Capitalize single letters preceeding a number
  // vitamin b12 -> vitamin B12
  formattedString = formattedString.replace(/(\w\d+)/g, (match) => match.toUpperCase());

  // Capitalize single letters following the words group, vitamin and immunoglobulin
  // meningococcal group a, vitamin b12, immunoglobulin d -> meningococcal group A, vitamin B12, immunoglobulin D
  formattedString = formattedString.replace(
    /((group)|(vitamin)|(immunoglobulin)|(and))(\s)(([,]{0,1}.(?![A-z]{2,})){0,})/gi,
    (match, word) => word + ' ' + match.replace(word, '').toUpperCase(),
  );

  // Capitalize the words vitamin and immunoglobulin
  // vitamin, immunoglobulin -> Vitamin, Immunoglobulin
  formattedString = formattedString.replace(/((vitamin)|(immunoglobulin))/gi, (match) => capitalize(match));

  // Change roman numbers following the word factor to uppercase
  // recombinant factor viia -> recombinant factor VIIa
  formattedString = formattedString.replace(/\bfactor\s+([IVXLCDM]+)/gi, (match, word) =>
    word ? 'factor ' + word.toUpperCase() : match,
  );

  // Capitalize the first letter of a phrase
  // vitamin -> Vitamin
  return capitalize(formattedString).replace(/ +(?= )/g, '');
};

const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);
