import React from 'react';
import { Button, ButtonTypes } from '@common/components';
import {
  ActiveIngredients,
  ExitMarkWrapper,
  HeaderDetails,
  HeaderTitle,
  Icon,
  Left,
  Right,
  SubmissionFlowHeaderWrap,
} from '@common/components/SubmissionFlowHeader/styles';
import { ProductGroupHistoryList, SubmissionInfo } from './SubmissionsInfo/ProductGroupHistoryList';
import Divider from '@common/components/Divider/Divider';
import { ReactSVG } from 'react-svg';
import { useIsOverflow } from '@common/hooks/useIsOverflow';
import { Tooltip } from '@common/components/tooltip/Tooltip';
import { useUnsavedChanges } from '@common/context/hooks/useUnsavedChanges';
import useAppNavigation from '../../routing/useAppNavigation';
import { SubmissionStatus } from '@common/services/types';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

interface ISubmissionFlowHeader {
  title?: string;
  submissionTitle?: string;
  activeIngredients?: string;
  children?: React.ReactNode;
  shouldRenderSubmissionInfo?: boolean;
  submissionStatus?: SubmissionStatus;
  onBackClick?: () => void;
  productGroupId?: number | null;
  isSubmissionView?: boolean;
}

const SubmissionFlowHeader = ({
  title,
  activeIngredients,
  children,
  submissionTitle,
  shouldRenderSubmissionInfo = true,
  submissionStatus,
  onBackClick,
  productGroupId,
  isSubmissionView,
}: ISubmissionFlowHeader) => {
  const { goBack, goToHome } = useAppNavigation();
  const { showViewHierarchy } = useFeatureFlags();
  const titleRef = React.useRef<HTMLSpanElement>(null);
  const noCallback = undefined;
  const isTitleOverflow = useIsOverflow(titleRef, noCallback, [title]);

  const { handleUnsavedChanges } = useUnsavedChanges({});

  const handleBackClick = () => {
    handleUnsavedChanges(() => (onBackClick ? onBackClick() : goBack()));
  };

  const handleCloseClick = () => {
    handleUnsavedChanges(() => goToHome());
  };

  const breadcrumbs = children;

  const statusesForDisplayingSubmissionsInfoList = [
    SubmissionStatus.InProgress,
    SubmissionStatus.Draft,
    SubmissionStatus.Published,
  ];

  const shouldShowSubmissionHistory =
    statusesForDisplayingSubmissionsInfoList.includes(submissionStatus!) &&
    productGroupId != null &&
    !(submissionStatus === SubmissionStatus.Published && isSubmissionView);

  return (
    <SubmissionFlowHeaderWrap>
      <Left>
        <Button onClick={handleBackClick} text="< Back" type={ButtonTypes.PRIMARY} />
        <HeaderDetails>
          {breadcrumbs}
          <HeaderTitle
            data-tooltip-id={'submission-flow-header'}
            data-tooltip-content={title}
            ref={titleRef}
            data-testid="submission-header-title"
          >
            {title}
          </HeaderTitle>
          <ActiveIngredients>{activeIngredients}</ActiveIngredients>
          <Tooltip hidden={!isTitleOverflow} id={'submission-flow-header'} style={{ maxWidth: '30vw' }} />
        </HeaderDetails>
      </Left>
      <Right>
        {shouldRenderSubmissionInfo && (
          <>
            <Divider type="vertical" size="55px" />
            {showViewHierarchy && (
              <Icon>
                <ReactSVG src="/icons/hierarchy.svg" />
              </Icon>
            )}
            <Icon onClick={() => window.open(process.env.REACT_APP_HELP_URL, '_blank')}>
              <ReactSVG src="/icons/walkthrough.svg" />
            </Icon>
            <Divider type="vertical" size="50px" />
            {shouldShowSubmissionHistory ? (
              <ProductGroupHistoryList title={submissionTitle!} status={submissionStatus!} productGroupId={productGroupId!} />
            ) : (
              <SubmissionInfo title={submissionTitle!} status={submissionStatus!} />
            )}
          </>
        )}
        <ExitMarkWrapper onClick={handleCloseClick}>
          <ReactSVG src="/icons/exit_mark.svg" />
        </ExitMarkWrapper>
      </Right>
    </SubmissionFlowHeaderWrap>
  );
};

export default SubmissionFlowHeader;
