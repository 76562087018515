import { DocumentExtensionType, DocumentType } from '@common/types';

export const useGetOriginalFileExtensionType = () => {
  const getExtensionByDocumentType = (documentType?: DocumentType) => {
    switch (documentType) {
      case DocumentType.Smpc:
        return DocumentExtensionType.Docx;
      case DocumentType.Pil:
        return DocumentExtensionType.Pdf;
      default:
        return DocumentExtensionType.Pdf;
    }
  };

  return { getExtensionByDocumentType };
};
