import { configureStore } from '@reduxjs/toolkit';
import submissionReducer from 'apps/publisher-v3.ui.customer/src/features/submission/submissionSlice';
import userReducer from './features/user/userSlice';
import configurationReducer from './features/configuration/configurationSlice';
import globalReducer from './features/global/globalSlice';
import productGroupReducer from '@common/features/productGroup/slices/productGroup/productGroupSlice';

const store = configureStore({
  reducer: {
    configuration: configurationReducer,
    submission: submissionReducer,
    user: userReducer,
    productGroup: productGroupReducer,
    global: globalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
