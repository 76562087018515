import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import { DocumentRetireFormFields, useRetireModalForm } from './hooks/useRetireModalForm';

import { Button, ButtonTypes, Select } from '@common/components';
import Divider from '@common/components/Divider/Divider';

import { ISubmissionDto } from '@common/features/submission/types';
import { IModalState } from './types';
import { DocumentType, ISelectOption } from '@common/types';

import { StyledSVG } from '@common/styles';
import * as Styled from './styles';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { getRetireReasonOptions } from '@common/features/reasons/retirementReasons';

interface Props {
  submission?: ISubmissionDto;
  modalState: IModalState;
  contentType: DocumentType;
  onConfirmClick?: (documentId: string, reason: ISelectOption) => void;
  onCancelClick?: () => void;
}

export const RetireModal = ({ submission, modalState, contentType, onConfirmClick, onCancelClick }: Props) => {
  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
  } = useRetireModalForm();

  const retireReasonOptions = getRetireReasonOptions(contentType);

  const document = submission?.documents.find((item) => item.documentId === parseInt(modalState.documentId!));

  const associatedProductIds = document?.linkedProductIds;

  const dropdownError = errors[DocumentRetireFormFields.reason]?.message?.toString();

  const associatedProductNames = submission?.products
    .filter((item) => associatedProductIds?.includes(item.productId))
    .map((item, index) => <Styled.ListItem key={index}>{item.name}</Styled.ListItem>);

  const handleConfirmClick = () =>
    handleSubmit((data) => onConfirmClick!(modalState.documentId as string, data[DocumentRetireFormFields.reason]!))();

  return (
    <Styled.RetireModalContent>
      <Styled.ModalHeading>Retire content</Styled.ModalHeading>
      <FormProvider {...methods}>
        <div>
          <Controller
            control={control}
            name={DocumentRetireFormFields.reason}
            rules={{ required: 'Please select reason for retirement' }}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Reasons for retirement"
                name="retirement reasons"
                value={value as ISelectOption}
                onChange={onChange}
                options={retireReasonOptions}
              />
            )}
          />
          {dropdownError && <ErrorMessage>{dropdownError}</ErrorMessage>}
        </div>
      </FormProvider>
      <Styled.ContentText>Retiring this content will affect your product(s) on the emc</Styled.ContentText>
      <Styled.ListWrapper>
        <Styled.ListHeading>The following product(s) will be affected:</Styled.ListHeading>
        <Styled.ProductList>{associatedProductNames}</Styled.ProductList>
      </Styled.ListWrapper>
      <Styled.WarningDisplay>
        <StyledSVG src="/icons/errorBig.svg" />
        Products which only have retired SmPCs or PILs will be retired and will no longer display on the emc.
      </Styled.WarningDisplay>
      <Styled.ButtonsWrapper>
        <Button onClick={onCancelClick} text="Cancel" type={ButtonTypes.TEXT} />
        <Divider type="vertical" size="50px" thickness={1} />
        <Button onClick={handleConfirmClick} text="Submit" type={ButtonTypes.PRIMARY} />
      </Styled.ButtonsWrapper>
    </Styled.RetireModalContent>
  );
};
