import { Colors, ElementFontSizes, Elements, FontSizes } from './types';

const colors: Colors = {
  darkBlue: '#182E7A',
  mediumBlue: '#0073B2',
  lightBlue: '#E0E6F2',
  darkGreen: '#307349',
  mediumGreen: '#70C38D',
  lightGreen: '#F3F9F5',
  superlightGreen: '#F5F7F4',
  darkRed: '#F5F7F4',
  lightRed: '#FAF2F2',
  darkOrange: '#B47714 ',
  lightOrange: '#FBF8F3',
  superdarkGrey: '#231F20',
  darkGrey: '#5D5B5C',
  mediumGrey: '#918F90',
  lightGrey: '#B7B5B6',
  moderateGrey: '#ECECEC',
  superlightGrey: '#E9E7E8',
  defaultWhite: '#FFFFFF',
  dirtyWhite: '#F9F9F9',
  borderGray: '#B7B5B6',
  veryLightBlue: '#F2F8FB',
  backgroundBlue: '#0073b20d',
  red: '#A21110',
};

const elements: Elements = {
  defaultWhite: colors.defaultWhite,
  background: '#FAFAFA',
  primary: colors.darkBlue,
  primaryLight: colors.lightBlue,
  primaryMedium: colors.mediumBlue,
  success: colors.mediumGreen,
  borderGrey: colors.lightGrey,
  readonlyInputBackground: '#DADADA',
  grey: colors.dirtyWhite,
  textGrey: colors.superdarkGrey,
  textLightGrey: '#5E5E5E',
  unselectedSidebarEntry: colors.darkGrey,
  submissionTypeBackground: colors.backgroundBlue,
  submissionHeading: colors.superdarkGrey,
  submissionSubHeading: colors.darkGrey,
  dropdownGrey: '#525252',
  backgroundWhite: colors.dirtyWhite,
  textDarkGrey: colors.darkGrey,
  mediumGrey: colors.darkGrey,
  customLayoutGrey: '#F5F4F5',
  linkBlue: '#1b81b9',
  badgeGrey: colors.superlightGrey,
  infoBackground: '#F2F8FB',
  infoNew: '#397C67',
  infoUpdate: colors.darkBlue,
  tabBackgroundGrey: '#E9E7E8',
  disabledInputBg: colors.moderateGrey,
  labelBackground: colors.moderateGrey,
  removeRed: colors.red,
  retireOrange: colors.darkOrange,
};

const fontSizes: FontSizes = {
  tiny: '0.75rem', // 12px
  small: '0.875rem', // 14px
  medium: '1rem', // 16px
  large: '1.125rem', // 18px
  xLarge: '1.375rem', // 22px
  xxLarge: '1.563rem', // 25px
};

const elementFontSizes: ElementFontSizes = {
  default: fontSizes.medium,
  label: fontSizes.small,
  input: fontSizes.medium,
  button: fontSizes.medium,
  checkbox: fontSizes.small,
  pageTitle: fontSizes.xxLarge,
  sectionTitle: fontSizes.xLarge,
  subSectionTitle: fontSizes.large,
  modalTitle: fontSizes.xLarge,
  sectionText: fontSizes.small,
  modalContent: fontSizes.medium,
  pageContent: fontSizes.medium,
  errorMessage: fontSizes.small,
};

const theme = {
  elements,
  colors,
  fontSizes,
  elementFontSizes,
};

export default theme;
