import { SingleError } from '@common/components/SingleError';

import { ErrorLog } from '@common/types';

interface IProps {
  productInfoErrors: ErrorLog[];
  associatedContentErrors: ErrorLog[];
  productDetailsErrors: ErrorLog[];
}

export const ValidateProductErrors = ({ productInfoErrors, associatedContentErrors, productDetailsErrors }: IProps) => (
  <>
    {productInfoErrors.length > 0 && (
      <SingleError title="Product information" items={productInfoErrors} linkText="How to update a product?" helpLink={''} />
    )}
    {associatedContentErrors.length > 0 && (
      <SingleError title="Associated content" items={associatedContentErrors} linkText="How to update a product?" helpLink={''} />
    )}
    {productDetailsErrors.length > 0 && (
      <SingleError title="Product details" items={productDetailsErrors} linkText="How to update a product?" helpLink={''} />
    )}
  </>
);
