import './styles.css';
import Editor from './Editor';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';

interface IRichTextEditor {
  onChange?: any;
  value: any;
  fieldName?: string;
  readOnly?: boolean;
  toolbarOptions?: unknown[];
  className?: string;
}

export const RichTextEditor = ({
  onChange,
  value,
  fieldName,
  readOnly = false,
  toolbarOptions,
  className,
}: IRichTextEditor) => {
  const quillRef = useRef();

  const {
    formState: { errors },
  } = useFormContext() ?? { formState: { errors: [] } };

  const error = fieldName?.length! > 0 ? errors[fieldName as string]?.message?.toString() : [];

  return (
    <>
      <Editor
        readOnly={readOnly}
        ref={quillRef}
        defaultValue={value}
        onTextChange={onChange}
        onSelectionChange={undefined}
        toolbarOptions={toolbarOptions}
        className={className}
      ></Editor>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
