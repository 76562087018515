import { MultipleErrors } from '@common/components/MultipleErrors';
import { SingleError } from '@common/components/SingleError';
import { ErrorLog } from '@common/types';

export interface IValidationModalErrors {
  productGroupErrors: ErrorLog[];
  productsErrors: ErrorLog[];
  contentsErrors: ErrorLog[];
  productAssociationErrors: ErrorLog[];
  contentAssociationErrors: ErrorLog[];
  productUniquenessErrors: ErrorLog[];
}

interface IProps {
  validationErrors: IValidationModalErrors;
  onCancel: () => void;
  onProductGroupErrorClick: (errorLog: ErrorLog) => void;
  onProductErrorClick: (id: number) => void;
  onContentErrorClick: (id: number) => void;
  onProductAssociationErrorClick: (id: number) => void;
  onContentAssociationErrorClick: () => void;
}

export const ValidateSubmissionErrors = ({
  validationErrors: {
    productGroupErrors,
    productAssociationErrors,
    contentAssociationErrors,
    contentsErrors,
    productsErrors,
    productUniquenessErrors,
  },
  onProductGroupErrorClick,
  onProductErrorClick,
  onContentErrorClick,
  onProductAssociationErrorClick,
  onContentAssociationErrorClick,
}: IProps) => (
  <>
    {productGroupErrors?.map((e) => (
      <SingleError
        title="Product group information"
        items={[e]}
        linkText="How to update a product group?"
        helpLink={process.env.REACT_APP_HELP_URL!}
        onClick={() => onProductGroupErrorClick(e)}
      />
    ))}
    {productsErrors?.length > 0 && (
      <MultipleErrors
        title="Product information"
        items={productsErrors}
        linkText="How to update a product?"
        helpLink={process.env.REACT_APP_HELP_PRODUCT_URL!}
        onClick={onProductErrorClick}
      />
    )}
    {contentsErrors?.length > 0 && (
      <MultipleErrors
        title="Content information"
        items={contentsErrors}
        linkText="How to update content?"
        helpLink={process.env.REACT_APP_HELP_CONTENT_URL!}
        onClick={onContentErrorClick}
      />
    )}
    {productAssociationErrors?.length > 0 && (
      <MultipleErrors
        title="Product does not have an associated SmPC or PIL"
        items={productAssociationErrors}
        linkText="How to associate content?"
        helpLink={process.env.REACT_APP_HELP_ASSOCIATE_CONTENT_URL!}
        onClick={onProductAssociationErrorClick}
      />
    )}
    {contentAssociationErrors?.length > 0 && (
      <SingleError
        title="Content is not associated to a Product"
        items={contentAssociationErrors}
        linkText="How to associate content?"
        helpLink={process.env.REACT_APP_HELP_ASSOCIATE_CONTENT_URL!}
        additionalInfo="Please update the association or retire the content before proceeding"
        onClick={onContentAssociationErrorClick}
      />
    )}
    {productUniquenessErrors?.length > 0 && (
      <MultipleErrors
        title="Product is not unique"
        items={productUniquenessErrors}
        linkText="How to update a product?"
        helpLink={process.env.REACT_APP_HELP_URL!}
      />
    )}
  </>
);
