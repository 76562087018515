import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';
import * as Styled from './styles';
import * as SharedStyled from '@common/styles';

export const ReadonlyWeblink = ({ url, title }: { url: string; title?: string }) => {
  const { handleUrlClick } = useThirdPartyLinkWarning();

  return (
    <Styled.TitleWrap>
      <span>{title}</span>
      <Styled.UrlWrapper>
        <SharedStyled.StyledSVG src={`/icons/globe.svg`} />
        <Styled.UrlWrapperStyledLink
          to={url}
          target="_blank"
          onClick={(e) => handleUrlClick(e, url)}
          data-testid="datapharm-weblink"
        >
          {url}
        </Styled.UrlWrapperStyledLink>
      </Styled.UrlWrapper>
    </Styled.TitleWrap>
  );
};
