import { getXmlIcon } from '@common/helpers';
import { StyledXmlSVG } from '@common/styles';
import { SubmissionTaskType } from '@common/types';

interface Props {
  submissionType: SubmissionTaskType;
  isFileValid: boolean;
}

export const SubmissionTaskXmlState = ({ submissionType, isFileValid }: Props) => {
  return (
    (submissionType === SubmissionTaskType.ePILNew ||
      submissionType === SubmissionTaskType.ePILUpdate ||
      submissionType === SubmissionTaskType.SmpcNew ||
      submissionType === SubmissionTaskType.SmpcUpdate) && <StyledXmlSVG height={25} src={getXmlIcon(isFileValid)} />
  );
};
