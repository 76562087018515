import React, { ReactNode } from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';

interface IInfoModal {
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  children: ReactNode;
  additionalFooterItems?: ReactNode;
  onConfirm?: () => void;
}

const contentStyles = {
  BoxSizing: 'border-box',
  width: '500px',
};

export const InfoModal = ({ isOpen, title, children, subTitle, additionalFooterItems, onConfirm }: IInfoModal) => (
  <ModalWrapper isOpen={isOpen} zIndex={999} contentStyles={contentStyles}>
    <Styled.TitleIcon src={'/icons/information.svg'} />
    <Styled.Title>{title}</Styled.Title>
    <Styled.Content>
      <Styled.SubTitle>{subTitle}</Styled.SubTitle>
      {children}
    </Styled.Content>
    <Styled.Footer>
      {additionalFooterItems}
      <Button onClick={onConfirm} text="Confirm" type={ButtonTypes.PRIMARY} />
    </Styled.Footer>
  </ModalWrapper>
);
