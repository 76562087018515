import React from 'react';
import * as Styled from './styles';
import { StyledSVG } from '@common/styles';
import { StyledLink } from '@components/Link/styles';
import { Id } from '@common/types';

interface IUploadedFile {
  submissionId?: Id;
  documentId?: Id;
  fileName: string;
  active?: boolean;
  onClick?: () => void;
}

export const UploadedFile = ({ fileName, active = true, onClick }: IUploadedFile) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick!();
  };

  return (
    <Styled.UploadedFileWrapper active={active}>
      <StyledSVG src={`/icons/file.svg`} />
      {onClick ? (
        <StyledLink onClick={handleClick} to="#">
          {fileName}
        </StyledLink>
      ) : (
        <b>{fileName}</b>
      )}
    </Styled.UploadedFileWrapper>
  );
};
