import styled from 'styled-components';
import { StyledSVG } from '@common/components/Checkbox/styles';
import { TextAndIconWrapper } from '@common/components/TextWithIcon/styles';
import { DatePickerWrapper } from '@common/components/DatePickerWrapper/styles';

export const RegulatorApprovalWrap = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 10px;

  ${TextAndIconWrapper} {
    margin-bottom: 10px;
  }

  ${DatePickerWrapper}:nth-of-type(2) {
    justify-self: center;
  }
`;

interface IConfirmationStyleProps {
  isConfirmed: boolean;
}

export const ConfirmationWrap = styled.div<IConfirmationStyleProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid ${({ theme, isConfirmed }) => (isConfirmed ? theme.elements.success : theme.elements.borderGrey)};
  border-radius: 14px;
  padding: 15px 20px;

  ${StyledSVG} {
    min-width: 28px;
    min-height: 28px;

    svg {
      min-width: 28px;
      min-height: 28px;
    }
  }
`;

export const ConfirmationText = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.checkbox};
  margin-left: 20px;
`;

export const ConfirmationHeading = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.label};
  margin-bottom: 5px;
`;
