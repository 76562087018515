import Badge from '@common/components/Badge/Badge';
import { FiltersBadgeWrapper } from './styles';

interface IProps {
  appliedFiltersCount: number;
}
export const FiltersBadge = ({ appliedFiltersCount }: IProps) => (
  <FiltersBadgeWrapper>
    <Badge value={appliedFiltersCount} />
  </FiltersBadgeWrapper>
);
