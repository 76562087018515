import styled from 'styled-components';
import { StyledLink } from '../Link/styles';
import { StyledSVG } from '@common/styles';

export const SidebarWrapper = styled.div`
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  padding-right: 20px;
  padding-top: 20px;
  row-gap: 15px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
  min-width: 200px;
  position: sticky;

  ${StyledLink} {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 15px 10px;
    border-radius: 4px;
  }
`;

interface ISidebarLink {
  isActive: boolean;
}

export const SidebarLink = styled(StyledLink).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<ISidebarLink>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.elements.textLightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.elements.primaryMedium};
    color: ${({ theme }) => theme.elements.defaultWhite};
  }

  ${StyledSVG} {
    display: flex;
    align-items: center;
  }
`;
