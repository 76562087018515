import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const ComponentWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.elements.textGrey};
`;

interface IInputWrap {
  height?: number;
  width?: number;
  disabled?: boolean;
}

export const InputWrap = styled.div<IInputWrap>`
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  background-color: ${({ theme, disabled }) => (disabled ? theme.elements.disabledInputBg : theme.elements.defaultWhite)};
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => height || 35}px;
  width: ${({ width }) => `${width}px` || 'auto'};
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 0 7px;

  &.readonly {
    background-color: ${({ theme }) => theme.elements.readonlyInputBackground};
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  height: 20px;
  margin-bottom: -1px;
  font-size: 16px;

  &[readonly] {
    background-color: ${({ theme }) => theme.elements.readonlyInputBackground};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 5px;
  span {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const ClearIcon = styled(AiOutlineCloseCircle)`
  cursor: pointer;
`;
