import { useAppSelector } from '@common/hooks/redux';
import { getContentReportData } from '@common/services/submissionService';
import { selectToken } from '@features/user/selectors';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { convertObjectToQueryString } from '@common/filterFormHelpers';
import { ISelectOption } from '@common/types';
import { usePleaseWaitOverlay } from '@common/components/PleaseWaitOverlay/usePleaseWaitOverlay';

interface IMutationData {
  statuses?: ISelectOption[];
  types?: ISelectOption[];
  requestedFields?: ISelectOption[];
  includeAllVersionsContent?: boolean;
  includeRetiredContent?: boolean;
  fromDate?: string | null;
  toDate?: string | null;
}

interface IMutationProps {
  successCallback: (response: unknown) => void;
  errorCallback: () => void;
}

export const useGetContentReportData = ({ successCallback, errorCallback }: IMutationProps) => {
  const token = useAppSelector(selectToken);
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const handleSuccess = (response: unknown) => {
    handleHideOverlay();
    successCallback(response);
  };

  const handleError = () => {
    handleHideOverlay();
    errorCallback();
  };

  const { mutate: generateContentReport, isLoading: isContentReportGenerating } = useWaitingMutation(
    (data: IMutationData) => {
      const queryString = convertObjectToQueryString(data);
      return getContentReportData(token, queryString);
    },
    { onMutate: handleShowOverlay, onSuccess: handleSuccess, onError: handleError },
  );

  return {
    generateContentReport,
    isContentReportGenerating,
  };
};
