import React, { useCallback } from 'react';
import { InputActionMeta } from 'react-select';
import { ButtonTypes, Select } from '@common/components/';
import { CheckboxOption } from '@common/components/CheckboxOption/CheckboxOption';
import { SelectedActiveIngredient } from './SelectedActiveIngredient';
import * as Styled from './styles';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Section } from '@common/components/Section/Section';
import { StyledSVG } from '@common/styles';
import { ISelectOption } from '@common/types';
import { ISubstanceDto } from '@common/features/submission/types';
import { IActiveIngredientOption } from '@common/features/product/types';
import { formatActiveIngredientName } from './hooks/useActiveIngredientSelection/helpers';

interface Props {
  activeIngredients: ISubstanceDto[];
  isFetchingActiveIngredients: boolean;
  activeIngredientToAdd: IActiveIngredientOption | '';
  selectedActiveIngredients: IActiveIngredientOption[];
  isNoActiveIngredientsChecked: boolean;
  noActiveIngredientsError?: string;
  alreadyExistsError?: string;
  noTitle?: boolean;
  onActiveIngredientAdd: () => void;
  onActiveIngredientRemove: (index: number) => void;
  onActiveIngredientInputChange: (newValue: string, { action }: InputActionMeta) => false | void;
  onNoActiveIngredientsChange: () => void;
  onDraftActiveIngredientChange: (o: ISelectOption) => void;
}

export const ActiveIngredientsSelection = ({
  activeIngredients,
  isFetchingActiveIngredients,
  activeIngredientToAdd,
  noTitle,
  selectedActiveIngredients,
  isNoActiveIngredientsChecked,
  noActiveIngredientsError,
  alreadyExistsError,
  onActiveIngredientAdd,
  onActiveIngredientRemove,
  onActiveIngredientInputChange,
  onNoActiveIngredientsChange,
  onDraftActiveIngredientChange,
}: Props) => {
  const renderSelectedActiveIngredients = useCallback(
    () =>
      selectedActiveIngredients?.map((item: IActiveIngredientOption, index: number) => (
        <SelectedActiveIngredient
          index={index}
          key={item.label}
          name={formatActiveIngredientName(item.label)}
          onDeleteClick={onActiveIngredientRemove}
          isListed={item.isListed}
        />
      )),
    [onActiveIngredientRemove, selectedActiveIngredients],
  );

  const activeIngredientsOptions: IActiveIngredientOption[] = activeIngredients.map(({ id, name }) => ({
    value: id as string,
    label: name,
    isListed: true,
  }));

  const handleKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  const renderActiveIngredients = useCallback(
    () => (
      <Styled.ActiveIngredientsContentWrap>
        <CheckboxOption
          item={{ id: 'noActiveIngredient', text: 'No active ingredients' }}
          name="noActiveIngredient"
          isChecked={isNoActiveIngredientsChecked}
          onChange={onNoActiveIngredientsChange}
        />
        <Select
          label="Add Active Ingredient(s)"
          name="Draft active ingredient"
          placeholder="Enter ingredient name"
          onChange={onDraftActiveIngredientChange}
          onInputChange={onActiveIngredientInputChange}
          onKeyDown={handleKeyDown}
          value={activeIngredientToAdd}
          options={activeIngredientsOptions}
          disabled={isNoActiveIngredientsChecked}
          isLoading={isFetchingActiveIngredients}
        />
        <Styled.ActiveIngredientsSelectSearchHint data-testid="add-activeIngredientHint">
          (Enter at least 3 characters to search)
        </Styled.ActiveIngredientsSelectSearchHint>
        {alreadyExistsError && <ErrorMessage>{alreadyExistsError}</ErrorMessage>}
        {selectedActiveIngredients?.length !== 0 && (
          <Styled.SelectedActiveIngredientsWrap>{renderSelectedActiveIngredients()}</Styled.SelectedActiveIngredientsWrap>
        )}
        <Styled.ActiveIngredientNameAddButton
          onClick={onActiveIngredientAdd}
          text="Add"
          data-testid="add-activeIngredientButton"
          type={ButtonTypes.DASHED_BORDER}
          disabled={isNoActiveIngredientsChecked}
        />
        {noActiveIngredientsError && <ErrorMessage>{noActiveIngredientsError}</ErrorMessage>}
      </Styled.ActiveIngredientsContentWrap>
    ),
    [
      activeIngredientToAdd,
      activeIngredientsOptions,
      alreadyExistsError,
      isFetchingActiveIngredients,
      isNoActiveIngredientsChecked,
      noActiveIngredientsError,
      onActiveIngredientAdd,
      onActiveIngredientInputChange,
      onDraftActiveIngredientChange,
      onNoActiveIngredientsChange,
      renderSelectedActiveIngredients,
      selectedActiveIngredients?.length,
    ],
  );

  return noTitle ? (
    renderActiveIngredients()
  ) : (
    <Section
      text="Active ingredients"
      subtext="Ingredient means the active ingredient(s) as listed in the SmPC, if you are adding a new active ingredient this will display in red text and will be reviewed by our QC team."
      icon={<StyledSVG src={`/icons/info_circle.svg`} />}
      data-testid="active-ingredients-selection"
    >
      {renderActiveIngredients()}
    </Section>
  );
};
