import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { SubmissionSubmitCommand } from 'apps/publisher-v3.ui.customer/src/features/submission/types';
import { submitSubmission } from '@common/services/submissionService';

export const useSubmitSubmission = (submissionId: string) => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation((payload: SubmissionSubmitCommand) =>
    submitSubmission(token, submissionId, { ...payload }),
  );

  return { submitSubmission: mutate };
};
