import { ColumnSort, SortingState } from '@tanstack/react-table';
import { useState } from 'react';
import { SortColumnValue } from './types';

export const useCustomSorting = <T>(sortColumnValues: SortColumnValue<T>) => {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const sortingQuery = sortingState.length > 0 ? getSortingQuery(sortingState[0], sortColumnValues) : undefined;
  return { sortingQuery, customSorting: { sortColumnValues, sortingState, onSortingChange: setSortingState } };
};

const getSortingQuery = <T>(sorting: ColumnSort, sortColumnValues: SortColumnValue<T>) => {
  var dir = sorting.desc ? 'desc' : 'asc';
  var field = sortColumnValues[sorting.id as keyof T] ?? sorting.id;
  return `${field}:${dir}`;
};
