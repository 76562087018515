import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 10px;
`;

export const Text = styled.div``;

export const DownloadAnchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
  width: fit-content;
`;
