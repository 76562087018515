import { setShowPleaseWaitLoader } from '@common/features/global/globalSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const usePleaseWaitOverlay = () => {
  const dispatch = useDispatch();

  const handleShowOverlay = useCallback(() => dispatch(setShowPleaseWaitLoader(true)), [dispatch]);

  const handleHideOverlay = useCallback(() => dispatch(setShowPleaseWaitLoader(false)), [dispatch]);

  return { handleShowOverlay, handleHideOverlay };
};
