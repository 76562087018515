import { PleaseWaitLoader } from '../PleaseWaitLoader';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { NoBorder } from './styles';

export const PleaseWaitOverlay = () => (
  <ModalWrapper isOpen={true} contentStyles={{ padding: 0 }}>
    <NoBorder>
      <PleaseWaitLoader />
    </NoBorder>
  </ModalWrapper>
);
