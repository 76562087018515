import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProductDetailsFormErrors } from '../types';
import { IMaNumber } from '@common/features/submission/types';
import { useEnsureMaNumberWithSpaces } from './useIncludeMaNumberSpacing';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { useFetchMaNumbersConfig } from './useFetchMaNumbersConfig';

export const useMaNumbersSelection = () => {
  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const { maNumbersConfig } = useFetchMaNumbersConfig();

  const [EnsureMaNumberWithSpaces] = useEnsureMaNumberWithSpaces();

  const maNumberToAdd = watch(ProductFormPaths.maNumberInput) ?? '';
  const selectedMaNumbers = watch(ProductFormPaths.maNumbers) || [];
  const noMaNumbers = watch(ProductFormPaths.noMaNumbers);

  useEffect(() => {
    trigger(ProductFormPaths.maNumbers);
  }, [noMaNumbers, trigger]);

  const clearMaNumbersInputError = () => clearErrors(ProductFormPaths.maNumberInput);

  const addMaNumber = (maNumber: IMaNumber) => {
    const maNumbers = getValues(ProductFormPaths.maNumbers) ?? [];
    setValue(ProductFormPaths.maNumbers, [...maNumbers, maNumber]);
  };

  const removeMaNumber = (index: number) => {
    const maNumbers = getValues(ProductFormPaths.maNumbers) ?? [];
    setValue(
      ProductFormPaths.maNumbers,
      maNumbers.filter((_, i) => i !== index),
      { shouldValidate: true },
    );
  };

  const handleMaNumberAdd = () => {
    const maNumbers: IMaNumber[] = getValues(ProductFormPaths.maNumbers) ?? [];

    const maNumberToAddWithOutSpaces = maNumberToAdd?.replaceAll(' ', '');

    const alreadyAdded = maNumbers?.find((i) => i.number?.replaceAll(' ', '') === maNumberToAddWithOutSpaces);

    if (alreadyAdded)
      setError(ProductFormPaths.maNumberInput, {
        type: 'alreadyAdded',
        message: ProductDetailsFormErrors.maNumbersAlreadyAdded,
      });

    const matchingConfig = maNumbersConfig?.find((item: any) => {
      //@TODO temp solution until backend is fixed to not double '/' characters
      const parsedPattern = item.pattern?.replace('//', '/');
      const reg = new RegExp(parsedPattern);
      return maNumberToAdd.trim()?.match(reg);
    });

    if (!matchingConfig)
      setError(ProductFormPaths.maNumberInput, {
        type: 'invalidFormat',
        message: ProductDetailsFormErrors.maNumbersInvalidFormat,
      });

    if (maNumberToAdd !== '' && !alreadyAdded && matchingConfig) {
      var maNumberWithSpaces = EnsureMaNumberWithSpaces(matchingConfig.format, maNumberToAddWithOutSpaces);
      addMaNumber({ number: maNumberWithSpaces, country: matchingConfig.region });

      setValue(ProductFormPaths.maNumberInput, '');
      clearErrors([ProductFormPaths.maNumbers]);
    }
  };
  const handleMaNumberRemove = (index: number) => {
    removeMaNumber(index);
  };

  const handleNoMaNumbersChange = () => {
    const isChecked = getValues(ProductFormPaths.noMaNumbers);
    if (!isChecked) {
      setValue(ProductFormPaths.maNumberInput, '');
    }
    clearMaNumbersInputError();
    setValue(ProductFormPaths.noMaNumbers, !isChecked);
  };

  return {
    maNumberToAdd,
    selectedMaNumbers,
    errors,
    noMaNumbers,
    handleMaNumberAdd,
    handleMaNumberRemove,
    handleNoMaNumbersChange,
  };
};
