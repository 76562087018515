import React from 'react';
import * as Styled from './styles';
import { Input } from '@common/components';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { RegisterOptions } from 'react-hook-form';

interface IDocumentTitleInput {
  title: string;
  isReadonly?: boolean;
  rules?: RegisterOptions<any, string>;
  error?: string;
}

export const DocumentTitleInput = ({ title, isReadonly, rules, error }: IDocumentTitleInput) => {
  const renderContent = () => {
    if (isReadonly) {
      return <Input name="document title" value={title} disabled={true} />;
    }

    return <HookFormInput name={ContentDetailsFormField.documentTitle} rules={rules} error={error}/>;
  };

  return <Styled.DocumentTitleWrapper>{renderContent()}</Styled.DocumentTitleWrapper>;
};
