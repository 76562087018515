import React from 'react';
import * as Styled from '@common/features/productGroup/styles';
import SubmissionFlowHeader from '../../../../components/SubmissionFlowHeader/SubmissionFlowHeader';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import {
  prepareContentColumns,
  prepareProductGroupColumns,
  prepareSubmissionDetailsColumns,
  prepareProductsColumns,
} from './submissionSummaryTablesSetup';
import { useAppSelector } from '@common/hooks/redux';
import { selectActiveCompany, selectUsername } from '@common/features/user/selectors';
import { dateFormat } from '@common/constants';
import { format } from 'date-fns';
import { SideDecorationContent } from '@common/components';
import { Card } from './components/Card/Card';
import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from '@common/components/Textarea/Textarea';
import { ConfirmationWrapper } from '@common/features/document/components/ConfirmationWrapper';
import { IProductGroupTable } from './types';
import { FormErrors, SubmissionSummaryFormFields } from '../../hooks/useSubmissionSummary/types';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { IDocumentWithTask, IProductDto } from '@common/features/submission/types';
import { Id } from '@common/types';
import { SubmissionStatus } from '@common/services/types';
import { PleaseWaitOverlay } from '@common/components/PleaseWaitOverlay';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';

interface ISubmissionSummary {
  submissionTitle: string;
  tasks: IDocumentWithTask[];
  products: IProductDto[];
  productGroup: IProductGroupTable;
  submissionStatus: SubmissionStatus;
  pleaseWait: boolean;
  onCancel: () => void;
  onFileOpen: (taskId: Id) => void;
  onSubmit: () => void;
  onBackClick: () => void;
  onViewContent: (text: string, title: string) => void;
}

const SubmissionSummary = ({
  submissionTitle,
  tasks,
  products,
  productGroup,
  submissionStatus,
  pleaseWait,
  onCancel,
  onFileOpen,
  onSubmit,
  onBackClick,
  onViewContent,
}: ISubmissionSummary) => {
  const username = useAppSelector(selectUsername);
  const activeCompany = useAppSelector(selectActiveCompany);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { handleUrlClick } = useThirdPartyLinkWarning();
  const submissionDetailsColumns = prepareSubmissionDetailsColumns();
  const productGroupColumns = prepareProductGroupColumns();
  const contentColumns = prepareContentColumns({ onFileOpen, onViewContent, onUrlClick: handleUrlClick });
  const productsColumns = prepareProductsColumns();

  const submissionDetailsValues = [
    {
      company: activeCompany?.name,
      submitter: username,
      submissionDate: format(new Date(), dateFormat),
    },
  ];

  const textAreaError = errors[SubmissionSummaryFormFields.additionalInformation]?.message?.toString();
  const confirmationError = errors[SubmissionSummaryFormFields.confirmation]?.message?.toString();

  return (
    <Styled.LayoutWrapper>
      <SubmissionFlowHeader
        submissionStatus={submissionStatus}
        submissionTitle={submissionTitle}
        onBackClick={onBackClick}
        title="Submission Summary"
      >
        {pleaseWait && <PleaseWaitOverlay />}
        <HeaderBreadcrumb>
          <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Submission Summary
        </HeaderBreadcrumb>
      </SubmissionFlowHeader>
      <Styled.ProductGroupWrap>
        <Styled.Card>
          <Styled.ContentWrapper>
            <Styled.ContentHeaderWrapper>
              <Styled.Title>{submissionTitle}</Styled.Title>
            </Styled.ContentHeaderWrapper>
            <Styled.ShortTableWrapper>
              <DatapharmTable columns={submissionDetailsColumns} documents={submissionDetailsValues} data-testid="submission" />
            </Styled.ShortTableWrapper>
          </Styled.ContentWrapper>
        </Styled.Card>
        <Styled.CreateSubmissionCompactWrap>
          <SideDecorationContent>
            <Card title="Product group">
              <Styled.ShortTableWrapper>
                <DatapharmTable columns={productGroupColumns} documents={[productGroup]} data-testid="product-group" />
              </Styled.ShortTableWrapper>
            </Card>
            {tasks.length > 0 ? (
              <Card title="Content">
                <Styled.TableWrapper>
                  <DatapharmTable columns={contentColumns} documents={tasks} data-testid="content" />
                </Styled.TableWrapper>
              </Card>
            ) : undefined}
            {products.length > 0 ? (
              <Card title="Products">
                <Styled.TableWrapper>
                  <DatapharmTable columns={productsColumns} documents={products} data-testid="products" />
                </Styled.TableWrapper>
              </Card>
            ) : undefined}
            <Styled.AdditionalInfoCard>
              <Styled.ContentWrapper>
                <Styled.ContentHeaderWrapper>
                  <Styled.Subtitle>Additional information</Styled.Subtitle>
                </Styled.ContentHeaderWrapper>
                <Controller
                  control={control}
                  name={SubmissionSummaryFormFields.additionalInformation}
                  rules={{ maxLength: { value: 1000, message: FormErrors.additionalInformation } }}
                  render={({ field: { value, onChange } }) => (
                    <Textarea
                      placeholder="Please enter any additional information you would like our QC team to know in relation to your submission"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {textAreaError && <ErrorMessage>{textAreaError}</ErrorMessage>}
              </Styled.ContentWrapper>
            </Styled.AdditionalInfoCard>
            <Styled.ConfirmCard>
              <Styled.ContentWrapper>
                <Styled.Subtitle>Confirm your selection</Styled.Subtitle>
                <Styled.TextWrapper>
                  {`Please review the information above and confirm that the details are correct,\nNote: Once approved the information you have provided in the submission will be displayed on emc.`}
                </Styled.TextWrapper>
                <Controller
                  control={control}
                  name={SubmissionSummaryFormFields.confirmation}
                  rules={{ validate: (data) => !!data || FormErrors.confirmation }}
                  render={({ field: { value, onChange } }) => (
                    <ConfirmationWrapper
                      name="Summary confirmation"
                      value={value}
                      onChange={onChange}
                      text="I confirm that the data supplied is correct and I am happy to proceed with my submission."
                    />
                  )}
                />
                {confirmationError && <ErrorMessage>{confirmationError}</ErrorMessage>}
              </Styled.ContentWrapper>
            </Styled.ConfirmCard>
          </SideDecorationContent>
        </Styled.CreateSubmissionCompactWrap>
      </Styled.ProductGroupWrap>
      <SubmissionFlowFooter onContinue={onSubmit} onCancel={onCancel} continueText="Submit" />
    </Styled.LayoutWrapper>
  );
};

export default SubmissionSummary;
