import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';
import { TextWithIcon } from '@common/components/TextWithIcon/TextWithIcon';
import { emcTooltipText, medicinalTooltipText } from '@common/constants';
import { ProductName } from './styles';

interface IProps {
  emcNameValue: string;
  medicinalNameValue: string;
}

export const ReadonlyProductNames = ({ emcNameValue, medicinalNameValue }: IProps) => (
  <div>
    <ProductName>
      <label>
        <TextWithIcon icon={<IconWithTooltip tooltipId="emc" iconSrc="/icons/info_circle.svg" tooltipText={emcTooltipText} />}>
          emc Display Name
        </TextWithIcon>
      </label>
      <span data-testid={`emc-display-name`}>{emcNameValue}</span>
    </ProductName>
    <ProductName>
      <label>
        <TextWithIcon
          icon={<IconWithTooltip tooltipId="medicinal" iconSrc="/icons/info_circle.svg" tooltipText={medicinalTooltipText} />}
        >
          Medicinal Product Name
        </TextWithIcon>
      </label>
      <span data-testid={`emc-medicinal-product-name`}>{medicinalNameValue}</span>
    </ProductName>
  </div>
);
