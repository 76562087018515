import React from 'react';
import { ModalTitle } from '@common/features/productGroup/styles';
import { Button, ButtonTypes } from '@common/components/';
import { ModalWrapper } from '@common/components/ModalWrapper/ModalWrapper';
import * as Styled from './styles';
import { ChangeEntry, ChangeList, EntryHeading } from '@common/components/ChangeList/styles';
import { Tag } from '@common/components/Tag/styles';
import { ProductGroupPlan } from 'apps/publisher-v3.ui.customer/src/features/submission/types';
import Divider from '@common/components/Divider/Divider';
import { TextWithIcon } from '@common/components/TextWithIcon/TextWithIcon';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';

interface IProductGroupReviewPopup {
  isOpen: boolean;
  draftState: ProductGroupPlan;
  handleClose: () => void;
  handleContinue: () => void;
}

function TagForState(exists: boolean) {
  if (exists) {
    return (
      <Tag data-testid="product-group-confirmation__tag" className="normal">
        Update
      </Tag>
    );
  }
  return (
    <Tag data-testid="product-group-confirmation__tag" className="success">
      New
    </Tag>
  );
}

const ProductGroupReviewPopup = ({ isOpen, draftState, handleClose, handleContinue }: IProductGroupReviewPopup) => {
  const familyGroupTooltipText =
    'Product Family name is based on either the approved name of the medicine or the brand name depending upon type of name under which the medicine is marketed.';
  const productGroupTooltipText =
    'Products that share the same product family and active ingredients belong to the same product group.';

  return (
    <ModalWrapper isOpen={isOpen} label="Confirm the details are correct">
      <Styled.ModalContent>
        <Styled.ModalIcon src={`/icons/document-view.svg`} />
        <ModalTitle>Confirm the details are correct</ModalTitle>
        <ChangeList>
          <ChangeEntry>
            <EntryHeading>
              <TextWithIcon
                icon={
                  <IconWithTooltip
                    tooltipId="familyGroup"
                    iconSrc="/icons/info_circle.svg"
                    tooltipText={familyGroupTooltipText}
                  />
                }
              >
                <span className="entry-title">Product family</span>
              </TextWithIcon>
              {TagForState(draftState.doesProductFamilyExist)}
            </EntryHeading>
            <ul>
              <li data-testid="product-group-confirmation__trade-group">{draftState.productFamilyName}</li>
            </ul>
          </ChangeEntry>

          <ChangeList>
            <ChangeEntry>
              <EntryHeading>
                <TextWithIcon
                  icon={
                    <IconWithTooltip
                      tooltipId="productGroup"
                      iconSrc="/icons/info_circle.svg"
                      tooltipText={productGroupTooltipText}
                    />
                  }
                >
                  <span className="entry-title">Product group</span>
                </TextWithIcon>
                {TagForState(draftState.doesProductGroupExist)}
              </EntryHeading>
              <ul>
                <li>
                  <span data-testid="product-group-confirmation__product-group">{draftState.productGroupName}</span>
                  <ul>
                    {draftState.activeIngredients.map((ingredient, index) => {
                      return (
                        <li data-testid="product-group-confirmation__active-ingredient" key={index}>
                          {ingredient.name}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </ChangeEntry>
          </ChangeList>
        </ChangeList>
        <Styled.ModalButtons>
          <Button onClick={handleClose} text="Cancel" type={ButtonTypes.TEXT} />
          <Divider type="vertical" size="50px" thickness={1} />
          <Button onClick={handleContinue} text="Continue" type={ButtonTypes.PRIMARY} />
        </Styled.ModalButtons>
      </Styled.ModalContent>
    </ModalWrapper>
  );
};

export default ProductGroupReviewPopup;
