import { IActiveIngredient } from '../submission/types';

export const prepareActiveIngredientsForForm = (activeIngredients: IActiveIngredient[]) => {
  const denominatorValue = activeIngredients[0]?.denominatorValue ?? '';
  const denominatorUnit = activeIngredients[0]?.denominatorUnit ?? '';

  const denominator = {
    denominatorValue,
    denominatorUnit: { label: denominatorUnit ?? '', value: denominatorUnit ?? '' },
  };

  const ingredientsWithNumerators = activeIngredients.map((item) => {
    return {
      name: item?.name,
      numeratorValue: item?.numeratorValue ?? '',
      numeratorUnit: { label: item?.numeratorUnit ?? '', value: item?.numeratorUnit ?? '' },
    };
  });

  return { denominator, ingredientsWithNumerators };
};
