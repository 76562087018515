import { AxiosProgressEvent } from 'axios';
import { RequestType } from './types';
import { sendAxiosFileRequest } from '@api/uploadFileWithProgression';
import { buildUrl, handleRequest } from './requestHelper';
import { IPublicClientApplication } from '@azure/msal-browser';

const getData = async (
  token: string,
  endpoint: string,
  query = '',
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ token, url, requestType: RequestType.GET, shouldUseSubscriptionKey });
};

const addData = async (
  token: string,
  endpoint: string,
  data: any,
  instance?: IPublicClientApplication,
  query = '',
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ token, url, requestType: RequestType.POST, shouldUseSubscriptionKey, data, instance });
};

const updateData = async (
  token: string,
  endpoint: string,
  data: any,
  query: string,
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ token, url, requestType: RequestType.PUT, shouldUseSubscriptionKey, data });
};

const deleteData = async (
  token: string,
  endpoint: string,
  query?: string,
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ token, url, requestType: RequestType.DELETE, shouldUseSubscriptionKey });
};

const uploadAxiosFiles = async (token: string, data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl('/staged-file', basePath, '');

  return sendAxiosFileRequest(token, RequestType.POST, data, url, onUploadProgress);
};

const deleteFile = async (token: string, fileName: string, shouldUseSubscriptionKey = true) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl(`/staged-file/${fileName}`, basePath, '');

  return handleRequest({ token, url, requestType: RequestType.DELETE, shouldUseSubscriptionKey });
};

const replaceAltText = async (token: string, submissionId: string, documentId: string, data: unknown, shouldUseSubscriptionKey: boolean = true) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/alternative-text`, basePath, '');

  return handleRequest({ token, url, requestType: RequestType.PUT, data, shouldUseSubscriptionKey });
}


export { getData, addData, updateData, deleteData, deleteFile, uploadAxiosFiles, replaceAltText };
