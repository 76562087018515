import { UnsavedChangesContext } from '@common/context/UnsavedChangesContext';
import { useContext, useEffect } from 'react';

interface Props {
  isDirty?: boolean;
}

export const useUnsavedChanges = ({ isDirty }: Props) => {
  const { setHandleConfirm, setHasUnsavedChanges, hasUnsavedChanges } = useContext(UnsavedChangesContext);

  const handleUnsavedChanges = (handleConfirm: () => void) => {
    if (hasUnsavedChanges) setHandleConfirm(() => () => handleConfirm());
    else handleConfirm();
  };

  useEffect(() => {
    isDirty !== undefined && setHasUnsavedChanges(isDirty);
  }, [isDirty, setHasUnsavedChanges]);

  return { handleUnsavedChanges };
};
