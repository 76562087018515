import styled from 'styled-components';

export const SectionWrap = styled.div`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  height: fit-content;
`;
