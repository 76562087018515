import React from 'react';
import { ModalWrapper } from '@common/components/ModalWrapper/ModalWrapper';
import * as Styled from './styles';
import { Button } from '@common/components/Button/Button';
import { ButtonTypes } from '@common/components/Button/types';
import { SubmissionDataType } from './hooks/useRemoveSubmissionData';
import { RemoveModalContent } from './constants';
import { StyledSVG, Title } from '@common/styles';

interface IRemoveModal {
  isOpen: boolean;
  onCancelClick: () => void;
  onConfirmClick: (id: number) => void;
  title: string;
  type: SubmissionDataType;
}

const RemoveModal = ({ isOpen, onCancelClick, onConfirmClick, title, type }: IRemoveModal) => {
  return (
    <ModalWrapper isOpen={isOpen} zIndex={998}>
      <Styled.Wrap>
        <Styled.Top>
          <StyledSVG src="/icons/question_mark_red.svg" />
          <Title>{RemoveModalContent[type].title}</Title>
          {RemoveModalContent[type].getDescription(title)}
        </Styled.Top>
        <Styled.Bottom>
          <Styled.CancelButtonWrap>
            <Button onClick={onCancelClick} text="Cancel" type={ButtonTypes.TEXT} />
          </Styled.CancelButtonWrap>
          <Button onClick={onConfirmClick} width={163} text="Confirm" type={ButtonTypes.PRIMARY} />
        </Styled.Bottom>
      </Styled.Wrap>
    </ModalWrapper>
  );
};

export default RemoveModal;
