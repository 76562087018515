import { UseFormReturn, useFormContext } from 'react-hook-form';
import { ICheckboxEntry } from '@common/types';

export const useFormSelectedItems = (fieldName: string, methods?: UseFormReturn<any, any, undefined>) => {
  const { watch, setValue } = useFormContext() ?? methods;

  const selectedItems = watch(fieldName) as ICheckboxEntry[];
  const isItemSelected = (item: ICheckboxEntry) => !!selectedItems?.find((i) => i.id === item.id);

  const selectItem = (item: ICheckboxEntry) => {
    setValue(fieldName, [...selectedItems, item], { shouldDirty: true });
  };

  const deselectItem = (item: ICheckboxEntry) =>
    setValue(
      fieldName,
      selectedItems.filter((currentItem) => currentItem.id !== item.id),
      { shouldDirty: true },
    );

  const toggleItem = (item: ICheckboxEntry) => {
    isItemSelected(item) ? deselectItem(item) : selectItem(item);
  };

  return {
    selectedItems,
    isItemSelected,
    toggleItem,
  };
};
