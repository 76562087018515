import React from 'react';
import { ModalTitle } from '@common/features/productGroup/styles';
import { Button, ButtonTypes } from '@common/components';
import { ModalWrapper } from '@common/components/ModalWrapper/ModalWrapper';
import * as Styled from './styles';
import Divider from '@common/components/Divider/Divider';

interface IProductGroupAlreadyExistsPopup {
  isOpen: boolean;
  handleClose: () => void;
  handleViewGroup: () => void;
}

const ProductGroupAlreadyExistsPopup = ({ isOpen, handleClose, handleViewGroup }: IProductGroupAlreadyExistsPopup) => {
  return (
    <ModalWrapper isOpen={isOpen} label="Product group already exists">
      <Styled.ModalContent>
        <Styled.ModalIcon src={`/icons/warning-sign.svg`} />
        <ModalTitle>Product group already exists</ModalTitle>

        <Styled.ModalButtons>
          <Button
            data-testid="existing-product-group-popup__cancel-btn"
            onClick={handleClose}
            text="Cancel"
            type={ButtonTypes.TEXT}
          />
          <Divider type="vertical" size="50px" thickness={1} />
          <Button onClick={handleViewGroup} text="View existing product group" type={ButtonTypes.PRIMARY} />
        </Styled.ModalButtons>
      </Styled.ModalContent>
    </ModalWrapper>
  );
};

export default ProductGroupAlreadyExistsPopup;
