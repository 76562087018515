import {
  IProductTypeSelectOption,
  IsTruthySelectItemLabel,
  IsTruthySelectItemValue,
  ProductTypeSelectLabels,
  ProductTypeSelectValues,
  DocumentType,
  DocumentStatus,
} from './types';

export const dateTimeFormat = 'dd-LLL-yyyy HH:mm:ss';
export const dateTimeFormatNoSeconds = 'dd-LLL-yyyy HH:mm';
export const dateFormat = 'dd-LLL-yyyy';

export const isTruthySelectOptions = [
  { label: IsTruthySelectItemLabel.Yes, value: IsTruthySelectItemValue.Yes },
  { label: IsTruthySelectItemLabel.No, value: IsTruthySelectItemValue.No },
];

export const productTypeSelectOptions: IProductTypeSelectOption[] = [
  {
    label: ProductTypeSelectLabels.BRANDED_INNOVATOR,
    value: ProductTypeSelectValues.BRANDED_INNOVATOR,
  },
  {
    label: ProductTypeSelectLabels.GENERIC,
    value: ProductTypeSelectValues.GENERIC,
  },
  {
    label: ProductTypeSelectLabels.BRANDED_GENERIC,
    value: ProductTypeSelectValues.BRANDED_GENERIC,
  },
];

export const atcCodePattern = `^[A-Za-z][0-9]{2}(?:[A-Za-z]{1,2}|[A-Za-z]{2}[0-9]{2})?$`;
export const searchInputPattern = new RegExp('^[^ ]{3}');

export const UNITS_DIVIDER = 1024;

export const FILE_SIZE_LIMIT = 5 * UNITS_DIVIDER * UNITS_DIVIDER;

export const documentTypeToCustomLabelMap: DocumentTypeToCustomLabel = {
  [DocumentType.Smpc]: 'SmPC',
  [DocumentType.Pil]: 'PIL',
  [DocumentType.Rmm]: 'RMM',
  [DocumentType.UserManual]: 'User Manual',
  [DocumentType.SafetyAlert]: 'Safety alert',
  [DocumentType.AudioVideo]: 'Audio & video',
  [DocumentType.LiveChat]: 'Live Chat',
  [DocumentType.ProductInformation]: 'Product Information',
  [DocumentType.Dhcp]: 'DHPC',
  [DocumentType.Epil]: 'ePIL',
  [DocumentType.Video]: 'Video',
  [DocumentType.Audio]: 'Audio',
};

type DocumentTypeToCustomLabel = {
  [key in DocumentType]?: string;
};

export const associatedContentStatuses = [DocumentStatus.Draft, DocumentStatus.Published, DocumentStatus.Submitted];

export const emcTooltipText = 'This is the product name as it will appear on the emc';
export const medicinalTooltipText = 'This is the Medicinal Product Name as shown in section 1 of the SmPC';
