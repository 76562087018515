import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WeblinksWrap = styled.div`
  display: flex;
  column-gap: 1rem;
`;

export const UrlWrapperStyledLink = styled(Link)`
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
  text-decoration: none;
`;

export const UrlWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.elements.disabledInputBg};
  width: auto;
`;

export const EditableUrlWrapper = styled(UrlWrapper)`
  padding: 0.5rem 20px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;
