import { ISelectOption } from '@common/types';
import { useForm } from 'react-hook-form';

export enum DocumentRetireFormFields {
  reason = 'reason',
}

interface IDocumentRetireForm {
  [DocumentRetireFormFields.reason]: ISelectOption | null;
}

export const useRetireModalForm = () => {
  const methods = useForm<IDocumentRetireForm>({
    mode: 'onSubmit',
    defaultValues: {
      [DocumentRetireFormFields.reason]: null,
    },
  });

  return { methods, ...methods };
};
