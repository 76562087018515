import React from 'react';
import { BadgeWrapper } from './styles';

interface IBadge {
  value?: number;
  ['data-testid']?: string;
}

const Badge = ({ value = 0, ...other }: IBadge) => {
  return <BadgeWrapper data-testid={other['data-testid']}>{value}</BadgeWrapper>;
};

export default Badge;
