import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 0.5rem 13px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const Icon = styled(ReactSVG)`
  margin-right: 10px;
  margin-top: 0.5rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.elements.textGrey};
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.elements.textGrey};
  word-break: break-word;
`;

export const Item = styled.div`
  font-size: 14px;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.elements.textDarkGrey};
  word-break: break-word;
`;

export const Text = styled.div`
  margin-bottom: 0.5rem;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  button {
    height: 3rem;
    min-width: 6rem;
    width: 6rem;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const Content = styled.div`
  width: 100%;
`;
