import styled, { css } from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { ReactSVG } from 'react-svg';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border-bottom: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Info = styled.div``;

export const UserName = styled.div`
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  text-align: end;
`;

export const Company = styled.div`
  color: ${({ theme }) => theme.elements.dropdownGrey};
  text-align: right;
`;

export const UserInfoWrap = styled.div`
  width: 160px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.elements.textLightGrey};
  padding-left: 20px;
  border-left: 1px solid ${({ theme }) => theme.elements.borderGrey};
  margin: 0 10px 0 20px;
`;

export const ArrowIcon = styled(IoIosArrowDown).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{ isActive: boolean }>`
  color: ${({ theme }) => theme.elements.textGrey};
  margin-top: 3px;
  transition: all 0.2s ease-out;
  margin-left: 3px;
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(180deg);
    `}
`;

export const UserInfoPopoverWrap = styled.div`
  position: relative;
  color: ${({ theme }) => theme.elements.dropdownGrey};
`;

export const PopoverArrowWrap = styled.div`
  position: absolute;
  top: -9px;
  right: 45px;
  border-left: 10.5px solid transparent;
  border-right: 10.5px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.borderGray};
  width: 0;
  height: 0;
`;

export const PopoverArrow = styled.div`
  position: absolute;
  left: -10px;
  top: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.defaultWhite};
  width: 0;
  height: 0;
`;

export const PopoverItem = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  z-index: 1;
  &:last-child {
    border-bottom: none;
  }
`;

export const PopoverContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  margin-right: 30px;
  margin-top: 15px;
`;

export const PopoverHeader = styled(PopoverItem)`
  display: flex;
  align-items: center;
`;

export const PopoverHeaderLeft = styled.div`
  margin-top: 2px;
  font-size: 16px;
`;

export const PopoverHeaderRight = styled.div`
  margin-left: 10px;
`;

export const EditProfileLink = styled.div`
  color: ${({ theme }) => theme.elements.primaryMedium};
  cursor: pointer;
  margin-top: 5px;
`;

export const CompanyItem = styled.div<{ isCurrent?: boolean }>`
  cursor: pointer;
  display: flex;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SignOutWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const PopoverItemText = styled.div`
  margin-left: 10px;
`;

export const MenuSpacer = styled.div`
  width: 26px;
  height: 26px;
`;

export const StyledSVG = styled(ReactSVG)`
  div {
    display: flex;
    svg {
      path {
        fill: ${({ theme }) => theme.elements.primary};
      }
    }
  }
`;

export const StyledTickIcon = styled(StyledSVG)`
  div {
    svg {
      path {
        fill: ${({ theme }) => theme.elements.primaryMedium};
      }
    }
  }
`;

export const StyledAccountIcon = styled(StyledSVG)`
  div {
    svg {
      path {
        fill: ${({ theme }) => theme.elements.dropdownGrey};
      }
    }
  }
`;

export const HeaderText = styled.div`  
  width: calc(100% - 440px);    
  margin: 0px;  
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  text-align: center;
  color: rgb(95, 165, 204);
  font-size: ${({ theme }) => theme.fontSizes.xxLarge};
  text-transform: uppercase;  
`;
