import styled from 'styled-components';

interface IStyledRadio {
  width: number;
  height: number;
}

export const StyledRadio = styled.input.attrs({ type: 'radio' })<IStyledRadio>`
  cursor: pointer;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  :disabled {
    cursor: default;
  }
`;
