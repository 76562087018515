import { useEffect, useState } from 'react';
import { DocumentExtensionType, DocumentType } from '@common/types';
import { useFetchPublishedDocument } from '@hooks/useFetchPublishedDocument';

interface IDownloadFileProps {
  documentId?: string;
  version?: string;
  documentType?: DocumentType;
  extensionType?: DocumentExtensionType;
}

const getFileName = (contentDisposition?: string) => contentDisposition?.split('filename=')[1].split(';')[0] as string;

export const useDownloadAndOpenPublishedFile = () => {
  const [downloadFileParams, setDownloadFileParams] = useState<IDownloadFileProps>();

  const { data, refetch } = useFetchPublishedDocument(
    true,
    downloadFileParams?.documentId,
    downloadFileParams?.version,
    downloadFileParams?.documentType,
    downloadFileParams?.extensionType,
  );

  const downloadAndOpen = (downloadFileParams: IDownloadFileProps) => {
    setDownloadFileParams(downloadFileParams);
  };

  useEffect(() => {
    if (downloadFileParams?.documentId) refetch();
  }, [downloadFileParams, refetch]);

  if (data) {
    const fileName = getFileName(data?.headers['content-disposition'] as string) || 'Document file';
    const cleanFileName = fileName.replace(/"/g, '');
    const contentType = data?.headers['content-type'] as string;
    const file = new File([data.data], cleanFileName, { type: contentType });

    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    a.href = url;
    if (downloadFileParams?.extensionType === DocumentExtensionType.Docx) {
      a.download = cleanFileName;
    }
    a.target = '_blank';
    a.click();

    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
    setDownloadFileParams(undefined);
  }

  return { downloadAndOpen };
};
