import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SidebarEntry } from './SidebarEntry';
import { getProductDetailsSections } from 'apps/publisher-v3.ui.customer/src/features/submission/constants';

import * as Styled from './styles';

interface IProps {
  disabled?: boolean;
  shouldDisplayAmpId: boolean;
  shouldDisplayContactGroup: boolean;
}

export const ProductDetailsSidebar = ({ disabled, shouldDisplayAmpId, shouldDisplayContactGroup }: IProps) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const scrollTo = (anchor: string) => {
    const section = document.querySelector(`${anchor}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const renderOptions = () => {
    return getProductDetailsSections(shouldDisplayAmpId, shouldDisplayContactGroup).map((item, index) => {
      const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const anchor = `#${item.fieldName}`;
        e.preventDefault();
        scrollTo(anchor);
      };

      return (
        <SidebarEntry
          formValues={formValues}
          key={index}
          anchor={item.fieldName}
          name={item.name}
          onClick={onClick}
          disabled={disabled}
        />
      );
    });
  };
  return <Styled.SidebarWrapper>{renderOptions()}</Styled.SidebarWrapper>;
};
