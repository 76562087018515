import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';
import { replaceTaskFile } from '@common/services/submissionService';
import { usePleaseWaitOverlay } from '@components/PleaseWaitOverlay/usePleaseWaitOverlay';

interface IPostReplacementDocumentDetails {
  title: string;
}

export const useReplaceDocument = (taskId: Id, replacementFileName: string, replacementOriginalFileName: string) => {
  const token = useAppSelector(selectToken);
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const { mutate, data, isLoading } = useWaitingMutation(
    ({ title }: IPostReplacementDocumentDetails) => {
      const payload = {
        replacementFileName,
        replacementOriginalFileName,
        title,
      };

      return replaceTaskFile(token, taskId, payload);
    },
    { onMutate: handleShowOverlay, onSuccess: handleHideOverlay, onError: handleHideOverlay },
  );

  return { replaceDocument: mutate, data, isLoading };
};
