import styled from 'styled-components';

export const ErrorContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.label};
  margin-bottom: 0.5rem;
`;

export const ProductName = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  margin-bottom: 1rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
