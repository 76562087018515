import { Button, ButtonTypes } from '@common/components';
import * as Styled from './styles';
import { UnderlinedLink } from '@common/components/UnderlinedLink/styles';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';

interface IError {
  title: string;
  linkText: string;
  helpLink?: string;
  additionalInfo?: string;
  onClick: () => void;
}

export const Item = ({ title, linkText, helpLink = '', additionalInfo, onClick }: IError) => (
  <Styled.Wrapper>
    <Styled.Content>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Item>{additionalInfo}</Styled.Item>
      <Styled.Link>
        <UnderlinedLink to={helpLink} target="_blank">
          {linkText}
        </UnderlinedLink>
        <IconWithTooltip tooltipId="emc" iconSrc="/icons/info_circle.svg" tooltipText="Tooltip" />
      </Styled.Link>
    </Styled.Content>
    <Button onClick={onClick} text="Get started" type={ButtonTypes.SUCCESS} />
  </Styled.Wrapper>
);
