import { RegisterOptions, useFormContext } from 'react-hook-form';
import { SelectAll, ISelectOptions } from './SelectAll';
import { ErrorMessage } from '../ErrorMessage';
import { Wrapper } from './styles';
import { ISelectOption } from '@common/types';
import { getEnabledValuesAndOptions } from './helpers';

interface IHookFormSelectAll {
  name: string;
  label?: string;
  options: ISelectOptions | undefined;
  rules?: RegisterOptions<any, string>;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  inlineLabel?: boolean;
  isMulti?: boolean;
  className?: string;
  width?: string;
  onKeyDown?: (e: string) => void;
  onValueChange?: () => void;
  onAllDeselect?: () => void;
}

export const HookFormSelectAll = ({
  name,
  label,
  options,
  rules,
  error,
  disabled,
  placeholder,
  isLoading,
  inlineLabel,
  className,
  width,
  onKeyDown,
  onValueChange,
  onAllDeselect,
}: IHookFormSelectAll) => {
  const { setValue, watch, register, getValues } = useFormContext();

  if (rules) {
    register(name, {
      ...rules,
    });
  }

  const handleChange = (o: ISelectOptions) => {
    setValue(name, o, { shouldValidate: true, shouldDirty: true });
    onValueChange && onValueChange();
  };

  const handleSelectAllClick = () => {
    const formValues = getValues(name);

    const { areAllEnabledOptionsSelected, enabledOptions } = getEnabledValuesAndOptions(formValues, options ?? []);

    // Get disabled selected values and use it as default when selecting and deselecting all values
    const disabledSelectedValues = formValues.filter((v: ISelectOption) =>
      options?.find((o) => o.value === v.value && o.disabled),
    );

    if (areAllEnabledOptionsSelected) {
      //Deselecting "Select All" should leave only the ones that were selected but are disabled
      onAllDeselect && onAllDeselect();
      setValue(name, disabledSelectedValues, { shouldValidate: true, shouldDirty: true });
    } else {
      //Selecting "Select All" should add all enabled options to the selected disabled ones
      setValue(name, disabledSelectedValues.concat(enabledOptions), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const value = watch(name);

  return (
    <Wrapper>
      <SelectAll
        placeholder={placeholder}
        disabled={disabled}
        label={label}
        name={name}
        value={value}
        options={options}
        isLoading={isLoading}
        inlineLabel={inlineLabel}
        className={className}
        width={width}
        onChange={handleChange}
        onInputChange={onKeyDown}
        onSelectAllClick={handleSelectAllClick}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
