import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';
import { TextWithIcon } from '@common/components/TextWithIcon/TextWithIcon';
import { emcTooltipText, medicinalTooltipText } from '@common/constants';
import { ProductName } from './styles';

interface IProps {
  emcNameField: string;
  medicinalNameField: string;
  emcProductNameError?: string;
  disableEmcName?: boolean;
  medicinalProductNameError?: string;
  disableMedicinalName?: boolean;
}

export const ProductNames = ({
  emcNameField,
  emcProductNameError,
  disableEmcName,
  medicinalNameField,
  medicinalProductNameError,
  disableMedicinalName,
}: IProps) => (
  <div>
    <ProductName>
      <TextWithIcon icon={<IconWithTooltip tooltipId="emc" iconSrc="/icons/info_circle.svg" tooltipText={emcTooltipText} />}>
        <label>emc Display Name</label>
      </TextWithIcon>
      <HookFormInput
        name={emcNameField}
        rules={{ required: 'This field is required' }}
        error={emcProductNameError}
        disabled={disableEmcName}
      />
    </ProductName>
    <ProductName>
      <TextWithIcon
        icon={<IconWithTooltip tooltipId="medicinal" iconSrc="/icons/info_circle.svg" tooltipText={medicinalTooltipText} />}
      >
        <label>Medicinal Product Name</label>
      </TextWithIcon>
      <HookFormInput
        name={medicinalNameField}
        rules={{ required: 'This field is required' }}
        error={medicinalProductNameError}
        disabled={disableMedicinalName}
      />
    </ProductName>
  </div>
);
