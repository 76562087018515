import React from 'react';
import { Button, ButtonTypes } from '@common/components';

import { DocumentType, ValidationError } from '@common/types';

import {
  BorderedMessage,
  BorderedMessageTitle,
  BorderedMessageSubTitle,
  FileErrorBottom,
  BorderedMessageIcon,
  InfoLink,
  InfoIcon,
} from './styles';

interface IFileError {
  errors: ValidationError[];
  type?: DocumentType;
  onClose: () => void;
}

const FileError = ({ errors, type, onClose }: IFileError) => (
  <div>
    {errors.map((e) => (
      <BorderedMessage key={e.subMessage}>
        <BorderedMessageIcon src={'/icons/errorBig.svg'} />
        <div>
          <BorderedMessageTitle>{e.message}</BorderedMessageTitle>
          <BorderedMessageSubTitle>{e.subMessage}</BorderedMessageSubTitle>
        </div>
      </BorderedMessage>
    ))}
    <FileErrorBottom>
      {type && (
        <InfoLink to={process.env.REACT_APP_HELP_CONTENT_URL ?? ''} target="_blank">
          {`How to add a ${type}`} <InfoIcon src={'/icons/question_mark_filed.svg'} />
        </InfoLink>
      )}
      <Button onClick={onClose} text="Close" type={ButtonTypes.TEXT} />
    </FileErrorBottom>
  </div>
);

export default FileError;
