import { useState } from 'react';
import { HtmlContentModalParams } from '../types';

export const useHtmlContentModal = () => {
  const [htmlContentModalParams, setHtmlContentModalParams] = useState<HtmlContentModalParams | undefined>();

  const closeHtmlContentModal = () => {
    setHtmlContentModalParams(undefined);
  }

  const handleContentDescriptionModalOpen = (val: string) => {
    setHtmlContentModalParams({content: val, title: 'Content Description'});
  }

  const handleAssociatedContentHtmlModalOpen = (val: string) => {
    setHtmlContentModalParams({content: val, title: 'HTML'});
  }

  return { htmlContentModalParams, setHtmlContentModalParams, closeHtmlContentModal, handleContentDescriptionModalOpen, handleAssociatedContentHtmlModalOpen };
};
