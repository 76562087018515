import React from 'react';
import { Grid } from './styles';
import { DocumentType } from '@common/types';
import { documentTypeToCustomLabelMap } from '@common/constants';
import {
  AuditsForm,
  ContentReportColumns,
  IAuditsForm,
  ContentReportColumnValueToLabelMap,
  ContentReportColumnLabels,
} from './useAuditsForm';
import { StyledLabel } from '@common/components/Select/styles';
import { useFormContext } from 'react-hook-form';
import { HookFormSelectAll } from '@common/components/Select/HookFormSelectAll';
import { HookFormCheckbox } from '@common/components/Checkbox/HookFormCheckbox';
import { CheckboxOptionText } from '@common/components/CheckboxOption/styles';

const { Smpc, Pil, Rmm, Dhcp, UserManual, SafetyAlert, ProductInformation, AudioVideo, LiveChat } = DocumentType;
const documentTypes = [Smpc, Pil, Rmm, Dhcp, UserManual, SafetyAlert, ProductInformation, AudioVideo, LiveChat];

const { ContentTitle, ContentId, ContentVersion, RetiredDate, RetiredBy } = ContentReportColumns;

export const ContentReportFilters = () => {
  const {
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<IAuditsForm>();

  const contentTypeOptions = documentTypes.map((t) => ({ label: documentTypeToCustomLabelMap[t] ?? '', value: t }));

  const includeRetired = watch(AuditsForm.includeRetiredContent);
  const columnOptions = Object.values(ContentReportColumns).map((c) => {
    if ((!includeRetired && [RetiredDate, RetiredBy].includes(c)) || [ContentTitle, ContentId, ContentVersion].includes(c)) {
      return { label: ContentReportColumnValueToLabelMap[c] as ContentReportColumnLabels, value: c, disabled: true };
    }

    return { label: ContentReportColumnValueToLabelMap[c] as ContentReportColumnLabels, value: c, disabled: false };
  });

  const handleIncludeRetiredChange = (value: boolean) => {
    if (!value) {
      const prevColumns = structuredClone(getValues(AuditsForm.contentColumns));
      const filteredColumns = prevColumns.filter((c) => ![RetiredDate, RetiredBy].includes(c.value as ContentReportColumns));

      setValue(AuditsForm.contentColumns, filteredColumns);
    }
  };

  return (
    <Grid rowCount={4}>
      <StyledLabel>Select content type(s)</StyledLabel>
      <HookFormSelectAll
        name={AuditsForm.contentTypes}
        options={contentTypeOptions}
        error={errors.contentTypes?.message}
        width="20rem"
        inlineLabel
      />
      <CheckboxOptionText>Include retired content</CheckboxOptionText>
      <HookFormCheckbox name={AuditsForm.includeRetiredContent} onChange={handleIncludeRetiredChange} />      
      <StyledLabel>Select columns</StyledLabel>
      <HookFormSelectAll name={AuditsForm.contentColumns} options={columnOptions} width="20rem" inlineLabel />
    </Grid>
  );
};
