import React from 'react';
import { SubmissionTypes } from '@common/features/submission/types';
import { SubmissionType } from '../../components/SubmissionType/SubmissionType';
import { submissisonTypeToText } from '../../components/SubmissionType/helpers';
import { SubmissionTypePickerContent, SubmissionTypePickerHeading, SubmissionTypePickerWrap } from './styles';
import { ErrorMessage } from '@common/components/ErrorMessage';

interface IProps {
  onChange: (submissionType: SubmissionTypes) => void;
  value: SubmissionTypes;
  error?: string;
}

export const SubmissionTypePicker = ({ onChange, value, error }: IProps) => {
  const renderSubmissionTypePickers = () =>
    Object.values(SubmissionTypes).map((type) => {
      const isActive = value === type;
      const { text, subText } = submissisonTypeToText[type];

      return <SubmissionType key={type} value={type} onClick={onChange} isActive={isActive} text={text} subText={subText} />;
    });

  return (
    <SubmissionTypePickerWrap>
      <SubmissionTypePickerHeading>What submission would you like to create?</SubmissionTypePickerHeading>
      <SubmissionTypePickerContent>{renderSubmissionTypePickers()}</SubmissionTypePickerContent>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SubmissionTypePickerWrap>
  );
};
