import * as Styled from './styles';
import { ConfirmationWrapper } from '../ConfirmationWrapper';

interface IReadOnlyHealthCareProfessional {
  isVisibleToHCP: boolean | null;
}
export const ReadOnlyHealthCareProfessional = ({ isVisibleToHCP }: IReadOnlyHealthCareProfessional) => {
  return (
    <Styled.RegulatorApprovalWrap>
      <Styled.ConfirmationHeading>Who should be able to view this content?</Styled.ConfirmationHeading>
      <Styled.ConfirmationHeading>
        If 'Healthcare Professional' is selected user will have to self certify as an HCP to view
      </Styled.ConfirmationHeading>
      <ConfirmationWrapper
        name="HCP"
        value={isVisibleToHCP ?? false}
        onChange={(e) => {}}
        text={`Healthcare professionals only`}
        disabled
      />
    </Styled.RegulatorApprovalWrap>
  );
};
