import React from 'react';
import * as Styled from './styles';

interface ISideDecorationContent {
  children: (JSX.Element | undefined | null)[];
}

export const SideDecorationContent = ({ children }: ISideDecorationContent) => {
  const renderDecoratedComponents = () => {
    return children.map((child, index) => {
      return (
        !!child && (
          <Styled.DecoratedContentItem key={index}>
            <Styled.DecoratedContentInner>
              <Styled.DecoratedContentItemIcon src={`/icons/side_decoration.svg`} />
              {child}
            </Styled.DecoratedContentInner>
          </Styled.DecoratedContentItem>
        )
      );
    });
  };

  return (
    <Styled.ContentWithDecorationWrap>
      <Styled.DecoratedContentWrap>{renderDecoratedComponents()}</Styled.DecoratedContentWrap>
    </Styled.ContentWithDecorationWrap>
  );
};
