import styled from 'styled-components';

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  width: 200px;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const SidebarLogo = styled.img`
  width: 115%;
  margin: 0 0 15px 0;
`;
