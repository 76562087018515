import { Section } from '@common/components/Section/Section';
import { YesNoCheckbox } from '@common/components/YesNoCheckbox/YesNoCheckbox';
import { useFormContext } from 'react-hook-form';
import { alternativeTextFileCheckboxTexts } from '../../constants';
import { Title } from '@common/styles';
import FileUpload from '@common/components/FileUpload/FileUpload';
import { UNITS_DIVIDER } from '@common/constants';

interface IProps {
  field?: string;
  value?: boolean;
  disabled?: boolean;
  withBorderAndSubtext?: boolean;
  isLoading?: boolean;
  uploadProgress: number;
  uploadedFileSize: number;
  uploadedFileName: string;
  handleFileSubmit: (files: FileList | null) => void;
  handleRemove: () => void;
}

export const AdditionalTextFileSection = ({
  field = '',
  value,
  disabled,
  withBorderAndSubtext,
  isLoading = false,
  uploadProgress,
  uploadedFileSize,
  uploadedFileName,
  handleFileSubmit,
  handleRemove,
}: IProps) => {
  const { watch, setValue } = useFormContext() ?? {};

  const handleChange = (value: boolean) => setValue(field, value);

  const renderCheckbox = () => <YesNoCheckbox value={watch ? watch(field) : value} disabled={disabled} onChange={handleChange} />;

  const fileSize = Math.round((uploadedFileSize ?? 0) / UNITS_DIVIDER);
  const shouldRenderAlternativeTextUpload = watch(field);

  return withBorderAndSubtext ? (
    <Section text={alternativeTextFileCheckboxTexts.title} subtext={alternativeTextFileCheckboxTexts.subtitle}>
      {renderCheckbox()}
      {shouldRenderAlternativeTextUpload && (
        <FileUpload
          onFileSubmit={handleFileSubmit}
          loadingPresents={uploadProgress}
          isLoading={isLoading}
          fileName={uploadedFileName}
          fileSize={fileSize + 'KB'}
          fileInputSubText={'.DOCX 3MB max'}
          onRemove={handleRemove}
          disabled={false}
        />
      )}
    </Section>
  ) : (
    <>
      <Title small>{alternativeTextFileCheckboxTexts.title}</Title>
      {renderCheckbox()}
    </>
  );
};
