import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const HeaderTextWrapper = styled.div`
  padding: 8px 0 0 25px;
  margin-right: 10px;
`;

export const TableWrapper = styled.div`
  padding: 15px 20px 25px 20px;
`;
