import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 10px;
`;

export const Wrap = styled.div`
  width: 800px;
  padding: 5px 20px;
`;

export const Top = styled.div`
  margin-bottom: 42px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div`
  margin-top: 10px;
  width: auto;
  cursor: pointer;
`;
export const Right = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
`;

export const CancelButtonWrap = styled.div`
  margin-right: 23px;
  width: 77px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const BackButtonWrap = styled.div`
  float: left;
  justify-content: flex-start;
  width: 77px;
`;

export const Description = styled.div``;

export const DescriptionText = styled.div``;

export const DescriptionSubtext = styled.div``;

export const DescriptionList = styled.ul`
  margin: 0;
  list-style-position: inside;
  list-style-type: '- ';
  margin: 10px 0;
`;

export const DescriptionListItem = styled.li``;
