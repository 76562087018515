import { ICheckboxEntry } from '@common/types';

export interface ISubmissionForm {
  documentId: number;
  documentTitle: string;
  file?: File;
  webLink?: string;
  hasDigitalVersion?: boolean;
  isCustomerApprovalRequired: boolean | null;
  productIds: ICheckboxEntry[];
  content?: string;
}

export enum FormFields {
  documentId = 'documentId',
  documentTitle = 'documentTitle',
  file = 'file',
  webLink = 'webLink',
  hasDigitalVersion = 'hasDigitalVersion',
  isCustomerApprovalRequired = 'isCustomerApprovalRequired',
  productIds = 'productIds',
  content = 'content',
}
