import { ReactNode } from 'react';
import * as Styled from './styles';
import { TextWithIcon } from '../TextWithIcon/TextWithIcon';

interface ISection {
  text?: string;
  subtext?: string | ReactNode;
  icon?: JSX.Element;
  linkAtEnd?: JSX.Element;
  children: any;
  ['data-testid']?: string;
}

export const Section = ({ text, subtext, icon, linkAtEnd, children, ...props }: ISection) => (
  <Styled.SectionWrap data-testid={props['data-testid']}>
    <Styled.SectionHeadingWrap>
      <TextWithIcon icon={icon}>{text && <Styled.SectionHeadingText>{text}</Styled.SectionHeadingText>}</TextWithIcon>
      {subtext && (
        <Styled.SectionHeadingSubText>
          {subtext}
          {linkAtEnd}
        </Styled.SectionHeadingSubText>
      )}
    </Styled.SectionHeadingWrap>
    {children}
  </Styled.SectionWrap>
);
