import { createSelector } from '@reduxjs/toolkit';
import { IGlobalState } from './types';
import { RootState } from '@common/store';

const selectGlobalState = (state: RootState) => state.global;

export const selectShowPleaseWaitOverlay = createSelector(
  selectGlobalState,
  (state: IGlobalState) => state.showPleaseWaitOverlay,
);

export const selectThirdPartyLink = createSelector(selectGlobalState, (state: IGlobalState) => state.thirdPartyLink);
