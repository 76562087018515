import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import { AddNoteFormFields, usePrepareNotesForm } from '../hooks/usePrepareNotesForm';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Button, ButtonTypes } from '@common/components';
import { Textarea } from '@common/components/Textarea/Textarea';

import * as Styled from './styles';

import Divider from '@common/components/Divider/Divider';

interface Props {
  submitCallback: (note: string) => void;
  isUpdating?: boolean;
  onClose: () => void;
}

export const ResubmitModal = ({ submitCallback, onClose, isUpdating }: Props) => {
  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
  } = usePrepareNotesForm();

  const textAreaError = errors[AddNoteFormFields.note]?.message?.toString();

  const onSubmit = () => handleSubmit((data) => submitCallback(data[AddNoteFormFields.note]))();

  return (
    <Styled.AddNoteWrapper>
      <Styled.ModalTitle>
        <Styled.TitleText>Add note</Styled.TitleText>
      </Styled.ModalTitle>
      <FormProvider {...methods}>
        <div>
          <Controller
            control={control}
            name={AddNoteFormFields.note}
            rules={{ maxLength: { value: 500, message: 'Max. length: 500 characters' } }}
            render={({ field: { value, onChange } }) => (
              <Textarea placeholder="Please enter note" value={value} onChange={onChange} />
            )}
          />
          {textAreaError && <ErrorMessage>{textAreaError}</ErrorMessage>}
        </div>
      </FormProvider>
      <Styled.ButtonsWrapper>
        <Button onClick={onClose} text="Cancel" type={ButtonTypes.TEXT} />
        <Divider type="vertical" size="50px" thickness={1} />
        <Button onClick={onSubmit} text="Submit" type={ButtonTypes.PRIMARY} isLoading={isUpdating} />
      </Styled.ButtonsWrapper>
    </Styled.AddNoteWrapper>
  );
};
