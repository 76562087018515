import React from 'react';
import { HorizontalDivider, VerticalDivider } from './styles';

interface IDividerProps {
  type: 'vertical' | 'horizontal';
  size?: string;
  thickness?: number;
  margin?: number;
}

const Divider = ({ type, size, thickness, margin }: IDividerProps) =>
  type === 'vertical' ? (
    <VerticalDivider size={size} thickness={thickness} margin={margin} />
  ) : (
    <HorizontalDivider size={size} thickness={thickness} margin={margin} />
  );

export default Divider;
