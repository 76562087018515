import { RectangleSkeletonLoader } from '@common/styles';
import * as Styled from './styles';

const ProductInfoSkeletonLoader = () => {
  return (
    <Styled.TabContentWrap className="product__tabContentWrap">
      <Styled.LeftCard>
        <Styled.ProductInformation>
          <Styled.TabTitle>
            <RectangleSkeletonLoader height="3rem" width="20rem" />
          </Styled.TabTitle>
          <Styled.SkeletonFormRow>
            <RectangleSkeletonLoader />
            <RectangleSkeletonLoader />
            <RectangleSkeletonLoader />
          </Styled.SkeletonFormRow>
        </Styled.ProductInformation>
        <Styled.SkeletonFormRow>
          <RectangleSkeletonLoader />
          <RectangleSkeletonLoader />
        </Styled.SkeletonFormRow>
      </Styled.LeftCard>
    </Styled.TabContentWrap>
  );
};

export default ProductInfoSkeletonLoader;
