import { documentTypeToCustomLabelMap } from '@common/constants';
import { ProductStatus } from '@common/features/submission/types';
import { DocumentType, ISelectOption } from '@common/types';
import { useForm } from 'react-hook-form';
import { AuditReportType } from './Audits';

export enum AuditsForm {
  fromDate = 'fromDate',
  toDate = 'toDate',
  productStatuses = 'productStatuses',
  contentTypes = 'contentTypes',
  includeRetiredContent = 'includeRetiredContent',
  includeAllVersionsContent = 'includeAllVersionsContent',
  contentColumns = 'contentColumns',
  productColumns = 'productColumns',
}

export interface IAuditsForm {
  fromDate: Date | null;
  toDate: Date | null;
  productStatuses: ISelectOption[];
  contentTypes: ISelectOption[];
  includeRetiredContent: boolean;
  includeAllVersionsContent: boolean;
  contentColumns: ISelectOption[];
  productColumns: ISelectOption[];
}

export const useAuditsForm = (selectedReport: AuditReportType) => {
  const methods = useForm<IAuditsForm>({
    mode: 'onChange',
    defaultValues: {
      fromDate: null,
      toDate: null,
      productStatuses: defaultProductStatuses,
      contentTypes: defaultContentTypes,
      includeRetiredContent: false,
      includeAllVersionsContent: false,
      contentColumns: defaultContentColumns,
      productColumns: defaultProductColumns,
    },
  });

  const { register } = methods;

  register(AuditsForm.contentTypes, {
    validate:
      selectedReport === AuditReportType.ContentAuditReport
        ? (data) => data?.length > 0 || 'Please select at least one content type to generate a report'
        : () => true,
  });

  register(AuditsForm.productStatuses, {
    validate:
      selectedReport === AuditReportType.ProductAuditReport
        ? (data) => data?.length > 0 || 'Please select at least one product status to generate a report'
        : () => true,
  });

  return { ...methods, methods };
};

export enum ProductReportColumns {
  ProductGroup = 'ProductGroupName',
  ProductName = 'Name',
  ProductId = 'Id',
  Status = 'Status',
  AssociatedContent = 'AssociatedContent',
  ProductDetails = 'Metadata',
  ProductCreatedDate = 'CreatedDate',
  CreatedBy = 'CreatedBy',
  LastUpdatedDate = 'LastUpdatedDate',
  UpdatedBy = 'LastUpdatedBy',
  DiscontinuedDate = 'DiscontinuedDate',
}

export enum ProductReportColumnLabels {
  ProductGroup = 'Product group',
  ProductName = 'Product name',
  ProductId = 'Product id',
  Status = 'Product status',
  AssociatedContent = 'Associated content',
  ProductDetails = 'Product details',
  ProductCreatedDate = 'Product created date',
  CreatedBy = 'Created by',
  LastUpdatedDate = 'Last updated date',
  UpdatedBy = 'Updated by',
  DiscontinuedDate = 'Discontinued date',
}

export const ProductReportColumnValueToLabelMap: ProductReportColumnValueToLabel = {
  [ProductReportColumns.ProductGroup]: ProductReportColumnLabels.ProductGroup,
  [ProductReportColumns.ProductName]: ProductReportColumnLabels.ProductName,
  [ProductReportColumns.ProductId]: ProductReportColumnLabels.ProductId,
  [ProductReportColumns.Status]: ProductReportColumnLabels.Status,
  [ProductReportColumns.AssociatedContent]: ProductReportColumnLabels.AssociatedContent,
  [ProductReportColumns.ProductDetails]: ProductReportColumnLabels.ProductDetails,
  [ProductReportColumns.ProductCreatedDate]: ProductReportColumnLabels.ProductCreatedDate,
  [ProductReportColumns.CreatedBy]: ProductReportColumnLabels.CreatedBy,
  [ProductReportColumns.LastUpdatedDate]: ProductReportColumnLabels.LastUpdatedDate,
  [ProductReportColumns.UpdatedBy]: ProductReportColumnLabels.UpdatedBy,
  [ProductReportColumns.DiscontinuedDate]: ProductReportColumnLabels.DiscontinuedDate,
};

export type ProductReportColumnValueToLabel = {
  [key in ProductReportColumns]?: ProductReportColumnLabels;
};

export enum ContentReportColumns {
  ContentTitle = 'Title',
  ContentId = 'Id',
  ContentVersion = 'Version',
  SubmittedBy = 'SubmittedBy',
  SubmittedDate = 'SubmittedDate',
  ApprovedDate = 'ApprovedDate',
  MhraEmaApprovalDate = 'RegulatorApprovalDate',
  RetiredDate = 'RetiredDate',
  RetiredBy = 'RetiredBy',
  AssociatedProduct = 'AssociatedProductName',
  AssociatedProductId = 'AssociatedProductId',
}

export enum ContentReportColumnLabels {
  ContentTitle = 'Content title',
  ContentId = 'Content id',
  ContentVersion = 'Content version',
  SubmittedBy = 'Submitted by',
  SubmittedDate = 'Submitted date',
  ApprovedDate = 'Approved date',
  MhraEmaApprovalDate = 'MHRA/EMA Approval date',
  RetiredDate = 'Retired date',
  RetiredBy = 'Retired by',
  AssociatedProduct = 'Associated product',
  AssociatedProductId = 'Associated product id',
}

export const ContentReportColumnValueToLabelMap: ContentReportColumnValueToLabel = {
  [ContentReportColumns.ContentTitle]: ContentReportColumnLabels.ContentTitle,
  [ContentReportColumns.ContentId]: ContentReportColumnLabels.ContentId,
  [ContentReportColumns.ContentVersion]: ContentReportColumnLabels.ContentVersion,
  [ContentReportColumns.SubmittedBy]: ContentReportColumnLabels.SubmittedBy,
  [ContentReportColumns.SubmittedDate]: ContentReportColumnLabels.SubmittedDate,
  [ContentReportColumns.ApprovedDate]: ContentReportColumnLabels.ApprovedDate,
  [ContentReportColumns.MhraEmaApprovalDate]: ContentReportColumnLabels.MhraEmaApprovalDate,
  [ContentReportColumns.RetiredDate]: ContentReportColumnLabels.RetiredDate,
  [ContentReportColumns.RetiredBy]: ContentReportColumnLabels.RetiredBy,
  [ContentReportColumns.AssociatedProduct]: ContentReportColumnLabels.AssociatedProduct,
  [ContentReportColumns.AssociatedProductId]: ContentReportColumnLabels.AssociatedProductId,
};

export type ContentReportColumnValueToLabel = {
  [key in ContentReportColumns]?: ContentReportColumnLabels;
};

const defaultContentTypes = [
  { label: documentTypeToCustomLabelMap[DocumentType.Smpc] ?? '', value: DocumentType.Smpc },
  { label: documentTypeToCustomLabelMap[DocumentType.Pil] ?? '', value: DocumentType.Pil },
];

const defaultContentColumns = [
  { label: ContentReportColumns.ContentTitle, value: ContentReportColumns.ContentTitle },
  { label: ContentReportColumns.ContentId, value: ContentReportColumns.ContentId },
  { label: ContentReportColumns.ContentVersion, value: ContentReportColumns.ContentVersion },
];

const defaultProductStatuses = [{ label: ProductStatus.Published, value: ProductStatus.Published }];

const defaultProductColumns = [
  { label: ProductReportColumns.ProductGroup, value: ProductReportColumns.ProductGroup },
  { label: ProductReportColumns.ProductName, value: ProductReportColumns.ProductName },
  { label: ProductReportColumns.ProductId, value: ProductReportColumns.ProductId },
  { label: ProductReportColumns.Status, value: ProductReportColumns.Status },
];
