export const invokeDataLayer = () => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
};

export const storeDataLayerValue = (value: Object) => {
  window.dataLayer.push(value);
};

export const sendDataLayerCustomEvent = (eventName: string, payload?: Object) => {
  invokeDataLayer();
  let event = { event: eventName };

  if (payload) {
    event = { ...event, ...payload };
  }

  window.dataLayer.push(event);
};
