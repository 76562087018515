import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProductDetailsFormErrors } from '../types';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';

export const useAmpIdSelection = () => {
  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const ampIdToAdd = watch(ProductFormPaths.ampIdInput);
  const selectedAmpId = watch(ProductFormPaths.ampId) || '';
  const noAmpId = watch(ProductFormPaths.noAmpId);

  useEffect(() => {
    trigger(ProductFormPaths.ampId);
  }, [noAmpId, trigger]);

  const addAmpId = (ampId: string) => {
    setValue(ProductFormPaths.ampId, ampId, { shouldValidate: true });
  };

  const removeAmpId = () => {
    setValue(ProductFormPaths.ampId, '', { shouldValidate: true });
  };

  const clearAmpIdError = () => clearErrors(ProductFormPaths.ampId);
  const clearAmpIdInputError = () => clearErrors(ProductFormPaths.ampIdInput);

  const handleAmpIdAdd = () => {
    const idTooShort = ampIdToAdd.length < 13;
    const idTooLong = ampIdToAdd.length > 18;
    const idIsNegative = Number(ampIdToAdd) < 0;

    if (idTooShort) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberTooShort',
        message: ProductDetailsFormErrors.ampNumberTooShort,
      });
    }

    if (idTooLong) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberTooLong',
        message: ProductDetailsFormErrors.ampNumberTooLong,
      });
    }

    if (idIsNegative) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberIsNegative',
        message: ProductDetailsFormErrors.ampNumberIsNegative,
      });
    }

    if (ampIdToAdd !== '' && !idTooShort && !idTooLong && !idIsNegative) {
      addAmpId(ampIdToAdd);

      setValue(ProductFormPaths.ampIdInput, '');
      clearErrors([ProductFormPaths.ampId]);
    }
  };
  const handleAmpIdRemove = (index: number) => {
    clearAmpIdError();
    removeAmpId();
  };

  const handleNoAmpIdChange = () => {
    const isChecked = getValues(ProductFormPaths.noAmpId);
    if (!isChecked) {
      setValue(ProductFormPaths.ampIdInput, '');
    }
    clearAmpIdInputError();
    setValue(ProductFormPaths.noAmpId, !isChecked);
  };

  return {
    ampIdToAdd,
    selectedAmpId,
    errors,
    noAmpId,
    handleAmpIdAdd,
    handleAmpIdRemove,
    handleNoAmpIdChange,
  };
};
