import React from 'react';

import { format } from 'date-fns';
import { dateFormat } from '@common/constants';
import { Button, ButtonTypes } from '@common/components';
import { IDocumentDto } from '@common/features/submission/types';
import { DocumentType } from '@common/types';
import { StyledLink as StyledToLink } from '@common/components/Link/styles';
import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { DocumentActions } from '../DocumentActions/DocumentActions';
import { createColumnHelper } from '@tanstack/react-table';
import { boolToYesNo, getDaysSinceWithDistanceFormat } from '@common/helpers';
import DOMPurify from 'dompurify';
import { IDocumentAttributes } from '@common/features/document/helpers';
import { TitleWeblinkCell } from '@common/features/document/components/TitleWeblinkCell';

export const prepareTaskDocumentColumns = (
  taskDocumentCallbacks: (type?: DocumentType) => ITaskDocumentTableCallbacks,
  onContentTitleClick: (documentId: string) => void,
  onDocumentDetailsClick: (documentId: string) => void,
  onContentDescriptionClick: (text: string) => void,
  onViewContent: (content: string, title: string) => void,
  documentAttributes: IDocumentAttributes,
  isReadonly: boolean,
) => {
  const columnHelper = createColumnHelper<IDocumentDto>();

  return [
    columnHelper.accessor('documentTitle', {
      header: 'Document title',
      cell: ({ row, getValue }) =>
        documentAttributes.showWebLink ? (
          getValue()
        ) : (
          <TitleWeblinkCell
            id={row.original.documentId}
            text={getValue()}
            htmlContent={row.original.htmlContent}
            link={row.original.link}
            onViewContent={onViewContent}
            onFileOpen={onContentTitleClick}
          />
        ),
    }),
    columnHelper.accessor('version', {
      header: 'Version',
    }),
    documentAttributes?.showHasDigitalVersion
      ? columnHelper.accessor('hasDigitalVersion', {
          header: 'ePIL',
          cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        })
      : undefined,
    columnHelper.accessor('reasonsForSubmission', {
      header: 'Reasons for submission',
      cell: (info) =>
        info.getValue().length > 0 ? (
          <>
            {info.getValue()?.map((reason) => (
              <p key={reason.text}>{reason.text}</p>
            ))}
          </>
        ) : (
          '-'
        ),
    }),
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Regulator approval date',
          cell: (info) => (info.getValue() ? format(new Date(info.getValue()!), dateFormat) : '-'),
        })
      : null,
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Days since approval',
          id: 'daysSinceApproval',
          cell: ({ getValue }) => {
            const approvalDate = getValue();
            return getDaysSinceWithDistanceFormat(approvalDate);
          },
        })
      : null,
    documentAttributes.showWebLink
      ? columnHelper.accessor('link', {
          header: 'Weblink',
          cell: ({ getValue }) => <TitleWeblinkCell text={getValue() ?? ''} link={getValue()} htmlContent={null}/>,
        })
      : undefined,
    documentAttributes?.showHcpOnly
      ? columnHelper.accessor('isVisibleToHCP', {
          header: 'HCP',
          cell: ({ getValue }) => boolToYesNo(getValue() ?? false),
        })
      : null,
    columnHelper.accessor('approvedByRegulator', {
      header: 'Regulator',
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    }),
    columnHelper.accessor('authorisedDate', {
      header: 'Date of first Authorisation',
      cell: (info) => (info.getValue() ? format(new Date(info.getValue()!), dateFormat) : '-'),
    }),
    columnHelper.accessor('linkedProductIds', {
      header: 'Associated products',
      cell: ({ getValue }) => getValue()?.length ?? '-',
    }),
    columnHelper.accessor('documentId', {
      header: 'Content ID',
    }),
    documentAttributes?.showContentDescription
      ? columnHelper.accessor('contentDescription', {
          header: 'Content Description',
          cell: ({ getValue }) => {
            const contentDescription = getValue() ?? '';
            return contentDescription.length > 0 ? (
              <StyledToLink onClick={(e) => onContentDescriptionClick(DOMPurify.sanitize(contentDescription))} to="#">
                {'View'}
              </StyledToLink>
            ) : (
              '-'
            );
          },
        })
      : null,
    columnHelper.accessor('details', {
      header: 'Details',
      cell: (info) => {
        const documentId = info.row.original.documentId.toString();
        const handleDocumentDetailsClick = () => onDocumentDetailsClick(documentId);
        return <Button type={ButtonTypes.TEXT} onClick={handleDocumentDetailsClick} text="" icon="/icons/eye.svg" height={25} />;
      },
    }),
    !isReadonly
      ? columnHelper.display({
          id: 'actions',
          header: 'Actions',
          cell: (info) => {
            const documentId = info.row.original.documentId.toString();
            const documentType = info.row.original.type;
            const actionCallbacks = taskDocumentCallbacks(documentType);
            return isReadonly ? false : <DocumentActions documentId={documentId} documentCallbacks={actionCallbacks} />;
          },
        })
      : undefined,
  ].filter((x) => x);
};

export const mapHasDigitalVersion = (productGroupDocuments: IDocumentDto[] = [], submissionDocuments: IDocumentDto[] = []) =>
  productGroupDocuments?.map((d) => ({
    ...d,
    hasDigitalVersion: submissionDocuments.find((sd) => sd.documentId === d.documentId)?.hasDigitalVersion ?? null,
  }));
