import * as Styled from './styles';
import { FormInput } from '@common/components/FormInput/styles';
import { IProductInfo } from './types';
import Divider from '@common/components/Divider/Divider';
import { reasonsForDiscontinueOptions } from '@common/features/reasons/discontinuationReasons';
import { HookFormSelect } from '@common/components/Select/HookFormSelect';
import { useFormContext } from 'react-hook-form';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { extraInformationValidation } from 'apps/publisher-v3.ui.customer/src/components/Product/AddProductPopup/constants';
import { ActiveIngredientsStrengthPicker } from '@common/components/ActiveIngredientsStrength/ActiveIngredientsStrengthPicker';
import { NoContentError } from '@common/components/NoContentError';
import { useFetchProductForms } from '@hooks/useFetchProductForms';
import { IProductForm, ProductFormPaths } from '../../hooks/useProductForm';
import { ProductNames } from '../../../ProductNames/ProductNames';

const ProductInfo = ({
  product,
  productType,
  activeIngredients,
  productGroupName,
  showError,
  productInfoReadonlySections = {},
  readonlyNumeratorsWhenInitialData = false,
}: IProductInfo) => {
  const divider = <Divider type="horizontal" size="100%" margin={30} />;

  const { productForms, isFetching: areProductFormsFetching } = useFetchProductForms();

  const {
    formState: { errors },
  } = useFormContext<IProductForm>();

  const extraInformationError = errors.productInfo?.extraInformation?.message?.toString();
  const emcProductNameError = errors?.productInfo?.name?.message?.toString();
  const medicinalProductNameError = errors?.productInfo?.regulatorApprovedName?.message?.toString();

  const numerators = activeIngredients.map((a) => ({ numeratorUnit: a.numeratorUnit, numeratorValue: a.numeratorValue }));

  return (
    <Styled.TabContentWrap className="product__tabContentWrap">
      <Styled.LeftCard>
        <Styled.ProductInformation>
          <Styled.TabTitle>
            Product info {showError && <NoContentError text="Please complete the following section" />}
          </Styled.TabTitle>
          <Styled.FormRow>
            <FormInput label="Product type" name="Product type" value={productType} />
            <FormInput label="Brand/generic name" name="Generic name" value={productGroupName} />
          </Styled.FormRow>
          {numerators && numerators.length > 0 ? (
            <>
              {divider}
              <ActiveIngredientsStrengthPicker
                activeIngredients={activeIngredients}
                fieldName={ProductFormPaths.activeIngredientsStrength}
                disabled={productInfoReadonlySections.activeIngredients}
                readonlyNumeratorsWhenInitialData={readonlyNumeratorsWhenInitialData}
              />
              {divider}
            </>
          ) : (
            divider
          )}
          <Styled.FormRow>
            <HookFormSelect
              label="Product form"
              name={ProductFormPaths.productForm}
              options={productForms}
              isLoading={areProductFormsFetching}
              disabled={productInfoReadonlySections.productForm}
              allowCustomOption
            />
            <HookFormInput
              label="Extra information"
              name={ProductFormPaths.extraInformation}
              value={ProductFormPaths.extraInformation}
              rules={extraInformationValidation}
              error={extraInformationError}
              disabled={productInfoReadonlySections.extraInformation}
            />
          </Styled.FormRow>
          {product?.discontinuationReason && (
            <>
              {divider}
              <Styled.FormRow>
                <HookFormSelect
                  label="Reason for Discontinue"
                  name={ProductFormPaths.discontinuationReason}
                  options={reasonsForDiscontinueOptions}
                />
              </Styled.FormRow>
            </>
          )}
        </Styled.ProductInformation>
        <ProductNames
          emcNameField={ProductFormPaths.name}
          emcProductNameError={emcProductNameError}
          medicinalNameField={ProductFormPaths.regulatorApprovedName}
          medicinalProductNameError={medicinalProductNameError}
          disableEmcName={productInfoReadonlySections.displayName}
          disableMedicinalName={productInfoReadonlySections.regulatorApprovedName}
        />
      </Styled.LeftCard>
    </Styled.TabContentWrap>
  );
};

export default ProductInfo;
