import { ChangeEvent } from 'react';
import { FormInput, Wrapper } from './styles';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { IInput } from '../Input/Input';

interface IHookFormInput extends IInput {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  rules?: RegisterOptions<any, string>;
  onClearInput?: () => void;
  error?: string;
  disabled?: boolean;
  onChangeValue?: () => void;
}

export const HookFormInput = ({
  name,
  label,
  placeholder,
  className,
  rules,
  onClearInput,
  error,
  disabled,
  onChangeValue,
  ...props
}: IHookFormInput) => {
  const { setValue, watch, register } = useFormContext();

  if (rules) {
    register(name, {
      ...rules,
    });
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target?.value.trim(), { shouldValidate: true, shouldDirty: true });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.currentTarget.value, { shouldValidate: true, shouldDirty: true });
    onChangeValue && onChangeValue();
  };

  const value = watch(name);

  return (
    <Wrapper>
      <FormInput
        disabled={disabled}
        label={label}
        name={name}
        className={className}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        onClearInput={onClearInput}
        data-testid={props['data-testid']}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
