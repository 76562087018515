import React from 'react';

import { IActiveIngredientsStrengthDisplay } from './types';

import * as Styled from './styles';
import { FormInput } from '@common/components/FormInput/styles';
import { StyledLabel } from '@common/components/Input/styles';
import { formatActiveIngredientName } from '../ActiveIngredientsSelection/hooks/useActiveIngredientSelection/helpers';

export const ActiveIngredientsStrengthDisplay = ({ activeIngredients }: IActiveIngredientsStrengthDisplay) => {
  const numerators = activeIngredients.map((a) => ({ unit: a.numeratorUnit, value: a.numeratorValue, name: a.name }));
  const denominator = { unit: activeIngredients[0].denominatorUnit, value: activeIngredients[0].denominatorValue };

  const renderContent = () => {
    return numerators?.map((item, index) => (
      <Styled.ActiveIngredient key={index}>
        <Styled.ActiveIngredientName>
          <FormInput
            className={'display'}
            disabled={true}
            name={`activeIngredient.name`}
            value={formatActiveIngredientName(item.name)}
          />
        </Styled.ActiveIngredientName>
        <Styled.InputSection>
          <FormInput className={'display'} disabled={true} name={`activeIngredientNumerator.value`} value={item.value} />
          <FormInput className={'display'} disabled={true} name={`activeIngredientNumerator.unit`} value={item.unit} />
        </Styled.InputSection>
        <Styled.InputSection>
          <FormInput
            className={'display'}
            disabled={true}
            name={`activeIngredientDenominator.value`}
            value={denominator && denominator.value?.toString()}
          />
          <FormInput
            className={'display'}
            disabled={true}
            name={`activeIngredientNumerator.unit`}
            value={denominator && denominator.unit}
          />
        </Styled.InputSection>
      </Styled.ActiveIngredient>
    ));
  };

  return (
    <Styled.ActiveIngredientsStrength>
      <Styled.Labels>
        <StyledLabel>Active Ingredient (s)</StyledLabel>
        <StyledLabel>Ingredient strength</StyledLabel>
        <StyledLabel>Per</StyledLabel>
      </Styled.Labels>
      <Styled.ActiveIngredientsList>{renderContent()}</Styled.ActiveIngredientsList>
    </Styled.ActiveIngredientsStrength>
  );
};
