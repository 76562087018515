import { useQuery } from '@tanstack/react-query';
import { downloadDocumentFile } from '@common/services/submissionService';
import { useAppSelector } from './redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';

export const useDownloadDocumentFile = (submissionId: Id, documentId: Id) => {
  const token = useAppSelector(selectToken);

  const { data, refetch } = useQuery(
    ['downloadDocumentFile', submissionId, documentId],
    () => downloadDocumentFile(token, submissionId, documentId),
    { enabled: false },
  );

  return { documentFileResponse: data, refetchDownloadDocumentFile: refetch };
};
