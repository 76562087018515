import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import { HeaderBreadcrumb } from '@components/SubmissionFlowHeader/styles';
import * as Styled from './styles';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { RectangleSkeletonLoader } from '@common/styles';
import TabsSkeletonLoader from '@common/components/Tabs/TabsSkeletonLoader';
import { DocumentContentSkeletonLoader } from '../../components/DocumentContentDetails/DocumentContentSkeletonLoader';

const DocumentDetailsSkeletonLoader = () => {
  return (
    <Styled.ContentDetailsWrap>
      <SubmissionFlowHeader>
        <HeaderBreadcrumb>
          <RectangleSkeletonLoader height="2rem" width="30rem" />
        </HeaderBreadcrumb>
      </SubmissionFlowHeader>
      <TabsSkeletonLoader>
        <DocumentContentSkeletonLoader />
      </TabsSkeletonLoader>
      <SubmissionFlowFooter />
    </Styled.ContentDetailsWrap>
  );
};

export default DocumentDetailsSkeletonLoader;
