import styled from 'styled-components';

export const ActionWrapper = styled.div`
  margin-right: 10px;

  &.white_space_nowrap {
    white-space: nowrap;
  }
`;

export const ColoredIcon = styled.div.attrs((props: { color: string }) => props)`
  path {
    fill:${(props) => props.color};
  }
`;
