import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { StyledLabel } from '@common/components/Select/styles';
import { ModalTitle } from '@common/features/productGroup/styles';

export const ModalIcon = styled(ReactSVG)`
  margin: 20px 0;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 945px;
  max-height: 50rem;

  ${ModalTitle} {
    margin-bottom: 20px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 40px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.elements.textDarkGrey};
  margin-top: 6px;
  margin-bottom: 16px;
`;

export const FormRow = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  ${StyledLabel} {
    font-size: 16px;
  }
`;

export const ProductName = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 10px;
  padding: 17px 24px;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  min-height: 6rem;
  & + & {
    margin-top: 10px;
  }

  label {
    font-weight: bold;
    display: block;
  }

  span {
    font-size: 14px;
  }
`;
