import styled from 'styled-components';

export const DetailsTableWrapper = styled.div`
  margin: 10px 15px;
  overflow-y: scroll;
  max-height: 300px;

  table {
    width: 100%;
    tbody {
      max-height: 300px;
    }
  }
`;
