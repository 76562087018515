import { useAppSelector } from '@hooks/redux';
import { selectToken } from '@features/user/selectors';
import { fetchProductSubmissionStatuses } from '@common/services/productService';
import { IProductSubmissionStatusDto } from '../../../pages/Products/types';
import { useQuery } from '@tanstack/react-query';
import { SubmissionStatus as SubmissionStatusEnum } from '@common/types';
import useAppNavigation from '../../../routing/useAppNavigation';
import { fetchPublicProductGroupState } from '@features/productGroup/services/productGroupService';
import { IPublicProductGroupState } from '@common/features/productGroup/types';
import { SubmissionStatus } from '@services/types';

export interface IProductGroupHistoryElement {
  title: string;
  status: SubmissionStatus;
  submissionId?: number;
  productGroupId?: number;
}

interface IUseProductGroupHistory {
  productGroupId: number;
}

export const useProductGroupHistory = ({ productGroupId }: IUseProductGroupHistory) => {
  const token = useAppSelector(selectToken);
  const { goToProductGroup, goToPublishedProductGroup } = useAppNavigation();

  const { data } = useQuery<IProductSubmissionStatusDto[]>(
    ['fetchProductSubmissionStatuses', productGroupId],
    () => fetchProductSubmissionStatuses(token, productGroupId, SubmissionStatusEnum.Active),
    { enabled: !!token && !!productGroupId },
  );

  const { data: productGroup } = useQuery<IPublicProductGroupState>(
    ['fetchPublicProductGroupState', productGroupId],
    () => fetchPublicProductGroupState(token, productGroupId),
    { enabled: !!token && !!productGroupId },
  );

  const onClick = (productGroupHistoryElement: IProductGroupHistoryElement) => {
    if (productGroupHistoryElement.submissionId != null) {
      goToProductGroup(productGroupHistoryElement.submissionId.toString());
      return;
    }

    goToPublishedProductGroup(productGroupHistoryElement.productGroupId!.toString());
  };

  const createProductGroupHistoryElements = () => {
    const submissionElements: IProductGroupHistoryElement[] =
      data?.map((x) => ({ title: x.title, status: x.status, submissionId: x.submissionId } as IProductGroupHistoryElement)) ?? [];

    if (productGroup != null) {
      submissionElements.push({
        title: productGroup.productGroup.productGroupName,
        status: SubmissionStatus.Published,
        productGroupId: productGroup.productGroupId,
      } as IProductGroupHistoryElement);
    }

    return submissionElements;
  };

  const productGroupElements = createProductGroupHistoryElements();

  return {
    productGroupElements,
    onClick,
  };
};
