import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const DatePickerLabel = styled.label`
  font-size: 0.875rem;
  margin-bottom: 5px;
`;

export const DateInputWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  padding: 10px 15px;
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  border-radius: 14px;
  cursor: pointer;
`;

export const DateAndButtonsWrapper = styled.div`
  flexflow: 'row';
  display: 'flex';
  width: '100%';
  justifycontent: 'center';
`;

export const MonthYearTitle = styled.div`
  flexGrow: 1,
`;

export const DateInput = styled.input`
  font-size: 16px;
  outline: none;

  cursor: pointer;
`;

export const DropDown = styled.select`
  width: 100px;
  -webkit-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border-radius: 5px;
  padding: 2px;
`;

export const DateInputHeader = styled.div`
  flex-flow: column;
  gap: 15px;
  display: flex;
`;

export const DateInputMonthYear = styled.div`
  gap: 10px;
  padding-left: 15px;
  height: 100%;
  width: fit-content;
  flex-flow: row;
  display: flex;
  justifycontent: center;
`;
