import { ChangeType } from '@common/features/submission/types';
import { TextForChanges } from '@common/styles';

interface IProps {
  value: any;
  changeType?: ChangeType;
}

export const CellWithChanges = ({ value = '-', changeType = ChangeType.Unchanged }: IProps) => {
  switch (changeType) {
    case ChangeType.Unchanged:
      return <div>{value}</div>;
    case ChangeType.Added:
      return <TextForChanges key={value}>{value}</TextForChanges>;
    case ChangeType.Removed:
      return (
        <TextForChanges lineThrough key={value}>
          {value}
        </TextForChanges>
      );
  }
};
