import React from 'react';
import { StyledSVG } from '@common/styles';
import { SubmissionTaskStatuses } from '@common/services/types';

interface IProps {
  value: SubmissionTaskStatuses;
}

const SubmissionTaskStatus = ({ value }: IProps) => {
  const renderStatus = () => {
    switch (value) {
      case SubmissionTaskStatuses.Draft:
        return <StyledSVG height={25} src="/icons/draft.svg" />;
      case SubmissionTaskStatuses.WithDataQuality:
      case SubmissionTaskStatuses.WithProduction:
      case SubmissionTaskStatuses.WithQC:
        return <StyledSVG height={25} src="/icons/with_datapharm.svg" />;
      case SubmissionTaskStatuses.WithClientApproval:
        return <StyledSVG height={25} src="/icons/needs_approval.svg" />;
      case SubmissionTaskStatuses.WithClientMoreInformation:
        return <StyledSVG height={25} src="/icons/needs_attention.svg" />;
      case SubmissionTaskStatuses.Approved:
        return <StyledSVG height={25} src="/icons/approved.svg" />;
      case SubmissionTaskStatuses.Cancelled:
        return <StyledSVG height={25} src="/icons/cancelled.svg" />;
      case SubmissionTaskStatuses.Published:
        return <StyledSVG height={25} src="/icons/published_tag.svg" />;
      default:
        return <div>{value}</div>;
    }
  };

  return renderStatus();
};

export default SubmissionTaskStatus;
