import { ReactNode } from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, Checkbox, ModalWrapper } from '@common/components';

interface IProps {
  isDontShowAgainChecked: boolean;
  children: ReactNode;
  onClose: () => void;
  onDontShowAgainCheck: () => void;
}

export const NextStepsModal = ({ isDontShowAgainChecked, children, onClose, onDontShowAgainCheck }: IProps) => {
  return (
    <ModalWrapper isOpen={true} contentStyles={{ width: '40rem' }}>
      <Styled.TitleIcon src={'/icons/walkthrough.svg'} />
      <Styled.Title>Next steps</Styled.Title>
      {children}

      <Styled.Subtext>
        Your progress has been saved as a draft. Press close to go to the Product group management screen or select one of the
        options above.
      </Styled.Subtext>
      <Styled.Footer>
        <Styled.CheckboxWrapper>
          <Checkbox name="showAgain" isChecked={isDontShowAgainChecked} onChange={onDontShowAgainCheck} />
          <Styled.CheckboxText>Don't show this again</Styled.CheckboxText>
        </Styled.CheckboxWrapper>
        <Button onClick={onClose} text="Close" type={ButtonTypes.TEXT} data-testid="close-button" />
      </Styled.Footer>
    </ModalWrapper>
  );
};
