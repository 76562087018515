import { createSlice } from '@reduxjs/toolkit';
import { IProductGroupState } from './types';

export const initialState: IProductGroupState = {
  isLoading: false,
  productGroupData: {
    id: null,
    name: '',
  },
};

const productGroupSlice = createSlice({
  name: 'productGroup',
  initialState,
  reducers: {
    setProductGroupName(state, action) {
      state.productGroupData.name = action.payload;
    },
  },
});

export const { setProductGroupName } = productGroupSlice.actions;

export default productGroupSlice.reducer;
