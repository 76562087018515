import Divider from '@common/components/Divider/Divider';
import { Button, ButtonTypes } from '@common/components';
import * as Styled from '../DatapharmTable/styles';
import theme from '@common/theme';
import { DocumentStatus, DocumentType } from '@common/types';
import { IDocumentActions } from '../DatapharmTable/types';
import { DocumentState } from '@common/features/submission/types';
import { getDocumentAttributes } from '@common/features/document/helpers';

const DocumentActions = ({ status, callbacks, state, type, hasDigitalVersion, documentId }: IDocumentActions) => {
  const { requiresRetirementReason } = getDocumentAttributes(type);

  const showUpdateOnSubmission =
    state !== DocumentState.PendingDigitalVersion &&
    (requiresRetirementReason || (!requiresRetirementReason && (state === DocumentState.New || state === DocumentState.Updated)));

  const isPil = type === DocumentType.Pil;

  const renderButtons = () => {
    switch (status) {
      case DocumentStatus.Retired:
        return (
          <Button
            type={ButtonTypes.TEXT}
            text="Update"
            icon="/icons/update.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={callbacks?.onUpdateClick}
            data-testid={documentId ? `${documentId}-update-action` : undefined}
          />
        );
      case DocumentStatus.Published:
        return (
          <>
            <Button
              type={ButtonTypes.TEXT}
              text="Update"
              icon="/icons/update.svg"
              height={25}
              onClick={callbacks?.onUpdateClick}
              color={theme.elements.primaryMedium}
              data-testid={documentId ? `${documentId}-update-action` : undefined}
            />
            {isPil && !hasDigitalVersion && (
              <>
                <Divider type="vertical" size="30px" />
                <Button
                  type={ButtonTypes.TEXT}
                  text="Request ePIL"
                  icon="/icons/green_arrow.svg"
                  height={25}
                  onClick={callbacks?.onRequestEpilClick}
                  color={theme.colors.darkGreen}
                  data-testid={documentId ? `${documentId}-request-epil-action` : undefined}
                />
              </>
            )}
            <Divider type="vertical" size="30px" />
            <Button
              type={ButtonTypes.TEXT}
              text="Retire"
              icon="/icons/retire.svg"
              height={25}
              onClick={callbacks?.onRetireClick}
              color={theme.elements.retireOrange}
              data-testid={documentId ? `${documentId}-retire-action` : undefined}
            />
          </>
        );
      default:
        return (
          <>
            {showUpdateOnSubmission && (
              <>
                <Button
                  type={ButtonTypes.TEXT}
                  text="Update"
                  icon="/icons/update.svg"
                  height={25}
                  onClick={callbacks?.onUpdateClick}
                  color={theme.elements.primaryMedium}
                  data-testid={documentId ? `${documentId}-update-action` : undefined}
                />
                <Divider type="vertical" size="30px" />
              </>
            )}
            <Button
              type={ButtonTypes.TEXT}
              text="Remove"
              icon="/icons/trash_bin.svg"
              height={25}
              onClick={callbacks?.onRemoveClick}
              color={theme.elements.removeRed}
              data-testid={documentId ? `${documentId}-remove-action` : undefined}
            />
          </>
        );
    }
  };

  return <Styled.ActionsWrapper>{renderButtons()}</Styled.ActionsWrapper>;
};

export default DocumentActions;
