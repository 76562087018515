import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { fetchCompanySubmissions } from '@common/services/submissionService';
import { getDaysSince } from '@common/helpers';
import { ICompanySubmission, ICompanySubmissionsDto } from '../types';
import { useEffect, useState } from 'react';

export const queryKey = 'fetchCompanySubmissions';

export const useFetchCompanySubmissions = (
  companyId: number,
  filtersQueryString: string,
  searchString?: string,
  orderBy?: string,
  recordLimit?: number,
  offset?: number,
  resetRecords?: boolean,
) => {
  const token = useAppSelector(selectToken);
  const [records, setRecords] = useState<ICompanySubmission[]>([]);

  const queryString = () => {
    var queryParts = [`companyId=${companyId}`];

    if (searchString) {
      queryParts.push(`searchTerm=${searchString}`);
    }

    if (filtersQueryString) {
      queryParts.push(filtersQueryString);
    }

    if (orderBy) {
      queryParts.push(`orderBy=${orderBy}`);
    }

    if (recordLimit) {
      queryParts.push(`limit=${recordLimit}`);
    }

    if (offset) {
      queryParts.push(`offset=${offset}`);
    }

    return queryParts.join('&');
  };

  const {
    data = null,
    isFetching: isFetchingSubmissions,
    isLoading: isLoadingSubmissions,
    refetch: refetchCompanySubmissions,
  } = useQuery<ICompanySubmissionsDto>(
    [queryKey, companyId, filtersQueryString, searchString, orderBy, recordLimit, offset],
    () => fetchCompanySubmissions(token, queryString()),
    { enabled: !!token && !!companyId },
  );

  useEffect(() => {
    const companySubmissions = data?.items?.map((s) => ({
      ...s,
      duration: getDaysSince(new Date(s.submittedAt)),
      existingProductGroupId: s.existingProductGroupId,
    }));

    if (companySubmissions != null) {
      if (resetRecords) {
        setRecords([...companySubmissions]);
        return;
      }
      setRecords((prevData) => [...prevData, ...companySubmissions]);
    }
  }, [data?.items, offset, resetRecords]);

  return {
    companySubmissions: records,
    isLoading: isFetchingSubmissions || isLoadingSubmissions,
    isFetchingSubmissions,
    isLoadingSubmissions,
    refetchCompanySubmissions,
    totalCount: data?.totalCount ?? 0,
  };
};
