import { useState } from 'react';

export interface IDownloadReportModalState {
  isOpen: boolean;
  url: string;
  fileName: string;
}

export const useDownloadReportModal = () => {
  const [downloadReportModalState, setDownloadReportModalState] = useState<IDownloadReportModalState>({
    isOpen: false,
    url: '',
    fileName: '',
  });

  const showDownloadReportModal = (url: string, fileName: string) => {
    setDownloadReportModalState({ isOpen: true, url, fileName });
  };

  const hideDownloadReportModal = () => {
    setDownloadReportModalState({ isOpen: false, url: '', fileName: '' });
  };

  return { downloadReportModalState, showDownloadReportModal, hideDownloadReportModal };
};
