import { Highlight } from '@common/styles';
import { DocumentType } from '@common/types';

export const epilConversionCheckboxTexts = {
  title: 'Would you like your PIL to be converted into an ePIL?',
  subtitle: (
    <>
      {`e-PILs are specially formatted PILs, that are accessible to the visually impaired and can be interpreted by screen
  readers. e-PILs do not include any graphics, tables or images. For more info `}
      <Highlight>{`click here >`}</Highlight>
    </>
  ),
};

export const alternativeTextFileCheckboxTexts = {
  title: 'Do any of the images, graphs or tables in this PIL need alternative text?',
  subtitle: (
    <>
      {`If the images, graphs or tables give essential information that is not covered in the text of the PIL, please provide alternative text that can be displayed instead.`}
    </>
  ),
};

export const pilAutoApproveCheckboxTexts = {
  title: 'Do you want your PIL to be sent back to you to approve?',
  subtitle: null,
};

export const FILE_EXTENSIONS = {
  [DocumentType.Pil.toString()]: ['pdf'],
  [DocumentType.Epil.toString()]: ['docx'],
  [DocumentType.Rmm.toString()]: ['pdf'],
  [DocumentType.UserManual.toString()]: ['pdf'],
  [DocumentType.Dhcp.toString()]: ['pdf'],
  [DocumentType.SafetyAlert.toString()]: ['pdf'],
  [DocumentType.ProductInformation.toString()]: ['pdf'],
  [DocumentType.Smpc.toString()]: ['docx'],
};

export const OLD_WORD_FILES_EXTENSIONS = ['doc'];
