import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { UserInfoWrap, Info, UserName, Company, ArrowIcon } from './styles';
import {
  selectActiveCompany,
  selectUserCompanies,
  selectUserEmail,
  selectUserId,
  selectUsername,
} from '../../features/user/selectors';
import { UserInfoPopoverContent } from './UserInfoPopoverContent';
import { useAppSelector } from '../../hooks/redux';

const UserInfo = () => {
  const [isActive, setIsActive] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => isActive && setIsActive(!isActive),
  });

  const username = useAppSelector(selectUsername);
  const email = useAppSelector(selectUserEmail);
  const userCompanies = useAppSelector(selectUserCompanies);
  const activeCompany = useAppSelector(selectActiveCompany);
  const userId = useAppSelector(selectUserId);

  return (
    <Popover
      ref={ref}
      isOpen={isActive}
      positions={['bottom', 'left']}
      content={
        <UserInfoPopoverContent companies={userCompanies} activeCompanyId={activeCompany?.id} email={email} userId={userId!} />
      }
    >
      <UserInfoWrap onClick={() => setIsActive(!isActive)}>
        <Info>
          <UserName>{username}</UserName>
          <Company>{activeCompany?.name}</Company>
        </Info>
        <ArrowIcon isActive={isActive} />
      </UserInfoWrap>
    </Popover>
  );
};

export default UserInfo;
