import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { DocumentType } from '@common/types';
import Tabs from '@common/components/Tabs/Tabs';
import { ReadonlyDocumentContent } from '../../components/DocumentContentDetails/ReadonlyDocumentContent';
import { IDocumentDto } from '@common/features/submission/types';
import * as Styled from './styles';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { HtmlDocument } from '@components/HtmlDocument/HtmlDocument';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { useHandleTaskFileByRole } from '@common/hooks/useHandleTaskFileByRole';
import { FileRoles } from '@common/features/document/types';

export interface IReadonlyDocumentDetailsContent {
  showHistoryFiltering: boolean;
  contentType: DocumentType;
  title: string;
  document: IDocumentDto;
  onCancel: () => void;
  onFileDownload?: () => void;
  displayHtmlViewTab?: boolean;
  isCustomerApprovalRequired?: boolean;
  submissionId: string;
  customerUrl: string | undefined;
  isAdmin: boolean;
}

const ReadonlyDocumentDetailsContent = ({
  showHistoryFiltering,
  contentType,
  title,
  document,
  onCancel,
  onFileDownload,
  displayHtmlViewTab,
  isCustomerApprovalRequired,
  submissionId,
  customerUrl,
  isAdmin,
}: IReadonlyDocumentDetailsContent) => {
  const { showVersionHistory } = useFeatureFlags();
  const documentTitle = document?.documentTitle;
  const fileName = document?.originalFileName ?? document?.link;
  const version = document?.version ?? 1;
  const approvalDate = document?.regulatorApprovalDate;
  const approved = !!document?.regulatorApprovalDate;
  const firstAuthorisationDate = document?.authorisedDate;
  const regulator = document?.approvedByRegulator;
  const reasonsForSubmission = document?.reasonsForSubmission;
  const documentState = document?.state;
  const hasDigitalVersion = document?.hasDigitalVersion ?? null;
  const url = document?.link;
  const htmlContent = document?.htmlContent;
  const { contextHandleFile } = useHandleTaskFileByRole(submissionId, document.documentId.toString(), FileRoles.AlternativeText);

  const reasonForRetirement =
    document?.reasonForRetirement?.length! > 0
      ? { label: document?.reasonForRetirement as string, value: document?.reasonForRetirement as string }
      : undefined;

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} Details`,
        content: (
          <ReadonlyDocumentContent
            documentTitle={documentTitle ?? ''}
            version={version ?? 1}
            fileName={fileName ?? ''}
            contentType={contentType}
            approvalDate={approvalDate ?? null}
            approved={approved}
            firstAuthorisationDate={firstAuthorisationDate ?? null}
            regulator={regulator ?? ''}
            reasonsForSubmission={reasonsForSubmission ?? []}
            reasonForRetirement={reasonForRetirement}
            url={url}
            documentState={documentState!}
            hasDigitalVersion={hasDigitalVersion}
            isCustomerApprovalRequired={isCustomerApprovalRequired}
            onFileClick={onFileDownload}
            contentDescription={document.contentDescription}
            isVisibleToHCP={document.isVisibleToHCP}
            htmlContent={htmlContent}
            alternativeTextFileName={contextHandleFile?.alternativeTextFileName}
            onAlternativeTextFileNameClick={contextHandleFile?.openAlternativeTextFile}
            customerUrl={customerUrl}
            isAdmin={isAdmin}
          />
        ),
      },
    ];

    if (showVersionHistory) {
      items.push({
        label: `Version history`,
        content: <DocumentVersionHistory documentId={document?.documentId ?? 0} showHistoryFiltering={showHistoryFiltering} />,
      });
    }

    if (displayHtmlViewTab)
      items.push({
        label: `View html`,
        content: (
          <HtmlDocument documentId={document?.documentId.toString()} documentType={contentType} version={version.toString()} />
        ),
      });

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.AudioVideo:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  return (
    <Styled.ContentDetailsWrap>
      <SubmissionFlowHeader title={title} />
      {renderContent()}
      <SubmissionFlowFooter onCancel={onCancel} cancelText="Back" />
    </Styled.ContentDetailsWrap>
  );
};

export default ReadonlyDocumentDetailsContent;
