import React from 'react';
import Header from '@common/components/Header/Header';
import Footer from '@common/components/Footer/Footer';
import { MainContainerWrap, Top, ContentWraper, DefaultContent, CustomContent } from './styles';
import { useLocation } from 'react-router';
import { routes } from '../../routing/routes';
import { ButtonTypes } from '@common/components/Button/types';
import { Button } from '@common/components';
import useAppNavigation from '../../routing/useAppNavigation';
import { globalRoutes } from '@common/routing/routes';
import GlobalSidebar from '@components/GlobalSidebar/GlobalSidebar';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { Icons } from '@common/icons';
import { ThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/ThirdPartyLinkWarning';

const getEntries = (showAuditReports: boolean) => {
  var items = [
    { display: 'Dashboard', to: globalRoutes.home, getIcon: () => <Icons.Home /> },
    { display: 'Submissions', to: routes.submissions, getIcon: () => <Icons.Submissions /> },
    { display: 'All Products', to: routes.products, getIcon: () => <Icons.Products /> },
  ];

  if (showAuditReports) items.push({ display: 'Audit Reports', to: routes.audits, getIcon: () => <Icons.Audits /> });

  return items;
};

interface IMainContainer {
  children: string | JSX.Element | JSX.Element[];
}

const MainContainer = ({ children }: IMainContainer) => {
  const { pathname } = useLocation();
  const { goToCreateSubmission } = useAppNavigation();
  const { showAuditReports } = useFeatureFlags();

  const hasCustomLayout =
    (pathname.startsWith(routes.submissions) && !pathname.endsWith(routes.submissions)) ||
    pathname.includes(routes.productGroups);

  const handleRedirect = () => {
    goToCreateSubmission();
  };

  return (
    <>
      {!hasCustomLayout ? (
        <MainContainerWrap>
          <Top>
            <GlobalSidebar entries={getEntries(showAuditReports)} />
            <ContentWraper>
              <Header>
                <Button text="Create Submission" type={ButtonTypes.PRIMARY} onClick={handleRedirect} />
              </Header>
              <DefaultContent>{children}</DefaultContent>
            </ContentWraper>
          </Top>
          <Footer />
        </MainContainerWrap>
      ) : (
        <MainContainerWrap>
          <Top>
            <ContentWraper>
              <CustomContent>{children}</CustomContent>
            </ContentWraper>
          </Top>
        </MainContainerWrap>
      )}
      <ThirdPartyLinkWarning />
    </>
  );
};

export default MainContainer;
