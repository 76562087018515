import { useDiscontinueProduct } from '@common/hooks/useDiscontinueProduct';
import { ISelectOption, Id } from '@common/types';
import { useState } from 'react';

interface Props {
  onDiscontinueSuccess: (productId: string) => void;
}

export const useDiscontinueProductModal = ({ onDiscontinueSuccess }: Props) => {
  const [productIdToDiscontinue, setProductIdToDiscontinue] = useState<Id | undefined>();
  const { discontinueProduct, isDiscontinuingProduct } = useDiscontinueProduct();

  const handleDiscontinueProductCancel = () => setProductIdToDiscontinue(undefined);

  const handleDiscontinueProductConfirm = (reason: ISelectOption) =>
    discontinueProduct(
      { productId: productIdToDiscontinue ?? '', reason },
      { onSuccess: () => onDiscontinueSuccess(productIdToDiscontinue?.toString() ?? '') },
    );

  const handleDiscontinueClick = (productId: Id) => setProductIdToDiscontinue(productId);

  return {
    handleDiscontinueProductCancel,
    handleDiscontinueProductConfirm,
    handleDiscontinueClick,
    productIdToDiscontinue,
    isDiscontinuingProduct,
  };
};
