import { StyledLink } from '@common/components/Link/styles';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';
import { IDocumentDto } from '@common/features/submission/types';
import { Id } from '@common/types';
import { Row } from '@tanstack/react-table';

interface Props {
  fileName: string | undefined;
  row: Row<IDocumentDto>;
  onFileOpen?: (documentId: Id) => void;
}

export const DocumentContentCell = ({ fileName, row, onFileOpen }: Props): JSX.Element => {
  const { handleUrlClick } = useThirdPartyLinkWarning();

  const handleClick = (e: any, documentId: Id) => {
    e.preventDefault();
    onFileOpen!(documentId);
  };

  const link = row.original.link;

  if (!!fileName && !link) {
    return onFileOpen ? (
      <StyledLink onClick={(e) => handleClick(e, row.original.documentId)} to="#">
        {fileName}
      </StyledLink>
    ) : (
      <b>{fileName}</b>
    );
  } else if (!fileName && !!link) {
    return (
      <StyledLink to={link} target="_blank" onClick={(e) => handleUrlClick(e, link)} data-testid="weblink">
        {link}
      </StyledLink>
    );
  } else {
    return <div>-</div>;
  }
};
