import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const TitleNotificationIcon = styled(ReactSVG)`
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  margin-bottom: 15px;
`;

export const ModalButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
