export const useEnsureMaNumberWithSpaces = () => {
  const EnsureMaNumberWithSpaces = (format: string, maNumber: string) => {
    maNumber = maNumber?.replace(' ', '');

    var prefixFormat = format?.replaceAll('x', '')?.replaceAll('/', '');

    var correctlyFormatedNumber = `${maNumber?.substring(0, prefixFormat?.length)} ${maNumber?.substring(
      prefixFormat?.length,
      maNumber?.length,
    )}`;

    return correctlyFormatedNumber;
  };

  return [EnsureMaNumberWithSpaces];
};
