import styled from 'styled-components';
import { Header, Table } from '@common/components/DatapharmTable/styles';
import * as SharedStyled from '@common/styles';
import { StyledButton } from '@common/components/Button/styles';
import theme from '@common/theme';

export const ExpandRowIcon = styled.div`
  display: flex;
  align-items: start;
  text-align: center;
  height: 100%;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
`;

export const DetailsTableWrapper = styled.div`
  max-height: 500px;
  overflow: auto;

  ${Table} {
    flex-grow: 0;
  }

  ${Header} {
    top: 0;
  }
`;

export const FilterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContentWrap = styled(SharedStyled.SubmissionFlowInner)`
  padding: 25px;
  row-gap: 20px;

  ${Table} {
    flex: 0 1 0%;
    width: 100%;

    ${StyledButton} {
      text-decoration: underline;
      color: ${theme.colors.mediumBlue};
      font-size: ${theme.fontSizes.small};
    }
  }
`;

export const HeadingText = styled.h2`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionTitle};
`;

export const SubHeadingText = styled.p`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionSubtitle};
`;
