import React from 'react';

import { Radio } from '../Radio/Radio';

import { IRadio } from '../Radio/Radio';

import * as Styled from './styles';

interface IRadioOption extends IRadio {
  label?: string;
}

export const RadioOption = ({ label, name, size = 14, id, isChecked, onChange = () => {}, onClick, value }: IRadioOption) => (
  <Styled.RadioOption>
    <Radio id={id} name={name} size={size} isChecked={isChecked} onChange={onChange} onClick={onClick} value={value} />
    {label && <Styled.RadioLabel>{label}</Styled.RadioLabel>}
  </Styled.RadioOption>
);
