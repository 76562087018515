import { updateSubmission } from '@common/services/submissionService';
import { useAppSelector } from '@common/hooks/redux';
import { selectActiveCompany, selectToken, selectUserId } from '@common/features/user/selectors';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { SubmissionUpdateCommand } from 'apps/publisher-v3.ui.customer/src/features/submission/types';

interface IUpdateSubmission {
  submissionId: string;
  payload: SubmissionUpdateCommand;
}

export const useUpdateSubmission = () => {
  const token = useAppSelector(selectToken);
  const activeCompany = useAppSelector(selectActiveCompany);
  const companyId = activeCompany?.id!;
  const userId = useAppSelector(selectUserId);

  const { mutate } = useWaitingMutation(({ submissionId, payload }: IUpdateSubmission) =>
    updateSubmission(token, submissionId, { ...payload, companyId, userId }),
  );

  return { updateSubmission: mutate };
};
