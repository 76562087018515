import React from 'react';
import * as Styled from './styles';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { ICheckboxEntry } from '@common/types';
import { ProductSection } from '../ProductSection/ProductSection';
import { ProductForm } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { StyledLabel } from '../Input/styles';

interface ILegalCategories {
  data: ICheckboxEntry[];
  isItemSelected: (item: ICheckboxEntry) => boolean;
  toggleItem: (item: ICheckboxEntry) => void;
  withError: boolean;
  readonly?: boolean;
}

export const LegalCategories = ({ data, isItemSelected, toggleItem, withError, readonly }: ILegalCategories) => {
  const renderDetails = () => {
    return data.map((item) => {
      const isSelected = isItemSelected(item);
      return (
        <CheckboxOption
          key={item.id}
          name={`submission detail ${item.id}`}
          item={item}
          onChange={toggleItem}
          isChecked={isSelected}
          tooltipText={item.tooltipText}
          disabled={readonly}
        />
      );
    });
  };
  return (
    <ProductSection
      elementId={ProductForm.legalCategories}
      heading="Legal categories"
      subtext="This is the legal classification of a pack of medicine that determines the level of control over its supply"
      withError={withError}
    >
      <Styled.Wrapper>
        <StyledLabel>Please select all that apply to this product</StyledLabel>
        {renderDetails()}
      </Styled.Wrapper>
    </ProductSection>
  );
};
