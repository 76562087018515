import styled from 'styled-components';

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 1rem;
`;
