//@revisist type - move to common?
export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  PRIMARY_BORDER = 'PRIMARY_BORDER',
  BORDER_CONTENT = 'BORDER_CONTENT',
  DASHED_BORDER = 'DASHED_BORDER',
  SUCCESS = 'SUCCESS',
  TEXT = 'TEXT',
  PRIMARY_MEDIUM_BORDER = 'PRIMARY_MEDIUM_BORDER',
  DISABLED = 'DISABLED',
  DROPDOWNBUTTON = 'DROPDOWNBUTTON'
}
