import styled from 'styled-components';
import { StyledButton } from '../Button/styles';

export const SubmissionFlowHeaderWrap = styled.div`
  display: flex;
  padding: 28px 25px 8px 28px;
`;

export const Left = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: start;
  align-items: center;

  ${StyledButton} {
    margin-bottom: 1rem;
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const ExitMarkWrapper = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: 50px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 2px solid ${({ theme }) => theme.elements.primary};
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;

  svg {
    display: flex;
    width: 40px;
    height: 40px;
  }

  :hover {
    background-color: ${({ theme }) => theme.elements.primary};
    svg {
      path {
        fill: white;
      }
    }
  }
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 10px;

  & #submission-flow-header {
    text-align: center;
    letter-spacing: 0px;
    color: #525252;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    border: 1px solid #d6d6d6;
    opacity: 1;
  }
`;

export const HeaderTitleWrap = styled.div`
  display: relative;
  max-width: 40vw;
`;

export const HeaderTitle = styled.span.attrs(({ ref }) => ({
  ref: ref,
}))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 740px);
  font-weight: 600;
  font-size: 25px;
`;

export const HeaderBreadcrumb = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.mediumGrey};
  font-weight: 600;
`;

export const Link = styled.span`
  color: ${({ theme }) => theme.elements.linkBlue};
`;

export const ActiveIngredients = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.mediumGrey};
`;
