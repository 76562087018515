export const useValidateData = (excludedFiles: string[] = [], hasHtml: string[] = []) => {
  interface IMappedDataItem {
    fieldName: string;
    value: any;
  }
  const validateData = (data: any) => {
    const emptyItems: string[] = [];
    const mappedData = mapData(data);
    mappedData.forEach((item: any) => setEmptyItem(item, emptyItems));

    return emptyItems;
  };
  
  const mapData = (data: any): IMappedDataItem[] => {
  
    const mappedData = Object.keys(data).map((key) => {
      return {
        fieldName: key,
        value:
          typeof data[key] === 'string'
            ? hasHtml.indexOf(key) === -1
              ? data[key]
              : data[key].replaceAll(/<(.|\n)*?>/g, '')
            : data[key],
      };
    });

    return excludedFiles.length
      ? mappedData.filter((item: IMappedDataItem) => !excludedFiles.includes(item.fieldName))
      : mappedData;
  };

  const setEmptyItem = (item: IMappedDataItem, storeArray: any[]) => {
    if (!item.value) {
      storeArray.push(item.fieldName);
    }

    if (Array.isArray(item.value) && !item.value.length) {
      storeArray.push(item.fieldName);
    }
  };

  const clearValidationFields = (validateDataArr: string[], shouldClearValidation: boolean) => {
    if (!shouldClearValidation) {
      return;
    }
    ['documentTitle'].forEach((item) => {
      const itemIndex = validateDataArr.indexOf(item);

      if (itemIndex < -1) {
        validateDataArr.splice(itemIndex, 1);
      }
    });
  };

  return {
    validateData,
    clearValidationFields,
  };
};
