import React from 'react';

import * as Styled from './styles';

interface ITextarea {
  value: string;
  name?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  'data-testid'?: string;
  onChange: (e: string | React.ChangeEvent) => void;
}

export const Textarea = ({ value, name, onChange, label, placeholder, disabled = false, ...other }: ITextarea) => {
  const dataTestId = other['data-testid'];

  return (
    <Styled.TextareaWrap>
      {label && <Styled.TextareaLabel htmlFor={name}>{label}</Styled.TextareaLabel>}
      <Styled.Textarea
        data-testid={dataTestId}
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder || ''}
      />
    </Styled.TextareaWrap>
  );
};
