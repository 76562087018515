import React from 'react';
import { NotifyModal } from '@common/components/NotifyModal';
import { Button, ButtonTypes } from '@common/components';
import Divider from '@common/components/Divider/Divider';
import * as Styled from './styles';
import { IModalProps } from './types';

export const DuplicateProductError = ({ isOpen, productName, onClose, onProductView }: IModalProps) => (
  <NotifyModal title={'Duplicate product'} isOpen={isOpen} data-testid="duplicate-product-error">
    <Styled.ErrorContent>
      <Styled.Text>The product you have created:</Styled.Text>
      <Styled.ProductName>{productName}</Styled.ProductName>
      <Styled.Text>Already exists with the same data in Publisher. Would you like to view it?</Styled.Text>
    </Styled.ErrorContent>
    <Styled.ModalButtons>
      <Button onClick={onClose} text="Cancel" type={ButtonTypes.TEXT} data-testid="cancel-button" />
      <Divider type="vertical" size="50px" thickness={1} />
      <Button onClick={onProductView} text="View product" type={ButtonTypes.PRIMARY} data-testid="view-product-button" />
    </Styled.ModalButtons>
  </NotifyModal>
);
