import { FieldValues, useForm } from 'react-hook-form';

export enum AddNoteFormFields {
  note = 'note',
}

interface IAddNoteForm {
  [AddNoteFormFields.note]: string;
}

export const usePrepareNotesForm = () => {
  const methods = useForm<IAddNoteForm, FieldValues>({
    mode: 'onChange',
    defaultValues: {
      [AddNoteFormFields.note]: '',
    },
  });

  return { methods, ...methods };
};
