import { useRequestChanges } from '@common/hooks/useRequestChanges';
import { ITaskIdSubmissionId } from '@common/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const useRequestChangesModal = (onRequestChangesSuccess?: (submissionId: number) => void) => {
  const [idForRequestChange, setIdForRequestChange] = useState<ITaskIdSubmissionId | undefined>(undefined);
  const [requestSubmittedOpen, setRequestSubmittedOpen] = useState(false);
  const { requestChanges, isRequestingChanges } = useRequestChanges();

  const methods = useForm();
  const { handleSubmit: handleSubmitForm, reset } = methods;

  const handleRequestChangesCancel = () => {
    setIdForRequestChange(undefined);
    reset({});
  };

  const handleRequestChanges = (taskId: number, submissionId: number) => setIdForRequestChange({ taskId, submissionId });

  const handleRequestChangesSuccess = () => {
    setIdForRequestChange(undefined);
    setRequestSubmittedOpen(true);
    reset({});
    onRequestChangesSuccess && onRequestChangesSuccess(idForRequestChange?.submissionId!);
  };

  const handleRequestChangesSubmit = () =>
    handleSubmitForm(({ additionalInformation }) => {
      requestChanges({ taskId: idForRequestChange!.taskId, additionalInformation }, { onSuccess: handleRequestChangesSuccess });
    })();

  const handleRequestSubmittedConfirm = () => setRequestSubmittedOpen(false);

  return {
    handleRequestChanges,
    handleRequestChangesCancel,
    handleRequestChangesSubmit,
    handleRequestSubmittedConfirm,
    requestChangesForm: methods,
    idForRequestChange,
    requestSubmittedOpen,
    isRequestingChanges,
  };
};
