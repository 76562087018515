import { createColumnHelper } from '@tanstack/react-table';
import { Controller, useFormContext } from 'react-hook-form';
import { ProductSection } from '../ProductSection/ProductSection';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { Button, ButtonTypes, Input } from '@common/components';
import { ErrorMessage } from '../ErrorMessage';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { IMaNumber } from '@common/features/submission/types';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';
import * as StyledProductSection from '../ProductSection/styles';
import * as CommonStyles from '../styles';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';

interface IMaNumbers {
  elementId: string;
  maNumbers: IMaNumber[];
  maNumberToAdd: string;
  isNoMaNumbers: boolean;
  readonly?: boolean;
  onMaNumberAdd: () => void;
  onMaNumberRemove: (index: number) => void;
  onNoMaNumbersChange: () => void;
}

export const MaNumbers = ({
  elementId,
  maNumbers,
  isNoMaNumbers,
  onMaNumberAdd,
  onMaNumberRemove,
  onNoMaNumbersChange,
  readonly,
}: IMaNumbers) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const isDataEmpty = !maNumbers.length && !isNoMaNumbers;

  const errorMessage = errors.productDetails?.maNumbersForm?.maNumberInput?.message;

  const columnHelper = createColumnHelper<IMaNumber>();

  const columns = [
    columnHelper.accessor('number', {
      header: 'Number',
    }),
    columnHelper.accessor('country', {
      header: 'Region',
    }),
    ...(readonly
      ? []
      : [
          columnHelper.display({
            header: 'Action',
            cell: (item) => {
              return (
                <CommonStyles.RemoveButton onClick={() => onMaNumberRemove(parseInt(item.cell.id))}>
                  <StyledSVG src={`/icons/trash_bin.svg`} />
                </CommonStyles.RemoveButton>
              );
            },
          }),
        ]),
  ];

  return (
    <ProductSection
      elementId={elementId}
      heading="Marketing Authorisation (MA) numbers"
      subtext={
        <>
          <StyledProductSection.Subtext>
            This can be found in section 8 of the SmPC and should be entered in the following format:
          </StyledProductSection.Subtext>
          <Styled.Example>PL 12345/1234 or EU 1/12/1234/123</Styled.Example>
        </>
      }
      withError={!readonly && isDataEmpty}
    >
      <Styled.MaNumbersWrap>
        <CheckboxOption
          item={{ id: 'noMaNumbers', text: 'No MA number is available' }}
          name="noMaNumbers"
          isChecked={isNoMaNumbers}
          onChange={onNoMaNumbersChange}
          disabled={readonly}
        />
        {!readonly && (
          <>
            <CommonStyles.InputButtonWrapper>
              <Controller
                control={control}
                name={ProductFormPaths.maNumberInput}
                render={({ field: { value = '', onChange } }) => (
                  <Input label="Enter MA Number" name="Ma Number" disabled={isNoMaNumbers} value={value} onChange={onChange} />
                )}
              />
              <Button disabled={isNoMaNumbers} text="Add" type={ButtonTypes.PRIMARY_BORDER} onClick={onMaNumberAdd} />
            </CommonStyles.InputButtonWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        {!isNoMaNumbers && maNumbers.length > 0 && <DatapharmTable columns={columns} documents={maNumbers} />}
      </Styled.MaNumbersWrap>
    </ProductSection>
  );
};
