import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 5px 20px;
`;

export const Top = styled.div`
  margin-bottom: 42px;
  font-size: ${({ theme }) => theme.fontSizes.small};

  svg {
    margin-bottom: 1rem;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButtonWrap = styled.div`
  margin-right: 23px;
  width: 77px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;
