import { DocumentType } from '@common/types';
import { DocumentDetailsReadonlySections } from '../DocumentContentDetails/types';

export interface IRegulatorApprovalOptions {
  label: RegulatorApprovalLabels;
  value: RegulatorApprovalValues;
}

export enum RegulatorApprovalValues {
  MHRA = 'MHRA',
  EMA = 'EMA',
  OTHER = 'OTHER',
}

export enum RegulatorApprovalLabels {
  MHRA = 'MHRA',
  EMA = 'EMA',
  OTHER = 'Other',
}

export interface IRegulatorApproval {
  regulatorApprovalOptions: IRegulatorApprovalOptions[];
  contentType: DocumentType;
  documentReadonlySections?: DocumentDetailsReadonlySections;
}

export interface IConfirmationWrapper {
  value: boolean;
  disabled?: boolean;
  onChange: (e: unknown) => void;
  text: string;
}
