import styled from 'styled-components';
import { CheckboxOptionWrap } from '../CheckboxOption/styles';
import { Table } from '../DatapharmTable/styles';

export const MaNumbersWrap = styled.div`
  ${CheckboxOptionWrap} {
    margin-bottom: 10px;
  }

  ${Table} {
    width: 80%;
  }
`;

export const Example = styled.p`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
  margin-top: 10px;
`;
