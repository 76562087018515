import { useCancelSubmission } from '@common/hooks/useCancelSubmission';
import { useState } from 'react';

export const useConfirmSubmissionCancellation = (onCancelSubmissionSuccess?: (submissionId: number) => void) => {
  const [submissionIdForCancel, setSubmissionIdForCancel] = useState<number | undefined>(undefined);
  const { cancelSubmission, isCancellingSubmission } = useCancelSubmission();

  const handleCancelSubmissionSuccess = () => {
    setSubmissionIdForCancel(undefined);
    onCancelSubmissionSuccess && onCancelSubmissionSuccess(submissionIdForCancel!);
  };
  const handleConfirmCancellation = () => cancelSubmission(submissionIdForCancel!, { onSuccess: handleCancelSubmissionSuccess });
  const handleConfirmCancellationCancel = () => setSubmissionIdForCancel(undefined);
  const handleSubmissionCancel = (submissionId: number) => setSubmissionIdForCancel(submissionId);

  return {
    submissionIdForCancel,
    isCancellingSubmission,
    handleConfirmCancellation,
    handleConfirmCancellationCancel,
    handleSubmissionCancel,
  };
};
