import React from 'react';
import * as Styled from './styles';
import { Input } from '@common/components';

interface IDocumentTitleDisplay {
  title: string;
}

export const DocumentTitleDisplay = ({ title }: IDocumentTitleDisplay) => {
  return (
    <Styled.DocumentTitleWrapper>
      <Input name="document title" value={title} disabled={true} />
    </Styled.DocumentTitleWrapper>
  );
};
