import React, { ReactNode } from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';
import Divider from '../Divider/Divider';

interface INotifyModal {
  isOpen: boolean;
  title?: string;
  children?: ReactNode;
  modalType?: ModalTypes;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  width?: string;
  className?: string;
  noIcon?: boolean;
  isLoading?: boolean;
}

export enum ModalTypes {
  error = 'error',
  info = 'info',
  warning = 'warning',
}

const NotifyModal = ({
  isOpen,
  title,
  modalType,
  children,
  onClose,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  width,
  className,
  noIcon,
  isLoading,
}: INotifyModal) => {
  const contentStyles = {
    BoxSizing: 'border-box',
    width: width ? width : '500px',
  };
  const generateIcon = () => {
    switch (modalType) {
      case ModalTypes.error:
        return '/icons/errorBig.svg';
      case ModalTypes.info:
        return '/icons/info_circle_unfilled.svg';
      case ModalTypes.warning:
        return '/icons/warning_circle.svg';
      default:
        return '/icons/errorBig.svg';
    }
  };
  return (
    <ModalWrapper className={className} isOpen={isOpen} zIndex={999} contentStyles={contentStyles}>
      {!noIcon && <Styled.TitleNotificationIcon src={generateIcon()} />}
      {title && <Styled.Title>{title}</Styled.Title>}
      {children}
      {(onClose || onConfirm) && (
        <Styled.ModalButtons>
          {onClose && <Button onClick={onClose} text={cancelText} type={ButtonTypes.TEXT} />}
          {onClose && onConfirm && <Divider type="vertical" size="50px" thickness={1} />}
          {onConfirm && <Button onClick={onConfirm} text={confirmText} type={ButtonTypes.PRIMARY} isLoading={isLoading} />}
        </Styled.ModalButtons>
      )}
    </ModalWrapper>
  );
};

export default NotifyModal;
