import React, { useState } from 'react';
import { TabContentWrapper, Tab, TabsLabelsWrapper, TabsWrapper, TabLabel, TabIcon } from './styles';

export type TabItem = {
  label: string;
  icon?: string;
  content: React.ReactNode;
  ['data-testid']?: string;
};

interface ITabs {
  items: TabItem[];
  styles?: {
    tabsLabelsWrapper?: React.CSSProperties;
  };
  selectedTab?: string;
  initialTab?: string;
  onTabSelected?: (label: string) => void;
}

const Tabs = ({ items, styles, selectedTab, onTabSelected, initialTab }: ITabs) => {
  const [selected, setSelected] = useState<string>(initialTab ?? items[0]?.label);

  const tab = selectedTab ?? selected;

  const renderContent = () => {
    const component = items.find((item) => item.label === tab)?.content;

    return component;
  };

  const handleLabelClick = (label: string) => {
    if (label !== tab) {
      onTabSelected ? onTabSelected(label) : setSelected(label);
    }
  };

  return (
    <TabsWrapper>
      <TabsLabelsWrapper style={styles ? styles.tabsLabelsWrapper : {}}>
        {items?.map((item) => (
          <Tab
            key={item.label}
            selected={item.label === tab}
            onClick={() => handleLabelClick(item.label)}
            data-testid={item['data-testid']}
          >
            <TabLabel>{item.label}</TabLabel>
            {item.icon ? <TabIcon src={item.icon} /> : null}
          </Tab>
        ))}
      </TabsLabelsWrapper>
      <TabContentWrapper>{renderContent()}</TabContentWrapper>
    </TabsWrapper>
  );
};

export default Tabs;
