import React from 'react';
import { StyledSVG } from '@common/styles';
import { DocumentStatus as DocumentStatusType } from '@common/types';

export interface IDocumentStatus {
  value: DocumentStatusType;
}

const DocumentStatus = ({ value }: IDocumentStatus) => {
  const renderStatus = () => {
    switch (value) {
      case DocumentStatusType.Retired:
        return <StyledSVG height={25} src="/icons/retired_tag.svg" />;
      case DocumentStatusType.Published:
        return <StyledSVG height={25} src="/icons/published_tag.svg" />;
      case DocumentStatusType.Submitted:
        return <StyledSVG height={25} src="/icons/submitted_tag.svg" />;
      case DocumentStatusType.Discontinued:
        return <StyledSVG height={25} src="/icons/discontinued_tag.svg" />;
      default:
        return <StyledSVG height={25} src="/icons/draft_tag.svg" />;
    }
  };

  return renderStatus();
};

export default DocumentStatus;
