import { generateFormProductName } from '@common/helpers';
import { IAddProductForm } from 'apps/publisher-v3.ui.customer/src/components/Product/AddProductPopup/types';
import { HttpStatusCode } from 'axios';
import { useMemo, useState } from 'react';
import { useCreateProduct } from '../useCreateProduct';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { ISubmissionDto } from '@common/features/submission/types';

interface IUseAddProduct {
  submission?: ISubmissionDto;
  handleSuccess: (productId: number) => void;
  handleDuplicateProductCallback?: (productId: string) => void;
}

interface IConflictErrorMessage {
  productId: string;
  productGroupId?: string;
  submissionId?: string;
}

export const useAddProduct = ({ submission, handleSuccess, handleDuplicateProductCallback }: IUseAddProduct) => {
  const { goToProduct, goToPublishedProduct } = useAppNavigation();
  const submissionId = submission?.submissionId.toString();

  const [isProductModalOpen, setProductIsOpen] = useState(false);
  const [duplicateProduct, setDuplicateProduct] = useState<string | undefined>(undefined);
  const [conflictErrorMessage, setConflictErrorMessage] = useState<IConflictErrorMessage>();

  const { createProduct, isCreatingProduct } = useCreateProduct(submissionId ?? '');

  const activeIngredientsNames = useMemo(
    () => (submission?.productGroup.substances ? submission.productGroup.substances.map((item) => item.name) : []),
    [submission?.productGroup.substances],
  );

  const handleError = (error: any, form: IAddProductForm) => {
    if (error.cause === HttpStatusCode.Conflict) {
      const existingProduct = generateFormProductName(
        submission?.name ?? '',
        form.activeIngredientsStrength.ingredientsWithNumerators,
        form.activeIngredientsStrength.denominator,
        form.productForm?.label ?? '',
        form.extraInformation,
      );

      setDuplicateProduct(existingProduct);
      setConflictErrorMessage(error.data.errorMessage);
    }
  };

  const handleProductCreate = (form: IAddProductForm, generatedProductName: string) => {
    const dataSet = {
      data: form,
      generatedProductName,
    };
    createProduct(dataSet, {
      onSuccess: (data) => {
        handleSuccess(data.productId);
      },
      onError: (e) => handleError(e, form),
    });
  };

  const handleProductModalClose = () => {
    setProductIsOpen(false);
  };

  const handleDuplicateErrorClose = () => setDuplicateProduct(undefined);
  const handleProductView = () => {
    if (handleDuplicateProductCallback) {
      handleDuplicateProductCallback(conflictErrorMessage!.productId);
    } else {
      conflictErrorMessage?.productGroupId
        ? goToPublishedProduct(conflictErrorMessage.productGroupId!, conflictErrorMessage.productId)
        : goToProduct(conflictErrorMessage!.submissionId!, conflictErrorMessage!.productId);
    }
  };

  const handleProductPopupOpen = () => {
    setProductIsOpen(true);
  };

  return {
    isProductModalOpen,
    duplicateProduct,
    activeIngredientsNames,
    isCreatingProduct,
    handleProductPopupOpen,
    handleProductCreate,
    handleDuplicateErrorClose,
    handleProductView,
    handleProductModalClose,
    setProductIsOpen,
  };
};
