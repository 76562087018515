import { useForm } from 'react-hook-form';
import { FormFields, ISubmissionForm } from '../../types';

export const usePrepareFileUploadForm = () => {
  const methods = useForm<ISubmissionForm>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.documentTitle]: '',
      [FormFields.file]: undefined,
      [FormFields.productIds]: [],
      [FormFields.hasDigitalVersion]: undefined,
      [FormFields.isCustomerApprovalRequired]: null,
    },
  });

  return { methods, ...methods };
};
