import styled from 'styled-components';
import { StyledSVG } from '@common/styles';
import { ComponentWrap, InputWrap, StyledLabel } from './Input/styles';
import { StyledButton } from './Button/styles';

export const RemoveButton = styled.div`
  cursor: pointer;
  ${StyledSVG} {
    svg {
      height: 26px;
      width: 26px;
    }
  }
`;

export const InputButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  max-width: 390px;

  ${ComponentWrap} {
    flex: 1;
  }

  ${InputWrap} {
    height: 45px;
    border-color: ${({ theme }) => theme.elements.borderGrey};
    border-radius: 10px;
  }

  ${StyledLabel} {
    margin-bottom: 10px;
  }

  ${StyledButton} {
    height: 45px;
    width: 100px;
    margin-left: 10px;
    border-color: ${({ theme }) => theme.colors.mediumBlue};
    color: ${({ theme }) => theme.colors.mediumBlue};
  }
`;

export const GroupedCheckboxSections = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
`;
