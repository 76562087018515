import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { useResubmitTask } from './useResubmitTask';
import { useState } from 'react';

export interface IAddResubmitNoteModalState {
  isOpen: boolean;
}

interface Props {
  taskId: string;
}

export const useAddResubmitNoteModal = ({ taskId }: Props) => {
  const { goToSubmissions } = useAppNavigation();
  const [statusModalState, setStatusModalState] = useState<IAddResubmitNoteModalState>({
    isOpen: false,
  });

  const { mutate, isResubmitting } = useResubmitTask(taskId as string);

  const handleResubmit = () => {
    setStatusModalState({
      isOpen: true,
    });
  };

  const resubmitCallback = (note: string) => {
    mutate(
      { note: note },
      {
        onSuccess: () => {
          handleCloseResubmitModal();
          return goToSubmissions();
        },
      },
    );
  };

  const handleCloseResubmitModal = () => {
    setStatusModalState({ ...statusModalState, isOpen: false });
  };

  return {
    statusModalState,
    handleCloseResubmitModal,
    handleStatusChange: handleResubmit,
    resubmitCallback,
    isResubmitting,
  };
};
