import React from 'react';

import * as Styled from '../../styles';
import { FormInput } from '@common/components/FormInput/styles';

interface IReadonlyActiveIngredientsContent {
  activeIngredientsNames: string[];
}

export const ReadonlyActiveIngredientsContent = ({ activeIngredientsNames }: IReadonlyActiveIngredientsContent) => {
  return (
    <Styled.TabContentWrapper>
      <Styled.BadgeElementWrapper>
        <Styled.Subtitle>Active Ingredients</Styled.Subtitle>
      </Styled.BadgeElementWrapper>
      <Styled.Content>
        <Styled.Description>
          Changes to the active ingredient(s) in this section will apply to all products within the product group
        </Styled.Description>

        <Styled.ProductGroupTabForm>
          {activeIngredientsNames.map((item, index) => (
            <FormInput key={index} disabled={true} name={`activeIngredient`} value={item} />
          ))}
        </Styled.ProductGroupTabForm>
      </Styled.Content>
    </Styled.TabContentWrapper>
  );
};
