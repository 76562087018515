import React from 'react';

import { useAppDispatch } from '../../hooks/redux';
import { setActiveCompany } from '../../features/user/userSlice';

import { ICompanyPermission } from '@common/features/user/types';

import { CompanyItem as StyledCompanyItem, StyledTickIcon, PopoverItemText, MenuSpacer } from './styles';

interface ICompanyItem {
  company: ICompanyPermission;
  isActiveCompany: boolean;
  userId: number;
}

export const CompanyItem = ({ company, isActiveCompany, userId }: ICompanyItem) => {
  const dispatch = useAppDispatch();

  const handleCompanyItemClick = () => {
    dispatch(setActiveCompany({ ...company, userId }));
  };

  return (
    <StyledCompanyItem onClick={handleCompanyItemClick} isCurrent={isActiveCompany}>
      {isActiveCompany ? <StyledTickIcon src={`/icons/tick.svg`} /> : <MenuSpacer></MenuSpacer>}
      <PopoverItemText>{company.name}</PopoverItemText>
    </StyledCompanyItem>
  );
};
