import React from 'react';

import { useFetchSubmissionTaskNotes } from '../../../hooks/useFetchSubmissionTaskNotes';

import DatapharmTable from '@common/components/DatapharmTable/DatapharmTable';

import { prepareNotesTabColumns } from './helpers';

import { INotesQueryDependencies } from '@common/features/submission/types';

import * as Styled from './styles';
interface Props {
  submissionTaskId: string;
  notesQueryDependencies?: INotesQueryDependencies;
}

export const NotesTab = ({ submissionTaskId, notesQueryDependencies }: Props) => {
  const { data, isFetchingSubmissionTaskNotes } = useFetchSubmissionTaskNotes(submissionTaskId, notesQueryDependencies);

  const columns = prepareNotesTabColumns();

  return (
    <Styled.DetailsTableWrapper>
      {data && (
        <DatapharmTable columns={columns} documents={data} showLoader={isFetchingSubmissionTaskNotes} data-testid="notes" />
      )}
    </Styled.DetailsTableWrapper>
  );
};
