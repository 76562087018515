import React from 'react';
import { ReadonlyActiveIngredientsContent } from './ReadonlyActiveIngredientsContent';
import { ProductGroupFormFields } from '../../hooks/useProductGroupForm/types';
import { ActiveIngredientsContent } from './ActiveIngredientsContent';

interface IActiveIngredientsTab {
  activeIngredientsNames: string[];
  activeIngredientsFieldName?: ProductGroupFormFields;
  noActiveIngredientsCheckboxFieldName?: ProductGroupFormFields;
  isEditable?: boolean;
}

const ActiveIngredientsTab = ({
  activeIngredientsNames,
  activeIngredientsFieldName,
  noActiveIngredientsCheckboxFieldName,
  isEditable = false,
}: IActiveIngredientsTab) => {
  if (isEditable) {
    return (
      <ActiveIngredientsContent
        activeIngredientsFieldName={activeIngredientsFieldName!}
        noActiveIngredientsCheckboxFieldName={noActiveIngredientsCheckboxFieldName!}
      />
    );
  }

  return <ReadonlyActiveIngredientsContent activeIngredientsNames={activeIngredientsNames} />;
};

export default ActiveIngredientsTab;
