import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { fetchActiveIngredientsSearch } from '@common/services/productService';
import { ISubstanceDto } from '@common/features/submission/types';

export const queryKey = 'fetchActiveIngredients';

export const useFetchActiveIngredients = (search: string) => {
  const token = useAppSelector(selectToken);

  const { data, isFetching: isFetchingActiveIngredients } = useQuery<ISubstanceDto[]>(
    [queryKey],
    () => fetchActiveIngredientsSearch(token, search),
    { enabled: !!token && search.trim().length >= 3 },
  );

  return {
    activeIngredients: data ?? [],
    isFetchingActiveIngredients,
  };
};
