import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';
import { addDocument, addDocumentVersion } from '@common/services/submissionService';
import { usePleaseWaitOverlay } from '@components/PleaseWaitOverlay/usePleaseWaitOverlay';
import { DocumentType } from '@common/types';

export const queryKey = 'contentDetailsData';

interface IPostInitialDocumentDetails {
  documentId: number | null;
  title: string;
  hasDigitalVersion?: boolean;
}

export const useAddDocument = (
  submissionId: Id,
  documentType: DocumentType,
  fileName?: string,
  originalFileName?: string,
  link?: string,
  isCustomerApprovalRequired?: boolean,
  htmlContent?: string,
) => {
  const token = useAppSelector(selectToken);
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const { mutate, data, isLoading } = useWaitingMutation(
    ({ documentId, title, hasDigitalVersion }: IPostInitialDocumentDetails) => {
      const linkValue = !link || link.length === 0 ? null : link;
      const payload = {
        originalFileName,
        fileName,
        documentType,
        title,
        hasDigitalVersion: hasDigitalVersion ?? false,
        isCustomerApprovalRequired,
        htmlContent,
        ...([DocumentType.Audio, DocumentType.Video].includes(documentType) ? { customerLink: linkValue } : { link: linkValue }),
      };

      return documentId
        ? addDocumentVersion(token, submissionId, documentId, payload).then(() => ({ documentId }))
        : addDocument(token, submissionId, payload);
    },
    { onMutate: handleShowOverlay, onSuccess: handleHideOverlay, onError: handleHideOverlay },
  );

  return { addDocument: mutate, data, isLoading };
};
