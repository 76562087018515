import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { StyledLink } from '@common/components/Link/styles';

export const FileErrorBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BorderedMessage = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 13px;
  display: flex;
  justify-content: left;
  align-items: start;
  border-radius: 10px;
  min-height: 88px;
  margin-bottom: 10px;
`;

export const BorderedMessageIcon = styled(ReactSVG)`
  margin-right: 10px;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const BorderedMessageTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.elements.textGrey};
  text-wrap: nowrap;
`;

export const BorderedMessageSubTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.elements.textDarkGrey};
`;

export const InfoLink = styled(StyledLink)`
  display: flex;
  text-decoration: underline;
  color: ${({ theme }) => theme.elements.primaryMedium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 600;
`;

export const InfoIcon = styled(ReactSVG)``;
