import React from 'react';
import { TabContentWrapper } from '@common/features/productGroup/styles';
import * as Styled from '@common/features/productGroup/styles';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';
import { NoContentError } from '@common/components/NoContentError';
import { Radio } from '@common/components';
import { ProductGroupFormFields } from '@common/features/productGroup/hooks/useProductGroupForm/types';
import { FormInput } from '@common/components/FormInput/styles';

interface IMaHolderTab {
  showWarning?: boolean;
  isVisible: boolean;
  isSameAsSubmitterCompany: boolean | null;
  companyName: string;
  editable?: boolean;
  onSameAsSubmitterCompanyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCompanyNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUnmount?: () => void;
}

const MaHolderTab = ({
  showWarning,
  isVisible,
  isSameAsSubmitterCompany,
  companyName,
  editable = true,
  onSameAsSubmitterCompanyChange,
  onCompanyNameChange,
  onUnmount = () => {},
}: IMaHolderTab) => (
  <TabContentWrapper>
    <Styled.BadgeElementWrapper>
      <Styled.Subtitle data-testid="ma-holder-tab-title">MA Holder</Styled.Subtitle>
      <IconWithTooltip tooltipId="maHolder" iconSrc="/icons/info_circle.svg" tooltipText={''} />
    </Styled.BadgeElementWrapper>
    <Styled.Description>
      <Styled.Content>
        {showWarning && <NoContentError text="Please complete the following section" />}
        Is the company responsible for submitting to emc the same as the MA Holder in Section 7 of the SmPC?
        <>
          <Styled.RadioWrapper>
            <Radio size={22} value={1} isChecked={isSameAsSubmitterCompany === true} onChange={onSameAsSubmitterCompanyChange} />
            Yes
          </Styled.RadioWrapper>
          <Styled.RadioWrapper>
            <Radio size={22} value={0} isChecked={isSameAsSubmitterCompany === false} onChange={onSameAsSubmitterCompanyChange} />
            No
          </Styled.RadioWrapper>
        </>
        {isVisible && (
          <Styled.InputWrapper>
            <Styled.BadgeElementWrapper>
              Please provide the MA Holder
              <IconWithTooltip tooltipId="maHolder" iconSrc="/icons/info_circle.svg" tooltipText={''} />
            </Styled.BadgeElementWrapper>
            <FormInput
              disabled={!editable}
              name={ProductGroupFormFields.companyName}
              placeholder="Enter company name"
              onChange={onCompanyNameChange}
              value={companyName}
            />
          </Styled.InputWrapper>
        )}
      </Styled.Content>
    </Styled.Description>
  </TabContentWrapper>
);

export default MaHolderTab;
