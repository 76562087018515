import { createSelector } from '@reduxjs/toolkit';
import { SubmissionState } from './types';
import { RootState } from '@common/store';

const selectSubmissionState = (state: RootState) => state.submission;

export const selectActiveSubmissionType = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.activeSubmissionType,
);

export const selectDraftSubmissionGenericName = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.draftSubmission.genericName,
);

export const selectDraftSubmissionProductType = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.draftSubmission.productType,
);

export const selectDraftSubmissionActiveIngredients = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.draftSubmission.activeIngredients,
);

export const selectDraftSubmissionError = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.draftSubmission.error,
);

export const selectReviewPopupState = createSelector(selectSubmissionState, (state: SubmissionState) => state.isReviewPopupOpen);

export const selectWarningPopupState = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.isDuplicateWarningPopupOpen,
);

export const selectProductGroupPlan = createSelector(selectSubmissionState, (state: SubmissionState) => state.productGroupPlan);

export const selectFileName = createSelector(selectSubmissionState, (state: SubmissionState) => state.fileName);

export const selectFileSize = createSelector(selectSubmissionState, (state: SubmissionState) => state.fileSize);

export const selectIsFileBeingUploaded = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.isFileBeingUploaded,
);

export const selectUploadProgress = createSelector(selectSubmissionState, (state: SubmissionState) =>
  Math.floor(state.uploadProgress * 100),
);

export const selectDocumentTitle = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.documentTitle
);

export const selectIsLoadingProductGroupPlan = createSelector(
  selectSubmissionState,
  (state: SubmissionState) => state.isLoading
);

