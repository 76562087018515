import { useState } from 'react';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { AxiosProgressEvent } from 'axios';
import { uploadAxiosFiles } from '../../services/requestService';
import { useAppSelector } from '../redux';
import { ValidationError } from '@common/types';
import { selectToken } from '../../features/user/selectors';
import { Payload } from './types';
import { IMappedAxiosError } from '@common/api/uploadFileWithProgression';

export const useUploadFile = (errorCallback: () => void) => {
  const token = useAppSelector(selectToken);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileName, setFileName] = useState('');
  const [fileErrors, setFileErrors] = useState<ValidationError[]>([]);

  const handleUploadProgress = (p: AxiosProgressEvent) => setUploadProgress(Math.floor((p.loaded / p.total!) * 100));

  const handleSuccess = (fileName: string) => {
    setFileName(fileName);
  };

  const clearFileName = () => setFileName('');

  const handleError = (e: IMappedAxiosError) => {
    setFileErrors(() => {
      const clonedErrors = [];
      const isVirusCheckError = e.cause === 400 && e.data?.errorMessage === 'Virus detected in the attached file';

      if (isVirusCheckError) {
        const virusErrorMessage = {
          message: 'Virus detected',
          subMessage: 'Your file could not be uploaded because it contains a virus',
        };
        return [virusErrorMessage];
      }

      clonedErrors.push({
        message: 'File validation error',
        subMessage: 'File validation did not succeed',
      });

      errorCallback();

      return clonedErrors;
    });
  };

  const { mutate, isLoading } = useWaitingMutation(
    (payload: Payload) => {
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('file', payload.file);
      formData.append('documentType', payload.documentType);
      formData.append('fileRole', payload.fileRole ?? '');

      return uploadAxiosFiles(token, formData, handleUploadProgress);
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );

  return { uploadFile: mutate, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName };
};
