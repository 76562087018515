import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const TitleIcon = styled(ReactSVG)`
  svg {
    width: 70px;
    height: auto;
  }

  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  color: ${({ theme }) => theme.elements.textLightGrey};
  button {
    color: ${({ theme }) => theme.elements.textLightGrey};
  }
`;

export const Subtext = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.textLightGrey};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const CheckboxText = styled.span`
  margin-left: 6px;
`;
