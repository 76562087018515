import { SubmissionTypes } from '@common/features/submission/types';

export const submissisonTypeToText = {
  [SubmissionTypes.CREATE_PRODUCT]: {
    text: 'Create a Product',
    subText: 'Create a product that is not currently on the emc',
  },
  [SubmissionTypes.UPDATE_PRODUCT]: {
    text: 'Update a Product',
    subText: 'Update product information on an existing product on the emc  (incl SmPC or PiL)',
  },
};
