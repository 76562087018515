import React from 'react';
import { InfoModal } from '@common/components/InfoModal';
import * as Styled from './styles';
import { UnderlinedLink } from '@common/components/UnderlinedLink/styles';

interface IProps {
  isOpen: boolean;
  document: string;
  products: string[];
  onConfirm: () => void;
}

export const AlreadyAssociatedProductsModal = ({ isOpen, document, products, onConfirm }: IProps) => (
  <InfoModal
    isOpen={isOpen}
    subTitle="Content associated to more than one product"
    additionalFooterItems={<UnderlinedLink to="#">How to associate content</UnderlinedLink>}
    onConfirm={onConfirm}
  >
    The following content:
    <Styled.Document>{document}</Styled.Document>
    will still be associated to the products below and will continue to be displayed on the emc:
    <Styled.Products>
      {products.map((p) => (
        <p key={p}>{p}</p>
      ))}
    </Styled.Products>
  </InfoModal>
);
