import React from 'react';
import { ButtonTypes } from './types';
import { Icon, StyledButton, ContentWrap, SpinnerBackground } from './styles';
import { StyledSVG } from '@common/styles';

interface IButton {
  type: ButtonTypes;
  text?: string;
  children?: any;
  height?: number;
  width?: number;
  minWidth?: number;
  icon?: string;
  showArrowPostfix?: boolean;
  color?: string;
  borderColor?: string;
  borderHoverColor?: string;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  value?: any;
  isLoading?: boolean;
  ['data-testid']?: string;
}

export const Button = ({
  text,
  type,
  height,
  width,
  minWidth,
  icon,
  showArrowPostfix,
  color,
  borderColor,
  borderHoverColor,
  disabled,
  onClick,
  value,
  children,
  isLoading,
  ...other
}: IButton) => (
  <StyledButton
    renderType={type}
    height={height}
    width={width}
    minWidth={minWidth}
    onClick={isLoading ? () => {} : onClick}
    icon={icon ? true : false}
    color={color}
    borderColor={borderColor}
    borderHoverColor={borderHoverColor}
    disabled={disabled}
    value={value}
    isLoading={isLoading}
    data-testid={other['data-testid']}
  >
    {children || isLoading ? (
      <ContentWrap>
        {icon ? <Icon className="contentIcon" src={icon} /> : null}
        {isLoading ? (
          <SpinnerBackground>
            <StyledSVG src={`/icons/spinner.svg`} className="spinner" />
          </SpinnerBackground>
        ) : (
          children ?? text
        )}
      </ContentWrap>
    ) : (
      <>
        {icon ? <Icon src={icon} /> : null}
        {text}
      </>
    )}
    {showArrowPostfix ? <Icon className="arrowPostfix" src="/icons/arrow_right.svg" height="15px" /> : null}
  </StyledButton>
);
