import { AddProductFormFields, IAddProductForm } from './types';

export const getDefaultForm = (activeIngredients: any): IAddProductForm => {
  return {
    [AddProductFormFields.productForm]: null,
    [AddProductFormFields.extraInformation]: '',
    [AddProductFormFields.activeIngredientsStrength]: activeIngredients,
    [AddProductFormFields.emcProductName]: '',
    [AddProductFormFields.medicinalProductName]: '',
  };
};
