import React from 'react';
import { ReactSVG } from 'react-svg';
import { Tooltip } from '../tooltip/Tooltip';
import { TooltipWrapper } from './styles';

interface IIconWithTooltip {
  tooltipId: string;
  iconSrc: string;
  tooltipText: string;
}

const IconWithTooltip = ({ tooltipId, iconSrc, tooltipText }: IIconWithTooltip) => {
  return (
    <TooltipWrapper className="icon-with-tooltip" data-testid="tooltip-wrapper">
      <a data-tooltip-id={tooltipId} data-tooltip-content={tooltipText}>
        <ReactSVG src={iconSrc} data-testid="tooltip-wrapper-icon" />
      </a>
      <Tooltip id={tooltipId} className="tooltip" data-testid="tooltip-wrapper-text" />
    </TooltipWrapper>
  );
};

export default IconWithTooltip;
