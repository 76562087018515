import { SyntheticEvent } from 'react';
import { FileInput, FileItem } from '..';
import { FileInputWrap, FileTemsWrap } from './styles';
import { Title, Text } from '@common/styles';

interface IFileUpload {
  title?: string;
  subtitle?: string;
  onFileSubmit: (files: FileList | null, event: SyntheticEvent) => void;
  onRemove: () => void;
  loadingPresents?: number;
  isLoading?: boolean;
  fileName?: string;
  fileSize?: string;
  fileInputSubText?: string;
  onOpen?: () => void;
  disabled?: boolean;
}

const FileUpload = ({
  title,
  subtitle,
  onFileSubmit,
  loadingPresents,
  isLoading,
  fileName,
  fileSize,
  fileInputSubText,
  onRemove,
  disabled = false,
}: IFileUpload) => (
  <>
    {title && <Title small>{title}</Title>}
    {subtitle && <Text>{subtitle}</Text>}
    <FileInputWrap>
      <FileInput subText={fileInputSubText} onFileSubmit={onFileSubmit} disabled={disabled} />
    </FileInputWrap>
    <FileTemsWrap>
      {fileName && (
        <FileItem
          loadingPresents={loadingPresents}
          isLoading={isLoading}
          fileName={fileName}
          fileSize={fileSize}
          onClick={onRemove}
        />
      )}
    </FileTemsWrap>
  </>
);

export default FileUpload;
