import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestEpil } from '@common/services/submissionService';

export const useRequestEpil = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation(({ documentId, submissionId }) => requestEpil(token, submissionId, documentId));

  return { requestEpil: mutate };
};
