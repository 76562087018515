import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { changeDocumentAssociation } from '@common/services/adminService';
import { Id } from '@common/types';
import useWaitingMutation from '@hooks/useWaitingMutation';

interface IChangeDocumentAssociationData {
  productIds: number[];
  documentId: Id;
}

export const useChangeDocumentAssociation = (submissionId: Id) => {
  const token = useAppSelector(selectToken);

  const { mutate, isLoading } = useWaitingMutation(({ productIds, documentId }: IChangeDocumentAssociationData) =>
    changeDocumentAssociation(token, submissionId, documentId, { productIds }),
  );

  return {
    changeDocumentAssociation: mutate,
    isChangingDocumentAssociation: isLoading,
  };
};
