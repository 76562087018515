import styled from 'styled-components';

export const SubmissionTypePickerWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 15px 20px 25px 20px;
  border-radius: 14px;
  margin-bottom: 30px;
`;

export const SubmissionTypePickerHeading = styled.h2`
  color: ${({ theme }) => theme.elements.submissionHeading};
  margin-bottom: 20px;
`;

export const SubmissionTypePickerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
`;
