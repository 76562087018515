import theme from '@common/theme';
import { ButtonTypes } from '../Button/types';
import Divider from '../Divider/Divider';
import { Button } from '../Button/Button';
import * as Styled from './styles';
import { CustomerAllowedTaskActions } from '@common/types';
import { Fragment } from 'react';

interface IProps {
  allowedActions: CustomerAllowedTaskActions[];
  onApprove: () => void;
  onCancel: () => void;
  onRequestChange: () => void;
  onViewConvertedContent: () => void;
  onReview: () => void;
  ['data-testid']?: string;
}
const {
  CustomerRequestChanges,
  CustomerViewConvertedContent,
  CustomerCompletesReview,
  CustomerApproves,
  CustomerCancels,
  ...other
} = CustomerAllowedTaskActions;

export const SubmissionTaskActions = ({
  allowedActions,
  onApprove,
  onCancel,
  onRequestChange,
  onViewConvertedContent,
  onReview,
  ...other
}: IProps) => {
  const sortedAllowedActions = [
    allowedActions.find((a) => a === CustomerRequestChanges),
    allowedActions.find((a) => a === CustomerViewConvertedContent),
    allowedActions.find((a) => a === CustomerCompletesReview),
    allowedActions.find((a) => a === CustomerApproves),
    allowedActions.find((a) => a === CustomerCancels),
  ].filter((x) => x);

  const itemsCount = sortedAllowedActions.length;
  const testId = other['data-testid'];
  const renderButtons = (action: CustomerAllowedTaskActions) => {
    switch (action) {
      case CustomerAllowedTaskActions.CustomerRequestChanges:
        return (
          <Button
            data-testid={`requestchanges_${testId}`}
            type={ButtonTypes.TEXT}
            text="Request changes"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={onRequestChange}
          />
        );
      case CustomerAllowedTaskActions.CustomerViewConvertedContent:
        return (
          <Button
            type={ButtonTypes.TEXT}
            text="View converted content"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={onViewConvertedContent}
          />
        );
      case CustomerAllowedTaskActions.CustomerCompletesReview:
        return (
          <Button type={ButtonTypes.TEXT} text="Review" data-testid={`review_${testId}`} height={25} color={theme.elements.primaryMedium} onClick={onReview} />
        );
      case CustomerAllowedTaskActions.CustomerApproves:
        return <Button type={ButtonTypes.TEXT} text="Approve" data-testid={`approve_${testId}`} height={25} color={theme.colors.darkGreen} onClick={onApprove} />;
      case CustomerAllowedTaskActions.CustomerCancels:
        return <Button type={ButtonTypes.TEXT} text="Cancel" data-testid={`cancel_${testId}`} height={25} color={theme.colors.red} onClick={onCancel} />;
      default:
        return <div />;
    }
  };

  return (
    <Styled.ActionsWrapper>
      {sortedAllowedActions.map((a, i) => (
        <Fragment key={a}>
          {renderButtons(a!)}
          {i !== itemsCount - 1 && <Divider type="vertical" size="30px" margin={10} />}
        </Fragment>
      ))}
    </Styled.ActionsWrapper>
  );
};
