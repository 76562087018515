import { UnsavedChangesModal } from '@common/components/UnsavedChangesModal';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useEffect, useState } from 'react';

interface UnsavedChanges {
  handleConfirm?: () => void;
  setHandleConfirm: Dispatch<SetStateAction<(() => void) | undefined>>;
  hasUnsavedChanges?: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  handleAfterConfirm?: () => void;
  setHandleAfterConfirm: Dispatch<SetStateAction<(() => void) | undefined>>;
}

export const UnsavedChangesContext = createContext<UnsavedChanges>({
  handleConfirm: undefined,
  setHandleConfirm: () => {},
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  handleAfterConfirm: undefined,
  setHandleAfterConfirm: () => {},
});

interface IProps {
  children: ReactNode;
}

export const UnsavedChangesContextProvider = ({ children }: IProps) => {
  const [handleConfirm, setHandleConfirm] = useState<() => void>();
  const [handleAfterConfirm, setHandleAfterConfirm] = useState<() => void>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleClose = () => setHandleConfirm(undefined);
  const handleConfirmClick = () => {
    setHasUnsavedChanges(false);
    handleConfirm!();
    handleAfterConfirm && handleAfterConfirm();
    setHandleConfirm(undefined);
    setHandleAfterConfirm(undefined);
  };


  const handleCloseWindow = useCallback(
    (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [hasUnsavedChanges],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseWindow);
    return () => {
      window.removeEventListener('beforeunload', handleCloseWindow);
    };
  }, [handleCloseWindow]);

  return (
    <UnsavedChangesContext.Provider
      value={{
        handleConfirm,
        setHandleConfirm,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        handleAfterConfirm,
        setHandleAfterConfirm,
      }}
    >
      {children}
      <UnsavedChangesModal onConfirm={handleConfirmClick} onClose={handleClose} isOpen={!!handleConfirm} />
    </UnsavedChangesContext.Provider>
  );
};
