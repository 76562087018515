import { SubmissionStatus, SubmissionStatusText } from '@common/types';

export const submissionStatusCheckboxes = [
  {
    id: 1,
    text: SubmissionStatusText.Draft,
    value: SubmissionStatus.Draft,
  },
  {
    id: 2,
    text: SubmissionStatusText.InProgress,
    value: SubmissionStatus.InProgress,
  },
  {
    id: 3,
    text: SubmissionStatusText.Approved,
    value: SubmissionStatus.Approved,
  },
  {
    id: 4,
    text: SubmissionStatusText.Cancelled,
    value: SubmissionStatus.Cancelled,
  },
  {
    id: 5,
    text: SubmissionStatusText.Published,
    value: SubmissionStatus.Published,
  },
];
