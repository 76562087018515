import React from 'react';
import useAppNavigation from '../../../routing/useAppNavigation';
import { IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { DocumentType } from '@common/types';
import { ISubmissionDto } from '@common/features/submission/types';
import { useParams } from 'react-router';
import { ReadonlyProductGroup } from './ReadonlyProductGroup';

interface ISubmissionReadonlyProductGroup {
  submission: ISubmissionDto;
}

const SubmissionReadonlyProductGroup = ({ submission }: ISubmissionReadonlyProductGroup) => {
  const { submissionId = '' } = useParams();
  const { goToHome, goToProduct, goToDocumentContentDetails } = useAppNavigation();

  const handleDetailsClick = (productId: string) => goToProduct(submissionId, productId);
  const handleDocumentDetailsClick = (documentId: string, contentType: DocumentType) =>
    goToDocumentContentDetails(submissionId, contentType, documentId);

  const tableCallbacks: IProductTableCallbacks = {
    onDetailsClick: handleDetailsClick,
  };

  const handleCancel = () => goToHome();

  return (
    <ReadonlyProductGroup
      submissionStatus={submission?.status!}
      submissionTitle={submission?.title!}
      dataSource={submission!}
      productTableCallbacks={tableCallbacks}
      onCancel={handleCancel}
      onDocumentDetailsClick={handleDocumentDetailsClick}
      isSubmissionView
    />
  );
};

export default SubmissionReadonlyProductGroup;
