import React from 'react';

import { ISubmissionTypeButton } from './types';

import { StyledSVG } from '@common/styles';
import {
  SubmissionTypeRadioWrap,
  SubmissionTypeSubtext,
  SubmissionTypeText,
  SubmissionTypeTextWrap,
  SubmissionTypeWrap,
} from './styles';

export const SubmissionType = ({ isActive, onClick, text, subText, value }: ISubmissionTypeButton) => {
  const handleClick = () => onClick(value);

  return (
    <SubmissionTypeWrap onClick={handleClick} isActive={isActive}>
      <SubmissionTypeTextWrap>
        <SubmissionTypeText isActive={isActive}>{text}</SubmissionTypeText>
        <SubmissionTypeSubtext>{subText}</SubmissionTypeSubtext>
      </SubmissionTypeTextWrap>
      <SubmissionTypeRadioWrap>
        {isActive ? <StyledSVG src={'/icons/circle_outline_filled.svg'} /> : <StyledSVG src={'/icons/circle_outline.svg'} />}
      </SubmissionTypeRadioWrap>
    </SubmissionTypeWrap>
  );
};
