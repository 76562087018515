import styled from 'styled-components';

interface IWrap {
  dragActive: boolean;
}

export const Wrap = styled.div<IWrap>`
  width: 496px;
  .fileDrop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    height: 123px;
    border: 1px dashed ${({ theme }) => theme.colors.borderGray};
    background-color: ${({ theme }) => theme.colors.defaultWhite};
    border-radius: 8px;
    cursor: pointer;
    color: ${({ theme }) => theme.elements.mediumGrey};
    border-color: ${({ dragActive, theme }) =>
      dragActive && theme.elements.primaryMedium};
    &:hover {
      border-color: ${({ theme }) => theme.elements.primaryMedium};
    }
  }
`;

export const Text = styled.div`
  display: block !important;
  span {
    font-weight: 600;
    color: ${({ theme }) => theme.elements.primaryMedium};
  }
`;

export const SubText = styled.div`
  font-weight: bold;
`;

export const StyledInput = styled.input.attrs({ type: 'file' })``;
