import { Button, ButtonTypes } from '@common/components';
import React from 'react';
import { useState } from 'react';

export interface IDropDownButtonOption {
  buttonText: string;
  action: () => void;
  icon: string;
}
interface IDropDownButton {
  buttonText: string;
  dropDownButtons: IDropDownButtonOption[];
}

export const DropDownButton = ({ buttonText, dropDownButtons }: IDropDownButton) => {
  const [mediaOpen, setButtonState] = useState(false);
  return (
    <div
      onBlur={(e: any) => {
        var target = e.relatedTarget;
        var tag = target?.getAttribute('data-testid');
        const isButton = tag !== undefined && tag?.startsWith(`add-${buttonText}-`);
        if (!isButton) setButtonState(false);
      }}
      style={{ position: 'relative' }}
    >
      <Button
        onClick={() => {
          setButtonState(!mediaOpen);
        }}
        text={buttonText}
        type={ButtonTypes.PRIMARY_MEDIUM_BORDER}
        icon="/icons/plus-sign.svg"
        minWidth={150}
        data-testid={buttonText}
      />

      {mediaOpen && (
        <ul style={{ listStyle: 'none', position: 'absolute', zIndex: 10 }}>
          {dropDownButtons.map((ddi) => {
            return (
              <Button
                key={ddi.buttonText}
                onClick={() => {
                  ddi.action();
                  setButtonState(false);
                }}
                text={ddi.buttonText}
                icon={ddi.icon}
                type={ButtonTypes.DROPDOWNBUTTON}
                minWidth={150}
                data-testid={`add-${buttonText}-${ddi.buttonText}-button`}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};
