import { ReactComponent as ArrowRight } from './arrow_right.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Account } from './account.svg';
import { ReactComponent as Approved } from './approved.svg';
import { ReactComponent as Audits } from './audits.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as BlueTick } from './blue_tick.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Cancel } from './cancel.svg';
import { ReactComponent as Cancelled } from './cancelled.svg';
import { ReactComponent as CircleOutlineFilled } from './circle_outline_filled.svg';
import { ReactComponent as CircleOutline } from './circle_outline.svg';
import { ReactComponent as Collapse } from './collapse.svg';
import { ReactComponent as CrossCircle } from './cross_circle.svg';
import { ReactComponent as Discontinue } from './discontinue.svg';
import { ReactComponent as DiscontinuedTag } from './discontinued_tag.svg';
import { ReactComponent as DocumentView } from './document-view.svg';
import { ReactComponent as DraftTag } from './draft_tag.svg';
import { ReactComponent as Draft } from './draft.svg';
import { ReactComponent as Drafts } from './drafts.svg';
import { ReactComponent as ErrorBig } from './errorBig.svg';
import { ReactComponent as ExitMark } from './exit_mark.svg';
import { ReactComponent as ExpandMoreCircle } from './expand_more_circle.svg';
import { ReactComponent as ExpandTick } from './expand_tick.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as FiltersIcon } from './filters_icon.svg';
import { ReactComponent as Globe } from './globe.svg';
import { ReactComponent as GrayTick } from './gray_tick.svg';
import { ReactComponent as GreenArrow } from './green_arrow.svg';
import { ReactComponent as GreenTick } from './green_tick.svg';
import { ReactComponent as Hierarchy } from './hierarchy.svg';
import { ReactComponent as Html } from './html.svg';
import { ReactComponent as InProgress } from './in_progress.svg';
import { ReactComponent as InfoCircleUnfilled } from './info_circle_unfilled.svg';
import { ReactComponent as InfoCircle } from './info_circle.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as NeedsApproval } from './needs_approval.svg';
import { ReactComponent as LiveChat } from './live_chat.svg';
import { ReactComponent as NeedsAttention } from './needs_attention.svg';
import { ReactComponent as NewTag } from './new_tag.svg';
import { ReactComponent as PlusSign } from './plus-sign.svg';
import { ReactComponent as Products } from './products.svg';
import { ReactComponent as PublishedTag } from './published_tag.svg';
import { ReactComponent as QuestionMarkFiled } from './question_mark_filed.svg';
import { ReactComponent as QuestionMarkRed } from './question_mark_red.svg';
import { ReactComponent as RedFlag } from './red_flag.svg';
import { ReactComponent as Reinstate } from './reinstate.svg';
import { ReactComponent as Retire } from './retire.svg';
import { ReactComponent as RetiredTag } from './retired_tag.svg';
import { ReactComponent as SideDecoration } from './side_decoration.svg';
import { ReactComponent as SmallWarningIcon } from './small_warning_icon.svg';
import { ReactComponent as Spinner } from './spinner.svg';
import { ReactComponent as SquareTickGray } from './square_tick_gray.svg';
import { ReactComponent as SquareTick } from './square_tick.svg';
import { ReactComponent as Submissions } from './submissions.svg';
import { ReactComponent as SubmittedTag } from './submitted_tag.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as TrashBin } from './trash_bin.svg';
import { ReactComponent as UpdateTag } from './update_tag.svg';
import { ReactComponent as Update } from './update.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Walkthrough } from './walkthrough.svg';
import { ReactComponent as WarningCircle } from './warning_circle.svg';
import { ReactComponent as WarningSignFilled } from './warning-sign-filled.svg';
import { ReactComponent as WarningSign } from './warning-sign.svg';
import { ReactComponent as WithDatapharm } from './with_datapharm.svg';
import { ReactComponent as CompanyTransfer } from './company_transfer.svg';

export const Icons = {
  ArrowRight,
  Account,
  Approved,
  Audits,
  Back,
  BlueTick,
  Calendar,
  Cancel,
  Cancelled,
  CircleOutlineFilled,
  CircleOutline,
  CompanyTransfer,
  Collapse,
  CrossCircle,
  Discontinue,
  DiscontinuedTag,
  DocumentView,
  DraftTag,
  Draft,
  Drafts,
  ErrorBig,
  ExitMark,
  ExpandMoreCircle,
  ExpandTick,
  Expand,
  Eye,
  File,
  FiltersIcon,
  Globe,
  GrayTick,
  GreenArrow,
  GreenTick,
  Hierarchy,
  Home,
  Html,
  InProgress,
  InfoCircleUnfilled,
  InfoCircle,
  Information,
  LiveChat,
  NeedsApproval,
  NeedsAttention,
  NewTag,
  PlusSign,
  Products,
  PublishedTag,
  QuestionMarkFiled,
  QuestionMarkRed,
  RedFlag,
  Reinstate,
  Retire,
  RetiredTag,
  SideDecoration,
  SmallWarningIcon,
  Spinner,
  SquareTickGray,
  SquareTick,
  Submissions,
  SubmittedTag,
  Tick,
  TrashBin,
  UpdateTag,
  Update,
  Upload,
  Walkthrough,
  WarningCircle,
  WarningSignFilled,
  WarningSign,
  WithDatapharm,
};
