import styled from 'styled-components';
import { CreateSubmissionWrap } from '../styles';
import theme from '@common/theme';
import { Table } from '@common/components/DatapharmTable/styles';

export const TableWrapper = styled.div`
  overflow: auto;
  max-height: 500px;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  ${Table} {
    thead,
    tbody {
      width: 100%;
      th:first-child,
      tr td:first-child {
        text-align: center;
      }
    }
  }
`;

export const SearchInputWrapper = styled.div`
  margin-bottom: 30px;
`;

export const ErrorText = styled.span`
  color: ${theme.colors.red};
  font-size: ${({ theme }) => theme.elementFontSizes.errorMessage};
  margin-right: 10px;
`;

export const CreateSubmissionOffsetWrap = styled(CreateSubmissionWrap)`
  margin-left: 50px;
`;
