import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import NoContentPanel from '@common/components/NoContentPanel/NoContentPanel';
import { Heading, Subtext } from './styles';
import { SectionWrapper, TableWrapper } from '../styles';
import { prepareColumns } from '../helpers';
import { DocumentType, Id } from '@common/types';
import { IDocumentDto } from '@common/features/submission/types';
import { getDocumentTypeLabel } from '@common/helpers';
import { useFormContext } from 'react-hook-form';
import { IProductForm, ProductForm } from '../../../hooks/useProductForm';
import { getDocumentAttributes, shouldAllowMultipleRowSelect } from '@common/features/document/helpers';
import { HtmlContentModal, useHtmlContentModal } from '@common/components';

interface IProps {
  documents: IDocumentDto[];
  disableContentSelection?: boolean;
  disableUpdateDocumentTitle?: boolean;
  type: DocumentType;
  onSelect: (documentId: number, type: DocumentType) => void;
  onFileOpen?: (documentId: Id) => void;
  onDocumentTitleClick?: (documentId: Id) => void;
}

export const AssociatedContentTable = ({
  documents,
  disableContentSelection,
  disableUpdateDocumentTitle,
  type,
  onSelect,
  onFileOpen,
  onDocumentTitleClick,
}: IProps) => {
  const { watch } = useFormContext<IProductForm>();
  const { htmlContentModalParams, closeHtmlContentModal, handleAssociatedContentHtmlModalOpen } = useHtmlContentModal();

  const ids = watch(ProductForm.associatedContent).map((ac) => ac.id);

  let indices: number[] = [];

  ids.forEach((id) => {
    const index = documents.findIndex((d) => d.documentId === id);
    if (index !== -1) {
      indices.push(index);
    }
  });

  const handleSelect = (rowIndex: number) => {
    const documentId = documents[rowIndex]?.documentId;
    onSelect(documentId, type);
  };

  const handleViewHtmlClick = (html: string) => {
    handleAssociatedContentHtmlModalOpen(html);
  };

  const documentAttributes = getDocumentAttributes(type, false);

  const columns = prepareColumns({
    onFileOpen,
    onDocumentTitleClick,
    onViewHtmlClick: handleViewHtmlClick,
    disableUpdateDocumentTitle,
    documentAttributes,
  });

  const label = getDocumentTypeLabel(type);

  const subText = `These are all your draft and published ${label}s, published ${label}s are live on the emc; newly uploaded ${label}s are
displayed as draft. ${shouldAllowMultipleRowSelect(type) ? '' : `A product can only have one ${label}.`}`;

  return (
    <>
      <Heading>{label}</Heading>
      <Subtext>{subText}</Subtext>
      <SectionWrapper>
        {documents.length === 0 ? (
          <NoContentPanel
            title="No content found"
            helpMessage="How do I add content?"
            helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
          />
        ) : (
          <TableWrapper>
            <DatapharmTable
              documents={documents}
              columns={columns}
              selectedRowsIndices={indices}
              onRowSelectionChange={handleSelect}
              readonly={disableContentSelection}
              hasSelectableRows={documentAttributes.allowMultipleSelect ? 'multiple' : 'single'}
              data-testid="associated-content-table"
            />
          </TableWrapper>
        )}
      </SectionWrapper>
      <HtmlContentModal modalParams={htmlContentModalParams} onClose={() => closeHtmlContentModal()} />
    </>
  );
};
