import { RegisterOptions, useFormContext } from 'react-hook-form';
import { IInput } from '../Input/Input';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';

interface IHookFormRichTextEditor extends IInput {
  className?: string;
  rules?: RegisterOptions<any, string>;
  onChangeValue?: () => void;
  formField: string;
  toolbarOptions?: unknown[];
}

export const HookFormRichTextEditor = ({
  className,
  rules,
  onChangeValue,
  formField,
  toolbarOptions,
}: IHookFormRichTextEditor) => {
  const { setValue, watch, register } = useFormContext();

  if (rules) {
    register(formField, {
      ...rules,
    });
  }

  const handleChange = (value: any) => {
    var val = value === '<p></p>' ? undefined : value;
    setValue(formField, val, { shouldValidate: true, shouldDirty: true });
    onChangeValue && onChangeValue();
  };

  const value = watch(formField);

  return (
    <RichTextEditor
      fieldName={formField}
      value={value}
      onChange={handleChange}
      toolbarOptions={toolbarOptions}
      className={className}
    />
  );
};
