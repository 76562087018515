import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;


export const ConfirmationHeading = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.label};
  margin-bottom: 5px;
`;

