import React, { useMemo, useState } from 'react';
import * as Styled from './styles';
import { useFetchProductsTree } from '../hooks/useFetchProductsTree';
import { SearchInput } from '@common/components';
import { useSearch } from '@common/components/SearchInput/useSearch';
import { SectionWrap } from '@common/components/Section/styles';
import { useFormContext } from 'react-hook-form';
import { FormFields } from '../types';
import { prepareColumns } from './helpers';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import NoContentPanel from '@common/components/NoContentPanel/NoContentPanel';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';

const MAX_PRODUCT_COUNT = 50;

interface Props {
  showUnknownProductGroup: () => void;
  isProductGroupUnknown: (groupId: string) => boolean;
}

const UpdateProduct = ({ showUnknownProductGroup, isProductGroupUnknown }: Props) => {
  const { setValue } = useFormContext();

  const [selectedProductIndicies, setSelectedProductIndices] = useState<number[]>([]);

  const { searchPhrase, displayPhrase, searchError, handleChangeSearch, handleClearSearch } = useSearch();

  const { data, isFetchingProductsTree } = useFetchProductsTree(searchPhrase);
  const { goToPublishedProductGroup } = useAppNavigation();

  const sortedData = data?.sort((d1, d2) => (d1.name.toLowerCase() >= d2.name.toLowerCase() ? 1 : -1));

  const columns = useMemo(
    () =>
      prepareColumns(data?.length > MAX_PRODUCT_COUNT, (id) => {
        if (isProductGroupUnknown(id)) showUnknownProductGroup();
        else goToPublishedProductGroup(id);
      }),
    [data?.length, goToPublishedProductGroup, isProductGroupUnknown, showUnknownProductGroup],
  );

  const handleRowSelected = (rowIndex: number) => {
    const group = sortedData[rowIndex];
    setSelectedProductIndices([rowIndex]);
    setValue(FormFields.selectedProductGroup, group);
  };

  const handleChangeSearchAndClearSelectedProducts = (e: any) => {
    setSelectedProductIndices([]);
    handleChangeSearch(e);
  };

  const handleClearSearchAndClearSelectedProducts = () => {
    setSelectedProductIndices([]);
    handleClearSearch();
  };

  return (
    <Styled.CreateSubmissionOffsetWrap>
      <SectionWrap>
        <Styled.SearchInputWrapper>
          <SearchInput
            name="globalSearch"
            id="globalSearch"
            onChange={handleChangeSearchAndClearSelectedProducts}
            autoComplete="off"
            value={displayPhrase}
            label="Search"
            onClearInput={handleClearSearchAndClearSelectedProducts}
            error={searchError}
          />
        </Styled.SearchInputWrapper>
        <Styled.TableWrapper>
          {isFetchingProductsTree || sortedData.length > 0 ? (
            <DatapharmTable
              columns={columns}
              documents={sortedData.filter((_, index) => index < MAX_PRODUCT_COUNT)}
              onRowSelectionChange={handleRowSelected}
              showLoader={isFetchingProductsTree}
              selectedRowsIndices={selectedProductIndicies}
              hasSelectableRows={'single'}
            />
          ) : !isFetchingProductsTree && data && data.length === 0 ? (
            <NoContentPanel title="No results found" />
          ) : null}
        </Styled.TableWrapper>
      </SectionWrap>
    </Styled.CreateSubmissionOffsetWrap>
  );
};

export default UpdateProduct;
