import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { Textarea } from '../Textarea/styles';

export const TitleIcon = styled(ReactSVG)`
  svg {
    width: 70px;
    height: auto;
  }

  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  white-space: pre;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 1rem;
  white-space: pre;
`;

export const Content = styled.div`
  ${Textarea} {
    max-width: 51rem;
    width: 70vw;
    height: 11rem;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
  margin-bottom: 1rem;
`;
