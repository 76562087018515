import {
  IRegulatorApprovalOptions,
  RegulatorApprovalLabels,
  RegulatorApprovalValues,
} from '@common/features/document/components/RegulatorApproval/types';
import { IProductDetailsSection } from './types';
import { ProductForm } from '@common/features/product/components/ProductContent/hooks/useProductForm';

export const regulatorApprovalOptions: IRegulatorApprovalOptions[] = [
  { label: RegulatorApprovalLabels.MHRA, value: RegulatorApprovalValues.MHRA },
  { label: RegulatorApprovalLabels.EMA, value: RegulatorApprovalValues.EMA },
];

export const getProductDetailsSections = (
  shouldDisplayAmpId: boolean,
  shouldDisplayContactGroup: boolean,
): IProductDetailsSection[] => {
  const sections = [
    { name: 'MA Numbers', fieldName: ProductForm.maNumbers },
    { name: 'Legal categories', fieldName: ProductForm.legalCategories },
    { name: 'Black triangle', fieldName: ProductForm.hasBlackTriangle },
    { name: 'Allergens and Pregnancy risk information', fieldName: ProductForm.allergensAndPregnancyRisk },
    { name: 'ATC code', fieldName: ProductForm.atcCodes },
  ];

  if (shouldDisplayAmpId) {
    sections.push({ name: 'AMP id', fieldName: ProductForm.ampId });
  }

  if (shouldDisplayContactGroup) {
    sections.push({ name: 'Contact Group', fieldName: ProductForm.contactGroup });
  }

  return sections;
};
