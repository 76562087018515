import { useState } from 'react';
import { SubmissionDataType, useRemoveSubmissionData } from './useRemoveSubmissionData';

export interface IProps {
  type: SubmissionDataType;
  onSuccess: () => void;
}

export const useRemoveModal = ({ type, onSuccess }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeDetails, setRemoveDetails] = useState({
    submissionId: '',
    id: '',
    title: '',
  });

  const { mutate } = useRemoveSubmissionData(removeDetails.submissionId, removeDetails.id, type);

  const handleConfirm = () => {
    mutate(null, {
      onSuccess: () => {
        setIsModalOpen(false);
        onSuccess();
      },
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (submissionId: string, id: string, title: string) => {
    setIsModalOpen(true);
    setRemoveDetails({
      id,
      submissionId,
      title,
    });
  };

  return {
    handleCancel,
    handleConfirm,
    handleOpenModal,
    isModalOpen,
    removeDetails,
  };
};
