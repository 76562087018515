import { useFormContext } from 'react-hook-form';
import { numericValidationPattern } from '@common/components/validation';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { HookFormSelect } from '@common/components/Select/HookFormSelect';
import { IIngredientWithNumerator } from '@common/features/product/types';
import { IActiveIngredientsStrengthPicker } from './types';
import { FormInput } from '@common/components/FormInput/styles';
import * as Styled from './styles';
import { StyledLabel } from '@common/components/Input/styles';
import { useFetchUnitsOfMeasurement } from '@common/hooks/useFetchUnitsOfMeasurement';
import { formatActiveIngredientName } from '../ActiveIngredientsSelection/hooks/useActiveIngredientSelection/helpers';

const numeratorValidation = {
  required: 'Please enter value',
  pattern: numericValidationPattern,
};

const denominatorValidation = {
  pattern: numericValidationPattern,
};

export const ActiveIngredientsStrengthPicker = ({
  activeIngredients,
  fieldName,
  disabled,
  readonlyNumeratorsWhenInitialData = false,
  errors: externalErrors,
}: IActiveIngredientsStrengthPicker) => {
  const { unitsOfMeasurement, isFetching } = useFetchUnitsOfMeasurement();

  const {
    formState: { errors: formErrors },
    watch,
  } = useFormContext();

  const denominatorUnitRules = {
    validate: () =>
      watch(`${fieldName}.denominator`)['denominatorValue'] !== '' &&
      watch(`${fieldName}.denominator`)['denominatorUnit']?.label === ''
        ? 'Please select units'
        : undefined,
  };

  const errors: any = externalErrors ?? formErrors[fieldName];

  const renderActiveIngredients = () => {
    const renderedContent: IIngredientWithNumerator[] = watch(`${fieldName}.ingredientsWithNumerators`);

    return renderedContent?.map((item, index: number) => {
      const numeratorError = errors?.ingredientsWithNumerators && errors?.ingredientsWithNumerators[index]?.['numeratorValue'];
      const denominatorError = errors?.denominator && errors?.denominator['denominatorValue'];
      const numeratorUnitError = errors?.ingredientsWithNumerators && errors?.ingredientsWithNumerators[index]?.['numeratorUnit'];
      const denominatorUnitError = errors?.denominator && errors?.denominator['denominatorUnit'];

      const isNumeratorWithInitialValue =
        readonlyNumeratorsWhenInitialData && !!activeIngredients.find((x) => x.name === item.name)?.numeratorValue;

      const isNumeratorWithInitialUnit =
        readonlyNumeratorsWhenInitialData && !!activeIngredients.find((x) => x.name === item.name)?.numeratorUnit;

      const isDenominatorWithInitialValue = readonlyNumeratorsWhenInitialData
        ? !!activeIngredients.find((x) => x.name === item.name)?.denominatorValue
        : true;

      const isDenominatorWithInitialUnit = readonlyNumeratorsWhenInitialData
        ? !!activeIngredients.find((x) => x.name === item.name)?.denominatorUnit
        : true;

      const numeratorUnitRules = {
        validate: () =>
          watch(`${fieldName}.ingredientsWithNumerators`)[index]?.numeratorValue !== '' &&
          watch(`${fieldName}.ingredientsWithNumerators`)[index]?.numeratorUnit.label === ''
            ? 'Please select units'
            : undefined,
      };

      const isDenominatorActive = !readonlyNumeratorsWhenInitialData && index === 0;

      return (
        <Styled.ActiveIngredient key={index}>
          <Styled.ActiveIngredientName>
            <FormInput disabled={true} name={`${fieldName}.name`} value={formatActiveIngredientName(item.name)} />
          </Styled.ActiveIngredientName>
          <Styled.InputSection>
            <HookFormInput
              name={`${fieldName}.ingredientsWithNumerators.${index}.numeratorValue`}
              rules={numeratorValidation}
              error={!disabled && numeratorError?.message}
              disabled={disabled || isNumeratorWithInitialValue}
            />
            <HookFormSelect
              name={`${fieldName}.ingredientsWithNumerators.${index}.numeratorUnit`}
              options={unitsOfMeasurement}
              rules={numeratorUnitRules}
              error={!disabled && numeratorUnitError?.message}
              disabled={disabled || isNumeratorWithInitialUnit}
              isLoading={isFetching}
              allowCustomOption
            />
          </Styled.InputSection>
          {isDenominatorActive ? (
            <Styled.InputSection>
              <HookFormInput
                name={`${fieldName}.denominator.denominatorValue`}
                rules={denominatorValidation}
                error={!disabled && denominatorError?.message}
                disabled={disabled}
              />
              <HookFormSelect
                name={`${fieldName}.denominator.denominatorUnit`}
                options={unitsOfMeasurement}
                rules={denominatorUnitRules}
                error={!disabled && denominatorUnitError?.message}
                disabled={disabled}
                isLoading={isFetching}
                allowCustomOption
              />
            </Styled.InputSection>
          ) : (
            <Styled.InputSection>
              <HookFormInput name={`${fieldName}.denominator.denominatorValue`} disabled={isDenominatorWithInitialValue} />
              <HookFormSelect
                name={`${fieldName}.denominator.denominatorUnit`}
                options={unitsOfMeasurement}
                disabled={isDenominatorWithInitialUnit}
                isLoading={isFetching}
                allowCustomOption
              />
            </Styled.InputSection>
          )}
        </Styled.ActiveIngredient>
      );
    });
  };

  return (
    <>
      {activeIngredients.length > 0 && (
        <Styled.ActiveIngredientsStrength>
          <Styled.Labels>
            <StyledLabel>Active Ingredient (s)</StyledLabel>
            <StyledLabel>Ingredient strength</StyledLabel>
            <StyledLabel>Per</StyledLabel>
          </Styled.Labels>
          <Styled.ActiveIngredientsList>{renderActiveIngredients()}</Styled.ActiveIngredientsList>
        </Styled.ActiveIngredientsStrength>
      )}
    </>
  );
};
