import { SideDecorationContent } from '@common/components/SideDecorationContent/SideDecorationContent';
import * as Styled from './styles';
import * as CommonStyles from '@common/styles';
import { Section } from '@common/components/Section/Section';

export const DocumentContentSkeletonLoader = () => {
  return (
    <Styled.SmpcDetailsWrap>
      <CommonStyles.SubmissionFlowWrap>
        <CommonStyles.SubmissionFlowInner>
          <Styled.SmpcDetailsHeadingWrap>
            <Styled.SmpcDetailsHeadingText>
              <CommonStyles.RectangleSkeletonLoader height="3rem" width="20rem" />
            </Styled.SmpcDetailsHeadingText>
          </Styled.SmpcDetailsHeadingWrap>
          <SideDecorationContent>
            <Section>
              <CommonStyles.RectangleSkeletonLoader />
            </Section>
            <Section>
              <CommonStyles.RectangleSkeletonLoader />
            </Section>
            <Section>
              <CommonStyles.RectangleSkeletonLoader />
            </Section>
          </SideDecorationContent>
        </CommonStyles.SubmissionFlowInner>
      </CommonStyles.SubmissionFlowWrap>
    </Styled.SmpcDetailsWrap>
  );
};
