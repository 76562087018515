import { useFormContext } from 'react-hook-form';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';

export const useContractGroupSelection = () => {
  const { setValue, clearErrors, watch } = useFormContext<IProductForm>();

  const contractGroupToAdd = watch(ProductFormPaths.contactGroupInput) ?? '';
  const selectedContactGroup = watch(ProductFormPaths.contactGroup) || null;

  const addContractGroup = (contractGroup: string) => {
    setValue(ProductFormPaths.contactGroup, contractGroup);
  };

  const removeContactGroup = (index: number) => {
    setValue(ProductFormPaths.contactGroup, '');
  };

  const clearContactGroupInputError = () => clearErrors(ProductFormPaths.contactGroupInput);

  const handleContractGroupAdd = () => {
    if (contractGroupToAdd.length > 0) {
      addContractGroup(contractGroupToAdd);
      setValue(ProductFormPaths.contactGroupInput, '');
      clearContactGroupInputError();
    }
  };
  const handleContactGroupRemove = (index: number) => {
    removeContactGroup(index);
  };

  return {
    contractGroupToAdd,
    selectedContactGroup,
    handleContractGroupAdd,
    handleContactGroupRemove,
  };
};
