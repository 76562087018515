import { useAppSelector } from '@hooks/redux';
import { selectToken } from '@features/user/selectors';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { softDeleteSubmission } from '@services/submissionService';

export const useSoftDeleteSubmission = () => {
  const token = useAppSelector(selectToken);

  const { mutate, isLoading } = useWaitingMutation((submissionId: number) => softDeleteSubmission(token, submissionId));

  return { removeSubmission: mutate, isRemovingSubmission: isLoading };
};
