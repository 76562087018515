import { IAnchorParams, ResponseContentType } from '@common/types';
import { FileRoles } from '@common/features/document/types';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { useQuery } from '@tanstack/react-query';
import { downloadTaskFileByRole, fetchSubmissionTaskDetailsByDocumentAndType } from '@common/services/submissionService';
import { DocumentType } from '@common/types'
import { useState } from 'react';

const getFileName = (contentDisposition?: string) => contentDisposition?.split('filename=')[1].split(';')[0] as string;

export interface IContextHandleTaskFileByRole {
  alternativeTextFileName: string;
  openAlternativeTextFile: () => void;
};

export const useHandleTaskFileByRole = (submissionId: string, documentId: string, role: FileRoles) => {
  const [contextHandleFile, setContextHandleFile] = useState<IContextHandleTaskFileByRole>();

  const getAnchorParams = (response: any): IAnchorParams => {
    const fileName = getFileName(response?.headers['content-disposition'] as string) || 'Document file';
    const cleanFileName = fileName.replace(/"/g, '');
    const contentType = response?.headers['content-type'] as string;
    const file = new File([response.data], cleanFileName, { type: contentType });

    const url = window.URL.createObjectURL(file);

    return {
      url,
      cleanFileName,
      contentType,
    };
  };

  const openFile = (fileInfo: IAnchorParams) => {
    const a = document.createElement('a');
    a.href = fileInfo.url;
    if (fileInfo.contentType === ResponseContentType.docx || fileInfo.contentType === ResponseContentType.csv) {
      a.download = fileInfo.cleanFileName;
    }
    a.target = '_blank';
    a.click();
    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(fileInfo.url);
    }, 100);
  };

  const token = useAppSelector(selectToken);

  useQuery<any, any>(
    ['fetchSubmissionTaskDetailsByDocumentAndType', submissionId, documentId, DocumentType.Epil],
    () => {
      return fetchSubmissionTaskDetailsByDocumentAndType(token, submissionId, documentId, DocumentType.Epil)
        .then(
          (value) => {
            return downloadTaskFileByRole(token, value.submissionTaskId, FileRoles.AlternativeText)
              .then(
                (value) => {
                  const fileInfo = getAnchorParams(value);

                  setContextHandleFile({
                    alternativeTextFileName: fileInfo.cleanFileName,
                    openAlternativeTextFile: () => { openFile(fileInfo); }
                  });
                  return {};
                },
                (reason) => {
                  return {};
                }
              );
          },
          (reason) => {
            return {};
          }
        );
    }
  );

  return {
    contextHandleFile
  };
};
