import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@hooks/redux';
import { selectToken } from '@features/user/selectors';
import { fetchMaNumbersConfig } from '@common/services/productService';

export const queryKey = 'fetchMaNumbersConfig';

export const useFetchMaNumbersConfig = () => {
  const token = useAppSelector(selectToken);

  const { data, isFetching: isFetchingMaNumbersConfig } = useQuery([queryKey], () => fetchMaNumbersConfig(token), {
    enabled: !!token,
  });

  return {
    maNumbersConfig: data,
    isFetchingMaNumbersConfig,
  };
};
