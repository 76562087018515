import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { Button, ButtonTypes, ProductGroupNameWithSubstances } from '@common/components';

import { IProductFamily } from '../../../features/submission/types';
import { IProductGroupTableEntry } from '../types';

import { IProductGroupProduct } from '@common/features/submission/types';

export const prepareColumns = (limitReached: boolean, onDetailsClick: (id: string) => void) => {
  const columnHelper = createColumnHelper<IProductGroupTableEntry>();

  return [
    columnHelper.accessor((row) => row.name, {
      header: 'Product Group',
      cell: (item) => {
        const substances = item.row.original.substances;
        return <ProductGroupNameWithSubstances productGroupName={item.getValue()} substances={substances} />;
      },
    }),
    columnHelper.accessor('products', {
      header: 'Product(s)',
      cell: (info) => {
        const value: IProductGroupProduct[] = info.getValue();
        return (
          <ul style={{ paddingLeft: 30 }}>
            {value.map((v) => (
              <li key={v.id}>{v.displayName}</li>
            ))}
          </ul>
        );
      },
    }),
    columnHelper.accessor('familyName', {
      header: 'Product Family',
    }),
    columnHelper.accessor('type', {
      header: 'Product Type',
    }),
    columnHelper.accessor((row) => row.id, {
      header: 'Details',
      cell: (item) => (
        <Button
          type={ButtonTypes.TEXT}
          text=""
          icon="/icons/eye.svg"
          height={25}
          onClick={() => onDetailsClick(item.getValue())}
        />
      ),
    }),
  ];
};

export const mapTreeToTableEntries = (productsTree: IProductFamily[]) => {
  return productsTree
    .map((productFamily) => {
      return productFamily.productGroups.map((productGroup) => {
        const entry: IProductGroupTableEntry = {
          ...productGroup,
          familyName: productFamily.name,
          type: productFamily.type,
          familyId: productFamily.id,
          substances: productGroup.substances,
        };
        return entry;
      });
    })
    .reduce((a, b) => a.concat(b), []);
};
