import { SubmissionTaskStatusesEnum, SubmissionTaskStatusesCustomerEnum } from '@common/services/types';
import { SubmissionTaskStatusLabels, SubmissionTaskStatusCustomerLabels } from './types';

export const submissionTaskStatusOptions = [
  { id: 1, text: SubmissionTaskStatusLabels.WithProduction, value: SubmissionTaskStatusesEnum.WithProduction },
  { id: 2, text: SubmissionTaskStatusLabels.WithQC, value: SubmissionTaskStatusesEnum.WithQC },
  { id: 3, text: SubmissionTaskStatusLabels.WithDataQuality, value: SubmissionTaskStatusesEnum.WithDataQuality },
  {
    id: 4,
    text: SubmissionTaskStatusLabels.WithClientMoreInformation,
    value: SubmissionTaskStatusesEnum.WithClientMoreInformation,
  },
  { id: 5, text: SubmissionTaskStatusLabels.WithClientApproval, value: SubmissionTaskStatusesEnum.WithClientApproval },
  { id: 6, text: SubmissionTaskStatusLabels.Approved, value: SubmissionTaskStatusesEnum.Approved },
  { id: 7, text: SubmissionTaskStatusLabels.Cancelled, value: SubmissionTaskStatusesEnum.Cancelled },
  { id: 8, text: SubmissionTaskStatusLabels.Published, value: SubmissionTaskStatusesEnum.Published },
  { id: 9, text: SubmissionTaskStatusLabels.Draft, value: SubmissionTaskStatusesEnum.Draft },
];

export const submissionTaskStatusCustomerOptions = [
  { id: 1, text: SubmissionTaskStatusCustomerLabels.NeedsAttention, value: SubmissionTaskStatusesCustomerEnum.NeedsAttention },
  { id: 2, text: SubmissionTaskStatusCustomerLabels.WithDatapharm, value: SubmissionTaskStatusesCustomerEnum.WithDatapharm },
  { id: 3, text: SubmissionTaskStatusCustomerLabels.NeedsApproval, value: SubmissionTaskStatusesCustomerEnum.NeedsApproval },
  { id: 4, text: SubmissionTaskStatusCustomerLabels.Approved, value: SubmissionTaskStatusesCustomerEnum.Approved },
  { id: 5, text: SubmissionTaskStatusCustomerLabels.Published, value: SubmissionTaskStatusesCustomerEnum.Published },
  { id: 6, text: SubmissionTaskStatusCustomerLabels.Cancelled, value: SubmissionTaskStatusesCustomerEnum.Cancelled },
];
