import { useQuery } from '@tanstack/react-query';
import { downloadTaskFileByRole } from '@common/services/submissionService';
import { useAppSelector } from './redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';

export const useDownloadTaskFileByRole = (taskId: Id, fileRole: string) => {
  const token = useAppSelector(selectToken);

  const { data, refetch } = useQuery(['downloadTaskFileByRole', taskId], () => downloadTaskFileByRole(token, taskId, fileRole), {
    enabled: false,
  });

  return { taskFileByRoleResponse: data, refetchDownloadTaskFileByRole: refetch };
};
