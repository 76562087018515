import React, { useState } from 'react';
import { BrandOrGenericName } from '../../containers/BrandOrGenericName/BrandOrGenericName';
import { ActiveIngredientsSelection } from '@components/ActiveIngredientsSelection/ActiveIngredientsSelection';
import { SideDecorationContent } from '@common/components/SideDecorationContent/SideDecorationContent';
import { useFetchActiveIngredients } from '@hooks/useFetchActiveIngredients';
import { useActiveIngredientSelection } from '@common/components/ActiveIngredientsSelection/hooks/useActiveIngredientSelection';
import * as Styled from './styles';

import { StyledLink } from '@common/components/Link/styles';
import { useFormContext } from 'react-hook-form';
import { FormFields } from '../../pages/CreateSubmission/types';

export const CreateProduct = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { activeIngredients, isFetchingActiveIngredients } = useFetchActiveIngredients(searchTerm);
  const {
    handleActiveIngredientAdd,
    handleActiveIngredientInputChange,
    handleActiveIngredientRemove,
    handleDraftActiveIngredientChange,
    handleNoActiveIngredientsChange,
    activeIngredientToAdd,
  } = useActiveIngredientSelection({
    activeIngredientsFieldName: FormFields.activeIngredients,
    noActiveIngredientsCheckboxFieldName: FormFields.noActiveIngredientsCheckbox,
  });

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const selectedActiveIngredients = watch(FormFields.activeIngredients);
  const isNoActiveIngredientsChecked = watch(FormFields.noActiveIngredientsCheckbox);
  const noActiveIngredientsError = errors[FormFields.noActiveIngredientsCheckbox]?.message?.toString();
  const alreadyExistsError = errors[FormFields.activeIngredients]?.message?.toString();

  return (
    <>
      <Styled.CreateSubmissionHeadingWrap>
        <Styled.CreateSubmissionTextHeadingText>Create a Product Group</Styled.CreateSubmissionTextHeadingText>
        <Styled.CreateSubmissionTextHeadingSubtext>
          A product group consists of products that have the same Brand/Generic name and active ingredients, a product group can
          have more than one presentation provided it has the same active ingredients
          <StyledLink to={'help'}> Learn more &gt;</StyledLink>
        </Styled.CreateSubmissionTextHeadingSubtext>
      </Styled.CreateSubmissionHeadingWrap>
      <Styled.CreateSubmissionCompactWrap>
        <SideDecorationContent>
          <BrandOrGenericName />
          <ActiveIngredientsSelection
            activeIngredients={activeIngredients}
            isFetchingActiveIngredients={isFetchingActiveIngredients}
            activeIngredientToAdd={activeIngredientToAdd}
            selectedActiveIngredients={selectedActiveIngredients}
            isNoActiveIngredientsChecked={isNoActiveIngredientsChecked}
            noActiveIngredientsError={noActiveIngredientsError}
            alreadyExistsError={alreadyExistsError}
            onActiveIngredientAdd={handleActiveIngredientAdd}
            onActiveIngredientRemove={handleActiveIngredientRemove}
            onActiveIngredientInputChange={(value, action) => {
              setSearchTerm(value);
              handleActiveIngredientInputChange(value, action);
            }}
            onNoActiveIngredientsChange={handleNoActiveIngredientsChange}
            onDraftActiveIngredientChange={handleDraftActiveIngredientChange}
          />
        </SideDecorationContent>
      </Styled.CreateSubmissionCompactWrap>
    </>
  );
};
