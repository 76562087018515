import { Button, ButtonTypes } from '@common/components';
import * as Styled from './styles';
import { ErrorLog } from '@common/types';
import { UnderlinedLink } from '@common/components/UnderlinedLink/styles';

interface IError {
  title: string;
  items: ErrorLog[];
  linkText: string;
  helpLink: string;
  onClick?: (id: number) => void;
}

export const MultipleErrors = ({ title, items, linkText, helpLink, onClick }: IError) => (
  <Styled.Wrapper>
    <Styled.Icon src={'/icons/errorBig.svg'} />
    <Styled.Content>
      <Styled.Title>{title}</Styled.Title>
      {items.map((i) => (
        <Styled.ItemWrapper key={i.itemId}>
          <Styled.Text key={i.itemId}>
            {i?.itemName && <Styled.SubTitle key={i.itemId}>{i.itemName}</Styled.SubTitle>}
            {i?.subError?.split('\r\n').map((x) => (
              <Styled.Item key={x + i.itemId}>{x}</Styled.Item>
            ))}
          </Styled.Text>
          {onClick && <Button onClick={() => onClick(i?.itemId)} text="Update" type={ButtonTypes.SUCCESS} />}
        </Styled.ItemWrapper>
      ))}
      <UnderlinedLink to={helpLink} target="_blank">
        {linkText}
      </UnderlinedLink>
    </Styled.Content>
  </Styled.Wrapper>
);
