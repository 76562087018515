import React, { useState } from 'react';
import { SearchInputWrap, InputStyled, SearchIcon, ClearIcon, ComponentWrap, StyledLabel } from './styles';
import { ErrorMessage } from '../ErrorMessage';

type AutoComplete = 'on' | 'off';

interface ISearchInput {
  name: string;
  value: string;
  id: string;
  placeholder?: string;
  label?: string;
  width?: number;
  height?: number;
  autoComplete?: AutoComplete;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInput?: () => void;
}

export const SearchInput = ({
  name,
  value,
  id,
  placeholder = 'Search...',
  label,
  width,
  height,
  autoComplete = undefined,
  error,
  onChange,
  onClearInput,
}: ISearchInput) => {
  const [isFocused, setIsFocused] = useState(false);

  const isActive = isFocused || !!value;

  const showClearIcon = value && onClearInput;

  return (
    <ComponentWrap>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <SearchInputWrap isActive={isActive}>
        <SearchIcon />
        <InputStyled
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          width={width}
          height={height}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isActive={isActive}
          autoComplete={autoComplete}
        />
        {showClearIcon && <ClearIcon onClick={onClearInput} />}
      </SearchInputWrap>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </ComponentWrap>
  );
};
