import * as msal from '@azure/msal-browser';

export const msalConfig: msal.Configuration = {
  auth: {
    protocolMode: msal.ProtocolMode.OIDC,
    clientId: `${process.env.REACT_APP_OIDC_CLIENT_ID}`,
    authority: process.env.REACT_APP_AUTH_URL,
    redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/`,
    postLogoutRedirectUri: `${process.env.REACT_APP_PUBLIC_URL}/`,
    knownAuthorities: [`${process.env.REACT_APP_AUTH_URL}`],
  },
};

export const loginScopes = process.env.REACT_APP_AUTH_SCOPES!.split(',');
