import { InputWrap } from '@common/styles';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { CheckboxWrapper } from './styles';

interface IProps {
  value: boolean | null;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

export const YesNoCheckbox = ({ value, disabled, onChange = () => {}}: IProps) => {
  return (
    <>
      <InputWrap>
        <CheckboxWrapper>
          <CheckboxOption
            item={{ id: 0, text: 'Yes' }}
            name="yes"
            isChecked={value === true}
            onChange={() => onChange(true)}
            disabled={disabled}
          />
          <CheckboxOption
            item={{ id: 1, text: 'No' }}
            name="no"
            isChecked={value === false}
            onChange={() => onChange(false)}
            disabled={disabled}
          />
        </CheckboxWrapper>
      </InputWrap>
    </>
  );
};
