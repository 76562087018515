import styled from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';

export const ProductSectionWrap = styled.div`
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.elements.borderGrey};

  ${ErrorMessage} {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

export const ProductSectionInner = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 40px;
`;

export const HeadingArea = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
`;

export const Heading = styled.h2`
  font-size: ${({ theme }) => theme.elementFontSizes.subSectionTitle};
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Subtext = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
`;