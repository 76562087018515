import React from 'react';
import { ISubstanceDto } from '@common/features/submission/types';

interface IProductGroupNameWithSubstances {
  productGroupName: string;
  substances: ISubstanceDto[];
}

export const ProductGroupNameWithSubstances = ({ productGroupName, substances }: IProductGroupNameWithSubstances) => {
  const name = `${productGroupName}${getSubstanceNames(substances)}`;

  return <div>{name}</div>;
};

export const getSubstanceNames = (substances: ISubstanceDto[]) => {
  if (!substances || substances.length < 1) {
    return '';
  }

  const sortedSubstances = substances.sort((s1, s2) => (s1.name.toLowerCase() > s2.name.toLowerCase() ? 1 : -1));

  return ` (${sortedSubstances.map((x) => x.name).join(', ')})`;
};
