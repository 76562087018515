import styled from "styled-components";
import { ReactSVG } from "react-svg";

interface IWrap {
  dragActive?: boolean;
}

export const Wrap = styled.div<IWrap>`
  width: 496px;
  padding: 14px 21px 17px 21px;
  background-color: ${({ theme }) => theme.colors.veryLightBlue};
  color: ${({ theme }) => theme.elements.textGrey};
  border-radius: 10px;
`;

export const Top = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const Left = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
`;

export const FileIcon = styled(ReactSVG)`
  margin-top: 4px;
  margin-right: 8px;
`;

export const CancelIcon = styled(ReactSVG)`
  cursor: pointer;
`;

export const Info = styled.div`
`

export const FileName = styled.div`
  margin-bottom: 3px;
`;

export const FileSize = styled.div`
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -6px;
`;

export const Progress = styled.div``
