import React, { ReactNode } from 'react';
import { TabContentWrapper, Tab, TabsLabelsWrapper, TabsWrapper, TabLabel } from './styles';
import { RectangleSkeletonLoader } from '@common/styles';

interface ITabLoader {
  selected?: boolean;
}

const TabLoader = ({ selected = true }: ITabLoader) => (
  <Tab selected={selected}>
    <TabLabel>
      <RectangleSkeletonLoader height="1rem" width="10rem" />
    </TabLabel>
  </Tab>
);

interface ITabs {
  children: ReactNode;
}

const TabsSkeletonLoader = ({ children }: ITabs) => {
  return (
    <TabsWrapper>
      <TabsLabelsWrapper>
        <TabLoader />
        <TabLoader selected={false} />
        <TabLoader selected={false} />
      </TabsLabelsWrapper>
      <TabContentWrapper>{children}</TabContentWrapper>
    </TabsWrapper>
  );
};

export default TabsSkeletonLoader;
