import { useEffect, useRef, useState } from 'react';

export const useDelayedLoader = (showPleaseWaitLoader: boolean) => {
  const [showDelayedLoader, setShowDelayedLoader] = useState(false);

  let timeoutId = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (showPleaseWaitLoader) {
      timeoutId.current = setTimeout(() => setShowDelayedLoader(true), 500);
    } else {
      !!timeoutId.current && clearTimeout(timeoutId.current);
      setShowDelayedLoader(false);
    }
  }, [showPleaseWaitLoader]);

  return { showDelayedLoader };
};
