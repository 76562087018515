import React from 'react';
import { Select } from '@common/components';

import { TextWithIcon } from '@common/components/TextWithIcon/TextWithIcon';

import * as Styled from './styles';
import { StyledSVG } from '@common/styles';
import { DatePickerWrapper } from '@common/components/DatePickerWrapper/DatePickerWrapper';
import { IRegulatorApproval } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { DocumentType } from '@common/types';
import { FormInput } from '@common/components/FormInput/styles';
import { getDocumentAttributes } from '../../helpers';
import { ConfirmationWrapper } from '../ConfirmationWrapper';
import { documentTypeToCustomLabelMap } from '@common/constants';

interface IReadonlyRegulatorApproval {
  contentType: DocumentType;
  approved: boolean;
  regulator: string;
  approvalDate: Date | null;
  firstAuthorisationDate: Date | null;
}

export const ReadonlyRegulatorApproval = ({
  contentType,
  approved,
  regulator,
  approvalDate,
  firstAuthorisationDate,
}: IReadonlyRegulatorApproval) => {
  const documentAttributes = getDocumentAttributes(contentType);

  return (
    <Styled.RegulatorApprovalWrap>
      <TextWithIcon icon={<StyledSVG src={`/icons/info_circle.svg`} />}>
        <Styled.ConfirmationHeading>Please confirm that this document has been approved</Styled.ConfirmationHeading>
      </TextWithIcon>
      <div>
        <ConfirmationWrapper
          name="Regulator confirmation"
          value={approved}
          onChange={() => {}}
          text={`I confirm that this version of the ${documentTypeToCustomLabelMap[contentType]} has been approved by the MHRA or EMA or is a minor variation (Type IA or IAIN)*`}
          disabled
        />
      </div>
      <Styled.Inputs>
        <FormInput name={ContentDetailsFormField.approvedByRegulator} label="Regulator" value={regulator} disabled={true} />
        {documentAttributes.showRegulatorApprovalDate && (
          <DatePickerWrapper
            value={approvalDate}
            name={ContentDetailsFormField.regulatorApprovalDate}
            label="Regulator approval date"
            onChange={() => {}}
            disabled={true}
          />
        )}

        {documentAttributes.showAuthorisedDate && (
          <DatePickerWrapper
            name={ContentDetailsFormField.authorisedDate}
            label={`Date of first authorisation or ${contentType} date of revision (most recent)`}
            onChange={() => {}}
            value={firstAuthorisationDate}
            disabled={true}
          />
        )}
      </Styled.Inputs>
    </Styled.RegulatorApprovalWrap>
  );
};

export const RegulatorApproval = ({ regulatorApprovalOptions, contentType, documentReadonlySections }: IRegulatorApproval) => {
  const { control } = useFormContext();

  const maxPickerDate = new Date();

  const documentAttributes = getDocumentAttributes(contentType);

  return (
    <Styled.RegulatorApprovalWrap>
      <TextWithIcon icon={<StyledSVG src={`/icons/info_circle.svg`} />}>
        <Styled.ConfirmationHeading>Please confirm that this document has been approved</Styled.ConfirmationHeading>
      </TextWithIcon>
      <Controller
        control={control}
        name={ContentDetailsFormField.isRegulatorApproved}
        render={({ field: { value, onChange } }) => (
          <ConfirmationWrapper
            name="Regulator confirmation"
            disabled={documentReadonlySections?.approved}
            value={value}
            onChange={onChange}
            text={`I confirm that this version of the ${documentTypeToCustomLabelMap[contentType]} has been approved by the MHRA or EMA or is a minor variation (Type IA or IAIN)*`}
          />
        )}
      />
      <Styled.Inputs>
        <Controller
          control={control}
          name={ContentDetailsFormField.approvedByRegulator}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="Please select"
              label="Regulator"
              name="test"
              value={value}
              onChange={onChange}
              options={regulatorApprovalOptions}
            />
          )}
        />

        {documentAttributes.showRegulatorApprovalDate && (
          <Controller
            control={control}
            name={ContentDetailsFormField.regulatorApprovalDate}
            render={({ field: { value, onChange } }) => (
              <DatePickerWrapper
                value={value}
                maxDate={maxPickerDate}
                name="Regulator approval date"
                label="Regulator approval date"
                onChange={onChange}
              />
            )}
          />
        )}

        {documentAttributes.showAuthorisedDate && (
          <Controller
            control={control}
            name={ContentDetailsFormField.authorisedDate}
            render={({ field: { value, onChange } }) => (
              <DatePickerWrapper
                name="First authorisation date"
                label={`Date of first authorisation or ${contentType} date of revision (most recent)`}
                onChange={onChange}
                value={value}
                maxDate={maxPickerDate}
              />
            )}
          />
        )}
      </Styled.Inputs>
    </Styled.RegulatorApprovalWrap>
  );
};
