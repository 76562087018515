import theme from '@common/theme';
import { ISelectOptions } from './SelectAll';
import { CSSObjectWithLabel, ControlProps, OptionProps, StylesConfig, MenuPlacement } from 'react-select';

export const createSelectOption = (value: string, id?: number) => {
  const selectOption = {
    label: value,
    value: value,
    id: id,
  };

  return selectOption;
};

export const getEnabledValuesAndOptions = (values: ISelectOptions, options: ISelectOptions) => {
  const enabledSelectedValues = values?.filter((v: any) => options?.find((o) => o.value === v.value && !o.disabled));
  const enabledOptions = options?.filter((o) => !o.disabled) ?? [];
  const areAllEnabledOptionsSelected = enabledSelectedValues?.length === enabledOptions.length;

  return { enabledOptions, enabledSelectedValues, areAllEnabledOptionsSelected };
};

export const getCommonStyles = (readonly: boolean = false, width?: string): StylesConfig => ({
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
  option: (baseStyles: CSSObjectWithLabel, { isSelected, isFocused, isDisabled }: OptionProps) => ({
    ...baseStyles,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.elements.borderGrey}`,
    backgroundColor: isSelected
      ? theme.colors.lightBlue
      : isFocused
      ? theme.colors.mediumBlue
      : isDisabled
      ? theme.colors.superlightGrey
      : theme.colors.defaultWhite,
    color: isSelected ? theme.elements.dropdownGrey : isFocused ? theme.elements.defaultWhite : theme.elements.dropdownGrey,
    padding: '10px 5px 10px 15px',
    cursor: isDisabled ? 'default' : 'pointer',
    '&:hover': {
      color: isDisabled ? theme.elements.dropdownGrey : isSelected ? theme.elements.dropdownGrey : theme.elements.defaultWhite,
    },
    '&:first-of-type': {
      borderRadius: '10px 10px 0 0',
    },
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 10px 10px',
    },
    '.selected-notifier': {
      visibility: isSelected ? 'visible' : 'hidden',
    },
  }),
  dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: readonly ? 'grey' : 'black',
    '&:hover': {
      color: 'black',
    },
  }),
  control: (baseStyles: CSSObjectWithLabel, { menuIsOpen, isFocused }: ControlProps) => ({
    ...baseStyles,
    borderColor: menuIsOpen || isFocused ? theme.colors.backgroundBlue : theme.colors.lightGrey,
    ...(readonly && { backgroundColor: theme.elements.readonlyInputBackground }),
    borderRadius: '10px',
    padding: '4px 5px',
    width: width ?? baseStyles.width,
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    border: `2px solid ${theme.colors.mediumBlue}`,
    borderRadius: '10px',
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: '0px',
    overflowX: 'hidden',
    borderRadius: '10px',
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: readonly ? 'black' : 'grey',
  }),
});

export const getCommonProps = (noOptionsMessage: string) => ({
  getOptionLabel: (e: any) => e.label,
  getOptionValue: (e: any) => e.value,
  noOptionsMessage: ({ inputValue }: any) => (!inputValue ? 'No options' : noOptionsMessage),
  menuPortalTarget: document.getElementById('portal-root'),
  menuShouldBlockScroll: true,
  menuPlacement: 'auto' as MenuPlacement,
  minMenuHeight: 188,
  maxMenuHeight: 470,
});
