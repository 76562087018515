import styled from 'styled-components';

export const NewsTableWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ISeparatedItem {
  addSeparator: boolean;
}

export const NewsItem = styled.div<ISeparatedItem>`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  border-bottom: ${({ addSeparator }) => (addSeparator ? 1 : 0)}px solid ${({ theme }) => theme.elementFontSizes.primary};
  margin-bottom: ${({ addSeparator }) => (addSeparator ? '10px' : 'unset')};
  padding-bottom: ${({ addSeparator }) => (addSeparator ? '10px' : 'unset')};
`;

export const NewsName = styled.div`
  flex-basis: 80%;
  font-size: ${({ theme }) => theme.elementFontSizes.pageContent};
  font-weight: 600;
`;

export const NewsDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const NewsDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
`;
