import { SidebarEntryWrapper, StyledLink, IconWrapper } from './styles';
import { useLocation } from 'react-router-dom';

interface ISidebarEntry {
  to: string;
  display: string;
  getIcon: any;
}

const SidebarEntry = ({ to, display, getIcon }: ISidebarEntry) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  return (
    <StyledLink to={to}>
      <SidebarEntryWrapper selected={isSelected}>
        <IconWrapper selected={isSelected}>{getIcon()}</IconWrapper>
        {display}
      </SidebarEntryWrapper>
    </StyledLink>
  );
};

export default SidebarEntry;
