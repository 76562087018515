import React from 'react';
import { ComponentWrap, InputWrap, InputStyled, StyledLabel, ClearIcon } from './styles';

export interface IInput {
  name: string;
  value?: string;
  inputType?: string;
  disabled?: boolean;
  label?: string;
  labelEndSign?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  width?: number;
  height?: number;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInput?: () => void;
  min?: string;
  ['data-testid']?: string;
}

export const Input = ({
  name,
  value,
  inputType,
  disabled = false,
  label,
  labelEndSign,
  id,
  placeholder,
  className,
  width,
  height,
  onChange,
  onBlur,
  onClearInput,
  min,
  ...props
}: IInput) => {
  const showClearIcon = onClearInput && value;
  const isReadonly = !onChange;
  return (
    <ComponentWrap className={className}>
      {label && (
        <StyledLabel htmlFor={id}>
          {label}
          {labelEndSign && <span>{labelEndSign}</span>}
        </StyledLabel>
      )}
      <InputWrap width={width} height={height} disabled={disabled} className={isReadonly ? 'readonly' : ''}>
        {!isReadonly && (
          <InputStyled
            id={id}
            disabled={disabled}
            name={name}
            type={inputType}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            min={min}
            data-testid={props['data-testid']}
          />
        )}

        {isReadonly && <InputStyled name={name} defaultValue={value} readOnly={true} id={id} />}
        {showClearIcon && <ClearIcon onClick={onClearInput} />}
      </InputWrap>
    </ComponentWrap>
  );
};
