import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { validateSubmission } from '@common/services/submissionService';

export const useValidateSubmission = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation((submissionId: string) => validateSubmission(token, submissionId));

  return { validateSubmission: mutate };
};
