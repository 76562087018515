import { convertObjectToQueryString } from '@common/filterFormHelpers';
import { getIsoStringWithTimezoneHandling } from '@common/helpers';
import { ICheckboxEntry } from '@common/types';
import { useState } from 'react';

export const useFilters = (initialValues: any, clearAllValues: any, fieldsToAccumulate?: string[]) => {
  const [defaultValues, setDefaultValues] = useState(initialValues);
  const [dirtyValues, setDirtyValues] = useState(initialValues);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filtersQueryString, setFiltersQueryString] = useState(convertObjectToQueryString(initialValues, fieldsToAccumulate));

  const updateQueryString = (data: any) => setFiltersQueryString(convertObjectToQueryString(data, fieldsToAccumulate));

  const handleConfirmFilters = () => {
    setDefaultValues(dirtyValues);
    updateQueryString(dirtyValues);
  };

  const handleCancelFilters = () => {
    setIsFiltersOpen(false);
    setDirtyValues(defaultValues);
  };

  const handleToggleFilters = () => {
    setIsFiltersOpen((prevValue) => !prevValue);
  };

  const handleClearAllFilters = () => {
    setDefaultValues(clearAllValues);
    setDirtyValues(clearAllValues);
    updateQueryString(clearAllValues);
  };

  const setterFn = (fields: string[], value: any) => (prev: any) => {
    const clonedPrevValues = JSON.parse(JSON.stringify(prev));
    fields.forEach((f) => {
      clonedPrevValues[f] = value;
    });

    return clonedPrevValues;
  };

  const handleClear = (fieldsToSet: string[], defaultValue: [] | null) => {
    setDirtyValues(setterFn(fieldsToSet, defaultValue));
    setDefaultValues(setterFn(fieldsToSet, defaultValue));

    let fieldsWithValues: any = {};
    fieldsToSet.forEach((f) => (fieldsWithValues[f] = defaultValue));

    updateQueryString({
      ...defaultValues,
      ...fieldsWithValues,
    });
  };

  const getCheckboxValue = (field: string, item: ICheckboxEntry) => {
    const clonedValues = JSON.parse(JSON.stringify(dirtyValues[field]));
    const isSelected = !!clonedValues.find((v: any) => v.id === item.id);

    if (isSelected) {
      return clonedValues?.filter((v: any) => v.id !== item.id);
    } else {
      clonedValues?.push(item);
      return clonedValues;
    }
  };

  const setCheckboxValue = (field: string, item: any) => setDirtyValues(setterFn([field], getCheckboxValue(field, item)));
  const setNonCheckboxValue = (field: string, item: any) => {
    if (item instanceof Date) {
      const isoStringDate = getIsoStringWithTimezoneHandling(item);
      return setDirtyValues(setterFn([field], isoStringDate));
    }
    return setDirtyValues(setterFn([field], item));
  };

  return {
    isFiltersOpen,
    dirtyValues,
    filtersQueryString,
    handleConfirmFilters,
    handleCancelFilters,
    handleToggleFilters,
    handleClearAllFilters,
    handleClear,
    setCheckboxValue,
    setNonCheckboxValue,
  };
};
