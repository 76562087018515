import styled from 'styled-components';

export const MainContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Top = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentWraper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const DefaultContent = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
`;

export const CustomContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
