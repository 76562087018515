import React from 'react';
import { ViewHtml } from '@common/pages/ViewHtml/ViewHtml';
import useAppNavigation from '../../routing/useAppNavigation';

export const ViewHtmlCustomer = () => {
  const { goToSubmissions } = useAppNavigation();

  const handleContinue = () => {
    goToSubmissions();
  };

  return <ViewHtml onContinue={handleContinue} />;
};
