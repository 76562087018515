import { documentTitleErrorMessage } from '@common/components/DocumentTitle/constants';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { FieldErrors } from 'react-hook-form';
import * as SharedStyled from '@common/styles';
import * as Styled from './styles';

interface IProps {
  fieldName: string;
  disabled?: boolean;
  label?: string;
  errors?: FieldErrors<any>;
  onClearInput?: () => void;
}

export const Weblink = ({ fieldName, label = 'WebLink', disabled, errors, onClearInput }: IProps) => (
  <Styled.TitleWrap>
    <span>{label}</span>
    <Styled.EditableUrlWrapper>
      <SharedStyled.StyledSVG src={`/icons/globe.svg`} />
      <HookFormInput
        disabled={disabled}
        labelEndSign="*"
        name={fieldName}
        id="documentWebLinkInput"
        placeholder="Web link"
        onClearInput={onClearInput}
        error={!!errors ? errors[fieldName]!.message?.toString() : undefined}
        rules={{
          maxLength: {
            value: 200,
            message: documentTitleErrorMessage,
          },
        }}
      />
    </Styled.EditableUrlWrapper>
  </Styled.TitleWrap>
);
