import { StyledSVG } from '@common/styles';
import styled, { css } from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const TabsLabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

const selectedStyle = css`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  color: ${({ theme }) => theme.elements.primaryMedium};
  border-top: 3px solid ${({ theme }) => theme.elements.primaryMedium};
  height: 41px;
  margin-bottom: -1px;
  padding-bottom: 3px;
`;

const unselectedStyle = css`
  background-color: ${({ theme }) => theme.elements.tabBackgroundGrey};
  color: ${({ theme }) => theme.elements.textGrey};
  border-top: 1px solid ${({ theme }) => theme.elements.borderGrey};
  height: 40px;
  padding: 3px 0;
`;

interface ITabProps {
  selected: boolean;
}

export const Tab = styled.div<ITabProps>`
  display: flex;
  justify-content: center;
  width: 200px;
  margin-right: 15px;
  ${({ selected }) => (selected ? selectedStyle : unselectedStyle)};
  border-left: 1px solid ${({ theme }) => theme.elements.borderGrey};
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
  cursor: pointer;

  :first-child {
    margin-left: 20px;
  }
`;

export const TabLabel = styled.span`
  font-weight: 600;
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  cursor: pointer;
`;

export const TabContentWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
`;

export const TabIcon = styled(StyledSVG)`
  display: flex;
  align-items: center;
  svg {
    path {
      width: 20px;
      height: 20px;
    }
  }
`;
