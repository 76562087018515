import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const TitleIcon = styled(ReactSVG)`
  svg {
    width: 70px;
    height: auto;
  }

  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.subSectionTitle};
  font-weight: 600;
  margin-bottom: 15px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
