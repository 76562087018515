import * as Styled from './styles';
import { useFormContext } from 'react-hook-form';
import { isTruthySelectOptions } from '@common/constants';
import { Select } from '../Select/Select';
import { ProductSection } from '../ProductSection/ProductSection';
import {
  IProductForm,
  ProductForm,
  ProductFormPaths,
} from '@common/features/product/components/ProductContent/hooks/useProductForm';

interface IProps {
  readonly?: boolean;
}

export const BlackTriangle = ({ readonly }: IProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const withBlackTriangleError = !readonly && !!errors.productDetails?.hasBlackTriangle;

  const handleChange = (e: any) => {
    setValue(ProductFormPaths.hasBlackTriangle, e, { shouldValidate: true });
  };

  return (
    <Styled.Wrapper>
      <ProductSection
        elementId={ProductForm.hasBlackTriangle}
        heading="Black triangle"
        subtext="Medicines under additional monitoring have a black inverted triangle displayed in their package leaflet and summary of product characteristics, together with a short sentence explaining what the triangle means."
        withError={withBlackTriangleError}
      >
        <Select
          placeholder=""
          label={`Does this product have a black triangle (\u25BC)?`}
          name={`${ProductForm.hasBlackTriangle}.select`}
          value={watch(ProductFormPaths.hasBlackTriangle) ?? ''}
          onChange={handleChange}
          options={isTruthySelectOptions}
          disabled={readonly}
        />
      </ProductSection>
    </Styled.Wrapper>
  );
};
