import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { dateTimeFormat, dateFormat } from '@common/constants';
import { IDocumentHistoryNote, IDocumentHistoryVersion } from './model/IDocumentHistoryVersion';
import DocumentStatus from '@common/components/DocumentStatus/DocumentStatus';
import { DocumentStatus as DocumentStatusType } from '@common/types';
import { Button, ButtonTypes } from '@common/components';

export const prepareDocumentColumns = (onContentClick: (taskId: number) => void) => {
  const columnHelper = createColumnHelper<Omit<IDocumentHistoryVersion, 'expandableContent' | 'documentNotes'>>();

  return [
    columnHelper.accessor('title', {
      header: 'Title',
    }),
    columnHelper.accessor('content', {
      header: 'Content',
      cell: ({ row }) => {
        var value = row.original.content;
        return <Button type={ButtonTypes.TEXT} text={value} height={25} onClick={() => onContentClick(row.original.taskId)} />;
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => <DocumentStatus value={info.getValue() as DocumentStatusType} />,
    }),
    columnHelper.accessor('version', {
      header: 'Version',
    }),
    columnHelper.accessor('regulatorApproval', {
      header: 'Regulator approval',
      cell: ({ getValue }) => (getValue() === null ? '-' : !!getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor('regulatorApprovalDate', {
      header: 'Regulator approval date',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? format(new Date(value), dateFormat) : '-';
      },
    }),
    columnHelper.accessor('approvedBy', {
      header: 'Approved by',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('approvedDate', {
      header: 'Approved date',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? format(new Date(value), dateTimeFormat) : '-';
      },
    }),
    columnHelper.accessor('retiredBy', {
      header: 'Retired by',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('retiredDate', {
      header: 'Retired date',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? format(new Date(value), dateTimeFormat) : '-';
      },
    }),
  ];
};

export const prepareDetailsColumns = () => {
  const columnHelper = createColumnHelper<IDocumentHistoryNote>();
  return [
    columnHelper.accessor('description', {
      header: 'Description',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('notes', {
      header: 'Notes',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('from', {
      header: 'From',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('to', {
      header: 'To',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
    columnHelper.accessor('date', {
      header: 'Date',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? format(new Date(value), dateTimeFormat) : '-';
      },
    }),
    columnHelper.accessor('performedBy', {
      header: 'Performed by',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? value : '-';
      },
    }),
  ];
};
