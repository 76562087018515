import React from 'react';
import * as Styled from './styles';

export interface ITextWithIcon {
  children: any;
  icon?: JSX.Element;
}

export const TextWithIcon = ({ children, icon }: ITextWithIcon) => {
  return (
    <Styled.TextAndIconWrapper>
      {children}
      &nbsp;{icon}
    </Styled.TextAndIconWrapper>
  );
};
