import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { IAtcCode } from '@common/features/product/types';
import { ProductDetailsFormErrors } from '../types';
import { atcCodePattern } from '@common/constants';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';

export const useAtcCodesSelection = () => {
  const { getValues, setValue, clearErrors, setError, watch, trigger } = useFormContext<IProductForm>();

  const atcCodeToAdd = watch(ProductFormPaths.atcCodeInput) ?? '';
  const selectedAtcCodes = watch(ProductFormPaths.atcCodes) || [];
  const noAtcCodes = watch(ProductFormPaths.noAtcCodes);

  useEffect(() => {
    trigger(ProductFormPaths.atcCodes);
  }, [noAtcCodes, trigger]);

  const addAtcCode = (atcCode: IAtcCode) => {
    const atcCodes = getValues(ProductFormPaths.atcCodes) ?? [];
    setValue(ProductFormPaths.atcCodes, [...atcCodes, atcCode]);
  };

  const removeAtcCode = (index: number) => {
    const atcCodes = getValues(ProductFormPaths.atcCodes) ?? [];
    setValue(
      ProductFormPaths.atcCodes,
      atcCodes.filter((_, i) => i !== index),
      { shouldValidate: true },
    );
  };

  const clearAtcCodesInputError = () => clearErrors(ProductFormPaths.atcCodeInput);

  const isMatchingFormat = (atcCode: string) => {
    const reg = new RegExp(atcCodePattern);
    return atcCode.match(reg);
  };

  const handleAtcCodeAdd = () => {
    const atcCodes: IAtcCode[] = getValues(ProductFormPaths.atcCodes) ?? [];

    const actCodeToAddWithOutSpaces = atcCodeToAdd?.trim().toUpperCase();

    const alreadyAdded = atcCodes.find((i) => i.code.toLowerCase() === actCodeToAddWithOutSpaces.toLowerCase());

    if (alreadyAdded) {
      setError(ProductFormPaths.atcCodeInput, {
        type: 'alreadyAdded',
        message: ProductDetailsFormErrors.atcCodeAlreadyAdded,
      });
      return;
    }

    if (actCodeToAddWithOutSpaces.length > 0 && !alreadyAdded && isMatchingFormat(actCodeToAddWithOutSpaces)) {
      addAtcCode({ code: actCodeToAddWithOutSpaces });
      setValue(ProductFormPaths.atcCodeInput, '');
      clearAtcCodesInputError();
      clearErrors([ProductFormPaths.atcCodes]);
    }

    if (!isMatchingFormat(actCodeToAddWithOutSpaces))
      setError(ProductFormPaths.atcCodeInput, {
        type: 'invalidFormat',
        message: ProductDetailsFormErrors.atcCodesInvalidFormat,
      });
  };
  const handleAtcCodeRemove = (index: number) => {
    removeAtcCode(index);
  };

  const handleNoAtcCodesChange = () => {
    const isChecked = getValues(ProductFormPaths.noAtcCodes);
    if (!isChecked) {
      setValue(ProductFormPaths.atcCodeInput, '');
    }
    clearAtcCodesInputError();
    setValue(ProductFormPaths.noAtcCodes, !isChecked);
  };

  return {
    atcCodeToAdd,
    selectedAtcCodes,
    noAtcCodes,
    handleAtcCodeAdd,
    handleAtcCodeRemove,
    handleNoAtcCodesChange,
  };
};
