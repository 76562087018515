import { DocumentType } from '@common/types';

export const smpcRetireReasonOptions = [
  { id: 109, text: 'Change of distributer for product' },
  { id: 162, text: 'Company ceased trading' },
  { id: 47, text: 'Change company name or merger' },
  { id: 164, text: 'Individual SmPC/PIL superseded by joint SmPC/PIL' },
  { id: 105, text: 'Joint SmPC/PIL superseded by Individual SmPC/PIL' },
  { id: 159, text: 'Licence withdrawn' },
  { id: 180, text: 'Product/presentation currently not marketed' },
  { id: 49, text: 'Product/presentation discontinued' },
  { id: 54, text: 'Retired pending re-submission' },
  { id: 55, text: 'Submitted in error' },
  { id: 152, text: 'Transfer of Marketing Authorisation holder' },
  { id: 109, text: 'Change of distributor for the product' },
  { id: 107, text: 'Change to Marketing Authorisation Holder address' },
  { id: 181, text: 'Presentation currently sold unlicensed' },
];

export const pilRetireReasonOptions = [
  { id: 108, text: 'Change of distributer for product' },
  { id: 161, text: 'Company ceased trading' },
  { id: 51, text: 'Change company name or merger' },
  { id: 150, text: 'Individual SmPC/PIL superseded by joint SmPC/PIL' },
  { id: 147, text: 'Joint SmPC/PIL superseded by Individual SmPC/PIL' },
  { id: 158, text: 'Licence withdrawn' },
  { id: 179, text: 'Product/presentation currently not marketed' },
  { id: 53, text: 'Product/presentation discontinued' },
  { id: 56, text: 'Retired pending re-submission' },
  { id: 57, text: 'Submitted in error' },
  { id: 151, text: 'Transfer of Marketing Authorisation holder' },
  { id: 108, text: 'Change of distributor for the product' },
  { id: 107, text: 'Change to Marketing Authorisation Holder address' },
  { id: 178, text: 'Presentation currently sold unlicensed' },
];

type DocumentTypeToReasonOption = { [key in DocumentType]?: { id: number; text: string }[] };

export const getRetireReasonOptions = (contentType: DocumentType) => {
  const retireReasonsByContentType: DocumentTypeToReasonOption = {
    [DocumentType.Smpc]: smpcRetireReasonOptions,
    [DocumentType.Pil]: pilRetireReasonOptions,
  };

  return retireReasonsByContentType[contentType]!
    ? retireReasonsByContentType[contentType]!.map((r) => ({ id: r.id, value: r.text, label: r.text }))
    : [];
};
