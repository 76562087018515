import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { fetchSubmissionTaskDetailsByDocumentAndType } from '@common/services/submissionService';
import { DocumentType, ISubmissionTaskDto } from '../../types';
import { Id } from '@common/types';

export const queryKey = 'fetchSubmissionTaskByDocument';

export const useFetchSubmissionTaskByDocument = (submissionId: string, documentId: Id, contentType: DocumentType) => {
  const token = useAppSelector(selectToken);

  const {
    data: submissionTaskData,
    isFetching: isFetchingSubmissionTaskByDocument,
    isFetched: isSubmissionTaskByDocumentFetched,
  } = useQuery<ISubmissionTaskDto>(
    [queryKey],
    () => fetchSubmissionTaskDetailsByDocumentAndType(token, submissionId, documentId, contentType),
    { enabled: !!token && !!submissionId && !!documentId && !!contentType },
  );

  return {
    submissionTaskData,
    isFetchingSubmissionTaskByDocument,
    isSubmissionTaskByDocumentFetched,
  };
};
