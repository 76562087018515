import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { ISubmissionStateDiffDto } from '@common/features/submission/types';
import { fetchSubmissionTaskProductGroupData } from '@common/services/submissionTaskService';

export const queryKey = 'fetchTaskProductGroupData';

export const useFetchTaskProductGroupData = (taskId: string) => {
  const token = useAppSelector(selectToken);

  const { data, isFetching, refetch } = useQuery<ISubmissionStateDiffDto>(
    [queryKey],
    () => fetchSubmissionTaskProductGroupData(token, taskId),
    { enabled: !!token && !!taskId },
  );

  return {
    productGroupWithTaskApplied: data,
    isFetchingTaskProductGroup: isFetching,
    refetchTaskProductGroupData: refetch,
  };
};
