import styled from 'styled-components';

export const RadioOption = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.div`
  margin-left: 5px;
`;
