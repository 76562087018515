import { ICheckboxEntry } from './types';

export const convertObjectToQueryString = (data: any, enumFieldsToAccumulate?: string[]) => {
  const arrayData = convertFiltersObjectToArray(data);
  const parsedData = arrayData
    .map(([prefix, value]) => {
      if (Array.isArray(value)) return prepareValueEntryQueryStringValues(prefix, value, enumFieldsToAccumulate);
      if (typeof value === 'object' && value !== null) return convertObjectToUrl(prefix, value);

      return (value || value === false) && `${prefix}=${value}`;
    })
    .join('&');

  return parsedData;
};

const convertFiltersObjectToArray = (data: any) =>
  Object.entries(data).filter(([prefix, value]) => {
    if (Array.isArray(value)) {
      return value && value.length > 0;
    }
    return (value || value === false) && (value as any).toString();
  });

const prepareValueEntryQueryStringValues = (prefix: string, values: ICheckboxEntry[], enumFieldsToAccumulate?: string[]) => {
  if (enumFieldsToAccumulate?.includes(prefix)) {
    const enumValue = values.map((value) => value.value as number).reduce((acc, current) => acc + current, 0);
    return `${prefix}=${enumValue}`;
  }

  const queryStringEntries = values.map((value) => `${prefix}=${value.value}`);
  return queryStringEntries.join('&');
};

const convertObjectToUrl = (prefix: string, value: any) => {
  if (value instanceof Date)
    return `${prefix}=${new Date(
      Date.UTC((value as Date).getFullYear(), (value as Date).getMonth(), (value as Date).getDate()),
    ).toISOString()}`;
  if (value.id !== undefined && value.id !== null) return `${prefix}Id=${value.id}`;
  if (value.value !== undefined && value.value !== null) return `${prefix}Id=${value.value}`;

  return `${prefix}=${value}`;
};
