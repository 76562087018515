import { createSlice } from '@reduxjs/toolkit';
import { ICompanyPayload, ICompanyPermission, IUserState } from './types';
import { fetchIdentityUserData, fetchUserData } from './thunks';
import { storeDataLayerValue } from '@common/services/dataLayerService';

export const initialState: IUserState = {
  token: '',
  isLoading: false,
  error: '',
  userData: {
    user: {
      userId: null,
      name: '',
      email: '',
    },
    isAdmin: false,
  },
  companyPermissions: [],
  activeCompany: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getToken(state, action) {
      const token = action.payload;
      state.token = token;
    },
    setActiveCompany(state, action) {
      const userId = action.payload.userId;
      localStorage.setItem(userId, JSON.stringify(action.payload));
      state.activeCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyPermissions = action.payload.companyPermissions;
      state.activeCompany = handleActiveCompany(action.payload);
      storeDataLayerValue({ userId: action.payload.user.userId });
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchIdentityUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIdentityUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userData.user.name = action.payload.user_name;
      state.userData.user.userId = action.payload.user_id;
      state.userData.user.email = action.payload.email;
    });
    builder.addCase(fetchIdentityUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const handleActiveCompany = (companyPayload: ICompanyPayload) => {
  const userId = companyPayload.user.userId?.toString() as string;

  if (!localStorage.getItem(userId)) {
    storeAndReturnDefaultPermission(companyPayload);
  }

  const storedUserData = JSON.parse(localStorage.getItem(userId) as string);
  const hasPermissionForStoredCompany = hasUserCompanyPermission(companyPayload.companyPermissions, storedUserData.id);

  if (!hasPermissionForStoredCompany) {
    storeAndReturnDefaultPermission(companyPayload);
  }

  return storedUserData;
};

const hasUserCompanyPermission = (permissions: ICompanyPermission[], companyId: number) => {
  const matchedCompanies = permissions.filter((item) => item.id === companyId);
  return matchedCompanies.length > 0;
};

const storeAndReturnDefaultPermission = (companyPayload: ICompanyPayload) => {
  const userId = companyPayload.user.userId?.toString() as string;
  localStorage.setItem(userId, JSON.stringify(companyPayload.companyPermissions[0]));

  return companyPayload.companyPermissions[0];
};

export const { getToken, setActiveCompany } = userSlice.actions;

export default userSlice.reducer;
