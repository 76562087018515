import { KeyboardEventHandler } from 'react';
import { default as ReactSelect, InputActionMeta, components } from 'react-select';
import { ISelectOption, INewProductSelectOption } from '@common/types';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';
import { getCommonStyles, getCommonProps } from './helpers';

export type ISelectOptions = (ISelectOption | INewProductSelectOption)[];
export type ISingleSelectOption = ISelectOption | INewProductSelectOption;

interface ISelect {
  label?: string;
  name: string;
  value: ISingleSelectOption | '';
  options?: ISelectOptions;
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  className?: string;
  onChange?: (e: any) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onKeyDown?: (e: any) => void;
  noOptionsMessage?: string;
}

const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    {props.data.label}
    <StyledSVG className="selected-notifier" src={`/icons/tick.svg`} />
    {props.data.isCustomOption && <Styled.CustomOption isFocused={props.isFocused}>Add</Styled.CustomOption>}
  </Option>
);

export const Select = ({
  label,
  name,
  options,
  id,
  value,
  disabled,
  readonly,
  isClearable,
  isLoading,
  placeholder,
  onChange,
  onInputChange = () => {},
  onKeyDown = () => {},
  className,
  noOptionsMessage = '',
}: ISelect) => {
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    onKeyDown(event);

    if (event.key === 'Enter') {
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  return (
    <Styled.Select data-testid="select-wrapper" className={className}>
      {label && <Styled.StyledLabel htmlFor={id}>{label}</Styled.StyledLabel>}
      <ReactSelect
        {...getCommonProps(noOptionsMessage)}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        options={options}
        isClearable={isClearable}
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={handleKeyDown}
        isDisabled={disabled || readonly}
        isLoading={isLoading}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
        styles={getCommonStyles(readonly)}
      />
    </Styled.Select>
  );
};
