import { Button, ButtonTypes } from '@common/components';
import { AssociatedContentCell } from './components/AssociatedContentCell';
import ProductStatus from '@components/ProductStatus/ProductStatus';
import { getActions } from '../../../helpers';
import {
  ChangeType,
  DiffDto,
  DocumentState,
  IDocumentDto,
  IProductChangesDto,
  IProductDto,
  ProductStatus as ProductStatusEnum,
} from '@common/features/submission/types';
import { createColumnHelper } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { dateTimeFormatNoSeconds } from '@common/constants';
import { AllergyAndPregnancyRiskInfo } from '@common/components/AllergyAndPregnancyRiskInfo';
import { ActionType, Dictionary, SubmissionTaskType } from '@common/types';
import { getLegalCategories } from '@components/LegalCategories/helpers';
import { CellWithChanges } from '@common/components/CellWithChanges/CellWithChanges';
import { canUpdateProductDetailsInTask } from '@common/features/document/helpers';

export interface ISubmissionNote {
  description: string;
  from: string;
  to: string;
  id: number;
  performedBy: string;
  text: string;
  submittedAt: string;
}

const ProductsTableColumnKeys = {
  productName: 'productName',
  linkedSmpc: 'linkedSmpc',
  linkedPil: 'linkedPil',
  blackTriangle: 'blackTriangle',
  legalCategories: 'legalCategories',
  maNumbers: 'maNumbers',
  atcCodes: 'atcCodes',
  allergyAndPregnancyRiskInfo: 'allergyAndPregnancyRiskInfo',
  associatedContent: 'associatedContent',
  productId: 'productId',
  status: 'status',
  details: 'details',
  actions: 'actions',
};

export const getProductsTableColumnData = (
  isReadonly: boolean,
  removeButtonText: string,
  showDocumentCountChanges: boolean,
  onEditXml: () => void,
  onProductUpdate: (productId: string) => void,
  onProductRemove: (productId: string) => void,
  onDetailsClick: (productId: string) => void,
) =>
  [
    {
      accessor: ProductsTableColumnKeys.productName,
      columnName: 'Product Name',
      cell: ({ getValue }: any) => <CellWithChanges value={getValue().value} changeType={getValue().changeType} />,
    },
    {
      accessor: ProductsTableColumnKeys.linkedSmpc,
      columnName: 'Linked SmPC',
      cell: ({ getValue }: any) => {
        return <CellWithChanges value={getValue().value} changeType={getValue().changeType} />;
      },
    },
    {
      accessor: ProductsTableColumnKeys.linkedPil,
      columnName: 'Linked PIL',
      cell: ({ getValue }: any) => {
        return <CellWithChanges value={getValue().value} changeType={getValue().changeType} />;
      },
    },
    {
      accessor: ProductsTableColumnKeys.blackTriangle,
      columnName: () => <div style={{ fontSize: '20px' }}>{'\u25BC'}</div>,
      cell: ({ getValue }: any) => <CellWithChanges value={getValue().value ? 'Yes' : 'No'} changeType={getValue().changeType} />,
    },
    {
      accessor: ProductsTableColumnKeys.legalCategories,
      columnName: 'Legal categories',
      cell: (info: any) => renderLegalCategories(info.getValue()),
    },
    {
      accessor: ProductsTableColumnKeys.maNumbers,
      columnName: 'MA Numbers',
      cell: (info: any) => {
        const maNumbers = info.getValue();
        return (
          <>
            {Object.keys(maNumbers).map((ma) => (
              <CellWithChanges value={ma} changeType={maNumbers[ma]} />
            ))}
          </>
        );
      },
    },
    {
      accessor: ProductsTableColumnKeys.atcCodes,
      columnName: 'ATC Codes',
      cell: (info: any) => {
        const atcCodes = info.getValue();
        return (
          <>
            {Object.keys(atcCodes).map((atc) => (
              <CellWithChanges value={atc} changeType={atcCodes[atc]} />
            ))}
          </>
        );
      },
    },
    {
      accessor: ProductsTableColumnKeys.allergyAndPregnancyRiskInfo,
      columnName: 'Allergy and Pregnancy risk info',
      cell: (info: any) => {
        const { isGlutenFree, isLactoseFree, isPregnancyRisk, isSuitableForVegans } = info.getValue();
        return (
          <AllergyAndPregnancyRiskInfo
            isGlutenFree={isGlutenFree.value}
            isLactoseFree={isLactoseFree.value}
            isPregnancyRisk={isPregnancyRisk.value}
            isSuitableForVegans={isSuitableForVegans.value}
            glutenFreeChangeType={isGlutenFree.changeType}
            lactoseFreeChangeType={isLactoseFree.changeType}
            pregnancyRiskChangeType={isPregnancyRisk.changeType}
            suitableForVegansChangeType={isSuitableForVegans.changeType}
          />
        );
      },
    },
    {
      accessor: ProductsTableColumnKeys.associatedContent,
      columnName: 'Associated content',
      cell: (info: any) => <AssociatedContentCell showChanges={showDocumentCountChanges} documentsData={info.getValue()} />,
    },
    { accessor: ProductsTableColumnKeys.productId, columnName: 'Product ID' },
    {
      accessor: ProductsTableColumnKeys.status,
      columnName: 'Status',
      cell: (info: any) => <ProductStatus value={info.getValue()} />,
    },
    {
      accessor: ProductsTableColumnKeys.details,
      columnName: 'Details',
      cell: (info: any) => {
        const productId = info.row.original.productId;
        const handleDetailsClick = () => onDetailsClick(productId);
        return <Button onClick={handleDetailsClick} type={ButtonTypes.TEXT} text="" icon="/icons/eye.svg" height={25} />;
      },
    },
    !isReadonly
      ? {
          accessor: ProductsTableColumnKeys.actions,
          columnName: 'Actions',
          cell: (info: any) => {
            const productId = info.row.original.productId;
            const handleUpdate = () => onProductUpdate(productId);
            const handleRemove = () => onProductRemove(productId);
            return getActions(info.getValue(), removeButtonText, onEditXml, () => {}, handleUpdate, handleRemove, 'products');
          },
        }
      : undefined,
  ].filter((x) => x);

const renderLegalCategories = (legalCategories: Dictionary<ChangeType>) => (
  <>
    {Object.keys(legalCategories).map((lc) => {
      const value = getLegalCategories(lc);

      return <CellWithChanges key={lc} value={value} changeType={legalCategories[lc]} />;
    })}
  </>
);

export const mapToProductsData = (
  products: IProductDto[] | undefined,
  documents: IDocumentDto[],
  submissionTaskType : SubmissionTaskType,
  productsChanges?: IProductChangesDto[],
) => {
  if (!products) {
    return [];
  }

  const filteredDocuments = (productId: number) => documents.filter((item) => item.linkedProductIds.includes(productId));

  return products.map((product) => {
    const isPublished = product.status === ProductStatusEnum.Published;

    const productChanges = productsChanges?.find((pc) => pc.productId === product.productId);

    const productName = productChanges?.productName ?? { value: product.name, changeType: ChangeType.Unchanged };
    const blackTriangle = productChanges?.blackTriangle ?? { value: product.hasBlackTriangle, changeType: ChangeType.Unchanged };
    const legalCategories = Object.fromEntries((product?.legalCategories ?? []).map((lc) => [lc, ChangeType.Unchanged]));
    const maNumbers = Object.fromEntries((product?.maNumbers ?? []).map((ma) => [ma.number, ChangeType.Unchanged]));
    const atcCodes = Object.fromEntries((product?.atcCodes ?? []).map((atc) => [atc, ChangeType.Unchanged]));
    const linkedSmpcTitle: DiffDto<string> = { value: product.linkedSmpcTitle, changeType: ChangeType.Unchanged };
    const linkedPilTitle: DiffDto<string> = { value: product.linkedPilTitle, changeType: ChangeType.Unchanged };

    const allergyAndPregnancyRiskInfo = {
      isSuitableForVegans: {
        value: productChanges ? productChanges.isSuitableForVegans.value : product.isSuitableForVegans,
        changeType: productChanges ? productChanges?.isSuitableForVegans.changeType : ChangeType.Unchanged,
      },
      isLactoseFree: {
        value: productChanges ? productChanges?.isLactoseFree.value : product.isLactoseFree,
        changeType: productChanges ? productChanges?.isLactoseFree.changeType : ChangeType.Unchanged,
      },
      isGlutenFree: {
        value: productChanges ? productChanges?.isGlutenFree.value : product.isGlutenFree,
        changeType: productChanges ? productChanges?.isGlutenFree.changeType : ChangeType.Unchanged,
      },
      isPregnancyRisk: {
        value: productChanges ? productChanges?.isPregnancyRisk.value : product.isPregnancyRisk,
        changeType: productChanges ? productChanges?.isPregnancyRisk.changeType : ChangeType.Unchanged,
      },
    };

    const canUpdateProductDetails = canUpdateProductDetailsInTask(submissionTaskType);    
    
    return {
      [ProductsTableColumnKeys.productName]: productName,
      [ProductsTableColumnKeys.linkedSmpc]: productChanges?.smpcTitle ?? linkedSmpcTitle,
      [ProductsTableColumnKeys.linkedPil]: productChanges?.pilTitle ?? linkedPilTitle,
      [ProductsTableColumnKeys.blackTriangle]: blackTriangle,
      [ProductsTableColumnKeys.legalCategories]: productChanges?.legalCategories ?? legalCategories,
      [ProductsTableColumnKeys.maNumbers]: productChanges?.maNumbers ?? maNumbers,
      [ProductsTableColumnKeys.atcCodes]: productChanges?.atcCodes ?? atcCodes,
      [ProductsTableColumnKeys.allergyAndPregnancyRiskInfo]: allergyAndPregnancyRiskInfo,
      [ProductsTableColumnKeys.associatedContent]: filteredDocuments(product.productId),
      [ProductsTableColumnKeys.productId]: product.productId,
      [ProductsTableColumnKeys.status]: product.status,
      [ProductsTableColumnKeys.details]: null,
      [ProductsTableColumnKeys.actions]: isPublished ? [] : [canUpdateProductDetails && ActionType.Update, ActionType.Remove],
    };
  });
};

export const prepareNotesTabColumns = () => {
  const columnHelper = createColumnHelper<ISubmissionNote>();

  const columns = [
    columnHelper.accessor('description', {
      header: 'Description',
      cell: ({ getValue }) => (getValue() && getValue().length > 0 ? getValue() : '-'),
    }),
    columnHelper.accessor('text', {
      header: 'Notes',
      cell: ({ getValue }) => (getValue() && getValue().length > 0 ? getValue() : '-'),
    }),
    columnHelper.accessor('from', {
      header: 'From',
      cell: ({ getValue }) => (getValue() && getValue().length > 0 ? getValue() : '-'),
    }),
    columnHelper.accessor('to', {
      header: 'To',
      cell: ({ getValue }) => (getValue() && getValue().length > 0 ? getValue() : '-'),
    }),
    columnHelper.accessor('submittedAt', {
      header: 'Date',
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? format(parseISO(value), dateTimeFormatNoSeconds) : '-';
      },
    }),
    columnHelper.accessor('performedBy', {
      header: 'Performed by',
      cell: ({ getValue }) => (getValue() && getValue().length > 0 ? getValue() : '-'),
    }),
  ];

  return columns;
};
