import styled from "styled-components";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";

interface ISearchInputWrap {
  height?: number;
  width?: number;
  isActive: boolean;
}

export const ComponentWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.elements.textGrey};
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 5px;
  font-size: 16px;
`;

export const SearchInputWrap = styled.div<ISearchInputWrap>`
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  border-radius: 4px;
  background-color: ${({ theme, isActive }) => isActive ? theme.elements.defaultWhite : theme.elements.grey};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => height || 35}px;
  width: ${({ width }) => width || 450}px;
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 0 7px;
`;

interface IInputStyled {
  isActive: boolean;
}

export const InputStyled = styled.input<IInputStyled>`
  width: 100%;
  height: 20px;
  background-color: ${({ theme, isActive }) => isActive ? theme.elements.defaultWhite : theme.elements.grey};
  margin-bottom: -1px;
  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled(AiOutlineSearch)`
  margin-right: 5px;
  font-size: 15px;
`;

export const ClearIcon = styled(AiOutlineCloseCircle)`
  cursor: pointer;
`;
