import React, { useState } from 'react';

import { StyledSVG } from '@common/styles';
import { Button, ButtonTypes } from '@common/components';
import { FilterItemContent, FilterItemContentClearWrap, FilterItemContentLeft, FilterLabel, FiltersItemWrap } from './styles';
import { FiltersBadge } from './FiltersBadge/FiltersBadge';

interface IFiltersItem {
  label: string;
  children: any;
  appliedFiltersCount?: number;
  onClear?: () => void;
}

export const FiltersItem = ({ label, children, appliedFiltersCount, onClear }: IFiltersItem) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => setIsExpanded(!isExpanded);

  return (
    <FiltersItemWrap isOpen={isExpanded}>
      <FilterLabel onClick={handleClick}>
        <div>
          {label}
          {!!appliedFiltersCount && appliedFiltersCount > 0 && <FiltersBadge appliedFiltersCount={appliedFiltersCount} />}
        </div>
        <StyledSVG className="section-opener" onClick={handleClick} src={`/icons/expand_tick.svg`} />
      </FilterLabel>
      {isExpanded && (
        <FilterItemContent className="filtering_options">
          <FilterItemContentLeft>{children}</FilterItemContentLeft>
          <FilterItemContentClearWrap>
            {onClear && <Button text="Clear" type={ButtonTypes.TEXT} onClick={onClear} />}
          </FilterItemContentClearWrap>
        </FilterItemContent>
      )}
    </FiltersItemWrap>
  );
};
