import React from 'react';

import { StyledSVG } from '@common/styles';
import * as Styled from './styles';

interface INoContentError {
  text: string;
}

export const NoContentError = ({ text }: INoContentError) => {
  return (
    <Styled.NoContentError>
      <StyledSVG src="/icons/small_warning_icon.svg" />
      {text}
    </Styled.NoContentError>
  );
};

export default NoContentError;
