import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
  ProductGroupHistoryListWrapper,
  Info,
  ProductGroupHistoryListHeader,
  ProductGroupHistoryListName,
  ArrowIcon,
  ProductGroupHistoryPopoverWrapper,
  PopoverItem,
  PopoverArrowWrapper,
  PopoverArrow,
  PopoverContent,
  ProductGroupHistoryItem,
  ProductGroupHistoryItemHeader,
  StyledTickIcon,
  IconWrap,
  SubmissionInfoWrapper,
} from './styles';
import { SubmissionStatus } from '@common/services/types';
import { SubmissionStatusToDisplayNameMap } from './helpers';
import { IProductGroupHistoryElement, useProductGroupHistory } from './useProductGroupHistory';
import { useUnsavedChanges } from '@common/context/hooks/useUnsavedChanges';
import { useParams } from 'react-router';

interface IProductGroupHistoryList {
  title: string;
  status: SubmissionStatus;
  productGroupId: number;
}

interface ISubmissionInfo {
  title: string;
  status: SubmissionStatus;
}

interface ISubmissionInfoPopoverContent {
  elements: IProductGroupHistoryElement[];
  onElementClick: (submission: IProductGroupHistoryElement) => void;
  currentTitle: string;
  productGroupIdFromRoute: string;
}

const ProductGroupHistoryListPopoverContent = ({
  elements,
  onElementClick,
  currentTitle,
  productGroupIdFromRoute,
}: ISubmissionInfoPopoverContent) => {
  const content = elements.map((item) => {
    const displayStatus = SubmissionStatusToDisplayNameMap[item.status] || item.status;
    const shouldDisplayTick =
      productGroupIdFromRoute !== '' ? item.productGroupId?.toString() === productGroupIdFromRoute : currentTitle === item.title;

    return (
      <PopoverItem key={item.submissionId ?? item.productGroupId} onClick={() => onElementClick(item)}>
        <IconWrap>{shouldDisplayTick && <StyledTickIcon src={`/icons/blue_tick.svg`} />}</IconWrap>
        <ProductGroupHistoryItem>
          <ProductGroupHistoryItemHeader>
            {displayStatus} {item.submissionId == null ? 'product group' : 'submission'}
          </ProductGroupHistoryItemHeader>
          {item.title}
        </ProductGroupHistoryItem>
      </PopoverItem>
    );
  });
  return (
    <ProductGroupHistoryPopoverWrapper>
      <PopoverArrowWrapper>
        <PopoverArrow />
      </PopoverArrowWrapper>
      <PopoverContent>{content}</PopoverContent>
    </ProductGroupHistoryPopoverWrapper>
  );
};

export const ProductGroupHistoryList = ({ title, status, productGroupId }: IProductGroupHistoryList) => {
  const [isActive, setIsActive] = useState(false);
  const { handleUnsavedChanges } = useUnsavedChanges({});
  const { productGroupId: productGroupIdFromRoute = '' } = useParams();
  const ref = useDetectClickOutside({
    onTriggered: () => isActive && setIsActive(!isActive),
  });

  const { productGroupElements, onClick } = useProductGroupHistory({ productGroupId });
  const displayStatus = SubmissionStatusToDisplayNameMap[status] || status;

  if (!displayStatus || !title) {
    return <></>;
  }

  const onElementClick = (item: IProductGroupHistoryElement) => {
    handleUnsavedChanges(() => onClick(item));
  };

  return (
    <Popover
      ref={ref}
      isOpen={isActive}
      positions={['bottom', 'left']} // preferred positions by priority
      content={ProductGroupHistoryListPopoverContent({
        elements: productGroupElements,
        onElementClick,
        currentTitle: title,
        productGroupIdFromRoute,
      })}
    >
      <ProductGroupHistoryListWrapper
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <Info>
          <ProductGroupHistoryListHeader>{displayStatus} submission</ProductGroupHistoryListHeader>
          <ProductGroupHistoryListName>{title}</ProductGroupHistoryListName>
        </Info>
        <ArrowIcon isActive={isActive} />
      </ProductGroupHistoryListWrapper>
    </Popover>
  );
};

export const SubmissionInfo = ({ title, status }: ISubmissionInfo) => {
  const displayStatus = SubmissionStatusToDisplayNameMap[status] || status;

  if (!displayStatus || !title) {
    return <></>;
  }

  return (
    <SubmissionInfoWrapper onClick={() => {}}>
      <Info>
        <ProductGroupHistoryListHeader>{displayStatus} submission</ProductGroupHistoryListHeader>
        <ProductGroupHistoryListName>{title}</ProductGroupHistoryListName>
      </Info>
    </SubmissionInfoWrapper>
  );
};
