import { updateSubmissionSubstances } from '@common/services/submissionService';
import { useAppSelector } from '@common/hooks/redux';
import { selectActiveCompany, selectToken, selectUserId } from '@common/features/user/selectors';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { SubmissionUpdateSubstancesCommand } from 'apps/publisher-v3.ui.customer/src/features/submission/types';

interface IUpdateSubmissionSubstances {
  submissionId: string;
  payload: SubmissionUpdateSubstancesCommand;
}

export const useUpdateSubmissionSubstances = () => {
  const token = useAppSelector(selectToken);
  const activeCompany = useAppSelector(selectActiveCompany);
  const companyId = activeCompany?.id!;
  const userId = useAppSelector(selectUserId);

  const { mutate } = useWaitingMutation(({ submissionId, payload }: IUpdateSubmissionSubstances) => {
    const substances = payload.activeIngredients.map((activeIngredient) => ({
      name: activeIngredient.label,
      id: activeIngredient.value,
    }));
    return updateSubmissionSubstances(token, submissionId, { substances, companyId, userId });
  });

  return { updateSubmissionSubstances: mutate };
};
