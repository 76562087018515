import { useState } from 'react';

export const useInfiniteLoader = (initialCount: number = 50, jump: number = 50, initialOffset: number = 0) => {
  const [offset, setOffset] = useState<number>(initialOffset);
  const [initialRecordLimit] = useState<number>(initialCount);
  const [initialRecordOffset] = useState<number>(initialOffset);

  const increaseOffset = () => {
    setOffset(offset + jump);
  };

  const resetOffset = () => {
    setOffset(initialRecordOffset);
  };

  return {
    increaseOffset,
    recordLimit: initialRecordLimit,
    resetOffset,
    offset,
    initialRecordOffset,
  };
};
