import React from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';

interface IProps {
  isOpen: boolean;
  onConfirm?: () => void;
}

export const RequestSubmittedModal = ({ onConfirm, isOpen }: IProps) => (
  <ModalWrapper isOpen={isOpen}>
    <Styled.Title>{`Your request for changes has been\nsubmitted`}</Styled.Title>
    <Styled.Footer>
      <Button onClick={onConfirm} text="Confirm" type={ButtonTypes.PRIMARY} />
    </Styled.Footer>
  </ModalWrapper>
);
