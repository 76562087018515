import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DocumentType, ICheckboxEntry, ValidationError } from '@common/types';
import {
  SideDecorationContent,
  Button,
  ButtonTypes,
  ModalWrapper,
  FormCheckboxesSection,
  HookFormRichTextEditor,
} from '@common/components';
import { getModalContent } from './constants';
import { FormFields } from './types';
import FormDocumentTitle from '@components/DocumentTitle/FormDocumentTitle';
import * as Styled from './styles';
import FileUpload from '@components/FileUpload/FileUpload';
import { UNITS_DIVIDER } from '@common/constants';
import FormWebLinkInput from '@common/components/WebLinkInput/FormWebLinkInput';
import { GroupedCheckboxSections, StyledSVG, Title } from '@common/styles';
import { epilConversionCheckboxTexts, pilAutoApproveCheckboxTexts } from '@common/features/document/constants';
import ProductsToUpdate from './ProductsToUpdate';
import { getDocumentAttributes } from '@common/features/document/helpers';
import { getDocumentTypeLabel } from '@common/helpers';

export type AddModalOpenModes = 'Add' | 'Update' | null;

export interface IAddModal {
  type: DocumentType;
  isOpen: AddModalOpenModes;
  isUploading: boolean;
  uploadProgress: number;
  productsMatchingDocument?: ICheckboxEntry[];
  onFileSubmit: (files: FileList | null) => void;
  onUploadClick: () => void;
  onCancelClick: () => void;
  onRemove: () => void;
  uploadErrors?: () => ValidationError[];
}

const AddDocumentModal = ({
  type,
  isOpen,
  uploadProgress,
  isUploading,
  productsMatchingDocument = [],
  onFileSubmit,
  onCancelClick,
  onUploadClick,
  onRemove,
  uploadErrors,
}: IAddModal) => {
  const { setValue, watch } = useFormContext();

  const uploadedFile = watch(FormFields.file);
  const webLink = watch(FormFields.webLink);

  const fileSize = Math.round((uploadedFile?.size ?? 0) / UNITS_DIVIDER);

  const handleDocumentTitleClear = () => setValue(FormFields.documentTitle, '');
  const handleWebLinkClear = () => setValue(FormFields.webLink, '');

  const documentAttributes = getDocumentAttributes(type, false);

  const shouldDisableFileInput = documentAttributes.showWebLink && !!webLink;
  const shouldDisableWebLinkInput = !!uploadedFile;
  const contentTypeLabel = getDocumentTypeLabel(type);
  const documentTitleLabel = documentAttributes.allowHtmlContentUpload ? 'Content title' : 'Document title';

  const [page, setPage] = useState(0);

  if (!type) {
    return null;
  }

  const shouldOfferProducts = () => productsMatchingDocument.length > 1;

  const reset = () => {
    setPage(0);
  };

  const nextPage = () => {
    if (shouldOfferProducts() && page === 0 && uploadErrors && uploadErrors()?.length === 0) setPage(1);
    if (!shouldOfferProducts() || page === 1) onUploadClick();
  };

  const previousPage = () => {
    setPage(0);
  };

  const modalContent = getModalContent(isOpen!)[type];

  const modalTitleTestId = `${isOpen === 'Add' ? 'add' : 'update'}-${type}-document-modal-title`;

  const toolbarOptions = [[{ header: [1, 2, 3, 4, false] }], ['bold', 'italic', 'underline'], [{ list: 'ordered' }], ['link']];
  const isPrimaryDocument = documentAttributes.showAssociationWarning;

  return (
    <ModalWrapper isOpen={!!isOpen}>
      <Styled.Wrap>
        <Styled.Top>
          <Title data-testid={modalTitleTestId}>{modalContent.title}</Title>
          {page === 0 ? <> {modalContent.description && modalContent.description}</> : null}
        </Styled.Top>
        {page === 0 ? (
          <SideDecorationContent>
            {documentAttributes.allowFileUpload ? (
              <FileUpload
                title={modalContent.fileUploadTitle}
                onFileSubmit={onFileSubmit}
                loadingPresents={uploadProgress}
                isLoading={isUploading}
                fileName={uploadedFile?.name}
                fileSize={fileSize + 'KB'}
                fileInputSubText={modalContent.fileSubText}
                onRemove={onRemove}
                disabled={shouldDisableFileInput}
              />
            ) : null}
            {documentAttributes.showWebLink ? (
              <FormWebLinkInput
                onClearInput={handleWebLinkClear}
                fieldName={FormFields.webLink}
                disabled={shouldDisableWebLinkInput}
                contentType={type}
              />
            ) : null}
            {documentAttributes.allowHtmlContentUpload ? (
              <>
                <Title small>Enter your {contentTypeLabel}</Title>
                <HookFormRichTextEditor
                  formField={FormFields.content}
                  name="html content"
                  toolbarOptions={toolbarOptions}
                  className="addModalEditorWrapper"
                  rules={{
                    maxLength: {
                      value: 5000,
                      message: 'Max. length: 5000 characters',
                    },
                  }}
                />
              </>
            ) : null}
            {type === DocumentType.Pil ? (
              <GroupedCheckboxSections>
                <FormCheckboxesSection
                  field={FormFields.hasDigitalVersion}
                  text={epilConversionCheckboxTexts.title}
                  subtextSection={epilConversionCheckboxTexts.subtitle}
                />
                <FormCheckboxesSection field={FormFields.isCustomerApprovalRequired} text={pilAutoApproveCheckboxTexts.title} />
              </GroupedCheckboxSections>
            ) : null}
            <FormDocumentTitle
              onClearInput={handleDocumentTitleClear}
              fieldName={FormFields.documentTitle}
              title={documentTitleLabel}
            />
          </SideDecorationContent>
        ) : null}
        {productsMatchingDocument.length > 1 && page === 1 ? (
          <SideDecorationContent>
            <></>
            <ProductsToUpdate isPrimaryDocument={isPrimaryDocument!} data={productsMatchingDocument} />
          </SideDecorationContent>
        ) : null}
        <Styled.Bottom>
          <Styled.Left>
            {page === 1 && (
              <StyledSVG
                onClick={() => {
                  previousPage();
                }}
                src={`/icons/back.svg`}
              />
            )}
          </Styled.Left>

          <Styled.Right>
            <Styled.CancelButtonWrap>
              <Button
                onClick={() => {
                  reset();
                  onCancelClick();
                }}
                text="Cancel"
                type={ButtonTypes.TEXT}
              />
            </Styled.CancelButtonWrap>
            <Button
              disabled={isUploading}
              onClick={() => nextPage()}
              width={163}
              text={shouldOfferProducts() && page === 0 ? 'Continue' : 'Upload'}
              type={ButtonTypes.PRIMARY}
            />
          </Styled.Right>
        </Styled.Bottom>
      </Styled.Wrap>
    </ModalWrapper>
  );
};

export default AddDocumentModal;
