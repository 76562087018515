import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 0.5rem 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  height: auto;

  button {
    white-space: nowrap;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const Icon = styled(ReactSVG)`
  margin-right: 10px;
  margin-top: 0.5rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.elements.textGrey};
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.elements.textGrey};
`;

export const Item = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.elements.textDarkGrey};
  margin-right: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
`;

export const Link = styled.div`
  display: flex;
`;
