import React, { useEffect } from 'react';

import { IDownloadReportModalState } from '../../pages/Audits/hooks/useDownloadReportModal/useDownloadReportModal';

import * as Styled from './styles';

interface Props {
  modalParams: IDownloadReportModalState;
}

export const DownloadReportFileModalContent = ({ modalParams }: Props) => {
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(modalParams.url);
    };
  }, [modalParams.url]);

  return (
    <Styled.Wrapper>
      <Styled.Text>Click on below link to download</Styled.Text>
      <Styled.DownloadAnchor href={modalParams.url} download={modalParams.fileName}>
        Download report
      </Styled.DownloadAnchor>
    </Styled.Wrapper>
  );
};
