import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Submissions from '../pages/Submissions/Submissions';
import Audits from '../pages/Audits/Audits';
import Drafts from '../pages/Drafts/Drafts';
import { SubmissionProduct } from '../pages/Product/SubmissionProduct';
import CreateSubmission from '../pages/CreateSubmission/CreateSubmission';
import { DocumentType } from '@common/types';
import { paths, routes } from './routes';
import { globalRoutes } from '@common/routing/routes';
import { TaskDetails } from '../pages/TaskDetails/TaskDetails';
import PublishedProductGroup from '../pages/ProductGroup/PublishedProductGroup';
import PublishedProduct from '../pages/Product/PublishedProduct';
import { SubmissionContentDetails } from '../pages/ContentDetails/SubmissionContentDetails';
import { ViewHtmlCustomer } from '../pages/ViewHtmlCustomer/ViewHtmlCustomer';
import PublishedProductsList from '../pages/Products/PublishedProductsList';
import { SubmissionProductGroup } from '../pages/ProductGroup/SubmissionProductGroup';
import PublishedContentDetails from '../pages/ContentDetails/PublishedContentDetails';
import { SubmissionTaskContentDetails } from '../pages/ContentDetails/SubmissionTaskContentDetails';
import { SubmissionTaskProduct } from '../pages/Product/SubmissionTaskProduct';

const documentTypesToRender = [
  DocumentType.Pil,
  DocumentType.Smpc,
  DocumentType.Rmm,
  DocumentType.Dhcp,
  DocumentType.UserManual,
  DocumentType.SafetyAlert,
  DocumentType.ProductInformation,
  DocumentType.AudioVideo,
  DocumentType.Video,
  DocumentType.Audio,
];

const DefaultRoutes = () => {
  return (
    <Routes>
      <Route path={globalRoutes.home} element={<Home />} />
      <Route path={routes.products} element={<PublishedProductsList />} />
      <Route path={routes.submissions} element={<Submissions />} />
      <Route path={routes.audits} element={<Audits />} />
      <Route path={routes.drafts} element={<Drafts />} />
      <Route path={routes.createSubmission} element={<CreateSubmission />} />
      <Route path={`${routes.submissions}/:submissionId/${paths.submissionTasks}/:submissionTaskId`} element={<TaskDetails />} />
      <Route path={`${routes.submissions}/:submissionId`} element={<SubmissionProductGroup />} />
      {documentTypesToRender.map((contentType) => (
        <Route
          key={contentType}
          path={`${routes.submissions}/:submissionId/${contentType.toLowerCase()}/:documentId`}
          element={<SubmissionContentDetails contentType={contentType} />}
        />
      ))}
      {documentTypesToRender.map((contentType) => (
        <Route
          key={contentType}
          path={`${routes.submissions}/:submissionId/${contentType.toLowerCase()}/:documentId/view`}
          element={<SubmissionContentDetails contentType={contentType} readonly />}
        />
      ))}
      {documentTypesToRender.map((contentType) => (
        <Route
          key={contentType}
          path={`${routes.submissions}/:submissionId/${contentType.toLowerCase()}/:documentId/update`}
          element={<SubmissionContentDetails contentType={contentType} />}
        />
      ))}
      {documentTypesToRender.map((contentType) => (
        <Route
          key={contentType}
          path={`${routes.submissions}/:submissionId/${
            paths.submissionTasks
          }/:taskId/${contentType.toLowerCase()}/:documentId/update`}
          element={<SubmissionTaskContentDetails contentType={contentType} />}
        />
      ))}
      {documentTypesToRender.map((contentType) => (
        <Route
          key={contentType}
          path={`${routes.productGroups}/:productGroupId/${contentType.toLowerCase()}/:documentId`}
          element={<PublishedContentDetails contentType={contentType} />}
        />
      ))}
      <Route path={`${routes.productGroups}/:productGroupId${routes.product}/:productId`} element={<PublishedProduct />} />
      <Route path={`${routes.submissions}/:submissionId${routes.product}/:productId`} element={<SubmissionProduct />} />
      <Route
        path={`${routes.submissions}/:submissionId${routes.product}/:productId/view`}
        element={<SubmissionProduct readonly />}
      />
      <Route
        path={`${routes.submissions}/:submissionId/${paths.submissionTasks}/:taskId${routes.product}/:productId`}
        element={<SubmissionTaskProduct />}
      />
      <Route
        path={`${routes.submissions}/:submissionId/${paths.submissionTasks}/:taskId${routes.product}/:productId/view`}
        element={<SubmissionTaskProduct readonly />}
      />
      <Route path={`${routes.productGroups}/:productGroupId`} element={<PublishedProductGroup />} />
      <Route
        path={`${routes.submissions}/:submissionId/${paths.submissionTasks}/:taskId/${paths.html}`}
        element={<ViewHtmlCustomer />}
      />
    </Routes>
  );
};

export default DefaultRoutes;
