import styled, { css } from 'styled-components';

export const SwitcherWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.elements.borderGrey};
  list-style-type: none;
`;

interface ISwitcherProps {
  selected: boolean;
}

const selectedStyle = css`
  border-bottom: 3px solid ${({ theme }) => theme.elements.primaryMedium};
  color: ${({ theme }) => theme.elements.textGrey};
  height: 42px;
  margin-bottom: -2px;
  padding-bottom: 9px;
`;

const unselectedStyle = css`
  height: 40px;
  color: ${({ theme }) => theme.elements.mediumGrey};
`;

export const Option = styled.li<ISwitcherProps>`
  margin-right: 10px;
  padding: 10px;
  ${({ selected }) => (selected ? selectedStyle : unselectedStyle)};

  :hover {
    ${selectedStyle}
  }
`;

export const OptionLabel = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: default;
  justify-content: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.label};
`;
