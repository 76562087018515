import { createColumnHelper } from '@tanstack/react-table';
import { Controller, useFormContext } from 'react-hook-form';
import { ProductSection } from '../ProductSection/ProductSection';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { Button, ButtonTypes, Input } from '@common/components';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { ErrorMessage } from '../ErrorMessage';
import { IAtcCode } from '@common/features/product/types';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';
import * as CommonStyles from '../styles';

interface IAtcCodesProps {
  elementId: string;
  atcCodes: IAtcCode[];
  atcCodeToAdd: string;
  isNoAtcCodes: boolean;
  readonly?: boolean;
  onAtcCodeAdd: () => void;
  onAtcCodeRemove: (index: number) => void;
  onNoAtcCodesChange: () => void;
}

export const AtcCodes = ({
  elementId,
  atcCodes,
  isNoAtcCodes,
  readonly,
  onAtcCodeAdd,
  onAtcCodeRemove,
  onNoAtcCodesChange,
}: IAtcCodesProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const isDataEmpty = !atcCodes.length && !isNoAtcCodes;

  const errorMessage = errors.productDetails?.atcCodesForm?.atcCodeInput?.message;

  const columnHelper = createColumnHelper<IAtcCode>();

  const columns = [
    columnHelper.accessor('code', {
      header: 'Code',
    }),
    ...(readonly
      ? []
      : [
          columnHelper.display({
            header: 'Action',
            cell: (item) => {
              return (
                <CommonStyles.RemoveButton onClick={() => onAtcCodeRemove(parseInt(item.cell.id))}>
                  <StyledSVG src={`/icons/trash_bin.svg`} />
                </CommonStyles.RemoveButton>
              );
            },
          }),
        ]),
  ];

  return (
    <ProductSection
      elementId={elementId}
      heading="ATC code"
      subtext="The Anatomical Therapeutic Chemical code: a unique code assigned to a medicine according to the organ or system it works on and how it works"
      withError={!readonly && isDataEmpty}
    >
      <Styled.MaNumbersWrap>
        <CheckboxOption
          item={{ id: 'noAtcCodes', text: 'No ATC code is available' }}
          name="noAtcCodes"
          isChecked={isNoAtcCodes}
          onChange={onNoAtcCodesChange}
          disabled={readonly}
        />
        {!readonly && (
          <>
            <CommonStyles.InputButtonWrapper>
              <Controller
                control={control}
                name={ProductFormPaths.atcCodeInput}
                render={({ field: { value = '', onChange } }) => (
                  <Input label="Enter ATC code" name="ATC code" disabled={isNoAtcCodes} value={value} onChange={onChange} />
                )}
              />
              <Button disabled={isNoAtcCodes} text="Add" type={ButtonTypes.PRIMARY_BORDER} onClick={onAtcCodeAdd} />
            </CommonStyles.InputButtonWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        {!isNoAtcCodes && atcCodes.length > 0 && <DatapharmTable columns={columns} documents={atcCodes} />}
      </Styled.MaNumbersWrap>
    </ProductSection>
  );
};
