import React from 'react';

import { NotifyModal } from '@common/components/NotifyModal';

import { DocumentType } from '@common/types';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';

import * as Styled from './styles';

interface IModalProps {
  missingFields: string[];
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  contentType: DocumentType;
}

type ContentDetailsFormFieldToError = {
  [key in ContentDetailsFormField]?: string;
};

const missingItemsOutputs = (contentType: DocumentType): ContentDetailsFormFieldToError => {
  const errors = {
    [ContentDetailsFormField.regulatorApprovalDate]: 'The regulator approval date',
    [ContentDetailsFormField.details]: 'Submission notes',
    [ContentDetailsFormField.isRegulatorApproved]: `Confirmation that this version of the ${contentType} has been approved`,
    [ContentDetailsFormField.reasonsForSubmission]: 'Reasons for submission',
    [ContentDetailsFormField.approvedByRegulator]: 'Regulator',
    [ContentDetailsFormField.documentTitle]: 'Document title',
    [ContentDetailsFormField.authorisedDate]: '',
    [ContentDetailsFormField.isVisibleToHCP]: 'Audience Visibility',
    [ContentDetailsFormField.contentDescription]: 'Content Description',
    [ContentDetailsFormField.alternativeTextFile]: 'Alternative text file included',
    [ContentDetailsFormField.isCustomerApprovalRequired]: 'Choice for auto approve of document',
    [ContentDetailsFormField.webLink]: 'Web link',
  };

  switch (contentType) {
    case DocumentType.Pil:
      errors.authorisedDate = `The date of first authorisation or PIL date of revision`;
      break;
    case DocumentType.Smpc:
      errors.authorisedDate = `The date of first authorisation or SmPC date of revision (most recent)`;
      break;
  }

  return errors;
};

export const ValidationError = ({ missingFields, isOpen, contentType, handleClose, handleConfirm }: IModalProps) => {
  const renderMissingFields = () =>
    missingFields.map((field, id) => (
      <Styled.MissingField key={id}>{missingItemsOutputs(contentType)[field as ContentDetailsFormField]}</Styled.MissingField>
    ));

  return (
    <NotifyModal title={'Warning - Required information missing'} isOpen={isOpen} onClose={handleClose} onConfirm={handleConfirm}>
      <Styled.ErrorContent>
        <Styled.MissingFieldsLabel>The following information will be needed before you can submit</Styled.MissingFieldsLabel>
        <Styled.MissingFields>{renderMissingFields()}</Styled.MissingFields>
      </Styled.ErrorContent>
    </NotifyModal>
  );
};
