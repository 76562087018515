import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useUploadFile } from '@hooks/useUploadFile';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { FormFields } from '../../types';
import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentType } from '@common/types';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { useReplaceDocument } from 'apps/publisher-v3.ui.customer/src/pages/TaskDetails/hooks/useReplaceDocument';
import { getIsFileValid, getCanUpload } from '@common/features/document/helpers';
import { AddModalOpenModes } from '../../AddDocumentModal';
import { IDocumentDto } from '@common/features/submission/types';

interface IProps {
  contentType: DocumentType;
  methods: UseFormReturn<any>;
  taskId?: string;
  submissionId?: string;
  existingDocuments?: IDocumentDto[];
}

export const useReplaceDocumentModal = ({
  contentType,
  methods: { setValue, reset, getValues, handleSubmit },
  submissionId,
  taskId = '',
  existingDocuments,
}: IProps) => {
  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors } = useUploadFile(handleRemove);
  const { goToUpdateTaskDocumentContentDetails } = useAppNavigation();
  const { deleteFile } = useDeleteFile();

  const { replaceDocument } = useReplaceDocument(parseInt(taskId as string), fileName, getValues(FormFields.file)?.name);

  const [isModalOpen, setIsModalOpen] = useState<AddModalOpenModes>(null);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: contentType });
      }
    }
  };

  const handleUploadClick = () => {
    const errors = getCanUpload(contentType, getValues(FormFields.documentTitle), getValues(FormFields.file));

    if (errors.length > 0) setFileErrors(errors);
    else {
      handleSubmit(() =>
        replaceDocument(
          {
            title: getValues(FormFields.documentTitle),
          },
          {
            onSuccess: (data) => goToUpdateTaskDocumentContentDetails(submissionId!, contentType, data.documentId, taskId),
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(null);
    reset({});
    abortUpload();
  };

  const clearErrors = () => setFileErrors([]);

  const handleOpenModal = (productGroupName: string, documentId?: number) => {
    const selectedDocument = existingDocuments?.find((d) => d.documentId === Number(documentId));
    const documentTitle = selectedDocument?.documentTitle ?? productGroupName;

    reset((fieldValues: any) => ({
      ...fieldValues,
      [FormFields.documentId]: documentId,
      [FormFields.documentTitle]: documentTitle,
    }));
    setIsModalOpen('Update');
  };

  return {
    handleFileSubmit,
    handleUploadClick,
    handleCancel,
    handleRemove,
    handleOpenModal,
    clearErrors,
    fileErrors,
    isModalOpen,
    isLoading,
    uploadProgress,
  };
};
