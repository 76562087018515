import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { Id } from '@common/types';
import { removeSubmissionDocument, removeProduct } from '@common/services/submissionService';
import useWaitingMutation from '@hooks/useWaitingMutation';

export type SubmissionDataType = 'document' | 'product';

export const useRemoveSubmissionData = (submissionId: Id, documentId: Id, type: SubmissionDataType) => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation((data: unknown) =>
    type === 'document'
      ? removeSubmissionDocument(token, submissionId, documentId)
      : removeProduct(token, submissionId, documentId),
  );

  return { mutate };
};
