import { useState } from 'react';

export const useUnknownProductGroup = () => {
  const [isUnknownGroupStatusOpen, setUknownGroupStatus] = useState<boolean>(false);

  const handleShowUnknownGroup = () => {
    setUknownGroupStatus(true);
  };

  const handleCloseUnknownGroup = () => {
    setUknownGroupStatus(false);
  };

  const isProductGroupUnknown = (familyId: string): boolean => {
    return familyId === '-1';
  };

  return {
    handleShowUnknownGroup,
    handleCloseUnknownGroup,
    isProductGroupUnknown,
    isUnknownGroupStatusOpen,
  };
};
