import styled from 'styled-components';

export const SectionWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  padding: 20px;
  border-radius: 14px;
`;

export const SectionHeadingWrap = styled.div`
  margin-bottom: 15px;
`;

export const SectionHeadingText = styled.h3`
  font-size: ${({ theme }) => theme.elementFontSizes.subSectionTitle};
  color: ${({ theme }) => theme.elements.submissionHeading};
  margin-bottom: 5px;
`;

export const SectionHeadingSubText = styled.span`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.submissionSubHeading};
`;
