import { Section } from '@common/components/Section/Section';
import { YesNoCheckbox } from '@common/components/YesNoCheckbox/YesNoCheckbox';
import { alternativeTextFileCheckboxTexts } from '../../constants';
import { Title } from '@common/styles';
import { UploadedFile } from '@common/components/UploadedFile/UploadedFile';
import { WrapperBottomIntend } from './styles';
interface IProps {
  withBorderAndSubtext?: boolean;
  alternativeTextFileName: string;
  onAlternativeTextFileDownload: () => void;
}

export const ReadonlyAdditionalTextFileSection = ({
  withBorderAndSubtext,
  alternativeTextFileName,
  onAlternativeTextFileDownload
}: IProps) => {

  return withBorderAndSubtext ? (
    <Section text={alternativeTextFileCheckboxTexts.title} subtext={alternativeTextFileCheckboxTexts.subtitle}>
      <WrapperBottomIntend>
        <YesNoCheckbox value={true} disabled={true} />
      </WrapperBottomIntend>
      {alternativeTextFileName !== null && alternativeTextFileName.length > 0 && (
        <UploadedFile
          active={true}
          fileName={alternativeTextFileName}
          onClick={onAlternativeTextFileDownload}
        />
      )}
    </Section>
  ) : (
    <>
      <Title small>{alternativeTextFileCheckboxTexts.title}</Title>
      <WrapperBottomIntend>
        <YesNoCheckbox value={true} disabled={true} />
      </WrapperBottomIntend>
      {alternativeTextFileName !== null && alternativeTextFileName.length > 0 && (
        <UploadedFile
          active={true}
          fileName={alternativeTextFileName}
          onClick={onAlternativeTextFileDownload}
        />
      )}
    </>
  );
};


