import styled from 'styled-components';
import { TabContentWrapper, TabsWrapper } from '@common/components/Tabs/styles';

export const ContentDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.elements.customLayoutGrey};

  ${TabsWrapper} {
    height: 100%;
  }

  ${TabContentWrapper} {
    height: calc(100% - 40px);
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const MissingFieldsLabel = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.label};
  margin-bottom: 15px;
`;

export const MissingFields = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 2px;
`;
export const MissingField = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
