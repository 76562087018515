import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { deleteFile } from '../../services/requestService';
import { useAppSelector } from '../redux';
import { selectToken } from '../../features/user/selectors';

export const useDeleteFile = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation((fileName: string) => {
    return deleteFile(token, fileName);
  });
  return { deleteFile: mutate };
};
