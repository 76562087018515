import styled from 'styled-components';
import { StyledButton } from '../Button/styles';
import { StyledSVG } from '@common/styles';
import { ComponentWrap, InputStyled, SearchInputWrap } from '../SearchInput/styles';

const inputHeight = '50px';
const filtersWidth = '270px';

interface IFilterProps {
  isOpen?: boolean;
}

export const FiltersDropdownContent = styled.div`
  display: flex;
  flex-flow: column;
  border: ${({ theme }) => `1px 0 solid ${theme.elements.borderGrey}`};
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border-radius: 0 0 4px 4px;
  width: ${filtersWidth};
`;

export const FiltersDropdownCtaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.elements.borderGrey}`};
  padding: 5px 5px;

  ${StyledButton} {
    max-width: 100px;
    height: 35px;
  }
`;

export const PopoverInvoker = styled.div<IFilterProps>`
  width: ${filtersWidth};
  height: ${inputHeight};
  padding: 5px 10px;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.elements.borderGrey}`};
  border-radius: 4px 4px 0 0;
  font-weight: 600;
  cursor: pointer;

  .section-opener {
    transform: ${({ isOpen }) => (isOpen ? `rotate(0deg)` : `rotate(270deg)`)};
  }
`;

export const PopoverInvokerTextWithBadge = styled.div`
  .filter-decoration {
    display: inline-block;
    margin-right: 5px;
  }
`;

export const PopoverInvokerButtons = styled.div`
  display: flex;
  align-items: center;

  ${StyledSVG} {
    cursor: pointer;
  }
`;

export const FilteringSection = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 45px;

  ${ComponentWrap} {
    width: 400px;
  }

  ${SearchInputWrap} {
    background-color: ${({ theme }) => theme.elements.defaultWhite};
    height: ${inputHeight};

    ${InputStyled} {
      background-color: ${({ theme }) => theme.elements.defaultWhite};
    }
  }
`;

export const FiltersItemWrap = styled.div<IFilterProps>`
  border: ${({ theme }) => `1px solid ${theme.elements.borderGrey}`};
  background-color: ${({ theme, isOpen }) => (isOpen ? theme.colors.lightBlue : 'unset')};

  ${StyledButton} {
    height: auto;
  }

  ${StyledSVG} {
    cursor: pointer;
  }

  .section-opener {
    transform: ${({ isOpen }) => (isOpen ? `rotate(0deg)` : `rotate(270deg)`)};

    svg,
    path {
      fill: ${({ theme, isOpen }) => isOpen && theme.colors.mediumBlue};
    }
  }

  .filtering_options {
    flex-flow: column;
  }
`;

export const FilterItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const FilterItemContentLeft = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 5px;
`;

export const FilterItemContentClearWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  margin-top: 5px;
`;

export const FilterLabel = styled.div<IFilterProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-weight: 600;
  cursor: pointer;

  span {
    margin-left: 0.5rem;
  }
`;

export const FiltersContent = styled.div`
  overflow: auto;
  max-height: 50vh;
`;
