import React from 'react';
import { useNavigate } from 'react-router';

import Divider from '@common/components/Divider/Divider';
import { Button, ButtonTypes } from '@common/components';

import { globalRoutes } from '@routing/routes';

import * as Styled from './styles';

interface ISubmissionFlowFooter {
  cancelText?: string;
  continueText?: string;
  errorText?: string;
  onContinue?: () => void;
  onCancel?: () => void;
}

const SubmissionFlowFooter = ({
  continueText = 'Continue',
  cancelText = 'Cancel',
  errorText,
  onCancel,
  onContinue,
}: ISubmissionFlowFooter) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    onCancel ? onCancel() : navigate(globalRoutes.home);
  };

  const prepareButtons = () => {
    let buttons = [];

    buttons.push(<Button key="cancel-button" onClick={handleCancel} text={cancelText} type={ButtonTypes.TEXT} />);

    if (onContinue) {
      buttons.push(
        <Button key="continue-button" data-testid='saveButton' onClick={onContinue} text={continueText} type={ButtonTypes.SUCCESS} minWidth={160} />,
      );
    }

    if (buttons.length > 1) {
      buttons.splice(1, 0, <Divider key="divider" type="vertical" size="50px" thickness={1} />);
    }

    return buttons;
  };

  return (
    <Styled.SubmissionFlowFooterWrap>
      <Styled.SubmissionFlowFooterInner>
        <Styled.Right>
          {errorText && <Styled.ErrorText>{errorText}</Styled.ErrorText>}
          {prepareButtons()}
        </Styled.Right>
      </Styled.SubmissionFlowFooterInner>
    </Styled.SubmissionFlowFooterWrap>
  );
};

export default SubmissionFlowFooter;
