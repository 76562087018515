import { useEffect, useState } from 'react';

interface IProps {
  initialShowModal?: boolean;
  onSmpcGetStarted?: () => void;
  onPilGetStarted?: () => void;
  onProductGetStarted?: () => void;
}

const localStorageKey = 'nextStepsDontShowAgain';

export const useNextStepsModal = ({
  initialShowModal = false,
  onProductGetStarted,
  onSmpcGetStarted,
  onPilGetStarted,
}: IProps = {}) => {
  const [isNextStepsOpen, setIsNextStepsOpen] = useState(false);
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);

  const nextStepsDontShowAgain = localStorage.getItem(localStorageKey) === '1';

  useEffect(() => {
    initialShowModal && !nextStepsDontShowAgain && setIsNextStepsOpen(true);
  }, [initialShowModal, nextStepsDontShowAgain]);

  const handleNextStepsClose = () => setIsNextStepsOpen(false);

  const handleDontShowAgainCheck = () => {
    if (isDontShowAgainChecked) {
      setIsDontShowAgainChecked(false);
      localStorage.removeItem(localStorageKey);
    } else {
      setIsDontShowAgainChecked(true);
      localStorage.setItem(localStorageKey, '1');
    }
  };

  const handleSmpcGetStarted = () => {
    onSmpcGetStarted && onSmpcGetStarted();
    setIsNextStepsOpen(false);
  };

  const handlePilGetStarted = () => {
    onPilGetStarted && onPilGetStarted();
    setIsNextStepsOpen(false);
  };

  const handleProductGetStarted = () => {
    onProductGetStarted && onProductGetStarted();
    setIsNextStepsOpen(false);
  };

  return {
    isNextStepsOpen,
    isDontShowAgainChecked,
    handleNextStepsClose,
    handleDontShowAgainCheck,
    handleSmpcGetStarted,
    handlePilGetStarted,
    handleProductGetStarted,
  };
};
