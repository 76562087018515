import styled from 'styled-components';
import { InputWrap } from '@common/components/Input/styles';

export const DocumentTitleWrapper = styled.div`
  width: 45%;
  font-size: ${({ theme }) => theme.fontSizes.small};

  ${InputWrap} {
    border-radius: 10px;
    padding: 15px 20px;
    height: auto;
  }
`;
