import React from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';
import Divider from '../Divider/Divider';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { Textarea } from '../Textarea/Textarea';
import { ErrorMessage } from '../ErrorMessage';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  onSubmit?: () => void;
  onCancel: () => void;
  methods: UseFormReturn;
}

export const RequestChangesModal = ({ onSubmit, onCancel, isOpen, isLoading = false, methods }: IProps) => {
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      <ModalWrapper isOpen={isOpen}>
        <Styled.Title>Request Changes</Styled.Title>
        <Styled.SubTitle>What would you like to change?</Styled.SubTitle>
        <Styled.Content>
          <Controller
            control={control}
            name="additionalInformation"
            rules={{
              maxLength: { value: 1000, message: 'Max. length: 1000 characters' },
              required: 'Please enter your request for changes',
            }}
            render={({ field: { value, onChange } }) => (
              <Textarea placeholder="Enter details here" value={value} onChange={onChange} />
            )}
          />
          {errors?.additionalInformation && <ErrorMessage>{errors?.additionalInformation.message?.toString()}</ErrorMessage>}
        </Styled.Content>
        <Styled.Footer>
          <Button onClick={onCancel} text="Cancel" type={ButtonTypes.TEXT} data-testid="cancel-button" />
          <Divider type="vertical" size="50px" thickness={1} />
          <Button onClick={onSubmit} text="Submit" type={ButtonTypes.PRIMARY} isLoading={isLoading} />
        </Styled.Footer>
      </ModalWrapper>
    </FormProvider>
  );
};
