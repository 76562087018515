import { Wrapper as InputWrapper } from '@common/components/FormInput/styles';
import { Wrapper as Select } from '@common/components/Select/styles';
import styled from 'styled-components';

export const ActiveIngredientsStrength = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  width: fit-content;
`;

export const ActiveIngredient = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
`;

export const ActiveIngredientsList = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
`;

export const Labels = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
`;

export const Strengths = styled.div`
  display: flex;
  column-gap: 40px;
`;

export const ActiveIngredientName = styled.div``;

export const InputSection = styled.div`
  display: flex;
  column-gap: 20px;

  ${InputWrapper} {
    width: 120px;
  }

  ${Select} {
    width: 120px;
  }

  .display {
    width: 120px;
  }

  @media (max-width: 1439px) {
    .display {
      width: 85px;

      ${InputWrapper} {
        width: 85px;
      }
    }
  }
`;
