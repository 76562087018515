import { ProductDetailsSidebar } from '../ProductDetailsSidebar/ProductDetailsSidebar';
import { useMaNumbersSelection } from './hooks/useMaNumbersSelection';
import { useAtcCodesSelection } from './hooks/useAtcCodesSelection';
import { useLegalCategoriesSelection } from '../LegalCategories/hooks/useLegalCategoriesSelection';
import { MaNumbers } from '../MaNumbers/MaNumbers';
import { LegalCategories } from '../LegalCategories/LegalCategories';
import { BlackTriangle } from '../BlackTriangle/BlackTriangle';
import { AllergensAndPregnancyRisk } from '../AllergensAndPregnancyRisk/AllergensAndPregnancyRisk';
import { AtcCodes } from '../AtcCodes/AtcCodes';
import { AmpId } from '@components/AmpId/AmpId';
import { legalCategoriesCheckboxes } from '../LegalCategories/constants';
import { ProductForm } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import * as Styled from './styles';
import { useAmpIdSelection } from '@components/ProductDetails/hooks/useAmpIdSelection';
import { ContactGroup } from '../ContactGroups/ContactGroup';
import { useContractGroupSelection } from './hooks/useContactGroupSelection';

interface IProductDetails {
  readonly?: boolean;
  shouldDisplayAmpId: boolean;
  shouldDisplayContactGroup: boolean;
}

export const ProductDetails = ({ readonly, shouldDisplayAmpId, shouldDisplayContactGroup }: IProductDetails) => {
  const { maNumberToAdd, selectedMaNumbers, noMaNumbers, handleMaNumberAdd, handleMaNumberRemove, handleNoMaNumbersChange } =
    useMaNumbersSelection();

  const { atcCodeToAdd, selectedAtcCodes, noAtcCodes, handleAtcCodeAdd, handleAtcCodeRemove, handleNoAtcCodesChange } =
    useAtcCodesSelection();

  const { contractGroupToAdd, selectedContactGroup, handleContractGroupAdd, handleContactGroupRemove } =
    useContractGroupSelection();

  const { ampIdToAdd, selectedAmpId, handleNoAmpIdChange, handleAmpIdAdd, noAmpId, handleAmpIdRemove } = useAmpIdSelection();

  const { isItemSelected, toggleItem, withLegalCategoriesError } = useLegalCategoriesSelection();

  return (
    <Styled.ProductDetailsWrap>
      <Styled.ProductDetailsInner>
        <Styled.Heading>
          <Styled.HeadingText>Product details</Styled.HeadingText>
        </Styled.Heading>
        <Styled.Content>
          <ProductDetailsSidebar
            disabled={readonly}
            shouldDisplayAmpId={shouldDisplayAmpId}
            shouldDisplayContactGroup={shouldDisplayContactGroup}
          />
          <Styled.ProductDetailsContentWrap id="IntersectionRoot">
            <MaNumbers
              elementId={ProductForm.maNumbers}
              maNumbers={selectedMaNumbers}
              maNumberToAdd={maNumberToAdd}
              isNoMaNumbers={noMaNumbers}
              onMaNumberAdd={handleMaNumberAdd}
              onMaNumberRemove={handleMaNumberRemove}
              onNoMaNumbersChange={handleNoMaNumbersChange}
              readonly={readonly}
            />
            <LegalCategories
              data={legalCategoriesCheckboxes}
              isItemSelected={isItemSelected}
              toggleItem={toggleItem}
              withError={!readonly && withLegalCategoriesError}
              readonly={readonly}
            />
            <BlackTriangle readonly={readonly} />
            <AllergensAndPregnancyRisk readonly={readonly} />
            <AtcCodes
              elementId={ProductForm.atcCodes}
              atcCodes={selectedAtcCodes}
              atcCodeToAdd={atcCodeToAdd}
              isNoAtcCodes={noAtcCodes}
              onAtcCodeAdd={handleAtcCodeAdd}
              onAtcCodeRemove={handleAtcCodeRemove}
              onNoAtcCodesChange={handleNoAtcCodesChange}
              readonly={readonly}
            />
            {shouldDisplayAmpId && (
              <AmpId
                elementId={ProductForm.ampId}
                ampId={selectedAmpId}
                ampIdToAdd={ampIdToAdd}
                isNoAmpId={noAmpId}
                onAmpIdAdd={handleAmpIdAdd}
                onAmpIdRemove={handleAmpIdRemove}
                onNoAmpIdChange={handleNoAmpIdChange}
                readonly={readonly}
              />
            )}
            {shouldDisplayContactGroup && (
              <ContactGroup
                elementId={ProductForm.contactGroup}
                contactGroup={selectedContactGroup}
                contractGroupToAdd={contractGroupToAdd}
                onContractGroupAdd={handleContractGroupAdd}
                onContractRemove={handleContactGroupRemove}
                readonly={readonly}
              ></ContactGroup>
            )}
          </Styled.ProductDetailsContentWrap>
        </Styled.Content>
      </Styled.ProductDetailsInner>
    </Styled.ProductDetailsWrap>
  );
};
