import Quill from 'quill';
import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import DOMPurify from 'isomorphic-dompurify';

interface IEditor {
  readOnly: boolean;
  defaultValue: any;
  onTextChange: any;
  onSelectionChange: any;
  toolbarOptions?: unknown[];
  className?: string;
}

const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange, toolbarOptions, className }: IEditor, ref: any) => {
    const containerRef = useRef<HTMLInputElement>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    const toolbarStylingOptions = useMemo(() => {
      return toolbarOptions ?? [['bold', 'italic', 'underline']];
    }, [toolbarOptions]);

    useEffect(() => {
      const container = containerRef.current;

      const editorContainer = container?.appendChild(container.ownerDocument.createElement('div')) ?? '';

      const quill = new Quill(editorContainer, {
        modules: {
          toolbar: toolbarStylingOptions,
        },
        theme: 'snow',
      });

      if (readOnly) quill.disable();
      else quill.enable();

      if (defaultValueRef.current) {
        //Only used with DOMPuriify - dangerous without it!
        const html = DOMPurify.sanitize(defaultValueRef.current, {ALLOWED_ATTR: ['target', 'href']});
        quill.clipboard.dangerouslyPasteHTML(html);
      }

      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map((op) => {
          return {
            insert: op.insert,
          };
        });
        return delta;
      });

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        var html = quill.getSemanticHTML();

        //Santise on the way out
        html = DOMPurify.sanitize(html, {ALLOWED_ATTR: ['target', 'href']});

        onTextChangeRef.current?.(html);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        if (container !== null) container.innerHTML = '';
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readOnly, ref]);

    return (
      <>
        <div className={className ?? ''} ref={containerRef}></div>
      </>
    );
  },
);

Editor.displayName = 'Editor';

export default Editor;
