import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Checkbox } from './Checkbox';

interface IHookFormCheckbox {
  name: string;
  disabled?: boolean;
  rules?: RegisterOptions<any, string>;
  onChange?: (value: boolean) => void;
}

export const HookFormCheckbox = ({ name, disabled, rules, onChange }: IHookFormCheckbox) => {
  const { setValue, watch, register, getValues } = useFormContext();

  if (rules) {
    register(name, {
      ...rules,
    });
  }

  const handleChange = () => {
    const value = getValues(name);
    setValue(name, !value, { shouldValidate: true, shouldDirty: true });

    onChange && onChange(!value);
  };

  const isChecked = watch(name);

  return <Checkbox name={name} isChecked={isChecked} disabled={disabled} onChange={handleChange} />;
};
