import React from 'react';
import { DocumentType } from '@common/types';
import * as Styled from './styles';
import { StyledLink } from '@common/components/Link/styles';
import { AddModalOpenModes } from './AddDocumentModal';

export const getModalContent = (mode: AddModalOpenModes) => ({
  [DocumentType.Smpc.toString()]: {
    title: `${mode} an SmPC`,
    fileUploadTitle: 'Upload your SmPC',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your SmPC:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>does not contain track changes</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>
            does not contain the Quality Review Document (QRD) sections as images
          </Styled.DescriptionListItem>
          <Styled.DescriptionListItem>does not contain any text on top of images/graphs or charts</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>does not contain nested tables</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.DOCX 3MB max',
  },
  [DocumentType.Pil.toString()]: {
    title: `${mode} a PIL`,
    fileUploadTitle: 'Upload your PIL',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your PIL:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not an artwork version of the PIL</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.Rmm.toString()]: {
    title: `${mode} a RMM`,
    fileUploadTitle: 'Upload your RMM',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your RMM:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>
            has no hidden metadata or personal information saved with the document
          </Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.Dhcp.toString()]: {
    title: `${mode} a DHPC`,
    fileUploadTitle: 'Upload your DHPC',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your DHPC:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>
            has no hidden metadata or personal information saved with the document
          </Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.UserManual.toString()]: {
    title: `${mode} a User Manual`,
    fileUploadTitle: 'Upload your User Manual',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your User Manual:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>
            has no hidden metadata or personal information saved with the document
          </Styled.DescriptionListItem>
          <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.SafetyAlert.toString()]: {
    title: `${mode} a Safety information`,
    fileUploadTitle: 'Upload your Safety information',
    description: (
      <Styled.Description>
        <Styled.DescriptionSubtext>
          Please enter the description of the Safety alert as you would like it to appear on emc.
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.ProductInformation.toString()]: {
    title: `${mode} a Product information`,
    fileUploadTitle: 'Upload your Product information',
    description: (
      <Styled.Description>
        <Styled.DescriptionSubtext>
          Please enter the description of the Product information as you would like it to appear on emc.
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
    fileSubText: '.PDF 3MB max',
  },
  [DocumentType.AudioVideo.toString()]: {
    title: `${mode} a Audio/Video`,
    fileUploadTitle: 'Add link to your Audio/Video',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          Please make sure you have all the details ready, and you have checked that your Audio/Video:
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>link is proper</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
  },
  [DocumentType.Audio.toString()]: {
    title: `${mode} Audio`,
    fileUploadTitle: 'Add link to your Audio',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          <p>
            We will host the content so we require a link to a location where we can download the file for review and upload to
            our hosting platform.
          </p>
          <br />
          <p>Audio formats accepted:</p>
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>WAV</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>MP3</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>MPEG4</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>AIFF</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on content acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
  },
  [DocumentType.Video.toString()]: {
    title: `${mode} a Video`,
    fileUploadTitle: 'Add link to your Video',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          <p>
            We will host the content so we require a link to a location where we can download the file for review and upload to
            our hosting platform.
          </p>
          <br />
          <p>Video formats accepted:</p>
        </Styled.DescriptionText>
        <Styled.DescriptionList>
          <Styled.DescriptionListItem>MP4</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>MOV</Styled.DescriptionListItem>
          <Styled.DescriptionListItem>WMV</Styled.DescriptionListItem>
        </Styled.DescriptionList>
        <Styled.DescriptionSubtext>
          Further information on content acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
        </Styled.DescriptionSubtext>
      </Styled.Description>
    ),
  },
});
