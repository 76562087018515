import styled from 'styled-components';

export const SubmissionTaskDetailsUnitWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 14px;
  width: auto;
  margin: 28px 25px 30px 28px;
`;

export const StyledLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.elements.linkBlue};
`;
