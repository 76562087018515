import { ErrorMessage } from '@common/components/ErrorMessage';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { Textarea } from '@common/components/Textarea/Textarea';
import { useFormContext } from 'react-hook-form';
import { ISubmissionReasonDto } from '@common/features/submission/types';
import { maxSubmissionReasonLength } from './constants';

export const SubmissionReason = () => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const value = watch(ContentDetailsFormField.reasonsForSubmission) as ISubmissionReasonDto[];

  const maxDescriptionError = errors[ContentDetailsFormField.reasonsForSubmission]?.message?.toString();

  const text = value && value[0] && (value[0].text as string);

  const maxReasonError = `Max length ${maxSubmissionReasonLength} chars`;

  const onChange = (event: any) => {
    var value = event.target.value;
    if (value!.length > maxSubmissionReasonLength) {
      setError(ContentDetailsFormField.reasonsForSubmission, { type: 'custom', message: maxReasonError });
    } else {
      clearErrors(ContentDetailsFormField.reasonsForSubmission);
    }

    if (value !== '') setValue(ContentDetailsFormField.reasonsForSubmission, [{ Id: -1, Text: value }]);
    else setValue(ContentDetailsFormField.reasonsForSubmission, null);
  };

  return (
    <>
      <Textarea
        data-testid={'submissionFreeTextReason'}
        value={text}
        onChange={(e) => {
          onChange(e);
        }}
      />
      {maxDescriptionError && <ErrorMessage>{maxDescriptionError}</ErrorMessage>}
    </>
  );
};
