import { useForm } from 'react-hook-form';
import { SubmissionSummaryFormFields } from './types';
import { useState } from 'react';
import { useDownloadAndOpenFile } from '@hooks/useDownloadAndOpenFile';
import { useSubmitSubmission } from '../useSubmitSubmission/useSubmitSubmission';
import { Id } from '@common/types';
import { fetchPublicProductGroupStateQueryKey } from '@common/features/productGroup/hooks/useFetchPublicProductGroupState/useFetchPublicProductGroupState';
import { useQueryClient } from '@tanstack/react-query';

interface IUseSummarySubmission {
  submissionId?: string;
  onSubmitSuccess?: () => void;
}

export enum SubmissionConfirmationType {
  None,
  Successfull,
  Failure,
}

export const useSubmissionSummary = ({ submissionId = '', onSubmitSuccess }: IUseSummarySubmission = {}) => {
  const [showSummary, setShowSummary] = useState(false);
  const { downloadAndOpen } = useDownloadAndOpenFile();
  const { submitSubmission } = useSubmitSubmission(submissionId);
  const queryClient = useQueryClient();

  const [showConfirmation, setShowConfirmation] = useState<SubmissionConfirmationType>(SubmissionConfirmationType.None);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleFileOpen = (taskId: Id) => downloadAndOpen({ taskId });

  const methods = useForm({
    defaultValues: {
      [SubmissionSummaryFormFields.additionalInformation]: '',
      [SubmissionSummaryFormFields.confirmation]: null,
    },
  });

  const { handleSubmit } = methods;

  const handleSummaryCancel = () => {
    setShowSummary(false);
  };

  const handleConfirmationClose = () =>
  {
    setShowConfirmation(SubmissionConfirmationType.None);
  }

  const handleSummarySubmit = () =>

    handleSubmit((data) => {
      const payload = {
        additionalInformation: data[SubmissionSummaryFormFields.additionalInformation],
      };
      setSaveInProgress(true);
      setShowConfirmation(SubmissionConfirmationType.None);

      submitSubmission(payload, {
        onError: (e) => {
          setShowConfirmation(SubmissionConfirmationType.Failure);
          setSaveInProgress(false);
        },
        onSuccess: () => {
          setSaveInProgress(false);
          setShowConfirmation(SubmissionConfirmationType.Successfull);
          queryClient.invalidateQueries([fetchPublicProductGroupStateQueryKey]);
        },
      });
    })();

  return {
    submissionSummaryMethods: methods,
    showSummary,
    showConfirmation,
    saveInProgress,
    setShowSummary,
    handleConfirmationClose,
    handleSummaryCancel,
    handleFileOpen,
    handleSummarySubmit,
  };
};
