import { useForm } from 'react-hook-form';
import { ContentDetailsFormField, IContentDetailsForm } from '../../../pages/ContentDetails/types';
import { IDocumentDto } from '@features/submission/types';
import { RegulatorApprovalLabels, RegulatorApprovalValues } from '../../RegulatorApproval/types';

export const usePrepareContentDetailsForm = (
  isCustomerApprovalRequired: boolean | null,
  document?: IDocumentDto,
  customerUrl?: string,
) => {
  const isRegulatorApproved = !!document?.regulatorApprovalDate;

  const methods = useForm<IContentDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      [ContentDetailsFormField.approvedByRegulator]: isRegulatorApproved
        ? {
            label: document?.approvedByRegulator as RegulatorApprovalLabels,
            value: document?.approvedByRegulator as RegulatorApprovalValues,
          }
        : { label: RegulatorApprovalLabels.MHRA, value: RegulatorApprovalValues.MHRA },
      [ContentDetailsFormField.regulatorApprovalDate]: isRegulatorApproved ? document?.regulatorApprovalDate! : undefined,
      [ContentDetailsFormField.authorisedDate]: isRegulatorApproved ? document?.authorisedDate : undefined,
      [ContentDetailsFormField.reasonsForSubmission]: document?.reasonsForSubmission ?? [],
      [ContentDetailsFormField.isRegulatorApproved]: isRegulatorApproved,
      [ContentDetailsFormField.details]: document?.details,
      [ContentDetailsFormField.documentTitle]: document?.documentTitle ?? '',
      [ContentDetailsFormField.hasDigitalVersion]: document?.hasDigitalVersion,
      [ContentDetailsFormField.hasAlternativeTextFile]: false,
      [ContentDetailsFormField.alternativeTextFile]: undefined,
      [ContentDetailsFormField.alternativeTextFileName]: undefined,
      [ContentDetailsFormField.isCustomerApprovalRequired]: isCustomerApprovalRequired,
      [ContentDetailsFormField.isVisibleToHCP]: document?.isVisibleToHCP,
      [ContentDetailsFormField.contentDescription]: document?.contentDescription ?? undefined,
      [ContentDetailsFormField.webLink]: document?.link ?? '',
      [ContentDetailsFormField.htmlContent]: document?.htmlContent ?? '',
      [ContentDetailsFormField.customerUrl]: customerUrl,
    },
  });

  return { methods, ...methods };
};
