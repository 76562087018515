import { DocumentState, DocumentType, IDocumentDto } from '@common/features/submission/types';
import { TextForChanges } from '@common/styles';

interface Props {
  documentsData: IDocumentDto[];
  showChanges: boolean;
}

export const AssociatedContentCell = (props: Props) => {
  return (
    <>
      <div>
        {docTypes.map((dt) => returnContentCount(props.documentsData, props.showChanges, dt.documentType, dt.description))}
      </div>
    </>
  );
};

interface DocTypeAndDescription {
  documentType: DocumentType;
  description: string;
}

const docTypes: DocTypeAndDescription[] = [
  { documentType: DocumentType.Rmm, description: 'RMMs' },
  { documentType: DocumentType.Dhcp, description: 'DHPC' },
  { documentType: DocumentType.UserManual, description: 'User Manuals' },
  { documentType: DocumentType.SafetyAlert, description: 'Safety alerts' },
  { documentType: DocumentType.ProductInformation, description: 'Production Information' },
  { documentType: DocumentType.AudioVideo, description: 'Audio/Video' },
  { documentType: DocumentType.LiveChat, description: 'Live Chat' },
];

const returnContentCount = (
  documentsData: IDocumentDto[],
  showChanges: boolean,
  documentType: DocumentType,
  documentTypeName: string,
) => {
  var documentCount = documentsData.filter((item) => item.type === documentType).length;

  var hasChanged =
    documentsData.filter((item) => item.type === documentType && item.state !== DocumentState.Unchanged).length > 0;

  const text = `${documentTypeName} - ${documentCount}`;
  if (!hasChanged || !showChanges) return <p key={documentTypeName}>{text}</p>;
  else return <TextForChanges key={documentTypeName}>{text}</TextForChanges>;
};
