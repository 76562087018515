import { StyledSVG } from '@common/styles';
import styled from 'styled-components';

const spacingBetweenDecoratedItems = 30;
const decorationIconTopSpacing = 10;

export const ContentWithDecorationWrap = styled.div`
  display: flex;
  margin-left: 50px;
`;

export const DecoratedContentWrap = styled.div`
  width: 100%;
`;

export const DecoratedContentInner = styled.div`
  margin-bottom: ${spacingBetweenDecoratedItems}px;
`;

export const DecoratedContentItemIcon = styled(StyledSVG)`
  position: absolute;
  left: -52px;
  top: ${decorationIconTopSpacing}px;
`;

export const DecoratedContentItem = styled.div`
  position: relative;

  &::before {
    position: absolute;
    background-color: ${({ theme }) => theme.elements.primaryLight};
    top: ${decorationIconTopSpacing + 2}px;
    left: -35px;
    height: calc(100% + ${spacingBetweenDecoratedItems}px);
    width: 2px;
    content: ' ';
  }

  &:last-child::before {
    height: 0;
  }
`;
