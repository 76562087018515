import React from 'react';
import { HtmlContentModalParams } from './types';
import { NotifyModal } from '@common/components/NotifyModal';
import * as SharedStyled from '@common/styles';

interface IHtmlContentModalProps {
  onClose: () => void;
  modalParams?: HtmlContentModalParams;
  width?: string;
}

export const HtmlContentModal = ({ modalParams, onClose, width }: IHtmlContentModalProps) => {
  return (
    <NotifyModal
      noIcon={true}
      onClose={onClose}
      title={modalParams?.title}
      isOpen={!!modalParams}
      width={width ?? '32rem'}
    >
      <SharedStyled.HtmlView>
        <div dangerouslySetInnerHTML={{ __html: modalParams?.content ?? '' }} />
      </SharedStyled.HtmlView>
    </NotifyModal>
  );
};
