import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISubmissionForm } from 'apps/publisher-v3.ui.customer/src/pages/CreateSubmission/types';
import { addData } from '@services/requestService';
import { RootState } from '@common/store';
import { IPublicClientApplication } from '@azure/msal-browser';
/* eslint-disable @typescript-eslint/no-unused-vars*/

export const generateProductGroupPlan = createAsyncThunk<any, ISubmissionForm & { instance: IPublicClientApplication }>(
  'product-group/plan',
  async ({ genericName, productType, activeIngredients, instance }, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.token;

    const request = {
      genericName,
      productType: productType.value,
      substances: activeIngredients.map((ingredient) => ({
        name: ingredient.label,
        id: ingredient.value,
      })),
    };

    const response = await addData(token, '/product-group/plan', request, instance);

    return response;
  },
);

export const createSubmissionDraft = createAsyncThunk<any, ISubmissionForm & { instance: IPublicClientApplication }>(
  'submission/draft',
  async ({ genericName, productType, activeIngredients, instance }, { getState }) => {
    const state = getState() as RootState;
    const companyId = state.user.activeCompany?.id;
    const token = state.user.token;

    const request = {
      genericName,
      companyId: companyId || null,
      productType: productType.value,
      substances: activeIngredients.map((ingredient) => ({
        name: ingredient.label,
        id: ingredient.value,
      })),
    };

    const response = await addData(token, '/submission/draft', request, instance);

    return response;
  },
);
