import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { mapToProductGroupData, getProductGroupTableColumnsData } from './helpers';
import { createColumns } from '../../helpers';
import { SubmissionTaskDetailsUnitWrapper } from '../../styles';
import { IProductGroupTable } from './types';
import { HeaderWrapper, TableWrapper } from './styles';

export const ProductGroupTable = ({ submissionTask, productGroup, isLoading, productGroupChanges }: IProductGroupTable) => {
  const columnsData = getProductGroupTableColumnsData(productGroupChanges);
  const columns = createColumns(columnsData);
  const documents = mapToProductGroupData(submissionTask, productGroup, productGroupChanges);

  return (
    <div>
      {!isLoading && (
        <SubmissionTaskDetailsUnitWrapper>
          <HeaderWrapper>
            <span>Product group</span>
          </HeaderWrapper>
          <TableWrapper>
            <DatapharmTable columns={columns} documents={documents!} data-testid="product-group-table" />
          </TableWrapper>
        </SubmissionTaskDetailsUnitWrapper>
      )}
    </div>
  );
};
