import styled from 'styled-components';

export const SubmissionDetailsWrap = styled.div``;

export const SubmissionReasonsWrap = styled.div`
  margin-bottom: 15px;
`;

export const SubmissionReasonsLabel = styled.div`
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.elementFontSizes.label};
`;

export const SubmissionReasonsContent = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-flow: column;
  row-gap: 15px;
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  max-height: 200px;
  overflow-y: auto;
`;

export const SubmissionChangeDetails = styled.div`
  margin-bottom: 15px;
`;

export const SubmissionChangeDetailsHeading = styled.div`
  font-weight: 600;
`;

export const SubmissionDetailsLabel = styled.div`
  margin-bottom: 10px;
`;

export const SubmissionChangeDetailsText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:not(:first-child) {
    margin-top: 10px;
  }
`;
