import { StyledSVG } from '@common/styles';
import styled from 'styled-components';

export const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledSVG} {
    margin-left: 5px;
  }
`;
