import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { useAppSelector } from '@common/hooks/redux';

import {
  RetireContentDetailsFormField,
  usePrepareRetireContentDetailsForm,
} from '../../components/DocumentContentDetails/hooks/usePrepareRetireContentDetailsForm';
import { useCompleteRetireDocument } from '../../components/DocumentContentDetails/hooks/useCompleteRetireDocument';
import { selectProductGroup } from '@common/features/productGroup/slices/productGroup/selectors';

import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import Tabs from '@common/components/Tabs/Tabs';
import { RetiredDocumentContent } from '../../components/DocumentContentDetails/RetiredDocumentContent';

import { IRetiredContentDetails } from './types';
import { DocumentType } from '@common/types';

import * as Styled from './styles';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { createSelectOption } from '@common/components/Select/helpers';
import { HtmlDocument } from '@components/HtmlDocument/HtmlDocument';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';

const RetiredDocumentDetailsContent = ({
  showHistoryFiltering,
  document,
  contentType,
  contentTitle,
  originalFileName,
  submissionId,
  documentId,
  onCancel,
  onSuccess,
  onFileDownload,
  displayHtmlViewTab,
}: IRetiredContentDetails) => {
  const { showVersionHistory } = useFeatureFlags();

  const { handleSubmit, resetField, methods } = usePrepareRetireContentDetailsForm();
  const productGroup = useAppSelector(selectProductGroup);

  const { completeRetireDocument } = useCompleteRetireDocument(parseInt(submissionId as string), parseInt(documentId!));

  useEffect(() => {
    resetField(RetireContentDetailsFormField.reason, {
      defaultValue: document?.reasonForRetirementId
        ? createSelectOption(document?.reasonForRetirement!, document.reasonForRetirementId)
        : null,
    });
  }, [document, resetField]);

  const handleSave = () => {
    handleSubmit((data) => {
      completeRetireDocument(
        { reason: data.reason! },
        {
          onSuccess: () => onSuccess(),
        },
      );
    })();
  };

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} Details`,
        content: (
          <RetiredDocumentContent
            documentTitle={contentTitle}
            fileName={originalFileName}
            contentType={contentType}
            onFileOpen={onFileDownload}
          />
        ),
      },
    ];

    if (showVersionHistory) {
      items.push({
        label: `Version history`,
        content: <DocumentVersionHistory documentId={document?.documentId ?? 0} showHistoryFiltering={showHistoryFiltering} />,
      });
    }

    if (displayHtmlViewTab)
      items.push({
        label: `View html`,
        content: (
          <HtmlDocument
            documentId={document?.documentId.toString()}
            documentType={contentType}
            version={document.version.toString()}
          />
        ),
      });

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  return (
    <Styled.ContentDetailsWrap>
      <FormProvider {...methods}>
        <SubmissionFlowHeader title={productGroup.name}>
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Document details
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        {renderContent()}
        <SubmissionFlowFooter onContinue={handleSave} continueText="Save changes" onCancel={onCancel} />
      </FormProvider>
    </Styled.ContentDetailsWrap>
  );
};

export default RetiredDocumentDetailsContent;
