import { Sla } from './SlaSection/types';
import { Tutorial } from './types';
import { News } from './NewsSection/types';

const newsItems: Array<News> = [
  {
    date: `23-May-2024`,
    title: `Datapharm Customer Meeting 2024`,
    link: `https://datapharm.com/resource-hub/datapharm-customer-meeting-2024/`,
    decription: `Join us to examine how changes in the regulatory landscape are set to accelerate a digital-first approach, driving positive change for Regulatory Affairs, Med Info and Commercial Pharma.`,
  },
  {
    date: `22-Mar-2024`,
    title: `FHIR 101: Pharma's guide to the emerging standard in healthcare information`,
    link: `https://www.datapharm.com/resource-hub/fhir-101-pharmas-guide-to-the-emerging-standard-in-healthcare-information/`,
    decription: `With FHIR comes the spark to ignite healthcare worldwide. Here's our go-to guide for Pharma on what this new standard is and why it's so important.`,
  },
  {
    date: `04-Mar-2024`,
    title: `How pharma and healthcare will benefit from the adoption of ePI?`,
    link: `https://www.datapharm.com/resource-hub/featured-in-fierce-biotech-how-pharma-and-healthcare-will-benefit-from-the-adoption-of-epi-standards/`,
    decription: `In this article published on Fierce Biotech, Datapharm’s Principal Architect, Philippe Michiels, explains what Pharma and Healthcare can gain from the adoption of ePI, as a common standard in medicines information.`,
  },
];

const oldSlaItems: Array<Sla> = [
  { name: 'Summary of Product Characteristics (SmPC):', value: '5 working days' },
  { name: 'Patient Information Leaflets (PILs):', value: '1 working day' },
  { name: 'Electronic Patient Information Leaflets (ePILs)', value: '10 working days' },
  { name: 'User Manuals:', value: '2 working days' },
  { name: 'Risk Minimisation Materials:', value: '5 working days' },
  { name: 'Safety Alerts:', value: '5 working days' },
  { name: 'Product Information:', value: '5 working days' },
  { name: 'Direct Healthcare Professional Communications (DHPC letters):', value: '5 working days' },
  { name: 'Videos, audios and live chat:', value: 'on a case-by-case basis' },
];

const newSlaItems: Array<Sla> = [
  {
    name: 'Summary of Product Characteristics (SmPC):',
    value: '5 working days',
    deadlines: ['12/12/2023', '15/12/2023'],
  },
  {
    name: 'Patient Information Leaflets (PILs):',
    value: '1 working day',
    deadlines: ['19/12/2023', '27/12/2023'],
  },
  {
    name: 'Electronic Patient Information Leaflets (ePILs)',
    value: '10 working days',
    deadlines: ['05/12/2023', '08/12/2023'],
  },
  { name: 'User Manuals:', value: '2 working days', deadlines: ['18/12/2023', '27/12/2023'] },
  {
    name: 'Risk Minimisation Materials:',
    value: '5 working days',
    deadlines: ['12/12/2023', '15/12/2023'],
  },
  { name: 'Safety Alerts:', value: '5 working days', deadlines: ['12/12/2023', '15/12/2023'] },
  { name: 'Product Information:', value: '5 working days', deadlines: ['12/12/2023', '15/12/2023'] },
  {
    name: 'Direct Healthcare Professional Communications (DHPC letters):',
    value: '5 working days',
    deadlines: ['12/12/2023', '15/12/2023'],
  },
  {
    name: 'Videos, audios and live chat:',
    value: '5 working days',
    deadlines: ['12/12/2023', '15/12/2023'],
  },
];

const tutorialItems: Array<Tutorial> = [
  { name: 'How do I add or update a Product?', link: process.env.REACT_APP_HELP_PRODUCT_URL },
  { name: 'How do I add or update content?', link: process.env.REACT_APP_HELP_CONTENT_URL },
  { name: 'How do I add or update a Product group?', link: process.env.REACT_APP_HELP_PRODUCT_GROUP_URL },
  { name: 'How do I associate content to a Product?', link: process.env.REACT_APP_HELP_ASSOCIATE_CONTENT_URL },
];

export { newsItems, newSlaItems, oldSlaItems, tutorialItems };
