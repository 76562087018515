import { StyledSVG } from '@common/styles';
import * as Styled from './styles';

export interface IPleaseWaitLoader {
  small?: boolean;
}

export const PleaseWaitLoader = ({ small = false }: IPleaseWaitLoader) => (
  <Styled.PleaseWaitLoader small={small}>
    <StyledSVG src={`/icons/spinner.svg`} />
    Please wait a moment
  </Styled.PleaseWaitLoader>
);
