import { useForm } from 'react-hook-form';
import { ProductGroupForm, ProductGroupFormFields } from './types';
import { useCallback, useEffect, useMemo } from 'react';
import { getProductTypeOption } from '@common/helpers';
import { ISubstanceDto } from '@common/features/submission/types';
import { mapSubstancesToActiveIngredients } from '@common/features/productGroup/helpers';

interface IUseProductGroupForm {
  isSameAsSubmitterCompany?: boolean;
  companyName?: string;
  productType?: string | null;
  substances?: ISubstanceDto[];
}

export const useProductGroupForm = ({ isSameAsSubmitterCompany, companyName, productType, substances }: IUseProductGroupForm) => {
  const activeIngredients = useMemo(() => {
    return mapSubstancesToActiveIngredients(substances!);
  }, [substances]);

  const methods = useForm<ProductGroupForm>({
    mode: 'onChange',
    defaultValues: {
      productType: null,
      maHolder: {
        isSameAsSubmitterCompany: null,
        companyName: '',
      },
      activeIngredients: [],
      noActiveIngredientsCheckbox: false,
    },
  });

  const {
    register,
    getValues,
    trigger,
    resetField,
    formState: { errors },
  } = methods;

  const showProductTypeWarning = errors.productType;

  const handleReset = useCallback(
    (currentAsDefault?: boolean) => {
      if (currentAsDefault) {
        resetField(ProductGroupFormFields.productType, { defaultValue: getValues(ProductGroupFormFields.productType) });
        resetField(ProductGroupFormFields.companyName, { defaultValue: getValues(ProductGroupFormFields.companyName) });
        resetField(ProductGroupFormFields.isSameAsSubmitterCompany, {
          defaultValue: getValues(ProductGroupFormFields.isSameAsSubmitterCompany),
        });
        resetField(ProductGroupFormFields.activeIngredients, { defaultValue: activeIngredients ?? [] });
        resetField(ProductGroupFormFields.noActiveIngredientsCheckbox, {
          defaultValue: activeIngredients.length > 0 ? false : true,
        });
      } else {
        resetField(ProductGroupFormFields.companyName);
        resetField(ProductGroupFormFields.isSameAsSubmitterCompany);
        resetField(ProductGroupFormFields.productType);
        resetField(ProductGroupFormFields.activeIngredients);
        resetField(ProductGroupFormFields.noActiveIngredientsCheckbox);
      }
      trigger();
    },
    [activeIngredients, getValues, resetField, trigger],
  );

  register(ProductGroupFormFields.isSameAsSubmitterCompany, {
    validate: (data) => {
      return data === true || getValues(ProductGroupFormFields.companyName) !== '';
    },
  });
  register(ProductGroupFormFields.companyName);
  register(ProductGroupFormFields.activeIngredients);
  register(ProductGroupFormFields.noActiveIngredientsCheckbox);
  register(ProductGroupFormFields.productType, {
    required: 'This field is required',
  });

  useEffect(() => {
    resetField(ProductGroupFormFields.isSameAsSubmitterCompany, {
      defaultValue:
        (isSameAsSubmitterCompany === false && companyName) || isSameAsSubmitterCompany ? isSameAsSubmitterCompany : null,
    });
    resetField(ProductGroupFormFields.companyName, { defaultValue: companyName ?? '' });
    productType && resetField(ProductGroupFormFields.productType, { defaultValue: getProductTypeOption(productType ?? '') });
    resetField(ProductGroupFormFields.activeIngredients, { defaultValue: activeIngredients ?? [] });
    resetField(ProductGroupFormFields.noActiveIngredientsCheckbox, { defaultValue: activeIngredients.length > 0 ? false : true });
    trigger();
  }, [companyName, isSameAsSubmitterCompany, productType, activeIngredients, resetField, trigger]);

  return { methods, ...methods, showProductTypeWarning, handleReset };
};
