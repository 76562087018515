import styled from 'styled-components';

export const TextareaWrap = styled.div`
  display: flex;
  flex-flow: column;
`;

export const TextareaLabel = styled.label`
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.elementFontSizes.label};
`;

export const Textarea = styled.textarea`
  resize: none;
  min-height: 150px;
  padding: 20px;
  border-radius: 14px;
  font-size: ${({ theme }) => theme.elementFontSizes.input};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;
