import { isStringNullOrEmpty } from '@common/helpers';
import { IDocumentAttributes } from '../../helpers';
import { DocumentDetailsReadonlySections } from '../DocumentContentDetails/types';
import { ReadonlyWeblink } from './ReadonlyWeblink';
import { Weblink } from './Weblink';
import * as Styled from './styles';

interface IProps {
  documentAttributes: IDocumentAttributes;
  customerUrl: string | undefined;
  url: string | null;
  fieldName: string;
  documentReadonlySections?: DocumentDetailsReadonlySections;
  onClearInput?: () => void;
}

export const ContentDetailsWeblink = ({
  documentAttributes,
  customerUrl,
  url,
  fieldName,
  documentReadonlySections,
  onClearInput,
}: IProps) => {
  const originalLink = isStringNullOrEmpty(customerUrl) ? url : customerUrl;

  return (
    <Styled.WeblinksWrap>
      {documentAttributes.allowAdminWeblinkUpload ? (
        <>
          <ReadonlyWeblink url={customerUrl!} title="Customer Link" />
          {documentReadonlySections?.adminWeblink ? (
            url && <ReadonlyWeblink url={url!} title="Datapharm Link" />
          ) : (
            <Weblink fieldName={fieldName} onClearInput={onClearInput} label="Datapharm Link" />
          )}
        </>
      ) : documentAttributes.showWebLink ? (
        <ReadonlyWeblink url={originalLink!} />
      ) : null}
    </Styled.WeblinksWrap>
  );
};
