import React from 'react';
import { StyledSVG } from '@common/styles';
import { SubmissionStatus as SubmissionStatusType } from '@common/services/types';

interface IProps {
  value: SubmissionStatusType;
}

const SubmissionStatus = ({ value }: IProps) => {
  const renderStatus = () => {
    switch (value) {
      case SubmissionStatusType.Draft:
        return <StyledSVG height={25} src="/icons/draft.svg" />;
      case SubmissionStatusType.InProgress:
        return <StyledSVG height={25} src="/icons/in_progress.svg" />;
      case SubmissionStatusType.Approved:
        return <StyledSVG height={25} src="/icons/approved.svg" />;
      case SubmissionStatusType.Cancelled:
        return <StyledSVG height={25} src="/icons/cancelled.svg" />;
      case SubmissionStatusType.Published:
        return <StyledSVG height={25} src="/icons/published_tag.svg" />;
      default:
        return <div>{value}</div>;
    }
  };

  return renderStatus();
};

export default SubmissionStatus;
