import { useReinstateProduct } from '@common/hooks/useReinstateProduct';
import { Id } from '@common/types';
import { useState } from 'react';

interface Props {
  onReinstatementSuccess: () => void;
}

export const useReinstatementProductModal = ({ onReinstatementSuccess }: Props) => {
  const [productIdToReinstate, setProductIdToReinstate] = useState<Id | undefined>();
  const { reinstateProduct, isReinstatingProduct } = useReinstateProduct();

  const handleReinstateProductCancel = () => setProductIdToReinstate(undefined);

  const handleReinstateProductConfirm = () =>
    reinstateProduct(
      { productId: productIdToReinstate ?? '' },
      {
        onSuccess: () => {
          setProductIdToReinstate(undefined);
          onReinstatementSuccess();
        },
      },
    );

  const handleReinstatementClick = (productId: Id) => setProductIdToReinstate(productId);

  return {
    handleReinstateProductCancel,
    handleReinstateProductConfirm,
    handleReinstatementClick,
    productIdToReinstate,
    isReinstatingProduct,
  };
};
