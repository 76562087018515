import { createSelector } from '@reduxjs/toolkit';
import { IConfigurationState } from './types';
import { RootState } from '@common/store';

const selectConfigurationState = (state: RootState) => state.configuration;

export const selectFeatureFlags = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.configuration.featureFlags,
);
