import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import * as msal from '@azure/msal-browser';

import { useAppDispatch } from '../hooks/redux';

import { getToken } from '../features/user/userSlice';

import { loginScopes } from './msalConfig';
import { fetchConfiguration } from '../features/configuration/thunk';
import { fetchIdentityUserData, fetchUserData } from '@common/features/user/thunks';

export const useGetToken = () => {
  const { inProgress, instance, accounts } = useMsal();
  const dispatch = useAppDispatch();

  const tokenRequest = {
    scopes: loginScopes,
    account: accounts[0],
  };

  useEffect(() => {
    if (inProgress === msal.InteractionStatus.None && accounts.length) {
      instance
        .acquireTokenSilent(tokenRequest)
        .then((res) => {
          dispatch(getToken(res.accessToken));
          dispatch(fetchUserData());
          dispatch(fetchIdentityUserData());
          dispatch(fetchConfiguration());
        })
        .catch((e) => {
          console.log('error', e);
        });
    }
  }, [inProgress]);

  return null;
};
