import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { validateSubmissionTask } from '@common/services/submissionTaskService';
import { Id } from '@common/types';
import useWaitingMutation from '@hooks/useWaitingMutation';

export const useValidateSubmissionTask = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation((taskId: Id) => validateSubmissionTask(token, taskId));

  return { validateSubmissionTask: mutate };
};
