import { IActiveIngredientOption } from '@common/features/product/types';
import { IProductTypeSelectOption } from '@common/types';

export enum ProductGroupFormFields {
  isSameAsSubmitterCompany = 'maHolder.isSameAsSubmitterCompany',
  companyName = 'maHolder.companyName',
  maHolder = 'maHolder',
  productType = 'productType',
  activeIngredients = 'activeIngredients',
  noActiveIngredientsCheckbox = 'noActiveIngredientsCheckbox',
}

export interface ProductGroupForm {
  productType: IProductTypeSelectOption | null;
  maHolder: {
    isSameAsSubmitterCompany: boolean | null;
    companyName: string;
  };
  activeIngredients: IActiveIngredientOption[];
  noActiveIngredientsCheckbox: boolean;
}
