import React, { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './styles';
import { Input, Select } from '@common/components/';
import { Section } from '@common/components/Section/Section';
import { productTypeSelectOptions } from '@common/constants';
import { FormErrors, FormFields } from '../../pages/CreateSubmission/types';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { StyledSVG } from '@common/styles';

export const BrandOrGenericName = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleGenericNameClear = () => setValue(FormFields.genericName, '');
  const brandOrGenericNameError = errors[FormFields.genericName]?.message?.toString();

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => setValue(FormFields.genericName, e.target?.value.trim());

  return (
    <Section text="Create brand or generic name" icon={<StyledSVG src={`/icons/info_circle.svg`} />}>
      <Styled.BrandOrGenericNameInputWrapper>
        <Controller
          control={control}
          name={FormFields.productType}
          render={({ field: { value, onChange } }) => (
            <Select label="Product type" name="test" value={value} onChange={onChange} options={productTypeSelectOptions} />
          )}
        />
        <Styled.BrandOrGenericNameInput>
          <Controller
            control={control}
            name={FormFields.genericName}
            rules={{ required: FormErrors.genericName }}
            render={({ field: { value, onChange } }) => (
              <Input
                label="Brand or generic name*"
                name="Generic name"
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onClearInput={handleGenericNameClear}
              />
            )}
          />
        </Styled.BrandOrGenericNameInput>
      </Styled.BrandOrGenericNameInputWrapper>
      {brandOrGenericNameError && <ErrorMessage>{brandOrGenericNameError}</ErrorMessage>}
    </Section>
  );
};
