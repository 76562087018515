import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { cancelSubmission } from '@common/services/submissionService';

export const useCancelSubmission = () => {
  const token = useAppSelector(selectToken);

  const { mutate, isLoading } = useWaitingMutation((submissionId: number) => cancelSubmission(token, submissionId, {}));

  return { cancelSubmission: mutate, isCancellingSubmission: isLoading };
};
