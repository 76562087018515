import styled, { css } from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { StyledSVG } from '@components/Header/styles';

export const ProductGroupHistoryListWrapper = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.elements.textLightGrey};
`;

export const SubmissionInfoWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.elements.textLightGrey};
`;

export const Info = styled.div``;

export const ProductGroupHistoryListHeader = styled.div`
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  text-align: end;
`;

export const ProductGroupHistoryListName = styled.div``;

export const ArrowIcon = styled(IoIosArrowDown).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{ isActive: boolean }>`
  color: ${({ theme }) => theme.elements.textGrey};
  margin-top: 3px;
  transition: all 0.2s ease-out;
  margin-left: 3px;
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ProductGroupHistoryPopoverWrapper = styled.div`
  width: 300px;
  position: relative;
  cursor: pointer;
`;

export const PopoverArrowWrapper = styled.div`
  position: absolute;
  top: -9px;
  right: 45px;
  border-left: 10.5px solid transparent;
  border-right: 10.5px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.borderGray};
  width: 0;
  height: 0;
`;

export const PopoverArrow = styled.div`
  position: absolute;
  left: -10px;
  top: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${({ theme }) => theme.colors.defaultWhite};
  width: 0;
  height: 0;
`;

export const PopoverContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  margin-right: 30px;
  margin-top: 15px;
`;

export const PopoverItem = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  z-index: 1;
  padding: 10px;
  &:last-child {
    border-bottom: none;
  }
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
  }
`;

export const StyledTickIcon = styled(StyledSVG)`
  div {
    svg {
      path {
        fill: ${({ theme }) => theme.elements.primaryMedium};
      }
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin-right: 0;
`;

export const ProductGroupHistoryItem = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductGroupHistoryItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
`;
