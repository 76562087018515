import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { ISubmissionDto } from '@common/features/submission/types';
import { IPublicProductGroupState } from '@common/features/productGroup/types';
import ReadOnlyProductInfo from './tabs/ProductInfo/ReadOnlyProductInfo';
import { AssociatedContent } from './tabs/AssociatedContent';
import { ProductDetails } from '@common/components/ProductDetails/ProductDetails';
import { ProductHistory } from './tabs/ProductHistory';
import * as Styled from './tabs/ProductInfo/styles';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import Tabs from '@common/components/Tabs/Tabs';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { getProductType, getSingleProduct } from './helpers';
import { useProductForm } from './hooks/useProductForm';
import { AssociatedContentReadonlySections } from './types';

const tabsLabelsWrapperStyle: React.CSSProperties = {
  paddingLeft: '40px',
};
const disabledAssociatedContentSections: AssociatedContentReadonlySections = {
  pilSmpcSelection: true,
  secondaryDocumentsSelection: true,
  editDocumentName: true,
};

interface IReadonlyProductContent {
  dataSource: ISubmissionDto | IPublicProductGroupState;
  productId: string;
  onCancel: () => void;
  shouldDisplayAmpId: boolean;
  shouldDisplayContactGroup: boolean;
}

export const ReadonlyProductContent = ({
  dataSource,
  productId,
  onCancel,
  shouldDisplayAmpId,
  shouldDisplayContactGroup,
}: IReadonlyProductContent) => {
  const { showProductHistory } = useFeatureFlags();

  const product = getSingleProduct(productId, dataSource?.products!);

  const activeIngredients = useMemo(() => product?.activeIngredients ?? [], [product?.activeIngredients]);

  const productGroupName = dataSource?.productGroup.productGroupName ?? '';

  const productType = getProductType(dataSource?.productGroup.productType);

  const documents = dataSource?.documents ?? [];

  const { productFormMethods, draftOrPublishedDocuments } = useProductForm({
    productGroupName,
    product,
    documents,
    activeIngredients,
  });

  const getItems = useCallback(() => {
    const items = [
      {
        label: 'Product info',
        content: (
          <ReadOnlyProductInfo
            product={product!}
            productType={productType}
            activeIngredients={activeIngredients}
            productGroupName={productGroupName}
          />
        ),
      },
      {
        label: 'Associated content',
        content: (
          <AssociatedContent
            showMissingAssociationWarning={false}
            showRetiredProductWarning={false}
            documents={draftOrPublishedDocuments}
            disabledSections={disabledAssociatedContentSections}
            onSelect={() => {}}
          />
        ),
      },
      {
        label: 'Product details',
        content: (
          <ProductDetails
            shouldDisplayContactGroup={shouldDisplayContactGroup}
            readonly={true}
            shouldDisplayAmpId={shouldDisplayAmpId}
          />
        ),
      },
    ];

    if (showProductHistory) items.push({ label: 'Product history', content: <ProductHistory /> });

    return items;
  }, [
    activeIngredients,
    draftOrPublishedDocuments,
    product,
    productGroupName,
    productType,
    shouldDisplayAmpId,
    shouldDisplayContactGroup,
    showProductHistory,
  ]);

  return (
    <>
      <Styled.LayoutWrapper>
        <SubmissionFlowHeader title={product?.name}>
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Product
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        <Styled.ProductWrap>
          <FormProvider {...productFormMethods}>
            <Tabs
              items={getItems()}
              styles={{
                tabsLabelsWrapper: tabsLabelsWrapperStyle,
              }}
            />
          </FormProvider>
        </Styled.ProductWrap>
        <SubmissionFlowFooter onCancel={onCancel} />
      </Styled.LayoutWrapper>
    </>
  );
};
