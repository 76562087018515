import { checkDigitalVersion } from '../document/helpers';
import { IActiveIngredientOption } from '../product/types';
import { ISubstanceDto } from '../submission/types';
import { IPublicProductGroupState } from './types';

export const mapPublicProductGroupState = (data: IPublicProductGroupState): IPublicProductGroupState => {
  data.products = data.products.map((p) => ({
    ...p,
    lastChangedDate: new Date(p.lastChangedDate),
  }));

  data.documents = data.documents.map((d) => ({
    ...d,
    authorisedDate: d.authorisedDate ? new Date(d.authorisedDate) : null,
    regulatorApprovalDate: d.regulatorApprovalDate ? new Date(d.regulatorApprovalDate) : undefined,
    hasDigitalVersion: checkDigitalVersion(d, data.documents),
  }));

  return data;
};

export const mapSubstancesToActiveIngredients = (substances: ISubstanceDto[]): IActiveIngredientOption[] => {
  return substances.map((x) => ({ isListed: x.id !== '0', label: x.name, value: x.id }));
};
