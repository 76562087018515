import styled, { css } from 'styled-components';
import { ISubmissionTypeWrapProps } from './types';

const activeStyles = css`
  background-color: ${({ theme }) => theme.elements.submissionTypeBackground};
  border: 1px solid ${({ theme }) => theme.elements.primaryMedium};
  pointer-events: none;
`;

const inactiveStyles = css`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  cursor: pointer;
`;

export const SubmissionTypeWrap = styled.div<ISubmissionTypeWrapProps>`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  border-radius: 10px;
  ${({ isActive }) => (isActive ? activeStyles : inactiveStyles)}
`;

export const SubmissionTypeTextWrap = styled.div`
  display: flex;
  flex-flow: column;
`;

export const SubmissionTypeText = styled.span<ISubmissionTypeWrapProps>`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  color: ${({ theme, isActive }) => (isActive ? theme.elements.textGrey : theme.colors.darkGrey)};
`;

export const SubmissionTypeSubtext = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const SubmissionTypeRadioWrap = styled.div`
  margin-left: auto;
`;
