import React, { useEffect, useMemo } from 'react';
import { ModalTitle } from '@common/features/productGroup/styles';
import { Button, ButtonTypes } from '@common/components';
import { ModalWrapper } from '@common/components/ModalWrapper/ModalWrapper';
import * as Styled from './styles';
import Divider from '@common/components/Divider/Divider';
import { FormInput } from '@common/components/FormInput/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { HookFormSelect } from '@common/components/Select/HookFormSelect';
import { AddProductFormErrors, AddProductFormFields, IAddProductForm } from './types';
import { getDefaultForm } from './helpers';
import { productTypeSelectOptions } from '@common/constants';
import { extraInformationValidation } from './constants';
import { IProductGroupData } from '@common/features/productGroup/slices/productGroup/types';
import { generateFormProductName } from '@common/helpers';
import { ISubstanceDto } from '@common/features/submission/types';
import { useFetchProductForms } from '@hooks/useFetchProductForms';
import { prepareActiveIngredientsForForm } from '@common/features/product/helpers';
import { ActiveIngredientsStrengthPicker } from '@common/components/ActiveIngredientsStrength/ActiveIngredientsStrengthPicker';

interface IAddProductPopup {
  isOpen: boolean;
  productGroup: IProductGroupData;
  substances: ISubstanceDto[];
  isAddingProduct: boolean;
  handleClose: () => void;
  handleCreate: (form: IAddProductForm, generatedProductName: string) => void;
}

const AddProductPopup = ({ isOpen, productGroup, substances, isAddingProduct, handleClose, handleCreate }: IAddProductPopup) => {
  const { productForms, isFetching: areProductFormsFetching } = useFetchProductForms();

  const activeIngredients = useMemo(
    () =>
      substances.map((s) => ({
        id: s.id,
        name: s.name,
        denominatorUnit: '',
        denominatorValue: '',
        numeratorUnit: '',
        numeratorValue: '',
      })),
    [substances],
  );
  const mappedActiveIngredients = useMemo(() => prepareActiveIngredientsForForm(activeIngredients), [activeIngredients]);

  const form = useForm<any>({
    mode: 'onSubmit',
    defaultValues: getDefaultForm(mappedActiveIngredients),
  });

  const {
    reset,
    setValue,
    formState: { errors, dirtyFields },
    watch,
    trigger,
    handleSubmit,
  } = form;

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
    trigger();
  }, [isOpen, reset, trigger]);

  const activeIngredientsStrength = watch(AddProductFormFields.activeIngredientsStrength);
  const productForm = watch(AddProductFormFields.productForm);
  const extraInformation = watch(AddProductFormFields.extraInformation);

  const extraInformationError = errors?.[AddProductFormFields.extraInformation]?.message?.toString();
  const emcProductNameError = errors?.[AddProductFormFields.emcProductName]?.message?.toString();
  const medicinalProductNameError = errors?.[AddProductFormFields.medicinalProductName]?.message?.toString();

  const generatedName = generateFormProductName(
    productGroup?.name,
    activeIngredientsStrength.ingredientsWithNumerators,
    activeIngredientsStrength.denominator,
    productForm?.label,
    extraInformation,
  );

  useEffect(() => {
    if (!dirtyFields[AddProductFormFields.emcProductName]) {
      setValue(AddProductFormFields.emcProductName, generatedName, { shouldDirty: false });
    }
    if (!dirtyFields[AddProductFormFields.medicinalProductName]) {
      setValue(AddProductFormFields.medicinalProductName, generatedName, { shouldDirty: false });
    }
  }, [productGroup, setValue, dirtyFields, generatedName]);

  const close = () => {
    reset();
    handleClose();
  };

  const create = () => {
    trigger();
    handleSubmit(async (formData) => {
      handleCreate(formData, generatedName);
    })();
  };

  const productTypeLabel = productTypeSelectOptions.find((o) => o.value === productGroup.productType)?.label;

  return (
    <ModalWrapper isOpen={isOpen} label="Add Product">
      <FormProvider {...form}>
        <Styled.ModalContent>
          <ModalTitle>Add product</ModalTitle>

          <Styled.FormRow>
            <FormInput label="Product type" name="Product type" value={productTypeLabel} />

            <FormInput label="Brand/generic name" name="Generic name" value={productGroup.name} />
          </Styled.FormRow>

          {substances.length > 0 ? (
            <>
              <Divider type="horizontal" size="100%" thickness={1} />

              <ActiveIngredientsStrengthPicker activeIngredients={activeIngredients} fieldName="activeIngredientsStrength" />

              <Divider type="horizontal" size="100%" thickness={1} />
            </>
          ) : (
            <Divider type="horizontal" size="100%" margin={30} thickness={1} />
          )}

          <Styled.FormRow>
            <HookFormSelect
              name={AddProductFormFields.productForm}
              label="Product form"
              isClearable={true}
              options={productForms}
              isLoading={areProductFormsFetching}
              allowCustomOption
            />

            <div>
              <HookFormInput
                name={AddProductFormFields.extraInformation}
                label="Extra information"
                placeholder="Eg. POM, Flavour, Colour"
                rules={extraInformationValidation}
                error={extraInformationError}
              />
            </div>
          </Styled.FormRow>

          <Styled.ProductName>
            <HookFormInput
              name={AddProductFormFields.emcProductName}
              label="emc Display Name"
              placeholder="Display name"
              rules={{ required: AddProductFormErrors.requiredField }}
              error={emcProductNameError}
            />
          </Styled.ProductName>

          <Styled.ProductName>
            <HookFormInput
              name={AddProductFormFields.medicinalProductName}
              label="Medicinal Product Name"
              placeholder="Medicinal name"
              rules={{ required: AddProductFormErrors.requiredField }}
              error={medicinalProductNameError}
            />
          </Styled.ProductName>

          <Styled.ModalButtons>
            <Button onClick={close} text="Cancel" type={ButtonTypes.TEXT} />
            <Divider type="vertical" size="50px" thickness={1} />
            <Button onClick={create} text="Create" type={ButtonTypes.PRIMARY} isLoading={isAddingProduct} />
          </Styled.ModalButtons>
        </Styled.ModalContent>
      </FormProvider>
    </ModalWrapper>
  );
};

export default AddProductPopup;
