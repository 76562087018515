export enum ProductDetailsFormErrors {
  maNumbers = 'Please complete the following section',
  maNumbersAlreadyAdded = 'Same MA Numbers already added',
  maNumbersInvalidFormat = 'Invalid format of MA Number',
  atcCodes = 'Please complete the following section',
  atcCodesInvalidFormat = 'Invalid format of ATC code',
  atcCodeAlreadyAdded = 'Same ATC code already added',
  ampNumberTooShort = 'Amp number is too short',
  ampNumberTooLong = 'Amp number is too long',
  ampNumberIsNegative = 'Amp number needs to be positive number',
}
