import { UseFormReturn } from 'react-hook-form';
import { useUploadFile } from '@hooks/useUploadFile';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentType } from '@common/types';
import { getIsFileValid } from '../helpers';
import { FileRoles } from '../types';

interface IProps {
  contentType: DocumentType;
  methods: UseFormReturn<any>;
  fileFormField: string;
  fileNameFormField: string;
  fileRole?: FileRoles;
}

export const useInlineFileUpload = ({
  contentType,
  methods: { setValue },
  fileFormField,
  fileNameFormField,
  fileRole,
}: IProps) => {
  const { deleteFile } = useDeleteFile();

  const shouldHandleWebLink = contentType === DocumentType.Rmm;

  const handleRemove = () => {
    setValue(fileFormField, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file, fileRole);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(fileFormField, file);
        uploadFile(
          { file, documentType: contentType, fileRole },
          {
            onSuccess: (data) => {
              setValue(fileNameFormField, data);
            },
          },
        );
      }
    }
  };

  const clearErrors = () => setFileErrors([]);

  return {
    handleFileSubmit,
    handleRemove,
    clearErrors,
    fileErrors,
    isLoading,
    uploadProgress,
    shouldHandleWebLink,
  };
};
