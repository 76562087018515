import { StyledButton } from '@common/components/Button/styles';
import { Table } from '@common/components/DatapharmTable/styles';
import { StyledSVG } from '@common/styles';
import styled from 'styled-components';

export const ProductsWrap = styled.div`
  padding: 20px 40px;
  width: 100%;

  ${Table} {
    flex: 1;
    width: 100%;
  }
`;

export const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  ${StyledButton} {
    ${StyledSVG} {
      pointer-events: none;
    }
  }
`;

export const ResultsCountText = styled.span`
  color: ${({ theme }) => theme.elements.linkBlue};
  margin-bottom: 10px;
  font-weight: bold;
`;

export const LoadMoreButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
