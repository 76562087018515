import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { replaceAltText } from '@common/services/requestService';

export interface IPayloadAltText {
  submissionId: string;
  documentId: string;
  fileName: string;
}


export const useReplaceAltText = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation(
    (payload: IPayloadAltText) => {
      return replaceAltText(token, payload.submissionId!, payload.documentId!, { AlternativeTextFileName: payload.fileName });
    },
  );

  return { replaceAltText: mutate };
};
