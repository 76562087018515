import { FiltersItem } from './FiltersItem';
import { DatePickerWrapper } from '../DatePickerWrapper/DatePickerWrapper';

interface IProps {
  label: string;
  fromValue: Date | null | undefined;
  toValue: Date | null | undefined;
  onFromChange: (...event: any[]) => void;
  onToChange: (...event: any[]) => void;
  onClear: () => void;
}

export const DateFilter = ({ label, fromValue, toValue, onFromChange, onToChange, onClear }: IProps) => (
  <FiltersItem label={label} onClear={onClear} appliedFiltersCount={[fromValue, toValue].filter((x) => x).length}>
    <DatePickerWrapper
      label="From"
      maxDate={new Date()}
      name={`${label} ${fromValue}`}
      onChange={onFromChange}
      value={fromValue}
    />
    <DatePickerWrapper label="To" maxDate={new Date()} name={`${label} ${toValue}`} onChange={onToChange} value={toValue} />
  </FiltersItem>
);
