import { KeyboardEventHandler } from 'react';
import { default as ReactSelect, InputActionMeta, components, StylesConfig } from 'react-select';
import { ISelectOption, INewProductSelectOption } from '@common/types';
import * as Styled from './styles';
import { Checkbox } from '../Checkbox/Checkbox';
import { getCommonStyles, getEnabledValuesAndOptions, getCommonProps } from './helpers';

export type ISingleSelectOption = ISelectOption | INewProductSelectOption;
export type ISelectOptions = ISingleSelectOption[];

interface ISelectAll {
  label?: string;
  name: string;
  value: ISingleSelectOption;
  options?: ISelectOptions;
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  className?: string;
  noOptionsMessage?: string;
  inlineLabel?: boolean;
  width?: string;
  onChange?: (e: any) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onEnterDown?: () => void;
  onSelectAllClick?: () => void;
}

const selectAllValue = 'selectAll';

const { Option, ValueContainer } = components;

const CustomValueContainer = (props: any) => (
  <ValueContainer {...props}>
    {props.children[1]}
    {props.children[0]}
  </ValueContainer>
);

const CustomMultiValueContainer = ({ data, selectProps }: any) => {
  const values = selectProps.value;
  const filteredValues = values.filter((v: any) => v.value !== selectAllValue);

  if (data.value === selectAllValue) return '';

  return filteredValues[filteredValues.length - 1].label === data.label ? data.label : data.label + ', ';
};

export const SelectAll = ({
  label,
  name,
  options = [],
  id,
  value,
  disabled,
  readonly,
  isLoading,
  placeholder,
  className,
  noOptionsMessage = '',
  inlineLabel,
  width,
  onChange,
  onInputChange = () => {},
  onEnterDown = () => {},
  onSelectAllClick = () => {},
}: ISelectAll) => {
  const optionsWithSelectAll = [{ label: 'Select All', value: selectAllValue }, ...options];

  const IconOption = ({ innerProps, ...props }: any) => {
    let isOptionSelected = props.isSelected;
    const isSelectAll = props.data.value === selectAllValue;

    if (Array.isArray(value)) {
      const { areAllEnabledOptionsSelected } = getEnabledValuesAndOptions(value, options);
      isOptionSelected = isSelectAll ? areAllEnabledOptionsSelected : props.isSelected;
    }

    return (
      <Option
        {...props}
        innerProps={{ ...innerProps, onClick: isSelectAll ? onSelectAllClick : innerProps.onClick }}
        isSelected={isOptionSelected}
      >
        {isSelectAll && <div style={{ width: '60%' }} />}
        {props.data.label}
        <Checkbox isChecked={isOptionSelected} name={''} disabled={props.isDisabled} />
      </Option>
    );
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onEnterDown();
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  return (
    <Styled.Select data-testid="select-wrapper" className={className} inlineLabel={inlineLabel}>
      {label && <Styled.StyledLabel htmlFor={id}>{label}</Styled.StyledLabel>}
      <ReactSelect
        {...getCommonProps(noOptionsMessage)}
        isOptionDisabled={(o) => o.disabled ?? false}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        options={optionsWithSelectAll}
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        isDisabled={disabled || readonly}
        isLoading={isLoading}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
          MultiValueContainer: CustomMultiValueContainer,
          MultiValueRemove: () => null,
          ClearIndicator: () => null,
          ValueContainer: CustomValueContainer,
        }}
        styles={
          {
            ...getCommonStyles(readonly, width),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              float: 'left',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flexWrap: 'nowrap',
              display: 'initial',
            }),
          } as StylesConfig<ISelectOptions>
        }
      />
    </Styled.Select>
  );
};
