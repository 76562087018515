import { SubmissionTaskDetailsUnitWrapper } from '@common/features/submission/SubmissionTaskDetails/styles';
import styled from 'styled-components';

export const ProductManagementWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled.span`
  color: ${({ theme }) => theme.elements.linkBlue};
`;

export const DetailsWithButton = styled.div`
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: auto;
  margin: 28px 25px 30px 28px;

  ${SubmissionTaskDetailsUnitWrapper} {
    margin: 0;
    border: none;
  }
`;

export const DetailsButtonWrapper = styled.div`
  margin: 15px 25px 0;
  display: flex;
  justify-content: flex-end;
`;
