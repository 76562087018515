import { useForm } from 'react-hook-form';
import { productTypeSelectOptions } from '@common/constants';
import { ISubmissionForm } from '../../types';

export enum FormFields {
  submissionType = 'submissionType',
  productType = 'productType',
  genericName = 'genericName',
  activeIngredients = 'activeIngredients',
  noActiveIngredientsCheckbox = 'noActiveIngredientsCheckbox',
  search = 'search',
  selectedProductGroup = 'selectedProductGroup',
}

export enum FormErrors {
  submissionType = 'Please select submission type',
  genericName = 'Please complete Brand/Generic Name',
  Details = 'Max. length: 500 characters',
  searchTooShort = 'Please enter at least 3 characters',
}

export const usePrepareForm = () => {
  const methods = useForm<ISubmissionForm>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.productType]: {
        value: productTypeSelectOptions[0].value,
        label: productTypeSelectOptions[0].label,
      },
      [FormFields.genericName]: '',
      [FormFields.activeIngredients]: [],
      [FormFields.noActiveIngredientsCheckbox]: false,
      [FormFields.search]: '',
      [FormFields.selectedProductGroup]: undefined,
    },
  });

  return { methods, ...methods };
};
