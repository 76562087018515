import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const TitleIcon = styled(ReactSVG)`
  svg {
    width: 70px;
    height: auto;
  }

  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  white-space: pre;
  margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-bottom: 3rem;
  white-space: pre;
`;
