import { selectActiveCompany, selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { TaskTypes } from '@common/types';
import { useQueries } from '@tanstack/react-query';
import { ITasksDto } from 'apps/publisher-v3.ui.customer/src/features/submission/types';
import { fetchTasks } from 'apps/publisher-v3.ui.customer/src/services/submissionTaskService';

export const useFetchTasksForMultipleSubmissions = (submissionIdsToFetchTasks: number[]) => {
  const token = useAppSelector(selectToken);
  const company = useAppSelector(selectActiveCompany);

  return useQueries<ITasksDto[]>({
    queries: submissionIdsToFetchTasks!.map((id) => {
      return {
        queryKey: ['fetchDocuments', TaskTypes.All, id],
        queryFn: () => fetchTasks(token, TaskTypes.All, undefined, undefined, id.toString(), company?.id!),
      };
    }),
  });
};
