import { createSlice } from '@reduxjs/toolkit';
import { IGlobalState } from './types';
import { createSubmissionDraft, generateProductGroupPlan } from 'apps/publisher-v3.ui.customer/src/features/submission/thunks';

export const initialState: IGlobalState = {
  showPleaseWaitOverlay: false,
  requestsPending: 0,
  thirdPartyLink: undefined,
};

const setShowPleaseWaitLoaderFn = (state: any, action: any) => {
  let newRequestsPending = action.payload ? state.requestsPending + 1 : state.requestsPending - 1;
  if (newRequestsPending < 0) newRequestsPending = 0;
  state.showPleaseWaitOverlay = newRequestsPending > 0;
  state.requestsPending = newRequestsPending;
};

const globalSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setShowPleaseWaitLoader(state, action) {
      setShowPleaseWaitLoaderFn(state, action);
    },
    setThirdPartyLink(state, action) {
      state.thirdPartyLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateProductGroupPlan.pending, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: true });
    });
    builder.addCase(generateProductGroupPlan.fulfilled, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: false });
    });
    builder.addCase(generateProductGroupPlan.rejected, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: false });
    });
    builder.addCase(createSubmissionDraft.pending, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: true });
    });
    builder.addCase(createSubmissionDraft.fulfilled, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: false });
    });
    builder.addCase(createSubmissionDraft.rejected, (state) => {
      setShowPleaseWaitLoaderFn(state, { payload: false });
    });
  },
});

export const { setShowPleaseWaitLoader, setThirdPartyLink } = globalSlice.actions;

export default globalSlice.reducer;
